import { useMemo, useState } from "react";
import SideAreaFooter from "../../../containers/SideAreaFooter";
import { areArrayItemsTheSame } from "../../../helpers/helpers";
import RoleEditorTemplate from "../../Roles/components/templates/RoleEditorTemplate";
import { useEditedRole } from "../../Roles/hooks/useEditedRole";
import { useRole } from "../../Roles/hooks/useRole";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import { useRolesApi } from "../../Roles/hooks/useRolesApi";
import { useTalentBoardsApi } from "../../TalentBoards/hooks/useTalentBoardsApi";

const RoleEditor = ({ roleId, fullRole, isSelf, isAdmin, onClose }) => {
    const { updateRole } = useRolesApi();
    const { updateRoleTalentBoards } = useTalentBoardsApi();
    const { selectedRole, autoBoards, manualBoards, checkIfNewDepartment } = useRole(roleId);
    const [editedRole, updateField, roleHasChanged, setRole] = useEditedRole(fullRole);
    const { getAllDepartmentsAbove } = useRoleRelationships();
    const [editedLinkedBoardIds, setEditedLinkedBoardIds] = useState(manualBoards);

    const parentDepartments = useMemo(() => {
        if (!roleId) return [];
        return getAllDepartmentsAbove(roleId);
    }, [roleId, getAllDepartmentsAbove]);

    const boardsHaveChanged = useMemo(() => {
        return !areArrayItemsTheSame(manualBoards, editedLinkedBoardIds);
    }, [manualBoards, editedLinkedBoardIds]);

    const hasEdits = useMemo(() => {
        const allThingsThatCouldChange = [roleHasChanged, boardsHaveChanged];
        return allThingsThatCouldChange.some((thingThatCouldChange) => thingThatCouldChange);
    }, [roleHasChanged, boardsHaveChanged]);

    const handleDiscard = () => {
        setRole(selectedRole);
        setEditedLinkedBoardIds(manualBoards);
        onClose();
    };

    const handleChangeBoardIds = (boardIds) => {
        setEditedLinkedBoardIds(boardIds);
    };

    const handleSubmit = async () => {
        if (!hasEdits) return;

        let batch;

        // Save role changes
        if (roleHasChanged) {
            const hasNewDepartment = checkIfNewDepartment();
            let roleUpdate = { ...editedRole };
            if (hasNewDepartment) {
                roleUpdate.department = editedRole.department;
            }
            batch = updateRole(roleId, roleUpdate, batch);
        }

        // Save board changes
        if (boardsHaveChanged) {
            batch = updateRoleTalentBoards(roleId, manualBoards, editedLinkedBoardIds, batch);
        }
        try {
            batch.commit();
            onClose();
        } catch (error) {
            console.error(error);
        } finally {
            return true;
        }
    };

    return (
        <div className="flex flex-col justify-between divide-y h-screen min-w-xl">
            <div className="p-4 bg-base-150">
                <h2>{`Edit ${editedRole?.jobTitle}`}</h2>
            </div>
            <div className="p-4 flex-1 overflow-auto space-y-4">
                <RoleEditorTemplate
                    isAdmin={isAdmin}
                    isSelf={isSelf}
                    roleId={roleId}
                    editedRole={editedRole}
                    parentDepartments={parentDepartments}
                    manualBoards={editedLinkedBoardIds}
                    autoBoards={autoBoards}
                    updateField={updateField}
                    changeBoards={handleChangeBoardIds}
                />
            </div>
            <SideAreaFooter hasChanges={hasEdits} onSubmit={handleSubmit} onDiscard={handleDiscard} />
        </div>
    );
};

export default RoleEditor;
