import classNames from "classnames";

const Breadcrumbs = ({
    size = "sm",
    items = [],
    onItemClick = () => {},
    className,
    itemClassName = "cursor-pointer font-semibold uppercase tracking-wide",
}) => {
    return (
        <div className={classNames("breadcrumbs", `text-${size}`, className)}>
            <ul>
                {items.map((item) => (
                    <li
                        key={item.id}
                        className={classNames(itemClassName)}
                        onClick={() => {
                            if (item.onClick) {
                                item.onClick(item);
                            } else {
                                onItemClick(item);
                            }
                        }}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Breadcrumbs;
