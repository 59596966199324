import { useMemo } from "react";
import { createBulkEmail } from "../../../common/utils/createBulkEmail";

const BULK_ACTIONS = [
    {
        id: "managers",
        groupId: "email",
        label: "Email Employees",
    },
    {
        id: "employees",
        groupId: "email",
        label: "Email Managers",
    },
    {
        id: "everyone",
        groupId: "email",
        label: "Email Managers & Employees",
    },
    {
        id: "incomplete",
        groupId: "email",
        label: "Email Incomplete",
    },
];

export function useBulkTrackingActions(reviews, excludedActions, checkedIds, onMarkEmployeesDone) {
    return useMemo(() => {
        const handleCreateBulkEmail = (whoToEmail) => {
            const reviewRows = reviews.filter((review) => checkedIds.includes(review.id));
            createBulkEmail(whoToEmail, reviewRows);
        };

        const emailActions = BULK_ACTIONS.map((action) => {
            return {
                ...action,
                onClick: () => handleCreateBulkEmail(action.id),
            };
        });

        const trackingActions = [
            {
                id: "markDone",
                label: "Mark As Done",
                groupId: "tracking",
                onClick: () => onMarkEmployeesDone(checkedIds),
            },
        ];

        return [...emailActions, ...trackingActions].filter((action) => !excludedActions.includes(action.id));
    }, [reviews, excludedActions, checkedIds, onMarkEmployeesDone]);
}
