import { debounce } from "lodash";
import applogger from "../../../common/utils/applogger";
import { setEmployees, setParentMap, setRoles } from "../../BusinessUnits/utils/businessUnitSlice";
import { applyBoardMap } from "../../BusinessUnits/utils/businessUnitThunks";
import { cleanEmployeeSnapshots } from "../../Snapshots/utils/snapshotsSlice";
import { prepEmployeeSnapshotsThunk } from "../../Snapshots/utils/snapshotThunks";
import { linkTraits } from "../utils/linkTraits";
import { setTalentData, setWorkspaceInitialised } from "../utils/workspaceSlice";

let waitingForDispatch = {};

const prepEmployees = (dispatch) => {
    const { employees, liveEmployeeIds } = waitingForDispatch;
    dispatch(setEmployees({ employees, liveEmployeeIds }));
    waitingForDispatch.employees = null;
    waitingForDispatch.liveEmployeeIds = null;
};

const prepRoles = (dispatch) => {
    const { roles, liveRoleIds } = waitingForDispatch;
    dispatch(setRoles({ roles, liveRoleIds }));
    waitingForDispatch.roles = null;
    waitingForDispatch.liveRoleIds = null;
};

const prepTalent = (dispatch) => {
    return new Promise((resolve) => {
        const { talent } = waitingForDispatch;
        if (talent) {
            const linkedTalent = linkTraits(talent);
            dispatch(setTalentData(linkedTalent));
            waitingForDispatch.talent = null;
        }
        resolve(); // Mark as completed
    });
};

const prepParentMap = (dispatch) => {
    return new Promise((resolve) => {
        const { parentMap } = waitingForDispatch;
        if (parentMap) {
            dispatch(setParentMap(parentMap));
            waitingForDispatch.parentMap = null;
        }
        resolve(); // Mark as completed
    });
};

const prepBoardMap = (dispatch) => {
    return new Promise((resolve) => {
        const { boardMap, parentMap } = waitingForDispatch;
        if (boardMap) {
            dispatch(applyBoardMap(boardMap, parentMap));
            waitingForDispatch.boardMap = null;
        }
        resolve(); // Mark as completed
    });
};

const prepSnapshots = (dispatch) => {
    return new Promise((resolve) => {
        const { snapshots, liveSnapshotIds } = waitingForDispatch;
        if (liveSnapshotIds) {
            // Remove any snapshots that are no longer live
            dispatch(cleanEmployeeSnapshots(liveSnapshotIds));
            waitingForDispatch.liveSnapshotIds = null;
        }
        if (snapshots) {
            dispatch(prepEmployeeSnapshotsThunk(snapshots));
            waitingForDispatch.snapshots = null;
        }
        resolve(); // Mark as completed
    });
};

// Updated prepWorkspace that ensures prepSnapshots runs last
const prepWorkspace = async (dispatch) => {
    applogger.info("WORKSPACE IS BEING PREPPED");

    // Add the employee role and ralent data to the store
    await prepTalent(dispatch);

    // Map the parentRoleIds to the roles
    await prepParentMap(dispatch);

    // Apply the boardMap to the employees
    await prepBoardMap(dispatch);

    // Prep the employee snapshots once the above is finished
    await prepSnapshots(dispatch);

    dispatch(setWorkspaceInitialised());
};
const debouncedPrepWorkspace = debounce(prepWorkspace, 300);

const setEntireWorkspaceThunk = (data) => async (dispatch, getState) => {
    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            waitingForDispatch[key] = value;
        }
    });

    // Employees and roles should be updated immediately as they aren't dependent on other data
    const { employees, roles } = waitingForDispatch;
    if (employees) {
        prepEmployees(dispatch);
    }
    if (roles) {
        prepRoles(dispatch);
    }

    // Prep the only workspace if all the data has been received
    const dataRequired = ["talent", "parentMap", "boardMap", "snapshots", "employees", "roles"];
    const allReceived = dataRequired.every((key) => key in waitingForDispatch);
    if (allReceived) {
        // Key data has been received, prep the workspace
        debouncedPrepWorkspace(dispatch);
    }
};

export default setEntireWorkspaceThunk;
