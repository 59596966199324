import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const MySwitch = ({
    onLabel = "on",
    offLabel = "off",
    isChecked = false,
    disabled,
    checkFirst,
    neutralColor,
    onChange = () => {},
}) => {
    const label = isChecked ? onLabel : offLabel;

    const handleToggle = () => {
        if (disabled) return;
        onChange(!isChecked);
    };

    return (
        <div className="form-control">
            <label className={classNames("label cursor-pointer space-x-2 flex", { "flex-row-reverse": !checkFirst })}>
                <input
                    type="checkbox"
                    className={classNames("toggle", {
                        "toggle-success": isChecked && !neutralColor,
                        "ms-2": !checkFirst,
                    })}
                    checked={isChecked}
                    onChange={handleToggle}
                />
                {label && <div className={classNames("label-text min-w-fit")}>{label}</div>}
            </label>
        </div>
    );
};

MySwitch.propTypes = {
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default MySwitch;
