import React, { useMemo } from "react";
import classNames from "classnames";
import { CrossIcon } from "../../../../../components/Icons/MyIcons";
import MyButton from "../../../../../components/MyComponents/Button";

const FilterDisplay = (props) => {
    const { filter, filterOptions, onRemove } = props;
    const classes =
        "flex-1 flex items-center uppercase border rounded me-1 p-1 bg-info bg-opacity-10 text-info font-bold";

    const fullField = useMemo(() => {
        if (!filterOptions || !filter) return null;
        return filterOptions.find((f) => f.id === filter?.fieldId);
    }, [filter, filterOptions]);

    const handleRemove = () => {
        onRemove(filter.filterId);
    };

    const renderFilter = (fullField, filter) => {
        const valueOptions = fullField?.filterOptions?.valueOptions || [];
        switch (filter?.fieldType) {
            case "binary":
                const matchAny = filter?.matchAny || [];
                const valueIndex = matchAny[0];
                let valueLabel = valueOptions[valueIndex];
                if (typeof valueLabel === "object") {
                    valueLabel = valueLabel.label;
                }
                return (
                    <>
                        <div className={classes}>Is</div>
                        <div className={classes}>{valueLabel}</div>
                    </>
                );
            case "range":
                return (
                    <>
                        <div className={classes}>Is between</div>
                        <div className={classes}>
                            {filter?.minValue} and {filter?.maxValue}
                        </div>
                    </>
                );
            case "select":
            case "likertScale":
                const values = filter?.matchAny?.map((valueIndex) => {
                    const valueOption = valueOptions[valueIndex];
                    return valueOption;
                });
                return (
                    <>
                        <div className={classes}>Matches any of</div>
                        <div className={classNames(classes, "oveflow-x-scroll hide-scroll-bars")}>
                            {values.join(", ")}
                        </div>
                    </>
                );
            default:
        }
    };

    return (
        <div className="flex p-1 text-tiny">
            <div className={classes}>{fullField?.label}</div>
            {renderFilter(fullField, filter)}
            {!props.hideButtons && (
                <div className="relative">
                    <MyButton size="sm" shape="circle" onClick={handleRemove}>
                        <CrossIcon />
                    </MyButton>
                </div>
            )}
        </div>
    );
};

export default FilterDisplay;
