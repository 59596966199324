import { useMemo } from "react";
import { calculateAverageOfRatings } from "../../Snapshots/utils/snapshotHelpers";
import { useAssessmentColors } from "../../Config/hooks/useAssessmentColors";

export function useTalentAreaTree(talentAreas, traits, traitRatings = {}, overallRating) {
    const { getRatingColor } = useAssessmentColors();

    return useMemo(() => {
        function rateTalentArea(linkedTraitIds) {
            const ratings = linkedTraitIds
                .map((traitId) => traitRatings?.[traitId])
                .filter((rating) => rating !== undefined);
            return calculateAverageOfRatings(ratings);
        }
        return {
            id: "overall",
            tooltip: "Overall",
            label: "Overall",
            isTopLevel: true,
            rating: overallRating,
            color: overallRating && getRatingColor(overallRating - 1),
            children: Object.values(talentAreas)
                .map((talentArea) => {
                    const { talentAreaName, linkedTraitIds = [] } = talentArea;
                    const rating = rateTalentArea(linkedTraitIds);
                    if (rating === null) return null;
                    return {
                        id: talentArea.id,
                        isTopLevel: false,
                        tooltip: talentAreaName,
                        talentArea: talentArea,
                        label: talentAreaName,
                        rating: rating,
                        color: getRatingColor(rating - 1),
                        children: linkedTraitIds
                            .map((traitId) => {
                                const trait = traits[traitId];
                                const traitRating = traitRatings?.[traitId];
                                const { traitName } = trait;
                                if (traitRating === undefined) return null;
                                return {
                                    id: traitId,
                                    tooltip: traitName,
                                    isTopLevel: false,
                                    trait: trait,
                                    rating: traitRating,
                                    color: getRatingColor(traitRating - 1),
                                };
                            })
                            .filter((trait) => trait !== null),
                    };
                })
                .filter((area) => area !== null),
        };
    }, [talentAreas, traits, traitRatings, overallRating, getRatingColor]);
}
