import classNames from "classnames";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { BsDot } from "react-icons/bs";
import "./word-cloud.scss";

const WordCloud = ({
    wordEntries = [],
    onWordClick = () => {},
    minSize = 18,
    midSize = 28,
    maxSize = 32,
    colors = [],
    divider = true,
}) => {
    const sizedEntries = useMemo(() => {
        if (!wordEntries || wordEntries.length === 0) return [];
    
        const sortedEntries = orderBy(wordEntries, ["rating"], ["desc"]);
        const maxRating = sortedEntries[0].rating;
        const minRating = sortedEntries[sortedEntries.length - 1].rating;
    
        const avgRating = (maxRating + minRating) / 2;
        const ratingRangeHigh = maxRating - avgRating;
        const ratingRangeLow = avgRating - minRating || 1; // prevent division by zero
    
        // Calculate a consistent line height based on the max size
        const consistentLineHeight = maxSize * 1.3;
    
        let firstHalf = [];
        let secondHalf = [];
    
        sortedEntries.forEach((entry, i) => {
            let fontSize;
            if (entry.rating >= avgRating) {
                const highFactor = (entry.rating - avgRating) / ratingRangeHigh;
                fontSize = midSize + highFactor * (maxSize - midSize);
            } else {
                const lowFactor = (avgRating - entry.rating) / ratingRangeLow;
                fontSize = midSize - lowFactor * (midSize - minSize);
            }
    
            const sizedEntry = { ...entry, fontSize };
    
            // Alternate entries into top and bottom halves
            if (i % 2 === 0) {
                firstHalf.push({ ...sizedEntry, bottomHalf: true });
            } else {
                secondHalf.push({ ...sizedEntry, topHalf: true });
            }
        });
    
        const displayOrder = firstHalf.reverse().concat(secondHalf);
    
        // Apply styles, consistent line height, and center alignment
        const coloredEntries = displayOrder.map((entry) => {
            const { fontSize } = entry;
            const color = colors[Math.max(0, entry.rating - 1)];
    
            return {
                ...entry,
                style: {
                    fontSize,
                    lineHeight: `${consistentLineHeight}px`,
                    color,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
                color,
            };
        });
    
        return coloredEntries;
    }, [wordEntries, minSize, midSize, maxSize, colors]);



    return (
        <div className="word-cloud">
            {sizedEntries.map((entry, i) => {
                let isDivider = false;
                if (sizedEntries.length > 1 && divider) {
                    isDivider = i < sizedEntries.length - 1;
                }
                return (
                    <div
                        onClick={() => onWordClick(entry)}
                        key={i}
                        className={classNames("word-container clickable", { "px-0": isDivider })}
                        style={entry.style}
                    >
                        <div className={classNames("word", { "px-0": isDivider })}>{entry.word}</div>
                        {isDivider && (
                            <span className="text-neutral">
                                <BsDot size={22} />
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

WordCloud.propTypes = {
    wordEntries: PropTypes.array,
    onWordClick: PropTypes.func,
    baseSize: PropTypes.number,
    maxSize: PropTypes.number,
    colors: PropTypes.array,
    divider: PropTypes.bool,
};

export default WordCloud;

/*
    const sizedEntries = useMemo(() => {
        if (wordEntries) {
            const sizeRange = maxSize - baseSize;
            const sizeIncrement = sizeRange / wordEntries.length;
            const sizedEntries = orderBy(wordEntries, ["rating"], ["desc"]);
            let firstHalf = [];
            let secondHalf = [];
            let prevRating = 0;
            sizedEntries.forEach((entry, i) => {
                let sizeDecrease = 0;
                if (entry.rating != prevRating) {
                    sizeDecrease = sizeIncrement * i;
                }
                prevRating = entry.rating;
                const fontSize = Math.max(maxSize - sizeDecrease, baseSize);
                const sizedEntry = { ...entry, fontSize: fontSize };
                const isEven = i % 2 === 0;
                if (isEven) {
                    firstHalf.push({ ...sizedEntry, bottomHalf: true });
                } else {
                    secondHalf.push({ ...sizedEntry, topHalf: true });
                }
            });
            const displayOrder = firstHalf.concat(secondHalf.reverse());
            const coloredEntries = displayOrder.map((entry, i) => {
                const { rating, fontSize } = entry;
                const lineHeight = fontSize * 1.3;
                const color = colors[Math.max(0, rating - 1)];
                let style = {
                    fontSize: fontSize,
                    lineHeight: `${lineHeight}px`,
                    color: color,
                };
                return {
                    ...entry,
                    style: style,
                    color: color,
                };
            });
            return coloredEntries;
        } else {
            return [];
        }
    }, [wordEntries, maxSize, baseSize, colors]);
    */
