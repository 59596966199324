import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { convertTimestampToDate } from "../../../utils/dateUtils";
import Select from "../../atoms/Select/Select";

// create an array mapping month numbers to month names (3 chars)
const months = Array.from({ length: 12 }, (_, i) => new Date(2021, i, 1).toLocaleString("en", { month: "short" }));
const monthOptions = months.map((month, i) => ({ id: i + 1, value: i + 1, label: month }));
// Creat an array of years, this year and next year only
const currentYear = new Date().getFullYear();
const years = [currentYear, currentYear + 1, currentYear + 2];
const yearOptions = years.map((year) => ({ id: year, value: year, label: year }));

const DateSelector = ({ dateValue, allowPast = true, onChange }) => {
    const referenceElement = useRef(null);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMonth, setMonth] = useState(null);
    const [selectedYear, setYear] = useState(currentYear);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement.current, popperElement, {
        placement: "top",
        modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    });

    useEffect(() => {
        const convertedDate = convertTimestampToDate(dateValue);
        if (convertedDate) {
            setMonth(convertedDate.getMonth() + 1);
            setYear(convertedDate.getFullYear());
        }
    }, [dateValue]);

    const dropdownRef = useRef(null);

    useClickOutside(dropdownRef, () => setIsOpen(false));

    const handleSetDate = (segment, value) => {
        if (segment === "month") {
            setMonth(value);
            handleSave(value, selectedYear);
        } else if (segment === "year") {
            setYear(value);
            handleSave(selectedMonth, value);
        }
    };

    const handleSave = (month, year) => {
        if (!month || !year) {
            return false;
        }
        const nextDate = new Date();
        nextDate.setDate(1);
        nextDate.setMonth(month - 1);
        nextDate.setFullYear(year);
        if (!allowPast) {
            const today = new Date();
            const isFuture = nextDate >= today;
            if (!isFuture) {
                setError("Date cannot be in the past");
                return false;
            }
        }
        onChange(nextDate);
        setError(null);
    };

    const renderDate = () => {
        if (dateValue) {
            const monthLabel = monthOptions[selectedMonth - 1]?.label;
            return (
                <>
                    <span>{monthLabel}</span>
                    <span>{selectedYear}</span>
                </>
            );
        } else {
            return <span className="text-error">Set Date</span>;
        }
    };

    return (
        <div className="relative">
            <div
                ref={referenceElement}
                onClick={() => setIsOpen(!isOpen)}
                className="cursor-pointer text-sm tracking-tight font-medium space-x-1"
            >
                {renderDate()}
            </div>
            {isOpen && (
                <div
                    ref={(element) => {
                        setPopperElement(element);
                        dropdownRef.current = element;
                    }}
                    style={styles.popper}
                    {...attributes.popper}
                    className={classNames(
                        "border border-border bg-white rounded-box shadow-lg z-10 transition-opacity duration-300 opacity-100"
                    )}
                >
                    <div
                        className={classNames("p-4 space-y-1", {
                            "bg-blue-100": !error,
                            "bg-error bg-opacity-10": !!error,
                        })}
                    >
                        <div className="flex space-x-2 items-center">
                            <Select
                                options={monthOptions}
                                minWidth={28}
                                placeholder="Month"
                                selectedIds={selectedMonth}
                                onChange={(val) => handleSetDate("month", val)}
                            />
                            <Select
                                options={yearOptions}
                                minWidth={28}
                                placeholder="Year"
                                selectedIds={selectedYear}
                                onChange={(val) => handleSetDate("year", val)}
                            />
                        </div>
                        {error && <div className="text-error text-xs">{error}</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateSelector;
