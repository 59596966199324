import { collection, doc, serverTimestamp, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { disableDoc, editDoc, getCollectionRef, getDocRef } from "../../../firebase/firebaseActions";
import { getUniqueId } from "../../../helpers/helpers";

function getEmployeeRef(workspaceId, employeeId) {
    const employeesRef = getCollectionRef(workspaceId, "employees");
    return getDocRef(employeesRef, employeeId);
}

const writeEmployee = (workspaceId, employeeId, employee, batch = writeBatch(db)) => {
    const employeeRef = getEmployeeRef(workspaceId, employeeId);
    const { id, talentAssessment, selfAssessment, ...enhancedEmployee } = employee;
    employee.updatedOn = serverTimestamp();
    batch = editDoc(employeeRef, enhancedEmployee, batch);
    return batch;
};

const disableEmployee = (workspaceId, employeeId, batch = writeBatch(db)) => {
    const employeeRef = getEmployeeRef(workspaceId, employeeId);
    batch = disableDoc(employeeRef, batch);
    return batch;
};

const writeEmployeeNote = (workspaceId, employeeId, update, batch = writeBatch(db)) => {
    const employeeRef = getEmployeeRef(workspaceId, employeeId);
    const notesRef = collection(employeeRef, "notes");
    const noteId = update.id;
    const newNoteRef = doc(notesRef, noteId);
    let { noteType } = update;
    const noteToSave = { ...update, noteType: noteType || "general" };
    batch.set(newNoteRef, noteToSave);
    return batch;
};

const writeNotification = (workspaceId, employeeId, notification, batch = writeBatch(db)) => {
    const employeeRef = getEmployeeRef(workspaceId, employeeId);
    const notificationId = notification.id || getUniqueId();
    const notificationRef = doc(employeeRef, "notifications", notificationId);
    batch.set(notificationRef, notification);
    return batch;
};

const deleteNotification = (workspaceId, employeeId, notificationId, batch = writeBatch(db)) => {
    const employeeRef = getEmployeeRef(workspaceId, employeeId);
    const notificationRef = doc(employeeRef, "notifications", notificationId);
    batch.delete(notificationRef);
    return batch;
};

export { deleteNotification, disableEmployee, writeEmployee, writeEmployeeNote, writeNotification };
