import classNames from "classnames";
import { InfiniteTable } from "../../../../common/components";
import { useUniqueId } from "../../../../common/hooks/useUniqueId";
import AssessmentBadge from "../../../Snapshots/components/AssessmentBadge";
import { withLiveTalentData } from "../../../Workspace/hoc/withLiveTalentData";

const RatingRankTable = ({ className = "flex-1 flex flex-col", ratings, traits }) => {
    const tableId = useUniqueId();

    const columns = [
        { id: "name", size: "lg", label: "Trait", cellRenderer: (entry) => traits[entry.id]?.traitName },
        {
            id: "rating",
            size: "sm",
            label: "Rating",
            headerRenderer: () => <div className="w-full flex justify-center">Rating</div>,
            cellRenderer: (entry) => (
                <div className="w-full flex justify-center">
                    <AssessmentBadge size="sm" rating={entry.rating} />
                </div>
            ),
        },
    ];

    if (!ratings) return null;

    return (
        <div className={classNames(className)}>
            <InfiniteTable
                defaultSortKey="rating"
                defaultSortOrder="desc"
                tableId={tableId}
                columns={columns}
                data={ratings}
            />
        </div>
    );
};

export default withLiveTalentData(RatingRankTable);
