import { useMemo } from "react";
import { getDocRef, getSubCollectionRef } from "../../../firebase/firebaseActions";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";

export function useRoleRefs(roleId) {
    const workspaceRef = useWorkspaceRef();

    const rolesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return getSubCollectionRef(workspaceRef, "roles");
    }, [workspaceRef]);

    const roleRef = useMemo(() => {
        if (!rolesRef || !roleId) return null;
        return getDocRef(rolesRef, roleId);
    }, [rolesRef, roleId]);

    return { rolesRef, roleRef };
}
