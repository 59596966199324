import React, { useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import ReactTooltip from "react-tooltip";
import { getUniqueId } from "../../../helpers/helpers";
import PropTypes from "prop-types";

const ScatterChart = ({
    data = [],
    width = 0,
    height = 0,
    xLabel = "xAxis",
    yLabel = "yAxis",
    xMax = 101,
    yMax = 101,
    customTooltip: CustomTooltip,
    onDotDoubleClick = () => {},
}) => {
    const d3Container = useRef(null);
    const [tooltipData, setTooltipData] = useState(null);
    const tooltipId = useMemo(() => getUniqueId(), []);

    useEffect(() => {
        if (data && d3Container.current && !isNaN(width) && !isNaN(height) && width > 0 && height > 0) {
            const validData = data.filter((d) => d.x !== undefined && d.y !== undefined && !isNaN(d.x) && !isNaN(d.y));

            const handleDoubleClick = (event, d) => {
                onDotDoubleClick && onDotDoubleClick(d);
            };

            // Define mouseover and mouseout functions
            const handleMouseOver = (_, d) => {
                setTooltipData(d);
            };

            const handleMouseOut = (_, d) => {
                setTooltipData((prev) => {
                    if (prev.id === d.id) return null;
                    return prev;
                });
            };

            // Clear previous SVG
            d3.select(d3Container.current).selectAll("*").remove();

            // Set up SVG
            const svg = d3.select(d3Container.current).attr("width", width).attr("height", height);

            // Define scales
            const xScale = d3.scaleLinear().domain([0, xMax]).range([0, width]);

            const yScale = d3.scaleLinear().domain([0, yMax]).range([height, 0]);

            // Draw horizontal grid line (center)
            const centerX = xScale(xMax / 2);
            const centerY = yScale(yMax / 2);

            svg.append("line")
                .attr("x1", 0)
                .attr("y1", centerY)
                .attr("x2", width)
                .attr("y2", centerY)
                .style("stroke", "#ddd")
                .style("stroke-dasharray", "3, 3");

            // Draw vertical grid line (center)
            svg.append("line")
                .attr("x1", centerX)
                .attr("y1", 0)
                .attr("x2", centerX)
                .attr("y2", height)
                .style("stroke", "#ddd")
                .style("stroke-dasharray", "3, 3");
            // Define axes
            const xAxis = d3.axisBottom(xScale).ticks(4);
            const yAxis = d3.axisLeft(yScale).ticks(4);

            // Draw axes
            svg.append("g").attr("transform", `translate(0,${height})`).call(xAxis);

            svg.append("g").call(yAxis);

            // Add labels
            svg.append("text")
                .attr("transform", `translate(${width / 2}, ${height + 40})`)
                .style("text-anchor", "middle")
                .text(xLabel);

            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - 50)
                .attr("x", 0 - height / 2)
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(yLabel);

            // Plot data
            svg.selectAll(".dot")
                .data(validData)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("cx", (d) => xScale(d.x || 0))
                .attr("cy", (d) => yScale(d.y || 0))
                .attr("r", 5)
                .style("fill", (d) => d.color || "#ccc")
                .attr("data-tip", (d) => d.label)
                .attr("data-for", tooltipId)
                .attr("class", "cursor-pointer")
                .on("dblclick", handleDoubleClick)
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);

            ReactTooltip.rebuild(); // Rebuild tooltips for dynamic data
        }
    }, [data, width, height, xLabel, yLabel, xMax, yMax, onDotDoubleClick, tooltipId]);

    return (
        <div>
            <svg ref={d3Container} />
            <ReactTooltip
                id={tooltipId}
                place="top"
                effect="solid"
                backgroundColor="transparent"
                getContent={() => <CustomTooltip data={tooltipData} />}
            />
        </div>
    );
};

ScatterChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            x: PropTypes.number,
            y: PropTypes.number,
            color: PropTypes.string,
        })
    ),
    width: PropTypes.number,
    height: PropTypes.number,
    xLabel: PropTypes.string,
    yLabel: PropTypes.string,
    xMax: PropTypes.number,
    yMax: PropTypes.number,
    onDotDoubleClick: PropTypes.func,
};

export default ScatterChart;
