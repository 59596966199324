import { NotUserIcon, PendingUserIcon, UserIcon } from "../../../../components/Icons/MyIcons";
import MyTooltip from "../../../../components/MyComponents/Tooltip";
import { USER_ROLES } from "../../../../constants";

export const UserStatusIcon = ({ employee, position = "right", size = 26 }) => {
    const { userRole, inviteAccepted } = employee;
    const isUser = userRole && userRole !== "none";

    const userRoleLabel = userRole === "owner" ? userRole : USER_ROLES[userRole]?.label || "User";

    function renderIcon() {
        if (isUser && !inviteAccepted) {
            return <PendingUserIcon size={size} />;
        } else if (isUser && inviteAccepted) {
            return <UserIcon size={size} className="text-success" />;
        } else {
            return <NotUserIcon size={size} className="opacity-20" />;
        }
    }
    const tooltipText = !isUser ? "No Access" : inviteAccepted ? userRoleLabel : "Yet to accept invite";
    return (
        <MyTooltip tooltip={tooltipText} position={position}>
            {renderIcon()}
        </MyTooltip>
    );
};
