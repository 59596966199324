export function advanceDueDate(date, recur) {
    const newDate = new Date(date);
    switch (recur) {
        case "monthly":
            newDate.setMonth(newDate.getMonth() + 1);
            break;
        case "quarterly":
            newDate.setMonth(newDate.getMonth() + 3);
            break;
        case "biannually":
            newDate.setMonth(newDate.getMonth() + 6);
            break;
        case "annually":
            newDate.setFullYear(newDate.getFullYear() + 1);
            break;
        default:
            break;
    }
    return newDate;
}