import { writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { writeEveryoneConfig } from "../../Config/utils/configDb";
import { deleteNotification, writeEmployee, writeNotification } from "../../Employees/utils/employeeDb";
import { writeUser } from "../../User/utils/userDb";
import { writeTraitMap, writeWorkspace } from "./workspaceDb";
import { getWorkspaceId } from "./workspaceSelectors";

const configureWorkspaceThunk = (userInfo, workspaceName) => async (dispatch, getState) => {
    const claims = getState().user.claims;
    const uid = getState().app.uid;
    const workspaceId = getWorkspaceId(getState());
    const workspaceUpdate = {
        workspaceName: workspaceName,
        configured: true,
    };
    const { employeeId } = claims;
    const userUpdate = { firstname: userInfo.firstname, surname: userInfo.surname };

    let batch = writeEmployee(workspaceId, employeeId, userUpdate);
    batch = writeUser(uid, userUpdate, batch);
    batch = writeWorkspace(workspaceId, workspaceUpdate, batch);
    await batch.commit();
};

const updateWorkspaceThunk = (workspaceUpdate, configUpdate) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    let batch = writeBatch(db);
    if (workspaceUpdate) batch = writeWorkspace(workspaceId, workspaceUpdate, batch);
    if (configUpdate) batch = writeEveryoneConfig(workspaceId, configUpdate, batch);
    await batch.commit();
};

const updateTraitMapThunk = (traitMapUpdate) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    let batch = writeTraitMap(workspaceId, traitMapUpdate);
    await batch.commit();
};

const resolveNotificationThunk = (notification) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    const claims = getState().user.claims;
    const employeeId = claims?.employeeId;
    if (employeeId) {
        let batch = writeNotification(workspaceId, employeeId, notification);
        await batch.commit();
    }
};

const deleteNotificationsThunk = (notifications) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    const claims = getState().user.claims;
    const employeeId = claims?.employeeId;
    if (employeeId) {
        let batch = writeBatch(db);
        notifications.forEach((notification) => {
            batch = deleteNotification(workspaceId, employeeId, notification.id, batch);
        });
        return batch;
    }
};

export {
    configureWorkspaceThunk,
    deleteNotificationsThunk,
    resolveNotificationThunk,
    updateTraitMapThunk,
    updateWorkspaceThunk,
};
