import React from "react";
import { Each } from "../../../../common/components";
import { DownloadIcon } from "../../../../components/Icons/MyIcons";
import ScheduledActionItem from "../../../ScheduledActions/ScheduledActionItem";

const CompletedActions = ({
    completedActions,
    onCompleteAction,
    onDeleteAction,
    onScheduleAction,
    toggleReport,
    fetchAction,
    setReportType,
}) => {
    const noCompletedActions = completedActions.length === 0;

    const handleShowCompletedReport = () => {
        setReportType("completed");
        toggleReport();
    };

    return (
        <div className="h-full flex flex-col overflow-auto flex-1 min-w-xl max-w-xl bg-blue-100 border-e border-primary py-6">
            <div className="flex justify-between pb-1 px-6">
                <h5>Completed Actions</h5>
                <button
                    className="btn btn-circle btn-ghost btn-sm"
                    disabled={noCompletedActions}
                    onClick={handleShowCompletedReport}
                >
                    <DownloadIcon />
                </button>
            </div>
            <div className="flex-1 overflow-y-scroll px-6 space-y-2">
                <Each
                    of={completedActions}
                    emptyStateProps={{ className: "flex-1 h-full", message: "No completed actions" }}
                    render={(action) => (
                        <ScheduledActionItem
                            mode="browse"
                            done={true}
                            fetchAction={fetchAction}
                            actionId={action.id}
                            actionName={action.actionName}
                            onScheduleAction={onScheduleAction}
                            onComplete={onCompleteAction}
                            onDelete={onDeleteAction}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default CompletedActions;
