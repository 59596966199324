import useEditValues from "../../../common/hooks/useEditValues";
import { CLAIM_OPTIONS } from "../../../constants";

function getDefaultClaims() {
    let claimsKeys = CLAIM_OPTIONS.map((claim) => claim.id);
    let defaultClaims = {};
    claimsKeys.forEach((key) => {
        defaultClaims[key] = false;
    });
    return defaultClaims;
}

export function useEditedEmployee(initialValues, locked = false) {
    const [editedEmployee, setValue, hasChanges, setAllValues] = useEditValues(initialValues);

    const updateEmployee = (updateType, val) => {
        if (locked) return;
        switch (updateType) {
            case "custom":
                const [customFieldId, value] = val;
                const newCustomFields = { ...editedEmployee.custom, [customFieldId]: value };
                setValue("custom", newCustomFields);
                break;
            case "userRole":
                if (editedEmployee.userRole === val) {
                    // userRole is being set back to initial values; reset claim fields
                    setValue("isUser", initialValues?.isUser || false);
                    setValue("userRole", initialValues?.userRole || "none");
                    setValue("customClaims", initialValues?.customClaims || {});
                    setValue("inviteAccepted", initialValues?.inviteAccepted || false);
                } else {
                    // userRole is being changed; update claim fields
                    const isUser = val !== "none";
                    if (!isUser) {
                        // User is no longer going to be a user; remove claims
                        setValue("isUser", false);
                        setValue("userRole", "none");
                        setValue("customClaims", {});
                        setValue("inviteAccepted", false);
                    } else {
                        // user is becoming a user; set default claims
                        const defaultClaims = getDefaultClaims();
                        setValue("isUser", isUser);
                        setValue("userRole", val);
                        setValue("customClaims", defaultClaims);
                        setValue("inviteAccepted", false);
                    }
                }
                break;
            case "roleId":
                if (editedEmployee.userRole !== "admin") {
                    // User isn't an admin; they should lose/gain access depending on having an org roleId
                    if (!val) {
                        updateEmployee("userRole", "none");
                    } else {
                        updateEmployee("userRole", "manager");
                    }
                }
                setValue("roleId", val);
                break;
            case "email":
                // When email changes; reset the claims so the user has to re-accept the invite
                setValue("customClaims", {});
                setValue("inviteAccepted", false);
                setValue("email", val);
                break;
            case "canCreateAdmins":
                const currentClaims = editedEmployee.customClaims || {};
                const newClaims = { ...currentClaims, [updateType]: val };
                setValue("customClaims", newClaims);
                break;
            default:
                // updateType is just a key in default case
                setValue(updateType, val);
                break;
        }
    };

    return [editedEmployee, updateEmployee, hasChanges, setAllValues];
}

/*
const changeWorkspaceClaimsThunk = (employeeId, newClaims, prevClaims) => async (dispatch, getState) => {
    const workspaceId = getState().workspace.workspaceId;
    const { isUser, userRole, inviteAccepted, ...customClaims } = newClaims;
    let employeeUpdate = {
        userRole,
        isUser,
        customClaims,
        workspaceId, // Ensures the workspaceId is on the document for invite querying
    };
    if (inviteAccepted !== undefined) {
        employeeUpdate.inviteAccepted = inviteAccepted;
    }
    // Create an invite for new users;
    const wasUser = Object.keys(prevClaims || {}).includes("userRole");
    const willBeUser = Object.keys(newClaims || {}).includes("userRole");
    const becomingUser = !wasUser && willBeUser;
    let batch = writeBatch(db);
    employeeUpdate.isUser = willBeUser;
    if (becomingUser) {
        employeeUpdate.inviteAccepted = false;
        employeeUpdate.inviteDate = serverTimestamp();
    }
    batch = writeEmployee(workspaceId, employeeId, employeeUpdate, batch);
    await batch.commit();
};
*/
