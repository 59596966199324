import { LoadingIndicator } from "../../../components";
import { useIsWorkspaceReady } from "../hooks/useIsWorkspaceReady";

export const withWaitForWorkspace = (Component) => {
    const WithWaitForWorkspace = (props) => {
        const isWorkspaceReady = useIsWorkspaceReady();
        if (!isWorkspaceReady) return <LoadingIndicator />;
        return <Component {...props} />;
    };

    return WithWaitForWorkspace;
};
