import classNames from "classnames";
import SunburstChart from "../../../../components/Charts/BaseCharts/SunburstChart";
import ChartContainer from "../../../../components/Charts/ChartContainer";

const DepartmentSunburst = ({ className = "flex-1 flex w-full", departmentTree, activeSegmentId, onSegmentClick }) => {
    const handleSunburstSegmentClick = (d) => {
        onSegmentClick(d?.data?.id || null);
    };

    return (
        <ChartContainer className={classNames(className)}>
            <SunburstChart
                data={departmentTree}
                activeSegmentId={activeSegmentId}
                onSegmentClick={handleSunburstSegmentClick}
            />
        </ChartContainer>
    );
};

export default DepartmentSunburst;
