import { calculateAverageOfRatings, calculateAverageTraitRatings } from "../../Snapshots/utils/snapshotHelpers";

export function buildCohortSnapshot(employeeCohort, talentAreas, talentBoards, traits) {
    const snapshots = employeeCohort.map((employee) => employee.snapshot).filter((snapshot) => snapshot);
    const traitRatings = buildAggregateRatingSnapshot(snapshots, talentAreas, talentBoards, traits);
    const flagSnapshot = buildAggregateFlagSnapshot(snapshots);
    //const objectiveSnapshot = buildAggregateObjectiveSnapshot(snapshots);
    const objectiveSnapshot = {};

    const traitIds = Object.keys(traitRatings);
    const representedBoards = getTalentBoards(employeeCohort, talentBoards);
    const representedAreas = getTalentAreas(traitIds, traits, talentAreas);
    const overallRating = calculateAverageOfRatings(Object.values(traitRatings));
    return { overallRating, traitRatings, flagSnapshot, objectiveSnapshot, representedBoards, representedAreas };
}

function buildAggregateRatingSnapshot(snapshots) {
    let traitIds = Object.values(snapshots).flatMap((snapshot) => Object.keys(snapshot?.ratings || {}));
    traitIds = [...new Set(traitIds)];
    let ratings = {};
    for (let traitId of traitIds) {
        const traitRating = calculateAverageTraitRatings(snapshots, traitId);
        ratings[traitId] = traitRating;
    }
    return ratings;
}

// Get all talentboardIds represented in the cohort
function getTalentBoards(employeeCohort, talentBoards) {
    const allBoardIds = employeeCohort.flatMap((employee) => employee.linkedTalentBoardIds || []);
    const uniqueBoardIds = [...new Set(allBoardIds)];
    return uniqueBoardIds.map((boardId) => talentBoards[boardId]);
}

// Get all talentAreaIds represented in the cohort
function getTalentAreas(traitIds, traits, talentAreas) {
    const uniqueRatingIds = [...new Set(traitIds)];
    const allTalentAreaIds = uniqueRatingIds.map((ratingId) => traits[ratingId].linkedTalentAreaId);
    const uniqueAreaIds = [...new Set(allTalentAreaIds)];
    return uniqueAreaIds.map((areaId) => talentAreas[areaId]);
}

function buildAggregateFlagSnapshot(snapshots) {
    const snapshotArray = Object.values(snapshots);
    const counts = {};
    const sums = {};
    const occurrences = {};

    snapshotArray.forEach((snapshot) => {
        const flags = snapshot?.flags;
        Object.keys(flags || {}).forEach((key) => {
            if (!counts[key]) {
                counts[key] = {};
                sums[key] = 0;
                occurrences[key] = 0;
            }
            const value = flags[key];
            if (!counts[key][value]) {
                counts[key][value] = 0;
            }
            counts[key][value] += 1;
            sums[key] += value;
            occurrences[key] += 1;
        });
    });

    return { counts, sums, occurrences };
}
