import classNames from "classnames";
import SunburstChart from "../../../../components/Charts/BaseCharts/SunburstChart";
import ChartContainer from "../../../../components/Charts/ChartContainer";

const CascadeSunburst = ({ className, cascadeTree, activeSegmentId, onSegmentClick = () => {} }) => {
    const handleSegmentClick = (segment) => {
        onSegmentClick(segment);
    };

    return (
        <ChartContainer className={classNames(className)}>
            <SunburstChart data={cascadeTree} activeSegmentId={activeSegmentId} onSegmentClick={handleSegmentClick} />
        </ChartContainer>
    );
};

export default CascadeSunburst;
