import { PropTypes } from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import IdPicker from "../../../components/Picker/IdPicker";
import { useTopLevelRoleId } from "../../BusinessUnits/hooks/useTopLevelRoleId";
import { listAllEmployeeIdsBelow, listChildEmployeeIds } from "../../Roles/utils/roleHelpers";

const tempDefaultProps = {
    onBlur: () => {},
    onChange: () => {},
}

const EmployeePicker = (props) => {
    const {
        selectedIds,
        isClearable,
        isMulti,
        includeAll,
        excludeSelf,
        onlyOrphans,
        includeOrphans,
        directReportsOf,
        excludeIds = [],
    } = props;
    const mergedProps = { ...tempDefaultProps, ...props };
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const topLevelRoleId = useTopLevelRoleId();
    const employees = useSelector((state) => state.businessUnit.employees);
    const roles = useSelector((state) => state.businessUnit.roles);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);

    // If Include all, ignore the users topLevelRoleId and include all roles
    const topLevelFilterId = includeAll ? rootRoleId : topLevelRoleId;

    // Determine the additional ids to exclude based on the props provided
    const dynamicExcludeList = useMemo(() => {
        let excludedIds = [];
        const employeeIdList = Object.keys(employees);
        if (onlyOrphans) {
            excludedIds = employeeIdList.filter((empId) => !!employees[empId].roleId);
        } else if (!includeOrphans) {
            let includeIds = [];
            if (directReportsOf) {
                includeIds = listChildEmployeeIds(parentMap, roles, directReportsOf);
            } else {
                includeIds = listAllEmployeeIdsBelow(parentMap, roles, topLevelFilterId);
            }
            if (!excludeSelf) {
                includeIds.push(userEmployeeId);
            }
            excludedIds = employeeIdList.filter((empId) => !includeIds.includes(empId));
        }
        return excludedIds;
    }, [
        topLevelFilterId,
        employees,
        roles,
        onlyOrphans,
        includeOrphans,
        directReportsOf,
        excludeSelf,
        parentMap,
        userEmployeeId,
    ]);

    const getLabel = (employee) => {
        return employee && employee.displayName;
    };

    return (
        <IdPicker
            {...mergedProps}
            name="employees"
            getLabel={getLabel}
            options={employees}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={dynamicExcludeList.concat(excludeIds)}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

EmployeePicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    includeAll: PropTypes.bool,
    excludeSelf: PropTypes.bool,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
};

export default EmployeePicker;
