import { checkForPages, checkForPageTypes } from "../../Forms/utils/checkForPageTypes";

export function loadObjectiveSnapshot(objectiveSnapshot) {
    if (!objectiveSnapshot) return null;
    const {
        c: confidence,
        s: successRate,
        b: blockers,
        n: count,
        cc: confidenceCounts,
        rc: resultCounts,
    } = objectiveSnapshot;
    return {
        confidence,
        successRate,
        blockers,
        count,
        confidenceCounts,
        resultCounts,
    };
}

function getRate(array, key) {
    // Excludes only `null` values, keeps `0` values
    const validValues = array.map((item) => item[key]).filter((value) => value !== null);

    // Returns undefined if no valid values are found
    if (validValues.length === 0) return undefined;

    // Returns the average of the valid values
    return Math.round(validValues.reduce((acc, val) => acc + val, 0) / validValues.length);
}

function getCounts(array, key) {
    return array.reduce((acc, item) => {
        const value = item[key];
        if (value !== null) {
            // Exclude null values
            acc[value] = (acc[value] || 0) + 1; // Increment count for the result
        }
        return acc;
    }, {});
}

function getBlockerList(array = []) {
    // Returns an array of blockerIds with the parentId (for cascade tracking)
    return array.flatMap(({ id, parentId, blockers = [] }) =>
        blockers.map((blockerId) => ({ oId: id, bId: blockerId, pId: parentId }))
    );
}

export function prepObjectiveSnapshot(objectives = {}, endCycle, prevSnapshot) {
    const objectivesArray = Array.isArray(objectives) ? objectives : Object.values(objectives);
    const confidence = getRate(objectivesArray, "confidence");
    const successRate = getRate(objectivesArray, "result");
    const resultCounts = getCounts(objectivesArray, "result");
    const confidenceCounts = getCounts(objectivesArray, "confidence");
    const blockers = getBlockerList(objectivesArray);

    let objectivesSnapshot = {};
    if (endCycle) {
        // Objective cycle is ending; only success rate and resultCounts are needed
        objectivesSnapshot.s = successRate;
        objectivesSnapshot.rc = resultCounts;
    } else {
        // Objective cycle is ongoing; all metrics are needed
        const { objectives } = prevSnapshot || {};
        const { successRate: prevSuccessRate, resultCounts: prevResultCounts } = objectives || {};
        objectivesSnapshot.c = confidence;
        objectivesSnapshot.b = blockers;
        objectivesSnapshot.n = objectivesArray.length || 0;
        objectivesSnapshot.cc = confidenceCounts;
        objectivesSnapshot.s = prevSuccessRate || null; // prevSuccessRate should be kept on the snapshot
        objectivesSnapshot.rc = prevResultCounts || null; // prevResultCounts should be kept on the snapshot
    }
    return objectivesSnapshot;
}

export function prepFormObjectiveSnapshot(reviewerRelationship, pages, objectives = {}, prevSnapshot) {
    const include = checkForPageTypes(reviewerRelationship, "objectives", pages);
    const endCycle = checkForPages(reviewerRelationship, "objectiveComplete", pages);
    if (!include) return null;
    return prepObjectiveSnapshot(objectives, endCycle, prevSnapshot);
}

/*
function buildAggregateObjectiveSnapshot(snapshots) {
    const snapshotArray = Object.values(snapshots);

    const allLive = snapshotArray.flatMap((snapshot) => snapshot?.objectives?.live).filter((obj) => obj !== undefined);
    const allComplete = snapshotArray
        .flatMap((snapshot) => snapshot?.objectives?.complete)
        .filter((obj) => obj !== undefined);
    const allAchieved = allComplete.filter((obj) => obj.result === 100);
    const allFailed = snapshotArray.filter((obj) => obj.result === 0);
    const allAbandoned = allComplete.filter((obj) => obj.result === null);
    const liveCount = allLive.length;
    const completeCount = allComplete.length;
    const achievedCount = allAchieved.length;
    const failedCount = allFailed.length;
    const abandonedCount = allAbandoned.length;
    const totalResult = allComplete.reduce((acc, obj) => acc + obj.result, 0);
    const successRate = Math.round(totalResult / completeCount) || 0;
    let averageConfidence = allLive.reduce((acc, obj) => acc + obj.confidence, 0) / liveCount;
    averageConfidence = isNaN(averageConfidence) ? 0 : Math.round(averageConfidence, 0);

    return {
        liveCount,
        completeCount,
        achievedCount,
        failedCount,
        abandonedCount,
        successRate,
        averageConfidence,
        liveBlockerCount,
        liveBlockerGroupCounts,
        failedBlockerCount,
        failedBlockerGroupCounts,
    };
}
*/
