export function buildBoardMapUpdate(currentBoardMap, roleId, talentBoardIds, boardMapUpdate = {}) {
    // Add a boardMap update for any connected boards
    if (talentBoardIds) {
        talentBoardIds.forEach((boardId) => {
            const currentMap = currentBoardMap?.[boardId] || [];
            if (!currentMap.includes(roleId)) {
                let newMap = [...currentMap];
                newMap.push(roleId);
                boardMapUpdate[boardId] = newMap;
            }
        });
    }
    return boardMapUpdate;
}
