import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGroupedTalentBoards } from "../../TalentBoards/hooks/useGroupedTalentBoards";

export function useRole(roleId) {
    const selectedRole = useSelector((state) => state.businessUnit.roles[roleId]);
    const parentRole = useSelector((state) => state.businessUnit.roles[selectedRole?.parentRoleId]);
    const { getLinkedBoardsIds } = useGroupedTalentBoards(selectedRole);

    const linkedBoards = useMemo(() => {
        return getLinkedBoardsIds(selectedRole);
    }, [selectedRole, getLinkedBoardsIds]);

    function checkIfNewDepartment() {
        return selectedRole?.department && selectedRole?.department !== parentRole?.department;
    }

    return {
        selectedRole,
        parentRole,
        autoBoards: linkedBoards.autoLinked,
        manualBoards: linkedBoards.manualLinked,
        checkIfNewDepartment,
    };
}
