import { useNavigate } from "react-router-dom";
import applogger from "../utils/applogger";

const useLoggedNavigate = () => {
    const navigate = useNavigate();

    return (path, options) => {
        applogger.info(`NAVIGATING TO: ${path}`, options);
        navigate(path, options);
    };
};

export default useLoggedNavigate;
