import PropTypes from "prop-types";
import React from "react";
import "./rich-text-editor.scss";
import RichTextDisplay from "./RichTextDisplay";
import RichTextEditor from "./RichTextEditor";

const tempDefaultProps = {
    maxLength: 2500,
    showBorder: true,
    bgClass: "bg-base-100",
    textSize: "sm",
    minHeight: "unset",
    maxHeight: "unset",
    onChange: () => {},
    onBlur: () => {},
};

const RichText = (props) => {
    const mergedProps = { ...tempDefaultProps, ...props };
    const { minHeight, maxHeight, onChange, ...rest } = mergedProps;
    let wrapperStyle = {
        minHeight: minHeight,
        maxHeight: maxHeight,
    };

    const handleChange = (newContent) => {
        onChange(newContent);
    };

    if (props.readOnly) {
        return <RichTextDisplay {...rest} wrapperStyle={wrapperStyle} />;
    } else {
        return <RichTextEditor {...rest} onChange={handleChange} wrapperStyle={wrapperStyle} />;
    }
};

RichText.propTypes = {
    maxLength: PropTypes.number,
    showBorder: PropTypes.bool,
    onChange: PropTypes.func,
    textSize: PropTypes.string,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    extraClasses: PropTypes.string,
    bgClass: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default RichText;
