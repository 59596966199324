import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { useListener } from "../../../firebase/hooks/useListener";
import { useBusinessUnitRefs } from "../refs/useBusinessUnitRefs";
import setEntireWorkspaceThunk from "../../Workspace/thunks/setEntireWorkspaceThunk";

export function useEmployeeInfoListener() {
    const dispatch = useDispatch();
    const { employeeInfoRef } = useBusinessUnitRefs();

    const handleDataReceived = useCallback(
        (snapshot) => {
            // Get the employee changes ready
            const employees = {};
            snapshot.docChanges().forEach((change) => {
                const infoDoc = change.doc.data();
                Object.entries(infoDoc).forEach(([employeeId, full]) => {
                    // temp: isUser was added to some infoDoc entries in error
                    const { isUser, ...employee } = full;
                    const dynamicIsUser = employee.userRole && employee.userRole !== "none";
                    const displayName = `${employee.firstname} ${employee.surname}`;
                    let prepped = {
                        id: employeeId,
                        displayName: displayName,
                        unitDocId: change.doc.id,
                        ...employee,
                    };
                    if (dynamicIsUser) prepped.isUser = true;
                    prepped = ensureSerializable(prepped);
                    employees[employeeId] = prepped;
                });
            });

            // Check which employees are still live
            let liveIds = [];
            snapshot.forEach((doc) => {
                const docData = doc.data();
                if (employees) {
                    liveIds = [...liveIds, ...Object.keys(docData)];
                }
            });
            dispatch(setEntireWorkspaceThunk({ employees, liveEmployeeIds: liveIds }));
        },
        [dispatch]
    );

    return useListener(employeeInfoRef, handleDataReceived);
}
