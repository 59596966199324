import { deleteField, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getUniqueId } from "../../../helpers/helpers";
import { writeEmployee } from "../../Employees/utils/employeeDb";
import { getEmployee } from "../../Employees/utils/employeeSelectors";
import { getChildRoleIds } from "../../Roles/hooks/useRoles";
import { writeRole } from "../../Roles/utils/rolesDb";
import { buildLinkedTalentBoardIds } from "../../TalentBoards/utils/buildLinkedTalentBoardIds";
import { getWorkspaceId } from "../../Workspace/utils/workspaceSelectors";
import { writeBusinessUnit } from "./businessUnitDb";
import { setBoardMap } from "./businessUnitSlice";

const getIds = (state) => {
    const { workspaceId } = state.workspace;
    const { businessUnitId } = state.businessUnit;
    return { workspaceId, businessUnitId };
};

const changeEmployeeBusinessUnitThunk = (employeeId, targetUnitId, targetUnitDoc) => async (dispatch, getState) => {
    const { workspaceId } = getIds(getState());
    const employee = getEmployee(getState(), employeeId);
    let batch = writeBatch(db);

    // Vacate role in current business unit if they have one
    const roleId = employee && employee.roleId;
    if (roleId) {
        batch = writeRole(workspaceId, roleId, { incumbentId: deleteField() }, batch);
    }

    // Set the business unitId, and provide a unitDocId for the employee
    const employeeUnitDocIds = targetUnitDoc.employeeUnitDocIds || [];
    let newEmployeeUnitDocId = employeeUnitDocIds[employeeUnitDocIds.length - 1];
    newEmployeeUnitDocId = newEmployeeUnitDocId || getUniqueId();
    const update = {
        roleId: deleteField(),
        parentRoleId: deleteField(),
        businessUnitId: targetUnitId,
        unitDocId: newEmployeeUnitDocId,
    };

    batch = writeEmployee(workspaceId, employeeId, update, batch);
    await batch.commit();
};

const createBusinessUnitThunk = (businessUnit) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    const unitId = getUniqueId();
    const firstRoleUnitDocId = getUniqueId();
    const newBusinessUnit = { id: unitId, firstRoleUnitDocId: firstRoleUnitDocId, ...businessUnit };
    let batch = writeBusinessUnit(workspaceId, unitId, newBusinessUnit);
    await batch.commit();
};

const updateBusinessUnitThunk = (update) => async (dispatch, getState) => {
    const { workspaceId } = getIds(getState());
    const { id: businessUnitId } = update;
    let batch = writeBusinessUnit(workspaceId, businessUnitId, update);
    await batch.commit();
};

const applyBoardMap = (pendingBoardMap, pendingParentMap) => async (dispatch, getState) => {
    const { talentBoards } = getState().workspace;
    const { roles, employees, boardMap: liveBoardMap, parentMap: liveParentMap } = getState().businessUnit;
    const allRoleIds = Object.keys(roles);
    const boardMap = pendingBoardMap || liveBoardMap;
    const parentMap = pendingParentMap || liveParentMap;

    let fullBoardMap = { ...boardMap };
    const allManagerRoleIds = allRoleIds.filter((roleId) => {
        return getChildRoleIds(roleId, parentMap).length > 0;
    });

    // Assign any global boards to every role
    const globalBoards = Object.values(talentBoards).filter((board) => board.auto === "all");
    globalBoards.forEach((board) => {
        const boardId = board.id;
        fullBoardMap[boardId] = allRoleIds;
    });

    // Assign any manager boards to manager roles
    const managerBoards = Object.values(talentBoards).filter((board) => board.auto === "managers");
    managerBoards.forEach((board) => {
        const boardId = board.id;
        fullBoardMap[boardId] = allManagerRoleIds;
    });

    // Add talentboardIds to employees and roles
    const updatedEmployees = { ...employees };
    const updatedRoles = { ...roles };

    Object.values(roles).forEach((role) => {
        const { linkedTalentBoardIds, boardLevels } = buildLinkedTalentBoardIds(role, fullBoardMap);
        updatedRoles[role?.id] = {
            ...role,
            linkedTalentBoardIds,
            boardLevels,
        };
        // If the role has an incumbent, update their employee record
        const incumbentId = role?.incumbentId;
        if (incumbentId) {
            const incumbent = updatedEmployees[incumbentId];
            updatedEmployees[incumbentId] = {
                ...incumbent,
                linkedTalentBoardIds,
                boardLevels,
            };
        }
    });
    dispatch(setBoardMap({ fullBoardMap, updatedEmployees, updatedRoles }));
};

export { applyBoardMap, changeEmployeeBusinessUnitThunk, createBusinessUnitThunk, updateBusinessUnitThunk };
