import _ from "lodash";
import { useSelector } from "react-redux";
import { getUniqueId } from "../../../helpers/helpers";
import { buildBoardMapUpdate } from "../utils/buildBoardMapUpdate";

export function useBusinessUnits() {
    const roles = useSelector((state) => state.businessUnit.roles);
    const employees = useSelector((state) => state.businessUnit.employees);
    const boardMap = useSelector((state) => state.businessUnit.boardMap);

    function handleBuildBoardMapUpdate(roleId, talentBoardIds, boardMapUpdate = {}) {
        return buildBoardMapUpdate(boardMap, roleId, talentBoardIds, boardMapUpdate);
    }

    function getUnitDocId(group, docSizeLimit) {
        // Find a viable doc that has fewer entries than the size limit, if there is one
        const items = group === "roles" ? roles : employees;
        const entriesToGroup = Object.values(items);
        const docGroups = _.groupBy(entriesToGroup, "unitDocId");
        const viableIds = Object.keys(docGroups).filter((docId) => {
            return docGroups[docId].length <= docSizeLimit;
        });

        // Return the first docId with space, or a new id if they're all full
        const id = viableIds[0] || getUniqueId();
        return id;
    }

    return { getUnitDocId, buildBoardMapUpdate: handleBuildBoardMapUpdate };
}
