import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ChartLegend from "../../../../components/Charts/ChartLegend/ChartLegend";

const TalentAreaLegend = ({ vertical, selectedAreaId, highlightSelected }) => {
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const items = useMemo(() => {
        return Object.values(talentAreas).map((talentArea) => {
            return { id: talentArea.id, label: talentArea.talentAreaName, color: talentArea.color };
        });
    }, [talentAreas]);

    return (
        <ChartLegend
            items={items}
            vertical={vertical}
            selectedId={selectedAreaId}
            highlightSelected={highlightSelected}
        />
    );
};

export default TalentAreaLegend;
