import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import setEntireWorkspaceThunk from "../../Workspace/thunks/setEntireWorkspaceThunk";
import { useBusinessUnitRefs } from "../refs/useBusinessUnitRefs";
import { checkIfFirestoreRef } from "../../../firebase/utils/identifyFirestoreRef";

export function useBoardMapListener() {
    const dispatch = useDispatch();
    const { boardMapRef } = useBusinessUnitRefs();

    const shouldListen = useMemo(() => {
        return checkIfFirestoreRef(boardMapRef)
    }, [boardMapRef]);

    const handleDataReceived = useCallback((doc) => {
        const boardMap = doc.data() || {};
        dispatch(setEntireWorkspaceThunk({ boardMap }));
    }, [dispatch]);

    useListener(boardMapRef, handleDataReceived, shouldListen);
}
