import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useEnabledModules() {
    // Enabled modules should have an object
    const enabledModules = useSelector((state) => state.config.everyoneConfig.enabledModules);
    return useMemo(() => {
        return enabledModules.reduce((acc, module) => {
            acc[module] = true;
            return acc;
        }, {});
    }, [enabledModules]);
}
