import classNames from "classnames";
import React from "react";
import { Button, Each } from "../../../../../../common/components";
import ExpandableCard from "../../../../../../common/components/organisms/ExpandableCard/ExpandableCard";
import { formatDate } from "../../../../../../common/utils/dateUtils";
import { MeasureIcon } from "../../../../../../components/Icons/MyIcons";
import DateDisplay from "../../../../../../components/MyComponents/DateDisplay";
import ObjectiveDescription from "../../../atoms/ObjectiveDescription";
import ObjectiveScopeBadge from "../../../atoms/ObjectiveScopeBadge";
import { ObjectiveStatusBadge } from "../../../atoms/ObjectiveStatus";

const ObjectiveViewCard = ({
    objective,
    fadeMe = false,
    toggled,
    onViewCascades,
    onViewComments,
    onToggle = () => {},
}) => {
    const { objectiveName, description, cascadeType, measures, result, status, dueOn, completedOn } = objective;
    const dateLabel = completedOn ? "Completed On" : "Due On";
    const formattedDate = formatDate(completedOn || dueOn);

    const hideCascadable = false; // hiding cascades until fixed
    const cascadable = !hideCascadable && cascadeType !== "individual";

    const handleToggle = () => {
        onToggle(objective);
    };

    const handleHeaderClick = () => {
        handleToggle();
    };

    const handleViewCascades = () => {
        onViewCascades(objective);
    };

    const handleViewComments = () => {
        onViewComments(objective);
    };

    const renderHeader = () => (
        <div
            className={classNames("fade-in move-up items-center bg-base-100 cursor-pointer flex px-6 py-2 space-x-3", {
                border: !toggled,
                "rounded-sm": !toggled,
                "border-t border-x rounded-t-box": toggled,
            })}
            onClick={handleHeaderClick}
        >
            <div className="flex-1 flex space-x-2 items-center h-8">
                <div className="w-28 flex items-center">
                    <ObjectiveScopeBadge objective={objective} />
                </div>
                <span className="font-medium tracking-tight capitalize">{objectiveName}</span>
            </div>
            <div className="flex space-x-12 items-center">
                <ObjectiveStatusBadge result={result} status={status} />
            </div>
        </div>
    );

    return (
        <div className={classNames({ "pb-6": toggled })}>
            <ExpandableCard
                headerComponent={renderHeader()}
                removeBorder
                fadeMe={fadeMe}
                toggled={toggled}
                onToggle={handleToggle}
            >
                <div className="flex flex-col bg-base-100 border">
                    <div className="flex-1 flex flex-col space-y-6 p-6">
                        <div className="flex-1 flex-col justify-between space-y-4">
                            <div className="flex-1">
                                <h6 className="font-semibold mb-1">Description</h6>
                                <ObjectiveDescription description={description} readOnly={true} />
                            </div>
                            <div className="divide-y border-b">
                                <h6>Measures</h6>
                                <Each
                                    of={measures}
                                    emptyStateProps={{ hidden: true }}
                                    render={(measure) => (
                                        <div className="text-sm tracking-tight flex space-x-2 items-center py-2">
                                            <MeasureIcon size={14} />
                                            <span>{measure.measureName}</span>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex items-center space-x-2 justify-between border-base-500">
                            <div className="max-w-md flex-1 flex space-x-2 justify-start font-semibold">
                                <span>{dateLabel}:</span>
                                <div className="flex items-center">
                                    <DateDisplay date={formattedDate} />
                                </div>
                            </div>
                            <div className="flex items-center space-x-2 justify-between">
                                <Button size="sm" onClick={handleViewComments}>
                                    Comments
                                </Button>
                                <Button hidden={!cascadable} size="sm" onClick={handleViewCascades}>
                                    Cascade Info
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ExpandableCard>
        </div>
    );
};

export default ObjectiveViewCard;
