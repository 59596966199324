import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CheckIcon, DeleteIcon } from "../../components/Icons/MyIcons";
import MyBadge from "../../components/MyComponents/Badge";
import MyButton from "../../components/MyComponents/Button";
import { useActions } from "../Actions/hooks/useActions";

const SummaryCard = ({ action, onDelete, onComplete, onShowMore }) => {
    const traits = useSelector((state) => state.workspace.traits);
    const linkedTraitIds = action?.linkedTraitIds || [];

    const handleDeleteAction = () => {
        onDelete(action?.id);
    };

    const handleCompleteAction = () => {
        onComplete(action?.id);
    };

    return (
        <div className="flex-1 flex flex-col p-4">
            <div className="flex-1">
                <h2 className="card-title text-tiny">Action</h2>
                <div>
                    <h4>{action.actionName}</h4>
                    <div className="text-sm space-x-1">
                        {linkedTraitIds.map((traitId) => (
                            <MyBadge color="secondary" key={`${action?.id}-${traitId}`}>
                                {traits?.[traitId]?.traitName}
                            </MyBadge>
                        ))}
                    </div>
                </div>
            </div>
            <div className="card-actions justify-center p-2 flex-1 items-center">
                <MyButton tooltip="Cancel" size="sm" shape="circle" onClick={handleDeleteAction}>
                    <DeleteIcon size={18} />
                </MyButton>
                <MyButton size="sm" onClick={onShowMore}>
                    More
                </MyButton>
                <MyButton tooltip="Complete" size="sm" shape="circle" onClick={handleCompleteAction}>
                    <CheckIcon size={18} />
                </MyButton>
            </div>
        </div>
    );
};

export const ScheduledActionCard = ({
    actionId,
    fetchAction,
    onShowMore = () => {},
    onComplete = () => {},
    onDelete = () => {},
    onReopen = () => {},
}) => {
    const [actionDoc, setActionDoc] = useState();
    const { getAction } = useActions();
    const action = getAction(actionId);
    const openedRef = useRef(false);

    const handleShowMore = async (e) => {
        if (!openedRef.current && !actionDoc) {
            openedRef.current = true;
            const action = await fetchAction(actionId);
            onShowMore(action);
            setActionDoc(action);
        } else {
            onShowMore(actionDoc);
        }
    };

    const props = {
        action,
        onComplete,
        onDelete,
        onReopen,
        onShowMore: handleShowMore,
    };

    return <SummaryCard {...props} />;
};

ScheduledActionCard.propTypes = {
    actionId: PropTypes.string.isRequired,
    actionsAreComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    fetchAction: PropTypes.func.isRequired,
    actionName: PropTypes.string.isRequired,
    checked: PropTypes.bool,
};

export default ScheduledActionCard;
