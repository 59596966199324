import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEmployeeFields } from "../../Employees/hooks/useEmployeeFields";
import { setPreppedEmployees } from "../utils/businessUnitSlice";
import applogger from "../../../common/utils/applogger";

const prepEmployees = (employees, fieldsToMap) => {
    return Object.keys(employees).reduce((acc, empId) => {
        const employee = employees[empId];
        const preppedEmployee = {
            ...employee, // Reference the original employee data
            ...fieldsToMap.reduce((extraFields, column) => {
                const returnedVal = column.getValue ? column.getValue(employee) : employee[column.id];
                // If returnedVal is not undefined, add it to the extraFields
                if (returnedVal !== undefined) {
                    extraFields[column.id] = returnedVal;
                }
                return extraFields;
            }, {}),
        };
        acc[empId] = preppedEmployee;
        return acc;
    }, {});
};

export function useEmployeePrepper() {
    const dispatch = useDispatch();
    const workspaceInitialised = useSelector((state) => state.workspace.workspaceInitialised);
    const lastInitialised = useSelector((state) => state.workspace.lastInitialised);
    const employees = useSelector((state) => state.businessUnit.employees);
    const { allFields } = useEmployeeFields();

    useEffect(() => {
        if (workspaceInitialised && lastInitialised) {
            // Set a new debounce timer
            const timer = setTimeout(() => {
                applogger.info("PREPPING EMPLOYEES");
                const preppedEmployees = prepEmployees(employees, allFields);
                dispatch(setPreppedEmployees(preppedEmployees));
            }, 500); // 500ms delay

            return () => clearTimeout(timer);
        }
    }, [workspaceInitialised, employees, lastInitialised, allFields, dispatch]);
}
