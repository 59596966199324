import { lazy } from "react";
import { withOrgNodeContext } from "../features/Organisation/hoc/withOrgNodeContext";

import DevelopmentPlanTemplate from "../features/Actions/components/templates/DevelopmentPlanTemplate";
import ObjectivesTemplate from "../features/Objectives/components/templates/ObjectivesTemplate";
import { withOrgContext } from "../features/Organisation/hoc/withOrgContext";
import WorkspaceContainer from "../features/Workspace/components/containers/WorkspaceContainer";
import WorkspaceFallback from "../features/Workspace/components/templates/WorkspaceFallbackTemplate";
import BusinessUnitsPage from "./businessUnits/BusinessUnitsPage";
import DashboardPage from "./dashboard/DashboardPage";

// Org Node Pages
const CommentsPage = lazy(() => import("./organisation/outlets/OrgNode/outlets/employee/CommentsPage"));
const EmployeeOverviewTemplate = lazy(() => import("../features/Employees/components/templates/EmployeeOverviewTemplate"));
const FormPage = lazy(() => import("./organisation/outlets/OrgNode/outlets/employee/FormPage"));
const BranchOverview = lazy(() => import("../features/Insights/components/templates/InsightsTemplate"));
const ReviewHistoryTemplate = lazy(() => import("../features/Employees/components/templates/ReviewHistoryTemplate"));
const TalentMapSubTemplate = lazy(() => import("../features/TalentMap/components/templates/TalentMapSubPageTemplate"));

// Org Pages
const OrgPage = lazy(() => import("./organisation/OrgPage"));
const OrgChartPage = lazy(() => import("./organisation/outlets/OrgChartPage"));
const OrgNodePage = lazy(() => import("./organisation/outlets/OrgNode/OrgNodePage"));
const OrgTablePage = lazy(() => import("./organisation/outlets/OrgTablePage"));
const TalentMapOrgTemplate = lazy(() => import("../features/TalentMap/components/templates/TalentMapOrgTemplate"));

// Talent Pages
const ActionPage = lazy(() => import("./actions/ActionPage"));
const ActionCompletedPage = lazy(() => import("./actions/ActionCompletedPage"));
const ActionScheduledPage = lazy(() => import("./actions/ActionScheduledPage"));
const ActionCandidatesPage = lazy(() => import("./actions/ActionCandidatesPage"));
const ActionLinkedTraitsPage = lazy(() => import("./actions/ActionLinkedTraitsPage"));
const ActionsPage = lazy(() => import("./actions/ActionsPage"));
const ActionConfigPage = lazy(() => import("./actions/ActionConfigPage"));
const TalentBoardPage = lazy(() => import("./talentboards/TalentBoardPage/TalentBoardPage"));
const BoardConfigOutlet = lazy(() => import("./talentboards/TalentBoardPage/outlets/BoardConfigOutlet"));
const BenchStrengthOutlet = lazy(() => import("./talentboards/TalentBoardPage/outlets/BenchStrengthOutlet"));
const TalentBoardsPage = lazy(() => import("./talentboards/TalentBoardsPage"));
const TalentAreasPage = lazy(() => import("./talentareas/TalentAreasPage"));
const LevelOutlet = lazy(() => import("./talentboards/TalentBoardPage/outlets/LevelOutlet"));
const TraitsPageTemplate = lazy(() => import("../features/Traits/components/templates/TraitsPageTemplate"));

// Settings Pages
const ProfileSettingsPage = lazy(() => import("./settings/ProfileSettingsPage"));
const SettingsIndexPage = lazy(() => import("./settings/SettingsIndexPage"));
const UploadWorkspacePage = lazy(() => import("./settings/dothings/UploadWorkspacePage"));
const WorkspaceMaintenancePage = lazy(() => import("./settings/dothings/WorkspaceMaintenancePage"));
const CustomFieldSettingsPage = lazy(() => import("./settings/workspace/CustomFieldSettingsPage"));
const WorkspaceSettingsPage = lazy(() => import("./settings/workspace/WorkspaceSettingsPage"));

// Sign-in Pages
const SignInPage = lazy(() => import("./sign-in/SignInPage"));
const JoinWorkspace = lazy(() => import("./sign-in/outlets/JoinWorkspace"));
const RequestSignIn = lazy(() => import("./sign-in/outlets/RequestSignIn"));
const CompleteSignIn = lazy(() => import("./sign-in/outlets/CompleteSignIn"));
const SignInSuccess = lazy(() => import("./sign-in/outlets/SignInSuccess"));
const SignInErrorPage = lazy(() => import("../features/ErrorPages/SignInErrorPage"));

const SchedulePage = lazy(() => import("./schedule/SchedulePage"));

const SchedulePages = {
    Schedule: SchedulePage,
};

const CorePages = {
    Dashboard: DashboardPage,
    Workspace: WorkspaceContainer,
    Fallback: WorkspaceFallback,
    BusinessUnits: BusinessUnitsPage,
};
const SignInPages = {
    Container: SignInPage,
    Request: RequestSignIn,
    Complete: CompleteSignIn,
    Join: JoinWorkspace,
    Success: SignInSuccess,
    Error: SignInErrorPage,
};

const OrgPages = {
    Org: OrgPage,
    OrgTable: OrgTablePage,
    OrgChart: OrgChartPage,
    TalentMap: withOrgContext(TalentMapOrgTemplate),
    OrgNode: OrgNodePage,
};

const RolePages = {
    RoleOverview: withOrgNodeContext(BranchOverview),
    TalentMap: withOrgNodeContext(TalentMapSubTemplate),
};

const EmployeePages = {
    Overview: withOrgNodeContext(EmployeeOverviewTemplate),
    DevelopmentPlan: withOrgNodeContext(DevelopmentPlanTemplate),
    Comments: withOrgNodeContext(CommentsPage),
    Objectives: withOrgNodeContext(ObjectivesTemplate),
    ReviewHistory: withOrgNodeContext(ReviewHistoryTemplate),
    Form: withOrgNodeContext(FormPage),
};

const SettingsPages = {
    Index: SettingsIndexPage,
    Profile: ProfileSettingsPage,
    Upload: UploadWorkspacePage,
    Maintenance: WorkspaceMaintenancePage,
    CustomFields: CustomFieldSettingsPage,
    Workspace: WorkspaceSettingsPage,
};

const TalentPages = {
    TalentAreas: TalentAreasPage,
    Traits: TraitsPageTemplate,
    TalentBoards: TalentBoardsPage,
    TalentBoard: TalentBoardPage,
    BenchStrength: BenchStrengthOutlet,
    BoardConfig: BoardConfigOutlet,
    LevelConfig: LevelOutlet,
    Actions: ActionsPage,
    Action: ActionPage,
    ActionConfig: ActionConfigPage,
    ActionScheduled: ActionScheduledPage,
    ActionCompleted: ActionCompletedPage,
    ActionCandidates: ActionCandidatesPage,
    ActionLinkedTraits: ActionLinkedTraitsPage,
};

const ObjectivePages = {
    Status: <div></div>,
};

export {
    CorePages,
    EmployeePages,
    ObjectivePages,
    OrgPages,
    RolePages,
    SchedulePages,
    SettingsPages,
    SignInPages,
    TalentPages,
};
