import { useCallback, useMemo } from "react";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { useListener } from "../../../firebase/hooks/useListener";
import { useFeatureFlips } from "../../Config/hooks/useFeatureFlips";
import { normalizeAssessment } from "../../Snapshots/utils/normalizeRating";
import { useIsThisMe } from "../../User/hooks/useIsThisMe";
import { useEmployeeRefs } from "../refs/useEmployeeRefs";

export function useAssessmentListener(empId, onDataReceived, assessor) {
    const { selfAssessmentRef, talentAssessmentRef } = useEmployeeRefs(empId);
    const { selfAssessment: enableSelfAssessment } = useFeatureFlips();
    const isSelf = useIsThisMe(empId);

    const assessmentRef = useMemo(() => {
        switch (assessor) {
            case "manager":
                const listenManager = !isSelf && !!empId;
                return listenManager ? talentAssessmentRef : null;
            case "self":
                const listenSelf = !!empId && enableSelfAssessment;
                return listenSelf ? selfAssessmentRef : null;
            default:
                return null;
        }
    }, [selfAssessmentRef, talentAssessmentRef, empId, assessor, isSelf, enableSelfAssessment]);

    const handleAssessmentReceived = useCallback(
        (snapshot) => {
            const doc = snapshot.docs[0];
            if (doc) {
                let talentAssessment = doc.data();
                talentAssessment = ensureSerializable(talentAssessment);
                //const normalized = normalizeAssessment(talentAssessment);
                onDataReceived(talentAssessment);
            } else {
                onDataReceived({});
            }
        },
        [onDataReceived]
    );

    return useListener(assessmentRef, handleAssessmentReceived);
}
