import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormStep } from "../../../../../../components";
import MyInputGroup from "../../../../../../components/MyComponents/InputGroup";
import { MyRadioGroup } from "../../../../../../components/MyComponents/RadioButtons";
import MyTextInput from "../../../../../../components/MyComponents/TextInput";
import { FIELD_SIZE_LIMITS } from "../../../../../../constants";
import { useLanguage } from "../../../../../../translations/LanguageContext";
import CustomFieldDisplay from "../../../../../CustomFields/CustomFieldDisplay";
import EmployeePicker from "../../../../../Employees/components/EmployeePicker";
import { isEmailInUse, isEmployeeNumberInUse } from "../../../../../Employees/hooks/useValidateEmployee";

const INCUMBENT_OPTIONS = [
    { value: "new", label: "Add New Employee" },
    { value: "current", label: "Fill With Current Employee" },
    { value: "vacant", label: "Create Vacant Role" },
];

const EmployeeDetailsStep = (props) => {
    const {
        values,
        touched,
        handleBlur,
        setStatus,
        setValues,
        setFieldValue,
        setFieldTouched,
        status,
        excludeVacant,
        language,
        customEmployeeFields,
    } = props;
    const { appLanguage } = useLanguage();
    const employees = useSelector((state) => state.businessUnit.employees);
    const preppedOptions = INCUMBENT_OPTIONS.filter((option) => !excludeVacant || option.value !== "vacant");
    const [errors, setErrors] = useState({});
    const custom = values.custom || {};

    const clearEmployee = () => {
        let { firstname, surname, email, employeeNumber, existingEmployeeId, ...newValues } = values;
        setValues({ ...newValues, emailInUse: false });
        setStatus({ ...status, existingEmployee: false });
        setErrors({});
    };

    const handleSetFillWith = (value) => {
        clearEmployee();
        setStatus({ ...status, fillWith: value });
    };

    const checkEmail = (_, newVal) => {
        if (newVal) {
            const emailInUse = isEmailInUse(employees, newVal, values.id);
            setFieldValue("emailInUse", emailInUse);
            if (emailInUse) {
                setErrors({ ...errors, email: appLanguage("warnings", "email_in_use") });
            } else {
                let { email, ...newErrors } = errors;
                setErrors(newErrors);
            }
        }
    };

    const checkEmployeeNumber = (_, newVal) => {
        if (newVal) {
            const employeeNumberInUse = isEmployeeNumberInUse(employees, newVal, values.existingEmployeeId);
            setFieldValue("employeeNumberInUse", employeeNumberInUse);
            if (employeeNumberInUse) {
                setErrors({ ...errors, employeeNumber: appLanguage("warnings", "employee_number_in_use") });
            } else {
                let { employeeNumber, ...newErrors } = errors;
                setErrors(newErrors);
            }
        }
    };

    const handleFieldChange = (fieldId, newVal) => {
        setFieldValue(fieldId, newVal);
    };

    const handleIncumbentTypeChange = (empId) => {
        const employee = employees[empId];
        if (employee) {
            let newValues = {
                ...values,
                firstname: employee.firstname,
                surname: employee.surname,
                email: employee.email,
                employeeNumber: employee.employeeNumber,
                custom: employee.custom,
                existingEmployeeId: empId,
            };
            setValues(newValues);
            setStatus({
                ...status,
                existingEmployee: true,
                existingEmail: !!employee.email,
            });
        } else {
            clearEmployee();
        }
    };

    const handleCustomFieldChange = (fieldId, value) => {
        const newCustomFields = { ...custom, [fieldId]: value };
        setFieldValue("custom", newCustomFields);
    };

    return (
        <FormStep
            title={language.employee_details_step}
            className="h-full flex flex-col justify-between p-4 overflow-auto"
        >
            <div className="flex-3 space-y-4 pe-3 overflow-auto">
                <div className="flex space-x-6">
                    <MyRadioGroup
                        checkInFront
                        options={preppedOptions}
                        onChange={handleSetFillWith}
                        value={status.fillWith}
                    />
                </div>
                {status.fillWith === "current" && (
                    <MyInputGroup label="Available Employees" name="existingEmployeeId">
                        <EmployeePicker
                            maxMenuHeight={280}
                            selectedIds={values.existingEmployeeId || []}
                            onlyOrphans
                            isClearable
                            excludeIds={[status.incumbentId]}
                            onBlur={() => setFieldTouched("existingEmployeeId", true)}
                            onChange={handleIncumbentTypeChange}
                        />
                    </MyInputGroup>
                )}
                {status.fillWith === "new" && (
                    <div className="space-y-4 pb-28">
                        <div className="flex space-x-4">
                            <div className="flex-1">
                                <MyInputGroup
                                    label="Firstname"
                                    name="firstname"
                                    errors={errors.firstname}
                                    placeholder="required"
                                >
                                    <MyTextInput
                                        maxLength={FIELD_SIZE_LIMITS.FIRSTNAME}
                                        invalid={errors.firstname && touched.firstname}
                                        onChange={handleFieldChange}
                                        onBlur={handleBlur}
                                        value={values.firstname || ""}
                                        autoComplete="off"
                                    />
                                </MyInputGroup>
                            </div>
                            <div className="flex-1">
                                <MyInputGroup
                                    label="Surname"
                                    name="surname"
                                    errors={errors.surname}
                                    placeholder="required"
                                >
                                    <MyTextInput
                                        maxLength={FIELD_SIZE_LIMITS.SURNAME}
                                        invalid={errors.surname && touched.surname}
                                        onChange={handleFieldChange}
                                        onBlur={handleBlur}
                                        value={values.surname || ""}
                                        autoComplete="off"
                                    />
                                </MyInputGroup>
                            </div>
                        </div>
                        <MyInputGroup label="Email" name="email" errors={errors.email}>
                            <MyTextInput
                                type="email"
                                maxLength={FIELD_SIZE_LIMITS.EMAIL}
                                value={values.email || ""}
                                invalid={(errors.email || errors.emailInUse) && touched.email}
                                onChange={handleFieldChange}
                                onBlur={checkEmail}
                                autoComplete="on"
                            />
                        </MyInputGroup>
                        <MyInputGroup
                            label="Employee Number"
                            name="employeeNumber"
                            errors={errors.employeeNumber}
                            placeholder="optional"
                        >
                            <MyTextInput
                                maxLength={FIELD_SIZE_LIMITS.EMPLOYEE_NO}
                                invalid={
                                    (errors.employeeNumber || errors.employeeNumberInUse) && touched.employeeNumber
                                }
                                onChange={handleFieldChange}
                                onBlur={checkEmployeeNumber}
                                value={values.employeeNumber || ""}
                                autoComplete="off"
                            />
                        </MyInputGroup>
                        {customEmployeeFields.map((field) => (
                            <MyInputGroup key={field.id} label={field.label} name={field.id}>
                                <CustomFieldDisplay
                                    value={custom[field.id]}
                                    key={field.id}
                                    field={field}
                                    onChange={handleCustomFieldChange}
                                />
                            </MyInputGroup>
                        ))}
                    </div>
                )}
            </div>
        </FormStep>
    );
};

export default EmployeeDetailsStep;
