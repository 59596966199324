import { useDispatch } from "react-redux";
import { useAssessmentListener } from "../../Employees/hooks/useAssessmentListener";
import { useEmployeeListener } from "../../Employees/hooks/useEmployeeListener";
import { useIsWorkspaceReady } from "../../Workspace/hooks/useIsWorkspaceReady";
import { useClaims } from "./useClaims";
import { setOwnEmloyeeDoc, setOwnObjectives, setOwnSelfAssessment } from "../utils/userSlice";
import { useCallback, useMemo } from "react";
import { useLiveObjectivesListener } from "../../Objectives/hooks/useLiveObjectivesListener";

export function useOwnDataListeners() {
    const dispatch = useDispatch();
    const { userEmployeeId } = useClaims();
    const workspaceReady = useIsWorkspaceReady();

    const handleSetEmployeeDoc = useCallback(
        (data) => {
            dispatch(setOwnEmloyeeDoc(data));
        },
        [dispatch]
    );

    const handleSetAssessment = useCallback(
        (data) => {
            dispatch(setOwnSelfAssessment(data));
        },
        [dispatch]
    );

    const handleSetObjectives = useCallback(
        (data) => {
            dispatch(setOwnObjectives(data));
        },
        [dispatch]
    );

    const employeeStatus = useEmployeeListener(userEmployeeId, handleSetEmployeeDoc, workspaceReady);
    const selfAssessStatus = useAssessmentListener(userEmployeeId, handleSetAssessment, workspaceReady && "self");
    const objectivesStatus = useLiveObjectivesListener(userEmployeeId, handleSetObjectives, workspaceReady);

    const allNecessaryLoaded = useMemo(() => {
        const all = [employeeStatus, selfAssessStatus, objectivesStatus];
        return all.every((status) => status === "success");
    }, [employeeStatus, selfAssessStatus, objectivesStatus]);

    return allNecessaryLoaded;
}
