import Fuse from "fuse.js";
import { useCallback, useMemo, useState } from "react";

export function useDynamicFilter(data, searchKeys = []) {
    const [filteredIds, setFilteredIds] = useState(null);

    const dataIsArray = useMemo(() => Array.isArray(data), [data]);

    const handleSearch = useCallback(
        (value) => {
            if (!value) {
                setFilteredIds(null);
                return;
            }

            const searchSettings = {
                keys: searchKeys,
                threshold: 0.2,
            };

            try {
                let fuse;
                if (dataIsArray) {
                    fuse = new Fuse(data, searchSettings);
                } else {
                    fuse = new Fuse(Object.values(data), searchSettings);
                }
                const filtered = fuse.search(value);

                if (!filtered || filtered.length === 0) {
                    setFilteredIds(null);
                    return;
                }

                const filteredItems = filtered.map((item) => item.item);
                const filteredIds = filteredItems.map((item) => item.id);
                setFilteredIds(filteredIds);
            } catch (e) {
                console.error(e.message, "search error");
                setFilteredIds(null);
            }
        },
        [data, dataIsArray, searchKeys, setFilteredIds]
    );

    const filteredData = useMemo(() => {
        if (!filteredIds) {
            if (dataIsArray) {
                return data;
            } else {
                return Object.values(data);
            }
        } else {
            if (dataIsArray) {
                return data.filter((row) => filteredIds.includes(row.id));
            } else {
                return Object.values(data).filter((row) => filteredIds.includes(row.id));
            }
        }
    }, [data, dataIsArray, filteredIds]);

    return [filteredData, handleSearch];
}
