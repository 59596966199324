import classNames from "classnames";
import { useMemo, useState } from "react";
import { useDontShrink } from "../../../../common/hooks/useDontShrink";
import { makeRatingsArray } from "../../../Snapshots/utils/snapshotHelpers";
import TalentStrength from "../../../Traits/components/organisms/TalentStrength";
import { useLiveInsightTree } from "../../hooks/useLiveInsightTree";
import TalentAreaSunburst from "../atoms/TalentAreaSunburst";
import RatingRankTable from "../molecules/RatingRankTable";
import InsightsToolbar from "../organisms/InsightsToolbar";

const InsightsTemplate = ({
    className,
    roleId,
    subordinates,
    roles,
    talentAreas,
    talentBoards,
    traits,
    comparisonSnapshot,
    fetchTrait,
}) => {
    const hasComparison = !!comparisonSnapshot;
    const [filteredDepartmentId, setFilteredDepartmentId] = useState(roleId);
    const { departmentTree, searchDepartmentTree } = useLiveInsightTree(
        roleId,
        subordinates,
        roles,
        talentAreas,
        talentBoards,
        traits
    );
    const selectedDepartment = useMemo(() => {
        return searchDepartmentTree(departmentTree, filteredDepartmentId);
    }, [departmentTree, filteredDepartmentId, searchDepartmentTree]);

    const { snapshot } = selectedDepartment || {};
    const { traitRatings, overallRating } = snapshot || {};

    const ratingsArray = useMemo(() => makeRatingsArray(traitRatings), [traitRatings]);

    return (
        <div className={classNames("flex-1 flex flex-col items-stretch overflow-hidden space-y-4", className)}>
            <div className="pt-4 px-8">
                <InsightsToolbar
                    topLevelRoleId={roleId}
                    departmentRoleId={filteredDepartmentId}
                    onSelectDepartment={setFilteredDepartmentId}
                />
            </div>
            <div className="flex-1 px-8 py-6 space-y-4 overflow-scroll">
                <div className="flex flex-col min-h-60">
                    <TalentStrength
                        departmentTree={departmentTree}
                        selectedDepartment={selectedDepartment}
                        filteredDepartmentId={filteredDepartmentId}
                        ratings={traitRatings}
                        fetchTrait={fetchTrait}
                        setFilteredDepartmentId={setFilteredDepartmentId}
                    />
                </div>
                <hr />
                <div className="flex py-8">
                    <div className="flex-4 px-12">
                        <TalentAreaSunburst
                            overallRating={overallRating}
                            traits={traits}
                            talentAreas={talentAreas}
                            traitRatings={traitRatings}
                            fetchTrait={fetchTrait}
                        />
                    </div>
                    <div className="flex-3 flex flex-col overflow-hidden rounded-lg border">
                        <RatingRankTable traits={traits} ratings={ratingsArray} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsightsTemplate;

/*
sunburst
                <div className={classNames("p-8 flex flex-col items-center space-y-2 bg-primary bg-opacity-10")}>
                    <DepartmentSunburst
                        departmentTree={departmentTree}
                        activeSegmentId={filteredDepartmentId}
                        onSegmentClick={setFilteredDepartmentId}
                    />
                    <h6>{selectedDepartment?.tooltip}</h6>
                </div>

*/

/* workout how to show talentboards

                    <div className={classNames("flex-1 flex flex-col divide-y")}>
                        <Each
                            of={representedBoards}
                            render={(board) => {
                                const { boardName, linkedTraitIds } = board;
                                return (
                                    <div className="p-8">
                                        <RatingRadarChart
                                            label={`${boardName} Board`}
                                            traits={traits}
                                            ratings={traitRatings}
                                            traitIds={linkedTraitIds || []}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>


*/
