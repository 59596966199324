import { RichText } from "../../../../components";

function checkIfEmpty(description) {
    if (!description) return true;
    const blocks = description.blocks || [];
    if (blocks.length === 0) return true;
    if (blocks.length === 1 && blocks[0].text === "") return true;
    return false;
}

const ObjectiveDescription = ({
    description,
    readOnly,
    placeholder = "No description provided",
    className = "flex-1 border-none",
    onChange = () => {},
}) => {
    const isEmpty = checkIfEmpty(description);
    if (isEmpty && readOnly) return <div className="h-16 p-4">No Description Provided.</div>;

    return (
        <RichText
            content={description}
            readOnly={readOnly}
            className={className}
            minHeight={120}
            maxHeight={180}
            onChange={onChange}
            removePadding
            placeholder={placeholder}
        />
    );
};

export default ObjectiveDescription;
