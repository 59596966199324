import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../../../components/MyComponents/Button";
import MyInputGroup from "../../../components/MyComponents/InputGroup";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../components/MyComponents/Modal";
import { selectEmployeeThunk } from "../../../redux/appSlice";
import { getSelectedEmployee } from "../../Employees/utils/employeeSelectors";
import { changeEmployeeBusinessUnitThunk } from "../utils/businessUnitThunks";
import BusinessUnitPicker from "./BusinessUnitPicker";

const ChangeEmployeeBusinessUnit = ({ isOpen, toggle }) => {
    const dispatch = useDispatch();
    const businessUnits = useSelector((state) => state.workspace.businessUnits);
    const businessUnitId = useSelector((state) => state.businessUnit.businessUnitId);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedEmployee = useSelector(getSelectedEmployee);
    const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState(businessUnitId);
    const targetBusinessUnit = businessUnits[selectedBusinessUnitId] || {};

    const handleSetSelectedBusinessUnitId = (unitId) => {
        setSelectedBusinessUnitId(unitId);
    };

    const handleMigrateEmployee = async () => {
        dispatch(changeEmployeeBusinessUnitThunk(selectedEmployeeId, selectedBusinessUnitId, targetBusinessUnit));
        dispatch(selectEmployeeThunk());
        toggle();
    };

    if (!selectedEmployee) return null;

    return (
        <MyModal isOpen={isOpen} size="sm">
            <MyModalHeader onToggle={toggle}>Change Business Unit</MyModalHeader>
            <MyModalBody className="p-3 px-8 pb-10">
                <h4 className="text-center">{selectedEmployee.displayName}</h4>
                <p>You are about to move this employee to a new Business Unit.</p>
                <hr />
                <ul className="text-sm list-disc list-outside ps-3 space-y-2 pt-3 pb-8">
                    <li>If they are holding a role in their current Business Unit, it will be vacated</li>
                    <li>They will be removed as a successor for any roles in the current Business Unit</li>
                    <li>
                        They will move to the new business unit with no role, and will be available to be assigned to
                        any vacant role
                    </li>
                </ul>
                <hr className="my-4" />
                <MyInputGroup label="New Business Unit" name="businessUnitName">
                    <BusinessUnitPicker
                        stretch
                        selectedIds={selectedBusinessUnitId}
                        onChange={handleSetSelectedBusinessUnitId}
                    />
                </MyInputGroup>
            </MyModalBody>
            <MyModalFooter>
                <MyButton
                    label="migrate"
                    color="secondary"
                    disabled={businessUnitId === selectedBusinessUnitId}
                    onClick={handleMigrateEmployee}
                >
                    Migrate Employee
                </MyButton>
            </MyModalFooter>
        </MyModal>
    );
};

ChangeEmployeeBusinessUnit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
};

export default ChangeEmployeeBusinessUnit;
