import classNames from "classnames";
import React, { useState } from "react";
import { useFieldLabels } from "../../../Workspace/hooks/useFieldLabels";
import { useTalentMap } from "../../hooks/useTalentMap";
import { useTalentMapConfig } from "../../hooks/useTalentMapConfig";
import CustomGrid from "./CustomGrid";
import SegmentView from "../molecules/SegmentView";
import TalentScatterChart from "./TalentScatterChart";
import "../talent-map.scss";

const TalentMap = ({ className, talentMapRef, preppedEmployees, onSegmentOpen, onItemClick }) => {
    const [selectedSegment, selectSegment] = useState(null);
    const { talentMapConfig } = useTalentMapConfig();
    const { grid, scatterData } = useTalentMap(preppedEmployees, talentMapConfig);
    const { getFieldLabel } = useFieldLabels();
    const { x, y, chartType, singleAxis } = talentMapConfig;
    const hideYAxis = singleAxis && chartType !== "scatter";

    const handleSegmentClick = (segment) => {
        selectSegment(segment);
        onSegmentOpen(true);
    };

    const handleCloseSegment = () => {
        selectSegment(null);
        onSegmentOpen(false);
    };

    const handleItemClick = (employeeId) => {
        onItemClick(employeeId);
    };

    if (selectedSegment) {
        return (
            <SegmentView
                isFullPage
                onItemClick={handleItemClick}
                segment={selectedSegment}
                onClose={handleCloseSegment}
            />
        );
    }

    return (
        <div className={classNames("flex h-full flex-col flex-1", className)}>
            <div ref={talentMapRef} className="overflow-hidden h-full w-full flex flex-col talent-map-container pt-2">
                <div className={classNames("talent-map")}>
                    {!hideYAxis && (
                        <div className="axis y-axis">
                            <div className="text">{getFieldLabel(y)}</div>
                        </div>
                    )}
                    {chartType === "scatter" && (
                        <TalentScatterChart snapshots={scatterData} onItemClick={handleItemClick} />
                    )}
                    {chartType === "grid" && (
                        <CustomGrid grid={grid} onSegmentClick={handleSegmentClick} onItemClick={handleItemClick} />
                    )}
                </div>
                <div className="axis text-center mb-3">{getFieldLabel(x)}</div>
            </div>
        </div>
    );
};

export default TalentMap;
