import { useMemo } from "react";
import { getParticipation } from "../utils/getParticipation";

function getLabel(done, required) {
    if (!done && required === false) {
        return <span className="text-base-500">Not Required</span>;
    } else if (done) {
        return <span className="text-success">Complete</span>;
    } else if (!done) {
        return <span className="text-error">Awaiting Response</span>;
    } else {
        return null;
    }
}

const TRACKING_FIELDS = [
    {
        id: "selfDone",
        label: "Employee Response",
        size: "sm",
        cellRenderer: (employee) => {
            const { selfDone, employeeRequired } = employee;
            return getLabel(selfDone, employeeRequired);
        },
    },
    {
        id: "managerDone",
        label: "Manager Response",
        size: "sm",
        cellRenderer: (employee) => {
            const { managerDone, managerRequired } = employee;
            return getLabel(managerDone, managerRequired);
        },
    },
];

export function useTrackingFields(iteration = {}) {
    const { pages } = iteration;
    const participationRequired = useMemo(() => {
        return getParticipation(pages);
    }, [pages]);

    return useMemo(() => {
        const { employeeRequired, managerRequired } = participationRequired;
        return TRACKING_FIELDS.filter((field) => {
            const includeEmployee = employeeRequired && field.id === "selfDone";
            const includeManager = managerRequired && field.id === "managerDone";
            return includeEmployee || includeManager;
        });
    }, [participationRequired]);
}
