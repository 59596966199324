import { collection, doc, limit, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";

const BASIC_NOTE_TYPES = ["general", "cycle"];

export function useEmployeeRefs(employeeId) {
    const workspaceRef = useWorkspaceRef();

    const employeesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "employees");
    }, [workspaceRef]);

    const employeeRef = useMemo(() => {
        if (!employeesRef || !employeeId) return null;
        return doc(employeesRef, employeeId);
    }, [employeesRef, employeeId]);

    const notesRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "notes");
    }, [employeeRef]);

    const assessmentsRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "assessments");
    }, [employeeRef]);

    const formsRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "forms");
    }, [employeeRef]);

    const selfAssessmentRef = useMemo(() => {
        if (!assessmentsRef) return null;
        const assessmentQuery = query(
            assessmentsRef,
            where("reviewerId", "==", employeeId),
            orderBy("updatedOn", "desc"),
            limit(1)
        );
        return assessmentQuery;
    }, [assessmentsRef, employeeId]);

    const talentAssessmentRef = useMemo(() => {
        if (!assessmentsRef) return null;
        const assessmentQuery = query(
            assessmentsRef,
            where("relationship", "==", "manager"),
            orderBy("updatedOn", "desc"),
            limit(1)
        );
        return assessmentQuery;
    }, [assessmentsRef]);

    const objectivesRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "objectives");
    }, [employeeRef]);

    const liveObjectivesRef = useMemo(() => {
        if (!objectivesRef) return null;
        const queryRef = query(objectivesRef, where("isComplete", "==", false));
        return queryRef;
    }, [objectivesRef]);

    const completeObjectivesRef = useMemo(() => {
        if (!objectivesRef) return null;
        const queryRef = query(objectivesRef, where("isComplete", "==", true));
        return queryRef;
    }, [objectivesRef]);

    const notificationsRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "notifications");
    }, [employeeRef]);

    const latestNotesRef = useMemo(() => {
        if (!notesRef) return null;
        const queryRef = query(
            notesRef,
            where("noteType", "in", BASIC_NOTE_TYPES),
            orderBy("createdOn", "desc"),
            limit(10)
        );
        return queryRef;
    }, [notesRef]);

    const latestSharedNotesRef = useMemo(() => {
        if (!notesRef) return null;
        const queryRef = query(
            notesRef,
            where("noteType", "in", BASIC_NOTE_TYPES),
            where("isPrivate", "==", false),
            orderBy("createdOn", "desc"),
            limit(10)
        );
        return queryRef;
    }, [notesRef]);

    return {
        employeesRef,
        employeeRef,
        selfAssessmentRef,
        talentAssessmentRef,
        objectivesRef,
        liveObjectivesRef,
        completeObjectivesRef,
        notificationsRef,
        notesRef,
        formsRef,
        latestNotesRef,
        latestSharedNotesRef,
        assessmentsRef,
    };
}
