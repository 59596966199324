import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { prepDocSnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { setWorkspaceData } from "../utils/workspaceSlice";

export function useWorkspaceListener() {
    const dispatch = useDispatch();
    const workspaceRef = useWorkspaceRef();

    const handleDataReceived = useCallback(
        (doc) => {
            const data = prepDocSnapshot(doc);
            dispatch(setWorkspaceData({workspaceId: doc.id, ...data}));
        },
        [dispatch]
    );

    return useListener(workspaceRef, handleDataReceived);
}
