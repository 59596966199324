import { withFormik } from "formik";
import { useSelector } from "react-redux";
import { ROOT_ROLE_ID } from "shared";
import { useLanguage } from "../../../../../translations/LanguageContext";
import { useBusinessUnits } from "../../../../BusinessUnits/hooks/useBusinessUnits";
import { useCustomFields } from "../../../../CustomFields/hooks/useCustomFields";
import { useEmployees } from "../../../../Employees/hooks/useEmployees";
import { useGroupedTalentBoards } from "../../../../TalentBoards/hooks/useGroupedTalentBoards";
import { useTalentBoards } from "../../../../TalentBoards/hooks/useTalentBoards";
import { useRoles } from "../../../hooks/useRoles";
import { getSelectedRole } from "../../../utils/roleSelectors";
import CreateOrFillRoleInnerForm from "./CreateOrFillRoleForm";
import { useEmployeesApi } from "../../../../Employees/hooks/useEmployeesApi";

const CreateRoleForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isOpen: props.isOpen,
            headerText: props.headerText,
            existingEmployee: false,
            fillWith: "new",
        };
    },
    mapPropsToValues: (props) => {
        return {
            ...props.defaultRoleValues,
            employeeNumberInUse: false,
            level: 2,
            parentRoleId: props.parentRoleId,
            department: props.defaultDepartment,
            talentBoardIds: props.defaultBoardIds,
            businessUnitId: props.businessUnitId,
        };
    },
    onSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
    enableReinitialize: true,
})(CreateOrFillRoleInnerForm);

const CreateRole = (props) => {
    const { appLanguage } = useLanguage();
    const language = appLanguage("forms", "create_or_fill_role");
    const { customEmployeeFields } = useCustomFields();
    const { getPreppedBoardIds } = useTalentBoards();
    const { autoAssignedBoardIds } = useGroupedTalentBoards();
    const selectedRole = useSelector(getSelectedRole);
    const employees = useSelector((state) => state.businessUnit.employees);
    let parentRoleId = selectedRole.id || ROOT_ROLE_ID;
    const { getNewRoleId, updateRole, getRole } = useRoles();
    const { getNewEmployeeId } = useEmployees();
    const { createEmployee, updateEmployee } = useEmployeesApi();
    const { buildBoardMapUpdate, updateBoardMap } = useBusinessUnits();
    const parentRole = getRole(parentRoleId);
    const defaultBoardIds = getPreppedBoardIds(parentRole, autoAssignedBoardIds);

    function getEmployeeId(fillWith, existingEmployeeId) {
        switch (fillWith) {
            case "new":
                return getNewEmployeeId();
            case "current":
                return existingEmployeeId;
            case "vacant":
            default:
                return null;
        }
    }

    const handleSubmit = async (formValues, formStatus) => {
        const {
            customClaims,
            existingEmployeeId,
            jobTitle,
            employeeNumberInUse,
            talentBoardIds,
            emailInUse,
            level,
            department,
            ...employeeFormValues
        } = formValues;
        const { fillWith } = formStatus;

        // Get the ids for the role and employee (employeeId will be null if the role is being created vacant)
        const employeeId = getEmployeeId(fillWith, existingEmployeeId);
        const roleId = getNewRoleId();

        // Create the role update
        let roleUpdate = {
            jobTitle: jobTitle,
            parentRoleId: parentRoleId,
            incumbentId: employeeId,
            level: level,
        };
        // If the department if different from the parent, add it to the role
        if (department && department !== parentRole.department) {
            roleUpdate.department = department;
        }

        let batch = updateRole(roleId, roleUpdate);

        // Update the board map
        let boardMapUpdate = buildBoardMapUpdate(roleId, talentBoardIds);
        if (Object.keys(boardMapUpdate).length > 0) {
            batch = updateBoardMap(boardMapUpdate, batch);
        }

        // Update the employee if there is one
        const currentEmployee = employees[employeeId] || {};
        const employeeUpdate = { ...currentEmployee, ...employeeFormValues, roleId, parentRoleId };
        if (employeeId) {
            if (existingEmployeeId) {
                batch = updateEmployee(employeeId, employeeUpdate, batch);
            } else {
                batch = createEmployee(employeeId, employeeUpdate, batch);
            }
        }

        // Commit the batch
        try {
            await batch.commit();
            // Update the redux store immediately
            /*
            const parentMapUpdate = {
                [roleId]: parentRoleId,
            };
            let selectThese = {
                roleId: roleId,
                employeeId: employeeId,
            };
            dispatch({ type: ADD_ROLE, payload: [parentMapUpdate, roleUpdate, currentEmployee] });
            dispatch({ type: SELECT_EMPLOYEE, payload: selectThese });
            */
            // Close the form
            props.toggle();
        } catch (e) {
            console.error("Error creating role", e);
        }
    };

    return (
        <CreateRoleForm
            {...props}
            defaultDepartment={parentRole.department}
            defaultBoardIds={defaultBoardIds}
            onSubmit={handleSubmit}
            language={language}
            customEmployeeFields={customEmployeeFields}
            headerText="Create Role"
        />
    );
};

export default CreateRole;
