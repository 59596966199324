import { useMemo } from "react";
import { useRoleRelationships } from "./useRoleRelationships";

export function filterRoles(roles, filters, getChildRoleIds, getAllRoleIdsBelow) {
    function helper_groupFilters(filters) {
        const includeFilters = filters.filter((filter) => filter.filterType === "include");
        const excludeFilters = filters.filter((filter) => filter.filterType === "exclude");
        return { includeFilters, excludeFilters };
    }

    function helper_determineRoleIds(groupedFilters, getChildRoleIds, getAllRoleIdsBelow) {
        function buildRoleIdArray(filter, ids) {
            const { roleId, filterSelectedRole, scope } = filter;

            // Add the role itself when requested
            if (filterSelectedRole || scope === "incumbent") ids.push(roleId);

            if (scope === "direct_reports") {
                // Add the direct child role Ids
                const childRoleIds = getChildRoleIds(roleId);
                ids.push(...childRoleIds);
            } else if (scope === "subordinates") {
                // Add all role Ids below the role
                const subordinateIds = getAllRoleIdsBelow(roleId);
                ids.push(...subordinateIds);
            }
        }

        const allIdsToInclude = [];
        const allIdsToExclude = [];
        const { includeFilters, excludeFilters } = groupedFilters;

        includeFilters.forEach((filter) => {
            buildRoleIdArray(filter, allIdsToInclude);
        });
        excludeFilters.forEach((filter) => {
            buildRoleIdArray(filter, allIdsToExclude);
        });
        const includeIds = [...new Set(allIdsToInclude)];
        const excludeIds = [...new Set(allIdsToExclude)];
        return { includeIds, excludeIds };
    }

    const groupedFilters = helper_groupFilters(filters);
    const roleIds = helper_determineRoleIds(groupedFilters, getChildRoleIds, getAllRoleIdsBelow);

    return Object.values(roles).filter((role) => {
        const { id } = role;
        const isIncluded = roleIds.includeIds.includes(id);
        const isExcluded = roleIds.excludeIds.includes(id);
        return isIncluded && !isExcluded;
    });
}

export function useRoleFilters(filters = [], roles = {}) {
    const { getChildRoleIds, getAllRoleIdsBelow } = useRoleRelationships();

    const filteredRoles = useMemo(() => {
        return filterRoles(roles, filters, getChildRoleIds, getAllRoleIdsBelow);
    }, [roles, filters, getChildRoleIds, getAllRoleIdsBelow]);

    return {
        filteredRoles,
    };
}
