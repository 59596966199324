import { firstDateIsBeforeSecondDate } from "../../../common/utils/dateUtils";
import { buildFormId } from "../../Forms/utils/buildFormId";

export function buildFormChangedStatus(
    userRelationship,
    iterationId,
    employeeId,
    managerAssessedOn,
    selfAssessedOn,
    lastViewed
) {
    const trackingId = buildFormId(iterationId, employeeId);
    const lastViewedDate = lastViewed[trackingId];
    switch (userRelationship) {
        case "employee":
            return firstDateIsBeforeSecondDate(lastViewedDate, managerAssessedOn);
        case "manager":
            return firstDateIsBeforeSecondDate(lastViewedDate, selfAssessedOn);
        default:
            return false;
    }
}
