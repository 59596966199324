import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import LoadingIndicator from "../../../../components/LoadingIndicator/LoadingIndicator";
import { loadLocalConfig } from "../../../../redux/localStorage";
import { useBusinessUnitListeners } from "../../../BusinessUnits/hooks/useBusinessUnitListeners";
import { useConfigListeners } from "../../../Config/hooks/useConfigListeners";
import { initialiseOwnConfig } from "../../../Config/utils/configSlice";
import { useActiveReviews } from "../../../Dashboard/hooks/useActiveReviews";
import { useNotificationListener } from "../../../Notifications/hooks/useNotificationListener";
import { useSnapshotListener } from "../../../Snapshots/hooks/useSnapshotListener";
import { useClaims } from "../../../User/hooks/useClaims";
import { useOwnDataListeners } from "../../../User/hooks/useOwnDataListeners";
import { useIsWorkspaceReady } from "../../hooks/useIsWorkspaceReady";
import { useWorkspaceListeners } from "../../hooks/useWorkspaceListeners";
import ConfigureWorkspace from "../../legacy/ConfigureWorkspace";
import WorkspaceTemplate from "../templates/WorkspaceTemplate";

const WorkspaceContainer = () => {
    const dispatch = useDispatch();
    const { userEmployeeId } = useClaims();
    const uid = useSelector((state) => state.app.uid);
    const [error, setError] = useState(null);
    const workspaceId = useSelector((state) => state.user.activeWorkspaceId);
    const isConfigured = useSelector((state) => state.workspace.configured);
    const workspaceReady = useWorkspaceListeners();
    const businessUnitReady = useBusinessUnitListeners();
    const configReady = useConfigListeners(uid, workspaceId);
    const workspaceInitialised = useIsWorkspaceReady();
    useSnapshotListener(workspaceReady, businessUnitReady);
    useNotificationListener(businessUnitReady);
    useActiveReviews(userEmployeeId);
    useOwnDataListeners();

    useEffect(() => {
        const localConfig = loadLocalConfig(uid, workspaceId);
        dispatch(initialiseOwnConfig(localConfig));
    }, [dispatch, workspaceId, uid]);

    // Timeout & throw error if the workspace doesn't load in a reasonable time
    useEffect(() => {
        if (uid) {
            const timeout = setTimeout(() => {
                if (!workspaceInitialised) {
                    setError("Workspace Failed to Load");
                }
            }, 15000);
            return () => clearTimeout(timeout);
        }
    }, [uid, workspaceInitialised]);

    if (error) {
        throw new Error(error);
    }

    if (!workspaceReady) return <LoadingIndicator fullscreen message="Fetching Workspace" />;
    if (!workspaceInitialised) return <LoadingIndicator fullscreen message="Preparing Workspace" />;
    if (!isConfigured) return <ConfigureWorkspace />;
    if (!businessUnitReady) return <LoadingIndicator fullscreen message="Preparing Workspace" />;
    if (!configReady) return <LoadingIndicator fullscreen message="Preparing Workspace" />;

    return <WorkspaceTemplate />;
};

export default WorkspaceContainer;
