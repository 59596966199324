import React from "react";
import { Button } from "../../../../common/components";
import { confirmFirst } from "../../../../common/utils/confirmFirst";
import { DeleteIcon, DownloadIcon, EditIcon, PlusIcon } from "../../../../components/Icons/MyIcons";
import { useClaims } from "../../../User/hooks/useClaims";
import { useTalentMapApi } from "../../hooks/useTalentMapApi";
import { useTalentMapConfig } from "../../hooks/useTalentMapConfig";
import TalentMapPicker from "../atoms/TalentMapPicker";

const iconSize = 16;

const TalentMapToolbar = ({ hideControls, setEditMode, setConfigValue, onExport }) => {
    const { isAdmin } = useClaims();
    const { removeTalentMap } = useTalentMapApi();
    const { talentMapConfig } = useTalentMapConfig();
    const { readOnly, shared } = talentMapConfig || {};
    const disableEdits = !talentMapConfig || readOnly || (shared && !isAdmin);
    const activeTalentMapId = talentMapConfig?.id;

    const handleChangeActiveMap = (id) => {
        setConfigValue("activeTalentMapId", id);
    };

    const handleEditTalentMap = () => {
        setEditMode("edit");
    };

    const handleCopyTalentMap = () => {
        setEditMode("copy");
    };

    const handleDeleteTalentMap = () => {
        function onConfirm() {
            removeTalentMap(activeTalentMapId, shared);
        }
        const { shared } = talentMapConfig;
        const sharedTitle = "Delete Shared Talent Map";
        const sharedMessage = "Are you sure? It will be deleted for all users.";
        const privateTitle = "Delete Talent Map";
        const privateMessage = "Are you sure you want to delete this Talent Map?";
        confirmFirst({
            title: shared ? sharedTitle : privateTitle,
            message: shared ? sharedMessage : privateMessage,
            onConfirm,
        });
    };

    const handleExportTalentMap = () => {
        onExport();
    };

    return (
        <div className="flex flex-1 items-center justify-between space-x-2">
            {hideControls && <h3>Talent Map</h3>}
            <div className="flex flex-1 space-x-2 items-center justify-end">
                <TalentMapPicker selectedId={activeTalentMapId} className="w-80" onChange={handleChangeActiveMap} />
                {!hideControls && (
                    <div className="flex space-x-2 items-center">
                        <Button
                            shape="circle"
                            size="sm"
                            tooltip="Delete Talent Map"
                            variant="outline"
                            color="error"
                            onClick={handleDeleteTalentMap}
                            disabled={disableEdits}
                        >
                            <DeleteIcon size={iconSize} />
                        </Button>
                        <Button
                            shape="circle"
                            size="sm"
                            tooltip="Edit Talent Map"
                            onClick={handleEditTalentMap}
                            color="secondary"
                            disabled={disableEdits}
                        >
                            <EditIcon size={iconSize} />
                        </Button>
                        <Button
                            color="secondary"
                            shape="circle"
                            size="sm"
                            tooltip="Create Talent Map"
                            onClick={handleCopyTalentMap}
                        >
                            <PlusIcon size={iconSize} />
                        </Button>
                        <Button
                            color="secondary"
                            shape="circle"
                            size="sm"
                            tooltip="Export Talent Map"
                            onClick={handleExportTalentMap}
                        >
                            <DownloadIcon size={iconSize} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TalentMapToolbar;
