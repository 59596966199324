import { useRef } from "react";
import { toPng } from "html-to-image";
import { useCallback } from "react";

const useComponentToImage = (onExport) => {
    const ref = useRef(null);
    const generateImageFromComponent = useCallback(async () => {
        if (ref.current) {
            try {
                const dataUrl = await toPng(ref.current);

                if (onExport) {
                    // Call the provided onExport function if it's available
                    onExport(dataUrl);
                } else {
                    // Otherwise, trigger a download automatically
                    const link = document.createElement("a");
                    link.href = dataUrl;
                    link.download = "exported-component.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Clean up the link element
                }
            } catch (err) {
                console.error("Could not export image:", err);
            }
        }
    }, [onExport]);

    return [ref, generateImageFromComponent];
};

export default useComponentToImage;
