import classNames from "classnames";
import { PropTypes } from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useClickOutside } from "../../../../common/hooks/useClickOutside";
import { useFilteredRoles } from "../../hooks/useFilteredRoles";
import { useRoleOptions } from "../../hooks/useRoleOptions";

const RolePicker = ({
    selectedIds = [],
    excludeIds = [],
    isMulti,
    isClearable,
    shortLabel,
    filterBasisRoleId,
    highestAllowedRoleId,
    excludeDirectReports,
    excludeBranch,
    excludeSelf,
    excludeParent,
    className,
    fallbackRoleId,
    maxMenuHeight = 220,
    placeholder = "Select Role",
    classNamePrefix,
    onChange = () => {},
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const clickRef = useRef(null);
    useClickOutside(clickRef, () => setIsOpen(false));

    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const baseRoleId = filterBasisRoleId || rootRoleId;

    const includedRoles = useFilteredRoles({
        baseRoleId,
        excludeSelf,
        excludeParent,
        excludeDirectReports,
        excludeBranch,
    });

    const roleOptions = useRoleOptions({ includedRoles, shortLabel, excludeIds });

    const value = useMemo(() => {
        function getIncludedValue() {
            let newValue = null;
            newValue = roleOptions.filter((option) => selectedIds.includes(option.id));
            if (isMulti) {
                return newValue;
            } else {
                return newValue[0];
            }
        }
        const includedValue = getIncludedValue();
        if (includedValue) return includedValue;
        if (!!selectedIds && !includedValue && fallbackRoleId) {
            const fallbackOption = roleOptions.find((option) => option.id === fallbackRoleId);
            if (fallbackOption) {
                return fallbackOption;
            }
        }
        return null;
    }, [roleOptions, selectedIds, isMulti, fallbackRoleId]);

    // If value isn't in options, set to fallbackRoleId
    const handleChange = (option) => {
        onChange(option?.id || highestAllowedRoleId);
    };

    return (
        <div ref={clickRef}>
            <Select
                menuIsOpen={isOpen}
                onMenuClose={() => setIsOpen(false)}
                onMenuOpen={() => setIsOpen(true)}
                isMulti={isMulti}
                isClearable={isClearable}
                className={classNames("h-10 field-picker", className)}
                classNamePrefix={classNames("field-picker", classNamePrefix)}
                maxMenuHeight={maxMenuHeight}
                type="select"
                placeholder={placeholder}
                onChange={handleChange}
                options={roleOptions}
                value={value}
            />
        </div>
    );
};

RolePicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    filterBasisRoleId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    excludeDirectReports: PropTypes.bool,
    excludeBranch: PropTypes.bool,
    excludeSelf: PropTypes.bool,
    excludeParent: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default RolePicker;
