import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { LoadingIndicator } from "../../../../components";
import WordCloud from "../../../../components/WordCloud/WordCloud";
import { useAssessmentColors } from "../../../Config/hooks/useAssessmentColors";

const TraitWordCloud = ({ traits, ratings = [], isPending, fetchTrait }) => {
    const [data, setData] = useState([]);
    const { assessmentColors } = useAssessmentColors();

    useEffect(() => {
        let newData = [];
        let newHighestRating = 0;
        ratings.forEach((rating) => {
            const trait = traits[rating.id];
            newData.push({
                word: trait && trait.traitName,
                traitId: rating.id,
                rating: rating.rating,
            });
            newHighestRating = Math.max(newHighestRating, rating.rating);
        });
        newData = orderBy(newData, ["value", "desc"]);
        setData(newData);
    }, [ratings, traits]);

    const handleClickTag = async (word) => {
        const fullTrait = await fetchTrait(word.traitId);
        confirmAlert({
            title: fullTrait.traitName,
            message: fullTrait.description,
            buttons: [
                {
                    label: "Done",
                },
            ],
        });
    };

    if (isPending) return <LoadingIndicator />;

    return <WordCloud wordEntries={data} colors={assessmentColors} onWordClick={handleClickTag} />;
};

TraitWordCloud.propTypes = {
    ratings: PropTypes.array,
};

export default memo(TraitWordCloud);
