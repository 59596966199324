import { useCallback, useMemo } from "react";
import { useListener } from "../../../firebase/hooks/useListener";
import { useRoleRefs } from "../refs/useRoleRefs";
import { ensureSerializable } from "../../../common/utils/objectUtils";

export function useRoleListener(roleId, onDataReceived) {
    const { roleRef } = useRoleRefs(roleId);

    const shouldListen = useMemo(() => {
        return !!roleId;
    }, [roleId]);

    const handleDataReceived = useCallback(
        (doc) => {
            let roleData = doc.data();
            const roleId = doc.id;
            roleData = ensureSerializable(roleData);
            const prepped = { ...roleData, id: roleId };
            onDataReceived(prepped);
        },
        [onDataReceived]
    );

    return useListener(roleRef, handleDataReceived, shouldListen);
}
