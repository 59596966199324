import { groupBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MyButton from "../../../../components/MyComponents/Button";
import { ViewIcon } from "../../../../components/Icons/MyIcons";
import TraitPieChart from "../../../Traits/containers/TraitPieChart";

const SummaryTile = ({ bigTile, segment = {}, onClick = () => {} }) => {
    const { cohort, percent } = segment;
    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        let groupedByZ = {};
        Object.entries(groupBy(cohort, "z")).forEach(([key, ary]) => {
            groupedByZ[key] = ary.length;
        });
        setPieData(groupedByZ);
    }, [cohort]);

    const employeeLabel = cohort.length > 1 ? "Employees" : "Employee";

    const renderSmallTile = () => (
        <div className="flex-1 flex p-2">
            <div className="p-2 flex-3 info-container flex flex-col justify-between">
                <div>
                    <h2 className="mb-2">{cohort.length}</h2>
                    <h6 className="text-sm">{`${employeeLabel} (${percent}%)`}</h6>
                </div>
                <MyButton size="sm" shape="circle" onClick={() => onClick(segment)}>
                    <ViewIcon />
                </MyButton>
            </div>
            <div className="flex-2">
                <TraitPieChart ratingCounts={pieData} className="px-3" />
            </div>
        </div>
    );

    const renderBigTile = () => (
        <div className="flex-1 flex p-2 flex-col">
            <div className="p-2 flex-3 info-container flex flex-col items-center">
                <div style={{ textAlign: "center" }}>
                    <h1 className="mb-2">{cohort.length}</h1>
                    <h6>{`${employeeLabel} (${percent}%)`}</h6>
                </div>
            </div>
            <div className="flex-2 px-5 pb-5">
                <TraitPieChart ratingCounts={pieData} className="p-2" />
            </div>
            <MyButton size="sm" shape="circle" onClick={() => onClick(segment)}>
                <ViewIcon />
            </MyButton>
        </div>
    );

    if (bigTile) {
        return renderBigTile();
    } else {
        return renderSmallTile();
    }
};

SummaryTile.propTypes = {
    segment: PropTypes.object,
    onClick: PropTypes.func,
};

export default SummaryTile;
