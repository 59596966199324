import { prepEmployeeSnapshot } from "./snapshotHelpers";
import { ADD_EMPLOYEE_SNAPSHOTS } from "./snapshotsSlice";

const prepEmployeeSnapshotsThunk = (snapshots) => async (dispatch, getState) => {
    const allSnapshots = getState().snapshots.employeeSnapshots;
    const { traits, talentBoards, talentAreas } = getState().workspace;
    const { employees, boardMap } = getState().businessUnit;
    let snapshotsToPrep = snapshots || allSnapshots || {};

    // Iterate the employee snapshots and prepare them
    let snapshotUpdates = {};
    Object.entries(snapshotsToPrep).forEach(([employeeId, rawSnapshot]) => {
        const thisEmployee = employees[employeeId];
        const preppedSnapshot = prepEmployeeSnapshot({
            employeeId,
            thisEmployee,
            rawSnapshot,
            traits,
            talentBoards,
            talentAreas,
            boardMap,
        });
        snapshotUpdates[employeeId] = preppedSnapshot;
    });

    dispatch({ type: ADD_EMPLOYEE_SNAPSHOTS, payload: snapshotUpdates });
    return snapshotUpdates;
};

export { prepEmployeeSnapshotsThunk };
