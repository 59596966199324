import { limit, query, where, orderBy } from "firebase/firestore";
import { useMemo } from "react";
import { usePaginatedFetch } from "../../../hooks/usePaginatedFetch";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { useClaims } from "../../User/hooks/useClaims";
import { useNotesApi } from "./useNotesApi";
import { useEmployees } from "../../Employees/hooks/useEmployees";

export function usePaginatedNotes(employeeId, objectiveId) {
    const { userEmployeeId } = useClaims();
    const { getEmployee } = useEmployees();
    const { latestNotesRef, latestSharedNotesRef, notesRef } = useEmployeeRefs(employeeId);
    const { createNote, editNote, deleteNote } = useNotesApi(employeeId);

    const initialNotesRef = useMemo(() => {
        if (objectiveId) {
            const objectiveQuery = query(
                notesRef,
                where("objectiveId", "==", objectiveId),
                orderBy("createdOn", "desc"),
                limit(10)
            );
            return objectiveQuery;
        } else if (userEmployeeId !== employeeId) {
            return latestNotesRef;
        } else {
            return latestSharedNotesRef;
        }
    }, [employeeId, objectiveId, userEmployeeId, notesRef, latestNotesRef, latestSharedNotesRef]);

    const [fetchedNotes, fetchMore, hasMore, _, addItem, removeItem] = usePaginatedFetch(initialNotesRef, 10);

    const notes = useMemo(() => {
        return fetchedNotes.map((note) => {
            const author = getEmployee(note.authorId);
            const enhancedNote = { ...note, author, isAuthor: note.authorId === userEmployeeId };
            return enhancedNote;
        });
    }, [fetchedNotes, getEmployee, userEmployeeId]);

    const handleCreateNote = (note) => {
        const dummyFetched = createNote(note);
        const ADD_TO_START = true;
        addItem(dummyFetched, ADD_TO_START);
    };

    const handleEditNote = (note) => {
        const fullNote = editNote(note);
        const ADD_TO_START = true;
        addItem(fullNote, ADD_TO_START);
    };

    const handleDeleteNote = (noteId) => {
        deleteNote(noteId);
        removeItem(noteId);
    };

    return {
        notes,
        fetchMore,
        hasMore,
        onEditNote: handleEditNote,
        onCreateNote: handleCreateNote,
        onDeleteNote: handleDeleteNote,
    };
}
