import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import useLoggedNavigate from "../../common/hooks/useLoggedNavigate";

const MyTabs = (props) => {
    const navigate = useLoggedNavigate();
    const {
        tabs = [],
        activeTabKey,
        tabKeyOverride,
        color = "border",
        className = "rounded-none bg-transparent",
        onChange = () => {},
        ...cleanProps
    } = props;

    const activeKey = typeof activeTabKey === "object" ? activeTabKey?.key : activeTabKey;

    const handleChange = (tabKey) => {
        const tab = tabs.find((tab) => tab.key === tabKey);
        if (tab.link) navigate(tab.link);
        if (tab?.disabled) return;
        onChange(tabKey);
    };

    const addColorTo = props.variant === "bordered" ? "border" : "bg";
    const colorClass = `${addColorTo}-${color}`;
    const contentColorClass = `text-${color}-content`;

    return (
        <div className={`tabs ${className}`} {...cleanProps}>
            {tabs.map((tab) => {
                const { key, label, disabled } = tab;
                const isActive = activeKey === key;
                const bordered = props.variant === "bordered";

                return (
                    <button
                        key={`tab-${key}`}
                        className={classNames("tab tab-bordered rounded-t-lg !font-normal", {
                            [colorClass]: isActive,
                            [contentColorClass]: isActive,
                            "border-transparent": !isActive,
                            "border-b-white": bordered && isActive,
                            "border-t border-x border-base-500": !disabled,
                            "!bg-white": isActive,
                        })}
                        onClick={() => handleChange(key)}
                        disabled={disabled}
                    >
                        <span className="tracking-tighter">{label}</span>
                    </button>
                );
            })}
        </div>
    );
};

MyTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            link: PropTypes.string,
            disabled: PropTypes.bool,
        })
    ),
    activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

export default MyTabs;
