import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Collapse } from "react-daisyui";
import { LessMoreIcon, PlusMinusIcon } from "../Icons/MyIcons";
import MyButton from "./Button";

function getIcon(variant, isOpen) {
    switch (variant) {
        case "expandPlus":
            return <PlusMinusIcon checked={isOpen} size={16} />;
        default:
            return <LessMoreIcon checked={isOpen} size={16} />;
    }
}

export const MyCollapseTitle = ({
    className = "p-1",
    closedClassName,
    toggleOnClick,
    hideToggle,
    variant,
    children,
    isOpen,
    onToggle,
}) => {
    const handleToggle = () => {
        if (onToggle) {
            onToggle();
        }
    };

    const handleClick = () => {
        if (!toggleOnClick) return;
        handleToggle();
    };

    return (
        <Collapse.Title
            onClick={handleClick}
            className={classNames("flex justify-between min-h-0", className, { [closedClassName]: !isOpen })}
        >
            <div className="flex-1 flex items-center">{children}</div>
            <div className="flex items-center">
                <MyButton hidden={hideToggle} size="xs" shape="circle" active={isOpen} onClick={handleToggle}>
                    {getIcon(variant, isOpen)}
                </MyButton>
            </div>
        </Collapse.Title>
    );
};

export const MyCollapseContent = ({ className, children }) => (
    <Collapse.Content className={className}>{children}</Collapse.Content>
);

export const MyCollapse = ({
    className = "cursor-pointer border border-base-800 bg-base-100",
    overflow = "hidden",
    isOpen,
    onToggle = () => {},
    children,
}) => {
    const handleToggle = () => {
        onToggle(!isOpen);
    };

    const enhancedChildren = React.Children.map(children, (child) => {
        if (!child) return null;
        return React.cloneElement(child, {
            onToggle: handleToggle,
            isOpen: isOpen,
        });
    });

    const style = {
        overflow: overflow,
    };

    return (
        <Collapse open={isOpen} className={classNames(className)} style={style}>
            {enhancedChildren}
        </Collapse>
    );
};

MyCollapse.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
};
