import { collection } from "firebase/firestore";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { useMemo } from "react";

export function useTalentRefs() {
    const workspaceRef = useWorkspaceRef();

    const talentInfoRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "talentInfo");
    }, [workspaceRef]);

    return { talentInfoRef };
}
