import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWorkspaceData } from "../../Workspace/utils/workspaceSlice";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { prepDocSnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useListener } from "../../../firebase/hooks/useListener";

export function useBusinessUnitsListener() {
    const dispatch = useDispatch();
    const businessUnitsRef = useWorkspaceRef("businessUnits");
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const featureFlips = useSelector((state) => state.config.everyoneConfig.featureFlips);
    const businessUnitsEnabled = featureFlips?.businessUnits;

    const shouldListen = useMemo(() => {
        return isAdmin && businessUnitsEnabled;
    }, [isAdmin, businessUnitsEnabled]);

    const setWorkspaceDataCallback = useCallback(
        (querySnapshot) => {
            const data = prepDocSnapshot(querySnapshot);
            dispatch(setWorkspaceData(data));
        },
        [dispatch]
    );

    const listenerStatus = useListener(businessUnitsRef, setWorkspaceDataCallback, shouldListen);
    const status = !businessUnitsEnabled ? "success" : listenerStatus;

    return status;
}
