import React, { useState } from "react";

const SearchField = ({ minWidth = 220, placeholder = "search", onChange }) => {
    const [searchText, setSearchText] = useState(null);
    const style = { minWidth: minWidth };

    const handleChange = (e) => {
        const value = e?.target?.value || null;
        setSearchText(value);
        onChange(value);
    };

    return (
        <div style={style}>
            <label className="bg-white px-4 rounded-lg border h-10 flex items-center gap-2">
                <input
                    value={searchText || ""}
                    type="text"
                    className="grow font-normal focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none"
                    placeholder={placeholder}
                    onChange={handleChange}
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="w-4 h-4 opacity-70"
                >
                    <path
                        fillRule="evenodd"
                        d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                        clipRule="evenodd"
                    />
                </svg>
            </label>
        </div>
    );
};

export default SearchField;
