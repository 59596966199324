export const TOOLTIP_ID = "react-tooltip";

export const SIDEBAR_WIDTH = 60;

export const SIDE_AREA_HEADER_TEXT = {
    editTalentArea: "Edit Talent Area",
    editAction: "Edit Action",
    editTrait: "Edit Trait",
    lateAssessments: "Expired Assessments",
    editTalentBoardTraits: "Add Traits",
    editTalentBoardMembers: "Add Members",
    viewNotes: "Employee Notes",
    roleAdminArea: "Edit",
    viewActions: "Action History",
    talentMapConfig: "Configure Talent Map",
    editOrgTable: "Edit Table",
    createOrgTable: "Create Table",
    compareRatings: "Compare Ratings",
};

export const RATING_SCALE = [1, 25, 50, 75, 100];
const RatingLabels = {
    1: "Never",
    25: "Rarely",
    50: "Sometimes",
    75: "Often",
    100: "Always",
};
export const RATING_OPTIONS = RATING_SCALE.map((value) => ({ value, label: RatingLabels[value] }));

export const APP_EMAIL_ADDRESS = "support@dothings.io";

export const EMPLOYEE_PAGE_PATH = "/organisation/view/employees";
export const ROLE_PAGE_PATH = "/organisation/view/roles";

export const MENU_STYLE = { zIndex: 9999 };

export const CLAIM_OPTIONS = [
    {
        id: "canCreateAdmins",
        label: "Super Admin",
        tooltip: "Employee will be able create other Admin users.",
        adminOnly: true,
    },
];

export const USER_ROLES = {
    none: { id: "none", label: "None", tooltip: "Employee will not have access to the workspace." },
    admin: { id: "admin", label: "Admin", tooltip: "Employee will be able to view and edit entire workspace." },
    manager: {
        id: "manager",
        label: "Standard",
        tooltip:
            "Employee will be able to view assessment data for employees in their branch. They will not be able to edit the Org structure or Talent data.",
    },
};

export const DEFAULT_ROLE_LEVELS = [
    { id: 3, label: "Entry Level" },
    { id: 2, label: "Mid Level" },
    { id: 1, label: "Senior" },
];

export const EMPLOYEE_FIELD_OPTIONS = [
    { id: "employeeNumber", label: "Employee Number" },
    { id: "displayName", label: "Full Name" },
    { id: "firstname", label: "First Name" },
    { id: "surname", label: "Surname" },
    { id: "email", label: "Email" },
    { id: "jobTitle", label: "Job Title" },
];

export const SUCCESSION_FIELD_OPTIONS = [
    { label: "Plan Strength", id: "strength" },
    { label: "Percentage Female", id: "femaleRatio" },
    { label: "Percentage External", id: "externalRatio" },
];

export const OBJECTIVE_FIELD_OPTIONS = [
    { label: "Live Objectives", id: "count", fieldType: "range", min: 0, max: 100 },
    { label: "Confidence", id: "confidence", fieldType: "range", range: true, min: 0, max: 100 },
    { label: "Success Rate", id: "successRate", fieldType: "range", range: true, min: 0, max: 100 },
];

export const ORG_TABLE_COLUMNS = [
    { id: "isUser", label: "User Status" },
    { id: "employeeNumber", label: "Employee Number" },
    { id: "displayName", label: "Full Name" },
    { id: "firstname", label: "First Name" },
    { id: "surname", label: "Surname" },
    { id: "email", label: "Email" },
    { id: "jobTitle", label: "Job Title" },
    { id: "department", label: "Department" },
    { id: "managerName", label: "Manager" },
    { id: "overall", label: "Overall Rating" },
];

export const DEFAULT_USER_ROLE = "manager";

export const EMPLOYEE_SEARCH_SETTINGS = {
    keys: ["name", "jobTitle", "department", "employeeNumber", "displayName", "email"],
    threshold: 0.2,
    ignoreLocation: true,
};

export const SCOPE_OPTIONS = [
    { value: "allEmps", label: "All Employees" },
    { value: "selectedEmps", label: "Selected Employees" },
];

export const FIELD_SIZE_LIMITS = {
    GENERIC_NAME: 80,
    GENERIC_LONG_NAME: 180,
    NOTE_TITLE: 240,
    GENERIC_DESCRIPTION: 320,
    OBJECTIVE_DESCRIPTION: 800,
    FIRSTNAME: 40,
    SURNAME: 40,
    JOB_TITLE: 80,
    DEPARTMENT_NAME: 40,
    EMPLOYEE_NO: 40,
    EMAIL: 80,
    ACTION_NAME: 80,
    TRAIT_NAME: 40,
    TALENT_BOARD_NAME: 40,
    TALENT_AREA_NAME: 40,
    DESCRIPTION: 320,
    WORKSPACE_NAME: 40,
    SEGMENT: 30,
    BOX_TITLE: 32,
    TABLE_NAME: 80,
};

export const MEASURE_TYPES = [
    { id: "number", label: "Target Value" },
    { id: "boolean", label: "Achieved / Not Achieved" },
];

export const DEFAULT_CYCLE_PAGE = {
    layoutId: null,
    fieldIds: [],
    privateFieldIds: [],
};

export const DEFAULT_ROLE_FILTER = {
    filterType: null,
    filterSelectedRole: false,
    roleId: null,
    scope: "incumbent",
};

export const ROLE_FILTER_SCOPE_OPTIONS = [
    { value: "incumbent", label: "Incumbent" },
    { value: "direct_reports", label: "Direct Reports" },
    { value: "subordinates", label: "All Subordinates" },
];

export const CYCLE_RECUR_OPTIONS = [
    { id: "once", label: "Once", months: 0 },
    { id: "monthly", label: "Monthly", months: 1 },
    { id: "quarterly", label: "Quarterly", months: 3 },
    { id: "biannually", label: "Biannually", months: 6 },
    { id: "annually", label: "Annually", months: 12 },
];

export const PAGE_MODULE_OPTIONS = [
    {
        id: "objectiveSetting",
        configLabel: "Set Objectives",
        label: "Set New Objectives",
        help: "Request that new objectives are set.",
        moduleType: "objectives",
    },
    {
        id: "objectiveComplete",
        configLabel: "Complete Objectives",
        label: "Objective To Review & Complete",
        help: "Review and complete current objectives.",
        moduleType: "objectives",
    },
    {
        id: "objectiveCheckIn",
        configLabel: "Check-In Objectives",
        label: "Objectives to Review & Update",
        help: "Request status updates on current objectives.",
        moduleType: "objectives",
    },
    {
        id: "talentAssessment",
        configLabel: "Talent Assessment",
        label: "Talent Assessment",
        help: "Perform Talent Assessments.",
        moduleType: "talent",
    },
    {
        id: "commentsAndFlags",
        configLabel: "Comments & Fields",
        label: "Comments & Other Information",
        help: "Collect Comments And Field Responses.",
        moduleType: "fields",
    },
];

export const MODULE_LABELS = {
    talent: "Talent Assessment",
    objectives: "Objectives",
};

export const SEGMENT_OPTIONS = [
    {
        id: "selfAssessment",
        label: "Self Assessment",
        module: "talent",
        color: "accent",
        order: 1,
    },
    {
        id: "managerAssessment",
        label: "Talent Assessment",
        module: "talent",
        color: "accent",
        order: 1,
    },
    {
        id: "objectiveReview",
        label: "Objective Review",
        color: "info",
        module: "objectives",
        order: 1,
    },
    {
        id: "objectiveSetting",
        label: "Objective Setting",
        module: "objectives",
        color: "info",
        order: 2,
    },
];

export const TRAIT_SCOPE_OPTIONS = ["everyone", "managers"];

export const TRAIT_FAMILY_OPTIONS = ["all", "matched"];

export const COLORS = {
    primary: "#4878ff",
    secondary: "#FFCF48",
    accent: "#35B2DD",
    neutral: "#32373b",
    "base-100": "#ffffff",
    info: "#9856c9",
    success: "#34c25f",
    warning: "#fec135",
    error: "#e23626",
    // Non-daisyui colors
    "base-150": "#F3F6F6",
    "base-500": "#adafb0",
    "base-800": "#646668",
    "blue-100": "#F2F6FF",
    "blue-500": "#0000EE",
    "blue-700": "#0000AA",
    "primary-dark": "#040a33",
    border: "#e9ecef",
};

export const DEFAULT_COLORS = {
    BAD: "#e23626",
    GOOD: "#34c25f",
    GREEN: "#34c25f",
    RED: "#fe5514",
    BLUE: "#4878ff",
    YELLOW: "#fec135",
    GREY: "#4B3B47",
    PURPLE: "#9856c9",
};

export const DEFAULT_ROUTE = "/people";

export const ALT_TEXT = {
    LOGO: "logo showing the word 'Do'",
};

export const CONFIDENCE_LABELS = {
    0: "Very unlikely to be achieved",
    25: "Unlikely to be achieved",
    50: "Might be achieved",
    75: "Likely to be achieved",
    100: "Very likely to be achieved",
};

export const DEFAULT_FEATURE_FLIPS = {
    businessUnits: false,
    selfAssessment: false,
    legacyAssessmentInput: true,
    hideNumberRatings: false,
    hideDevelopmentPlan: false,
    hideCommentsTab: false,
};

export const OBJECTIVE_BLOCKER_OPTIONS = [
    { id: "b-1", label: "Time constraints" },
    { id: "b-2", label: "Budget limitations" },
    { id: "b-3", label: "Excessive workload" },
    { id: "b-4", label: "Lack of resources" },
    { id: "b-5", label: "Insufficient training" },
    { id: "b-6", label: "Lack of clarity" },
    { id: "b-7", label: "Lack of support" },
    { id: "b-8", label: "Lack of collaboration" },
    { id: "b-9", label: "Technical difficulties" },
    { id: "b-10", label: "Personal / health problems" },
    { id: "b-11", label: "Organizational disruption" },
    { id: "b-12", label: "External factors" },
    { id: "b-13", label: "Lack of motivation" },
    { id: "b-14", label: "Expectation changes" },
    { id: "b-15", label: "Competing priorities" },
];

export const REVIEW_LABELS = {
    objectiveReview: "Review Objectives",
    objectiveSetting: "Next Objectives",
    managerAssessment: "Talent Assessment",
    selfAssessment: "Self Assessment",
    notes: "Notes & Indicators",
};

export const DEFAULT_SHARED_DRAFT = {
    objectives: [],
    scheduleActionIds: [],
};

export const DEFAULT_OWN_DRAFT = {
    notes: [],
    ratings: {},
    flags: {},
};
