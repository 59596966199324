import classNames from "classnames";

const Skeleton = ({ width, height, shape = "circle" }) => {
    const style = {
        height: isNaN(height) ? 0 : height,
        width: isNaN(width) ? 0 : width,
    };
    return <div className={classNames("skeleton mask", `mask-${shape}`)} style={style} />;
};

export default Skeleton;
