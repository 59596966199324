import classNames from "classnames";
import { forwardRef, useEffect, useRef, useState } from "react";
import notesImg from "../../../../assets/img/emptystates/NoMessages.png";
import { Button, Each, RichText } from "../../../../common/components";
import { confirmFirst } from "../../../../common/utils/confirmFirst";
import { formatDate } from "../../../../common/utils/dateUtils";
import { CopyIcon, DeleteIcon, EditIcon } from "../../../../components/Icons/MyIcons";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const EmptyState = ({ emptyStateMessage = "No Comments Yet" }) => {
    return (
        <div className="flex-1 flex justify-center">
            <div className="flex flex-col flex-1 items-center justify-center space-y-4 p-6 max-w-md">
                <div className="px-20">
                    <img src={notesImg} alt="No Messages" />
                </div>
                <h4 className="tracking-tight text-center">{emptyStateMessage}</h4>
            </div>
        </div>
    );
};

const NoteItem = forwardRef(({ employeeId, note, onDelete, onToggleEdit }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const { content, author = {}, createdOn, isAuthor } = note;
    const { id: authorId, displayName } = author;
    const authorIsEmployee = authorId === employeeId;
    const colorClass = authorIsEmployee ? "accent" : "secondary";
    const dateString = formatDate(createdOn);
    const editable = isHovered && isAuthor;

    const handleSetHovered = (newVal) => {
        setIsHovered(newVal);
    };

    const handleToggleEdit = () => {
        onToggleEdit(note);
    };

    const handleCopyText = () => {
        // Convert raw content state to Draft.js ContentState
        const contentState = convertFromRaw(content);

        // Convert ContentState to HTML
        const html = stateToHTML(contentState);

        // Copy HTML to clipboard
        navigator.clipboard.writeText(html);
    };

    const handleDeleteNote = () => {
        confirmFirst({
            title: "Delete Note",
            message: "Are you sure you want to delete this note?",
            onConfirm: () => onDelete(note.id),
        });
    };

    return (
        <div
            ref={ref}
            className={classNames("cursor-pointer chat", {
                "chat-start": authorIsEmployee,
                "chat-end": !authorIsEmployee,
            })}
        >
            <div className="chat-header space-x-2 pb-1">
                <span>{displayName}</span>
                <span className="text-xs opacity-50">{dateString}</span>
            </div>
            <div
                className={classNames(
                    "chat-bubble p-1 flex flex-col w-full h-full relative",
                    `chat-bubble-${colorClass}`,
                    "transition-all duration-500 ease-in-out delay-500", // Adds 1-second delay for background transition
                    {
                        "bg-opacity-25": isHovered,
                        "bg-opacity-100": !isHovered,
                    }
                )}
                onMouseEnter={() => handleSetHovered(true)}
                onMouseLeave={() => handleSetHovered(false)}
            >
                <div
                    className={classNames(
                        "transition-all duration-500 ease-in-out delay-500", // Adds 1-second delay for background transition
                        {
                            "opacity-25": editable,
                            "opacity-100": !editable,
                        }
                    )}
                >
                    <RichText placeholder="Enter your comments here" content={content} readOnly />
                </div>
                {/* Render actions overlay with fade-in transition */}
                <div
                    className={classNames(
                        "absolute inset-0 flex items-center justify-center space-x-4 bg-transparent",
                        "transition-opacity duration-500 ease-in-out delay-500", // Adds a 1-second delay
                        editable ? "opacity-100" : "opacity-0"
                    )}
                >
                    <Button size="sm" color={colorClass} tooltip="delete" shape="circle" onClick={handleDeleteNote}>
                        <DeleteIcon size={16} />
                    </Button>
                    <Button size="sm" color={colorClass} tooltip="edit" shape="circle" onClick={handleToggleEdit}>
                        <EditIcon size={18} />
                    </Button>
                    <Button size="sm" color={colorClass} tooltip="copy" shape="circle" onClick={handleCopyText}>
                        <CopyIcon size={18} />
                    </Button>
                </div>
            </div>
        </div>
    );
});

const NoteList = ({
    className = "px-1 py-6",
    employeeId,
    notes,
    hasMore,
    onFetchMore,
    onDeleteNote,
    onToggleEditNote,
}) => {
    const noteListRef = useRef(null);
    const lastNoteRef = useRef(null);
    const loadingMoreRef = useRef(false);

    useEffect(() => {
        if (loadingMoreRef.current && noteListRef.current) {
            // Scroll to the top when loading more comments
            noteListRef.current.scrollTop = 0;
            loadingMoreRef.current = false;
        } else if (lastNoteRef.current) {
            // Scroll to the bottom when a new comment is added
            lastNoteRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [notes]);

    const handleLoadMore = () => {
        loadingMoreRef.current = true;
        onFetchMore();
    };

    return (
        <div ref={noteListRef} className={classNames("flex-1 flex flex-col bg-diagonal-stripes space-y-4", className)}>
            <div className="flex justify-center">
                <Button hidden={!hasMore} size="xs" color="ghost" label="view older" onClick={handleLoadMore}>
                    Load Previous
                </Button>
            </div>
            <Each
                of={notes}
                renderEmpty={() => <EmptyState />}
                render={(note, index) => {
                    const isLastNote = index === notes.length - 1;
                    return (
                        <NoteItem
                            ref={isLastNote ? lastNoteRef : null}
                            employeeId={employeeId}
                            note={note}
                            onDelete={onDeleteNote}
                            onToggleEdit={onToggleEditNote}
                        />
                    );
                }}
            />
        </div>
    );
};

export default NoteList;
