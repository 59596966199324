import React from "react";
import { Select } from "../../../../common/components";
import { OBJECTIVE_BLOCKER_OPTIONS } from "../../../../constants";
import ConfidenceSlider from "../atoms/ConfidenceSlider";

const CheckInBlock = ({ hidden, objective, updateField, readOnly }) => {
    if (hidden) return null;
    const { confidence, blockers } = objective;

    const handleSetConfidence = (value) => {
        updateField("confidence", value);
    };

    const handleSetBlockers = (value) => {
        updateField("blockers", value);
    };

    return (
        <div className="space-x-12 flex border rounded-lg p-4 bg-blue-100">
            <ConfidenceSlider confidence={confidence} onChange={handleSetConfidence} disabled={readOnly} />
            <div className="flex-1 space-y-2">
                <h6 className="text-sm text-base-800 tracking-tight font-medium">
                    What might prevent this being achieved?
                </h6>
                <Select
                    disabled={readOnly}
                    selectedIds={blockers}
                    menuPlacement="top"
                    options={OBJECTIVE_BLOCKER_OPTIONS}
                    onChange={handleSetBlockers}
                    maxMenuHeight={180}
                    isMulti
                    clearable
                />
            </div>
        </div>
    );
};

export default CheckInBlock;
