import React, { useRef } from "react";
import { Button } from "../../../../common/components";
import useEditValues from "../../../../common/hooks/useEditValues";
import { removeUndefined } from "../../../../common/utils/objectUtils";
import { usePaginatedNotes } from "../../hooks/usePaginatedNotes";
import NoteContent from "../atoms/NoteContent";
import NoteList from "./NoteList";

const NotesSection = ({ userEmployeeId, employeeId, objectiveId, noteType = "general", noteListClassName }) => {
    const [editedNote, setNoteValue, hasChanges, setAllValues] = useEditValues();
    const { notes, fetchMore, hasMore, onCreateNote, onEditNote, onDeleteNote } = usePaginatedNotes(
        employeeId,
        objectiveId
    );
    const editingExistingNote = useRef(false);

    const handleFetchMore = () => {
        fetchMore();
    };

    const handleSaveNote = () => {
        if (editingExistingNote.current) {
            onEditNote(editedNote);
        } else {
            const enhancedNote = { ...editedNote, noteType, objectiveId };
            removeUndefined(enhancedNote);
            onCreateNote(enhancedNote);
        }
        editingExistingNote.current = false;
        setAllValues({});
    };

    const handleToggleEditNote = (noteToEdit) => {
        editingExistingNote.current = true;
        setAllValues(noteToEdit);
    };

    const handleNoteChange = (newContent) => {
        setNoteValue("content", newContent);
    };

    return (
        <div className="h-full flex flex-col overflow-hidden">
            <div className="border-t flex-1 flex flex-col divide-y overflow-scroll">
                <NoteList
                    className={noteListClassName}
                    userEmployeeId={userEmployeeId}
                    employeeId={employeeId}
                    notes={notes.slice().reverse()}
                    hasMore={hasMore}
                    onFetchMore={handleFetchMore}
                    onDeleteNote={onDeleteNote}
                    onToggleEditNote={handleToggleEditNote}
                />
            </div>
            <div className="relative h-60 p-4 border-t">
                <div className="absolute right-5 bottom-5 z-10">
                    <Button
                        size="xs"
                        color="primary"
                        hidden={!hasChanges || !Object.keys(editedNote).length}
                        onClick={handleSaveNote}
                    >
                        Save Comment
                    </Button>
                </div>
                <div className="h-full flex flex-col">
                    <NoteContent
                        className="flex-1 bg-editing border"
                        note={editedNote}
                        onChange={handleNoteChange}
                        alwaysShowToolbar={true}
                        placeholder="Add Comment"
                        readOnly={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default NotesSection;
