import classNames from "classnames";
import React from "react";
import useLoggedNavigate from "../../common/hooks/useLoggedNavigate";
import { SIDEBAR_WIDTH } from "../../constants";
import { connectedToEmulators, projectId } from "../../firebase/firebase";
import NotificationsDropdown from "../Notifications/NotificationsDropdown";
import { useClaims } from "../User/hooks/useClaims";
import AppSettingsDropdown from "./AppSettingsDropdown";
import Breadcrumbs from "./Breadcrumbs";
import GlobalSearchField from "./GlobalSearchField";

const AppNavBar = () => {
    const navigate = useLoggedNavigate();
    const { isDeveloper } = useClaims();

    function getColor() {
        const isDanger = isDeveloper && !connectedToEmulators
        if (!isDanger) return null;
        return projectId === "dothings-staging-app" ? "bg-secondary" : "bg-error";
    }

    const handleSelectEmployee = (empId) => {
        navigate(`/organisation/chart?employeeId=${empId}`);
    };

    return (
        <div style={{ paddingLeft: SIDEBAR_WIDTH + 20 }} className={classNames("navbar border-b pe-8", getColor())}>
            <div className="flex-1 flex">
                <Breadcrumbs />
            </div>
            <div className="flex justify-end min-w-fit space-x-6 items-center">
                <GlobalSearchField placeholder="Search Employees" onSelect={handleSelectEmployee} />
                <NotificationsDropdown />
                <AppSettingsDropdown />
            </div>
        </div>
    );
};

export default AppNavBar;
