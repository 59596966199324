import classNames from "classnames";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { sortArrayOfObjects } from "../../helpers/helpers";
import "./picker.scss";

const tempDefaultProps = {
    options: {},
    idField: "id",
    selectedIds: [],
    defaultValue: [],
    maxMenuHeight: 180,
    minWidth: 200,
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
    getLabel: (option) => {
        return option.label;
    },
};

const IdPicker = (props) => {
    const mergedProps = { ...tempDefaultProps, ...props };
    const { idField, isMulti, excludeIds, options, getLabel, hideLabel, unsorted } = mergedProps;
    const [preppedOptions, setPreppedOptions] = useState([]);
    const selectedIds = Array.isArray(mergedProps.selectedIds) ? mergedProps.selectedIds : [mergedProps.selectedIds];

    useEffect(() => {
        const optionsAreInArray = Array.isArray(options);
        let optionsArray = optionsAreInArray ? options : Object.values(options);
        let newOptions = optionsArray
            .map((option) => ({
                value: option?.[idField],
                label: getLabel(option),
            }))
            .filter((option) => !excludeIds.includes(option.value));
        if (!unsorted) {
            newOptions = sortArrayOfObjects(newOptions, "label");
        }

        setPreppedOptions(newOptions);
    }, [options, excludeIds, getLabel, idField, unsorted]);

    const deriveValueFromProps = () => {
        let selectTheseIds = selectedIds || mergedProps.defaultValue;
        return preppedOptions.filter((option) => {
            return selectTheseIds.includes(option.value);
        });
    };

    const handleChange = (selectedOptions) => {
        let returnValue;
        if (isMulti) {
            returnValue = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        } else {
            returnValue = selectedOptions && selectedOptions.value;
        }
        mergedProps.onChange(returnValue);
    };

    const showLabel = !hideLabel && !mergedProps.label;

    return (
        <>
            {showLabel && <h4 className="text-sm mb-1">{mergedProps.label}</h4>}
            <Select
                name={mergedProps.name}
                isDisabled={mergedProps.disabled}
                className={classNames("picker w-full", mergedProps.className)}
                classNamePrefix={classNames("picker", mergedProps.classNamePrefix)}
                maxMenuHeight={mergedProps.maxMenuHeight}
                menuIsOpen={mergedProps.menuIsOpen}
                minWidth={mergedProps.minWidth}
                type="select"
                isClearable={mergedProps.isClearable}
                isMulti={mergedProps.isMulti}
                placeholder={mergedProps.placeholder}
                onChange={handleChange}
                onBlur={mergedProps.onBlur}
                options={preppedOptions}
                value={deriveValueFromProps()}
            />
        </>
    );
};

IdPicker.propTypes = {
    name: PropTypes.string,
    idField: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    getLabel: PropTypes.func,
    maxMenuHeight: PropTypes.number,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    unsorted: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    excludeIds: PropTypes.array,
    minWidth: PropTypes.number,
    selectedIds: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    hideLabel: PropTypes.bool,
};

export default IdPicker;
