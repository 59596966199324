
export function getParticipation(pages) {
    if (!pages)
        return {
            managerRequired: false,
            employeeRequired: false,
        };
    else {
        const managerPages = pages.filter((page) => page.pageFor === "manager");
        const employeePages = pages.filter((page) => page.pageFor === "employee");
        const managerRequired = managerPages.length > 0;
        const employeeRequired = employeePages.length > 0;
        return { managerRequired, employeeRequired };
    }
}
