import { useMemo } from "react";
import { InfiniteTable } from "../../../../common/components";
import { useCheckedIds } from "../../../../hooks/useCheckedIds";
import { useEmployeeFields } from "../../../Employees/hooks/useEmployeeFields";
import { useBulkTrackingActions } from "../../../Schedule/hooks/useBulkTrackingActions";
import { useTrackingFields } from "../../../Schedule/hooks/useTrackingFields";

const EMPLOYEE_COL_IDS = ["displayName", "managerName"];
const EXCLUDED_ACTIONS = [];

// Forces the tracking fields to include both employee and manager tracking
const DUMMY_ITERATION = { pages: [{ pageFor: "manager" }, { pageFor: "employee" }] };

const ReviewQueueTable = ({ tableId, reviewQueue, onMarkEmployeesDone = () => {} }) => {
    const { filteredFields } = useEmployeeFields(EMPLOYEE_COL_IDS);
    const trackingFields = useTrackingFields(DUMMY_ITERATION);
    const [checkedIds] = useCheckedIds(tableId);
    const bulkActions = useBulkTrackingActions(reviewQueue, EXCLUDED_ACTIONS, checkedIds, onMarkEmployeesDone);

    const columns = useMemo(() => {
        const cycleNameCol = {
            id: "cycleName",
            label: "Review Name",
        };
        return [cycleNameCol, ...filteredFields, ...trackingFields];
    }, [filteredFields, trackingFields]);

    return (
        <div className="flex-1 flex flex-col overflow-auto bg-white border rounded">
            <InfiniteTable
                showCheckBoxes
                tableId={tableId}
                columns={columns}
                data={reviewQueue}
                bulkActions={bulkActions}
            />
        </div>
    );
};

export default ReviewQueueTable;
