import React, { useEffect, useRef, useState } from "react";
import { MyCollapse, MyCollapseContent, MyCollapseTitle } from "../../../../components/MyComponents/Collapse";
import Each from "../../molecules/Each/Each";
import FilterCreator from "./organisms/FilterCreator";
import FilterDisplay from "./organisms/FilterDisplay";
import classNames from "classnames";

const FilterEditor = ({
    filters = [],
    filterOptions = [],
    valueKey = "filters",
    emptyStateProps = { type: "noIcon", message: "No Filters Added" },
    hideList = false,
    onChange,
    onFilterOpen = () => {},
}) => {
    const [isAdding, setIsAdding] = useState(false);
    const displayRef = useRef(null);

    useEffect(() => {
        if (displayRef.current) {
            displayRef.current.scrollTop = displayRef.current.scrollHeight;
        }
    }, [filters]);

    useEffect(() => {
        onFilterOpen(isAdding);
    }, [isAdding, onFilterOpen]);

    const handleAddFilters = (newFilters) => {
        // Add new filters
        const updatedFilters = [...filters, ...newFilters];
        onChange(valueKey, updatedFilters);
        setIsAdding(false);
    };

    const handleRemoveFilter = (removeId) => {
        const newFilters = filters.filter((filter) => filter.filterId !== removeId);
        onChange(valueKey, newFilters);
    };

    const handleToggle = () => {
        if (!isAdding) {
            setIsAdding(true);
        } else {
            setIsAdding(false);
        }
    };

    return (
        <div className="flex-1 flex flex-col overflow-visible">
            <div className="mb-5">
                <MyCollapse isOpen={isAdding} onToggle={handleToggle} overflow="visible">
                    <MyCollapseTitle toggleOnClick variant="expandPlus" className="p-3">
                        Add Filter
                    </MyCollapseTitle>
                    <MyCollapseContent>
                        <div className="pb-4">
                            <FilterCreator
                                fieldOptions={filterOptions}
                                onAdd={handleAddFilters}
                                onClickOutside={() => setIsAdding(false)}
                            />
                        </div>
                    </MyCollapseContent>
                </MyCollapse>
            </div>
            <div ref={displayRef} className={classNames("flex-1 flex flex-col overflow-auto", { hidden: hideList })}>
                <h6>Included Filters</h6>
                <Each
                    of={filters}
                    emptyStateProps={emptyStateProps}
                    render={(filter) => (
                        <FilterDisplay filter={filter} filterOptions={filterOptions} onRemove={handleRemoveFilter} />
                    )}
                />
            </div>
        </div>
    );
};

export default FilterEditor;
