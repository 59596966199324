import React, { useMemo } from "react";
import MySteps from "../MyComponents/Steps";

const StepperProgress = ({ steps, currentStep, hideStepProgress }) => {
    const progressSteps = useMemo(() => {
        return steps.map((step, i) => {
            return {
                id: `${i}-${step.props.title}`,
                label: step.props.title,
            };
        });
    }, [steps]);

    if (steps.length < 2 || hideStepProgress) return null;

    return (
        <div className="py-4 flex justify-center bg-base-150">
            <MySteps steps={progressSteps} currentStep={currentStep} />
        </div>
    );
};

export default StepperProgress;
