import React from "react";
import { RichText } from "../../../../components";

const NoteContent = ({
    note = {},
    onChange,
    onBlur,
    minHeight,
    bgClass,
    alwaysShowToolbar,
    placeholder,
    className,
    readOnly = true,
}) => {
    const { content, id } = note;

    const handleChange = (newContent) => {
        onChange(newContent, note);
    };

    return (
        <RichText
            key={id} // reinitializes the component if it receives a new note
            showBorder={false}
            className={className}
            bgClass={bgClass}
            readOnly={readOnly}
            content={content}
            onBlur={onBlur}
            alwaysShowToolbar={alwaysShowToolbar}
            onChange={handleChange}
            minHeight={minHeight}
            placeholder={placeholder}
        />
    );
};

export default NoteContent;
