import { useCallback, useMemo } from "react";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { useEnabledModules } from "../../Config/hooks/useEnabledModules";

export function useLiveObjectivesListener(employeeId, onDataReceived, listenerEnabled = false) {
    const { objectives: objectiveEnabled } = useEnabledModules();
    const { liveObjectivesRef } = useEmployeeRefs(employeeId);

    const shouldListen = useMemo(() => {
        return objectiveEnabled && listenerEnabled && !!employeeId;
    }, [listenerEnabled, employeeId, objectiveEnabled]);

    const handleDataReceieved = useCallback(
        (querySnapshot) => {
            const data = prepQuerySnapshot(querySnapshot);
            onDataReceived(data);
        },
        [onDataReceived]
    );

    return useListener(liveObjectivesRef, handleDataReceieved, shouldListen);
}
