import React, { useState } from "react";
import { useOrgNodeContext } from "../../../pages/organisation/outlets/OrgNode/OrgNodePage";
import { useCustomFields } from "../../CustomFields/hooks/useCustomFields";
import { useRoleListener } from "../../Roles/hooks/useRoleListener";
import { useClaims } from "../../User/hooks/useClaims";
import EmployeeEditor from "./EmployeeEditor";
import RoleEditor from "./RoleEditor";

const OrgNodeEditorSideArea = ({ showEditEmployee, showEditRole, onClose }) => {
    const { roleId, employeeId, isSelf, activeEmployee, onUpdateEmployee } = useOrgNodeContext();
    const { customEmployeeFields } = useCustomFields();
    const { isAdmin, canCreateAdmins } = useClaims();
    const [fullRole, setFullRole] = useState({});
    const roleListenerStatus = useRoleListener(roleId, setFullRole);

    const props = {
        roleId,
        isSelf,
        isAdmin,
        canCreateAdmins,
        activeEmployee,
        employeeId,
        customEmployeeFields,
        onUpdateEmployee,
        onClose,
    };

    if (roleListenerStatus !== "success") return null;

    if (showEditEmployee) {
        return <EmployeeEditor {...props} />;
    } else if (showEditRole) {
        return <RoleEditor {...props} fullRole={fullRole} />;
    } else {
        return null;
    }
};

export default OrgNodeEditorSideArea;
