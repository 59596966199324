import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, remove } from "lodash";
import { convertObjectToArray, removeUndefined } from "../../../common/utils/objectUtils";
import { FIRST_BUSINESS_UNIT_ID } from "shared";

export const ADD_ROLE = "businessUnits/addRole";
export const MOVE_ROLE = "businessUnits/moveRole";
export const REMOVE_ROLE = "businessUnits/removeRole";
export const VACATE_ROLE = "businessUnits/vacateRole";
export const FILL_ROLE = "businessUnits/fillRole";

const initialState = {
    businessUnitId: FIRST_BUSINESS_UNIT_ID,
    externalUnitDocs: {},
    boardMap: {},
    parentMap: {},
    employees: {},
    preppedEmployees: {},
    employeesArePrepped: false,
    roles: {},
    employeesReady: false,
    rolesReady: false,
    boardsMapped: false,
    pendingRoleChanges: [],
    pendingEmployeeChanges: [],
};

const slice = createSlice({
    name: "businessUnit",
    initialState,
    reducers: {
        resetBusinessUnit() {
            return initialState;
        },
        setBusinessUnitId(state, action) {
            Object.assign(state, initialState);
            state.businessUnitId = action.payload;
        },
        setEmployees(state, action) {
            const { employees, liveEmployeeIds } = action.payload;
            // Set new employees data without overwriting dynamic fields
            Object.values(employees).forEach((employee) => {
                const currentEmployee = state.employees[employee.id] || {};
                const { roleId, isUser, linkedTalentBoardIds, boardLevels } = currentEmployee;
                if (roleId) {
                    employee.isUser = isUser || false;
                    employee.roleId = roleId;
                    employee.linkedTalentBoardIds = linkedTalentBoardIds || [];
                    employee.boardLevels = boardLevels;
                }
                removeUndefined(employee);
                state.employees[employee.id] = employee;
            });

            let updatedPending = [...state.pendingEmployeeChanges];
            const employeeIds = new Set(Object.keys(employees));
            updatedPending = updatedPending.filter((id) => !employeeIds.has(id));

            // Remove employees that are no longer live
            const newLiveEmployeeIds = new Set(liveEmployeeIds);
            Object.keys(state.employees).forEach((employeeId) => {
                if (!newLiveEmployeeIds.has(employeeId)) {
                    delete state.employees[employeeId];
                }
            });
            state.pendingEmployeeChanges = updatedPending;
            state.employeesReady = true;
        },
        setPreppedEmployees(state, action) {
            state.employeesArePrepped = true;
            state.preppedEmployees = action.payload;
        },
        setRoles(state, action) {
            const { roles, liveRoleIds } = action.payload;
            let newRoleState = cloneDeep(state.roles);

            // Add the new role data without overwriting dynamic fields
            Object.values(roles).forEach((role) => {
                const currentRole = newRoleState[role.id] || {};
                const { parentRoleId } = currentRole;
                role.parentRoleId = parentRoleId;
                removeUndefined(role);
                newRoleState[role.id] = role;
                // Add the roleId to the incumbent's employee record
                const { incumbentId } = role;
                if (incumbentId) {
                    const update = { id: incumbentId, roleId: role.id };
                    state.employees[incumbentId] = { ...state.employees[incumbentId], ...update };
                }
            });

            const newLiveRoleIds = new Set(liveRoleIds);
            Object.keys(newRoleState).forEach((roleId) => {
                if (!newLiveRoleIds.has(roleId)) {
                    // Remove roles that are no longer live
                    delete newRoleState[roleId];
                }
            });

            let updatedPending = [...state.pendingRoleChanges];
            const roleIds = new Set(Object.keys(roles));
            updatedPending = updatedPending.filter((id) => !roleIds.has(id));

            Object.assign(state.roles, newRoleState);
            state.pendingRoleChanges = updatedPending;
            state.rolesReady = true;
        },
        setPendingEmployeeChange(state, action) {
            const employeeId = action.payload;
            state.pendingEmployeeChanges = [...state.pendingEmployeeChanges, employeeId];
        },
        setPendingRoleChange(state, action) {
            const roleId = action.payload;
            state.pendingRoleChanges = [...state.pendingRoleChanges, roleId];
        },
        setParentMap(state, action) {
            const parentMap = action.payload;
            Object.entries(parentMap).forEach(([roleId, parentId]) => {
                const role = state.roles[roleId];
                if (parentId) {
                    role.parentRoleId = parentId;
                    state.roles[roleId] = role;
                }
            });
            state.parentMap = parentMap;
        },
        setBoardMap(state, action) {
            const { boardMap, updatedEmployees, updatedRoles } = action.payload;
            Object.assign(state.boardMap, boardMap);
            Object.assign(state.roles, updatedRoles);
            Object.assign(state.employees, updatedEmployees);
            state.boardsMapped = true;
        },
        deleteRoleFromParentMap(state, action) {
            const roleId = action.payload;
            const newParentMap = { ...state.parentMap };
            delete newParentMap[roleId];
            state.parentMap = newParentMap;
        },
    },
    extraReducers(builder) {
        builder.addCase(ADD_ROLE, (state, action) => {
            const [parentMap, role, employee] = action.payload;
            const addEmployee = {
                ...employee,
                roleId: role.id,
            };
            state.employees = { ...state.employees, [employee.id]: addEmployee };
            state.roles = { ...state.roles, [role.id]: role };
            state.parentMap = { ...state.parentMap, ...parentMap };
        });
        builder.addCase(REMOVE_ROLE, (state, action) => {
            const roleId = action.payload;
            delete state.parentMap[roleId];
            delete state.roles[roleId];
        });
        builder.addCase(VACATE_ROLE, (state, action) => {
            const roleId = action.payload;
            const currentRole = state.roles[roleId];
            if (currentRole) {
                const incumbentId = currentRole.incumbentId;
                const incumbent = state.employees[incumbentId];
                if (incumbent) {
                    const updatedIncumbent = { ...incumbent, roleId: null };
                    state.employees = { ...state.employees, [incumbentId]: updatedIncumbent };
                }
                const updatedRole = { ...currentRole, incumbentId: null };
                state.roles = { ...state.roles, [roleId]: updatedRole };
            }
        });
        builder.addCase(FILL_ROLE, (state, action) => {
            const [roleId, employeeId] = action.payload;
            const role = state.roles[roleId];
            const employee = state.employees[employeeId];
            if (role && employee) {
                role.incumbentId = employeeId;
                employee.roleId = roleId;
                state.roles = { ...state.roles, [roleId]: role };
                state.employees = { ...state.employees, [employeeId]: employee };
            }
        });
        builder.addCase(MOVE_ROLE, (state, action) => {
            const [roleId, newParentId] = action.payload;
            const role = state.roles[roleId];
            if (role) {
                role.parentRoleId = newParentId;
                state.parentMap[roleId] = newParentId;
                state.roles = { ...state.roles, [roleId]: role };
            }
        });
    },
});

const { actions, reducer } = slice;

export const {
    resetBusinessUnit,
    setBusinessUnitId,
    setUnitLoadStatus,
    setEmployees,
    setPreppedEmployees,
    setRoles,
    setPendingEmployeeChange,
    setPendingRoleChange,
    removeEmployeeRole,
    setParentMap,
    setBoardMap,
    deleteRoleFromParentMap,
} = actions;

export default reducer;
