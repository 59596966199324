import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { setNotifications } from "../../Workspace/utils/workspaceSlice";

export function useNotificationListener(enabled) {
    const dispatch = useDispatch();
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { notificationsRef } = useEmployeeRefs(userEmployeeId);

    const shouldListen = useMemo(() => {
        return enabled && notificationsRef;
    }, [enabled, notificationsRef]);

    const handleOnDataReceived = useCallback(
        (querySnapshot) => {
            const prepped = prepQuerySnapshot(querySnapshot);
            dispatch(setNotifications(prepped));
        },
        [dispatch]
    );

    return useListener(notificationsRef, handleOnDataReceived, shouldListen);
}
