import useEditValues from "../../../common/hooks/useEditValues";

export function useEditedRole(initialValues, locked = false) {
    const [editedRole, setValue, hasChanges, setAllValues] = useEditValues(initialValues);

    const updateRole = (updateType, val) => {
        if (locked) return;
        switch (updateType) {
            case "successorIds":
                const successorCount = val.length || 0;
                setValue("successorCount", successorCount);
                setValue(updateType, val);
                break;
            default:
                // updateType is just a key in default case
                setValue(updateType, val);
                break;
        }
    };

    return [editedRole, updateRole, hasChanges, setAllValues];
}
