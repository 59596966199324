import SideAreaFooter from "../../../containers/SideAreaFooter";
import EmployeeEditorTemplate from "../../Employees/components/templates/EmployeeEditorTemplate";
import { useEditedEmployee } from "../../Employees/hooks/useEditedEmployee";

const EmployeeEditor = ({
    isAdmin,
    canCreateAdmins,
    activeEmployee,
    employeeId,
    customEmployeeFields,
    onUpdateEmployee,
    onClose,
}) => {
    const [editedEmployee, updateField, hasChanges, setEmployee] = useEditedEmployee(activeEmployee);

    const handleDiscard = () => {
        setEmployee(activeEmployee);
        onClose();
    };

    const handleSubmit = async () => {
        if (!hasChanges) return;
        try {
            const batch = onUpdateEmployee(editedEmployee);
            batch.commit();
            onClose();
        } catch (error) {
            console.error(error);
        } finally {
            return true;
        }
    };

    return (
        <div className="flex flex-col justify-between divide-y h-screen min-w-xl">
            <div className="p-4 bg-base-150">
                <h2>{`Edit ${editedEmployee?.displayName}`}</h2>
            </div>
            <div className="p-4 flex-1 overflow-auto space-y-4">
                <EmployeeEditorTemplate
                    isAdmin={isAdmin}
                    userCanCreateAdmins={canCreateAdmins}
                    employeeId={employeeId}
                    editedEmployee={editedEmployee}
                    updateField={updateField}
                    customFields={customEmployeeFields}
                />
            </div>
            <SideAreaFooter hasChanges={hasChanges} onSubmit={handleSubmit} onDiscard={handleDiscard} />
        </div>
    );
};

export default EmployeeEditor;
