import classNames from "classnames";
import { confirmAlert } from "react-confirm-alert";
import SunburstChart from "../../../../components/Charts/BaseCharts/SunburstChart";
import ChartContainer from "../../../../components/Charts/ChartContainer";
import { useTalentAreaTree } from "../../../TalentAreas/hooks/useTalentAreaTree";

const TalentAreaSunburst = ({
    className = "flex-1 flex w-full",
    overallRating,
    traits,
    talentAreas,
    traitRatings,
    fetchTrait,
    onSegmentClick,
}) => {
    const handleSegmentClick = async (d) => {
        const data = d?.data || {};
        const { talentArea, trait } = data;
        let message = null;
        let title = null;
        if (talentArea) {
            title = talentArea?.talentAreaName;
            message = talentArea?.description;
        } else if (trait) {
            title = trait?.traitName;
            const fullTrait = await fetchTrait(trait.id);
            message = fullTrait?.description;
        }
        if (message && title) {
            confirmAlert({
                title,
                message,
                buttons: [
                    {
                        label: "Done",
                    },
                ],
            });
        }
    };

    const talentAreaTree = useTalentAreaTree(talentAreas, traits, traitRatings, overallRating);

    return (
        <ChartContainer className={classNames(className)}>
            <SunburstChart data={talentAreaTree} onSegmentClick={handleSegmentClick} />
        </ChartContainer>
    );
};

export default TalentAreaSunburst;
