import { collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import { removeUndefined } from "../../../common/utils/objectUtils";
import { batchSet } from "../../../firebase/firebaseActions";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";

function getRoleRef(rolesRef, roleId) {
    if (!rolesRef) return null;
    return doc(rolesRef, roleId);
}

export function useRolesApi() {
    const workspaceRef = useWorkspaceRef();

    const rolesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "roles");
    }, [workspaceRef]);

    function updateRole(roleId, role, batch) {
        const roleRef = getRoleRef(rolesRef, roleId);

        const cleanedRole = {};
        // Clean only the role data that should be saved
        const keysToSave = [
            "jobTitle",
            "department",
            "level",
            "incumbentId",
            "unitDocId",
            "businessUnitId",
            "successorIds",
            "successorCount",
        ];
        for (const key of keysToSave) {
            if (role[key] !== undefined) {
                cleanedRole[key] = role[key];
            }
        }

        removeUndefined(cleanedRole);
        batch = batchSet(roleRef, cleanedRole, batch);
        return batch;
    }

    return {
        updateRole,
    };
}
