import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../../../components/MyComponents/Button";
import MyInputGroup from "../../../components/MyComponents/InputGroup";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../components/MyComponents/Modal";
import MyTextInput from "../../../components/MyComponents/TextInput";
import { createBusinessUnitThunk, updateBusinessUnitThunk } from "../utils/businessUnitThunks";

const Form = ({ header, isOpen, businessUnitName, toggle, onChange, label, disabled, onSubmit }) => {
    return (
        <MyModal isOpen={isOpen} size="sm">
            <MyModalHeader onToggle={toggle}>{`${header} Business Unit`}</MyModalHeader>
            <MyModalBody className="p-3 h-80">
                <MyInputGroup label="Business Unit Name" name="businessUnitName" placeholder="Enter a name">
                    <MyTextInput
                        type="text"
                        name="businessUnitName"
                        id="businessUnitName"
                        onChange={onChange}
                        value={businessUnitName}
                    />
                </MyInputGroup>
            </MyModalBody>
            <MyModalFooter>
                <MyButton label="create business unit" color="secondary" disabled={disabled} onClick={onSubmit}>
                    {label}
                </MyButton>
            </MyModalFooter>
        </MyModal>
    );
};

export const EditBusinessUnit = ({ isOpen, businessUnitId, toggle }) => {
    const dispatch = useDispatch();
    const [businessUnitName, setBusinessUnitName] = useState();
    const businessUnits = useSelector((state) => state.workspace.businessUnits);
    const thisUnit = businessUnits[businessUnitId];
    const currentName = thisUnit?.businessUnitName || "";
    const disabled = !businessUnitName || currentName === businessUnitName;

    useEffect(() => {
        setBusinessUnitName(currentName);
    }, [currentName]);

    const handleChange = (_, newVal) => {
        setBusinessUnitName(newVal);
    };

    const handleSave = () => {
        const unitUpdate = {
            id: businessUnitId,
            businessUnitName: businessUnitName,
        };
        dispatch(updateBusinessUnitThunk(unitUpdate));
        toggle(false);
    };

    return (
        <Form
            header="Edit"
            isOpen={isOpen}
            businessUnitName={businessUnitName}
            onSubmit={handleSave}
            toggle={() => toggle(false)}
            onChange={handleChange}
            disabled={disabled}
            label="Edit Unit"
        />
    );
};

export const CreateBusinessUnit = ({ isOpen, toggle }) => {
    const dispatch = useDispatch();
    const [businessUnitName, setBusinessUnitName] = React.useState("");
    const noName = !businessUnitName || businessUnitName === "";

    const handleChange = (_, newVal) => {
        setBusinessUnitName(newVal);
    };

    const handleCreateUnit = () => {
        dispatch(createBusinessUnitThunk({ businessUnitName }));
        toggle();
    };

    return (
        <Form
            header="Create"
            isOpen={isOpen}
            businessUnitName={businessUnitName}
            onSubmit={handleCreateUnit}
            onChange={handleChange}
            disabled={noName}
            toggle={toggle}
            label="Create Unit"
        />
    );
};
