import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useIsWorkspaceReady() {
    const workspaceInitialised = useSelector((state) => state.workspace.workspaceInitialised);
    const employeesArePrepped = useSelector((state) => state.businessUnit.employeesArePrepped);
    const allReady = useMemo(() => {
        const mustBeTrue = [workspaceInitialised, employeesArePrepped];
        return mustBeTrue.every((value) => value);
    }, [workspaceInitialised, employeesArePrepped]);

    return allReady;
}
