import { useSelector } from "react-redux";
import { useValidation } from "../../../common/hooks/useValidation";
import { useMemo } from "react";
import * as Yup from "yup";
import { FIELD_SIZE_LIMITS } from "../../../constants";

export function isEmailInUse(employees, email, empId) {
    if (!email) return false;
    return (
        Object.values(employees).filter((emp) => {
            return emp.id !== empId && emp.email === email;
        }).length !== 0
    );
}

export function isEmployeeNumberInUse(employees, employeeNumber, empId) {
    if (!employeeNumber) return false;
    return (
        Object.values(employees).filter((emp) => {
            return emp.id !== empId && emp.employeeNumber === employeeNumber;
        }).length !== 0
    );
}

export function useValidateEmployee(employeeData) {
    const employees = useSelector((state) => state.businessUnit.employees);

    const employeeValidationSchema = useMemo(() => {
        return {
            firstname: Yup.string()
                .required("Name is required")
                .min(3, "Name is too short")
                .max(FIELD_SIZE_LIMITS.GENERIC_NAME, "Name is too long"),
            surname: Yup.string()
                .required("Surname is required")
                .max(FIELD_SIZE_LIMITS.GENERIC_NAME, "Surname is too long"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
        };
    }, []);

    const [passedSchema, errors] = useValidation(employeeData, employeeValidationSchema);

    const passedOther = useMemo(() => {
        const emailIsInUse = isEmailInUse(employees, employeeData?.email, employeeData?.id);
        const employeeNumberIsInUse = isEmployeeNumberInUse(employees, employeeData?.employeeNumber, employeeData?.id);
        return !emailIsInUse && !employeeNumberIsInUse;
    }, [employeeData, employees]);

    const passed = passedSchema && passedOther;

    return [passed, errors];
}
