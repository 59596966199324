
export function buildFormDueStatus(employeeId, entry, iterationInfo, participationRequired = {}) {
    function isDone(required, override, assessedOn, dueOn) {
        if (!required) return false;
        if (override) return true;
        if (!assessedOn) return false;
        return new Date(assessedOn) >= new Date(dueOn);
    }

    const { snapshotDates } = entry;
    const { id: cycleId, dueOn, doneOverrides } = iterationInfo;
    const { employeeRequired, managerRequired } = participationRequired;
    const doneOverride = doneOverrides.includes(employeeId);
    const selfAssessedOn = snapshotDates?.[cycleId]?.emp;
    const managerAssessedOn = snapshotDates?.[cycleId]?.man;
    const selfDone = isDone(employeeRequired, doneOverride, selfAssessedOn, dueOn);
    const managerDone = isDone(managerRequired, doneOverride, managerAssessedOn, dueOn);
    //const allDone = (!managerRequired || managerDone) && (!employeeRequired || selfDone);
    return {
        selfAssessedOn,
        managerAssessedOn,
        selfDone,
        managerDone,
        //allDone,
        ...participationRequired,
    };
}
