export function buildLinkedTalentBoardIds(role, boardMap) {
    function extractRoleAndLevel(roleId) {
        const roleOnly = roleId.slice(0, 7);
        let roleLevel = roleId[8]; // 8th character is the role level
        const isNumber = !isNaN(roleLevel);
        if (isNumber && roleId.length === 9 && roleId[7] === "-") {
            // Role has been assigned a board level; return level and role
            return [roleLevel, roleOnly];
        } else {
            // Role hasn't been assigned board level; Return the Role Level, or 1 if not found
            roleLevel = role?.level || 1;
            return [roleLevel, roleOnly];
        }
    }
    if (!role) return { linkedTalentBoardIds: [], boardLevels: {} };
    const linkedTalentBoardIds = [];
    const boardLevels = {};
    Object.entries(boardMap).forEach(([boardId, linkedRoleIds]) => {
        const rolesAndLevels = linkedRoleIds.map(extractRoleAndLevel);
        for (let [level, roleId] of rolesAndLevels) {
            // Employee isn't a board member, skip
            if (role?.id !== roleId) continue;

            // Employee is a board member,
            linkedTalentBoardIds.push(boardId);
            boardLevels[boardId] = level;
        }
    });
    return { linkedTalentBoardIds, boardLevels };
}
