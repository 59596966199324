import { deleteField } from "firebase/firestore";
import { FIRST_BUSINESS_UNIT_ID } from "shared";
import { userSignOut } from "../../../firebase/auth";
import { auth } from "../../../firebase/firebase";
import { resetApp } from "../../../redux/appSlice";
import { resetBusinessUnit } from "../../BusinessUnits/utils/businessUnitSlice";
import { resetConfig, setUserDetails } from "../../Config/utils/configSlice";
import { writeEmployee } from "../../Employees/utils/employeeDb";
import { resetSnapshots } from "../../Snapshots/utils/snapshotsSlice";
import { resetWorkspace } from "../../Workspace/utils/workspaceSlice";
import { writeUser } from "./userDb";
import { resetUser, setUserData } from "./userSlice";

function resetAll(dispatch) {
    dispatch(resetApp());
    dispatch(resetUser());
    dispatch(resetConfig());
    dispatch(resetBusinessUnit());
    dispatch(resetWorkspace());
    dispatch(resetSnapshots());
}

const userSignOutThunk = () => async (dispatch, getState) => {
    userSignOut();
    resetAll(dispatch);
};

export const setUserDataThunk = (rawUserData) => async (dispatch, getState) => {
    const { workspaces, claims, email, firstname, surname } = rawUserData;
    const isDeveloper = claims?.developer;
    const userData = {
        ...rawUserData,
    };
    const workspaceList = Object.keys(workspaces || {});
    const activeWorkspaceId = rawUserData?.activeWorkspaceId || workspaceList[0];
    if (!isDeveloper) {
        // If the user is a developer, they'll be redirected to choose a workspace if the don't have one
        // All other users will be redirected to the first workspace they have access to
        userData.activeWorkspaceId = activeWorkspaceId;
    }
    if (activeWorkspaceId) {
        userData.activeBusinessUnitId = rawUserData?.activeBusinessUnitId || FIRST_BUSINESS_UNIT_ID;
    }

    dispatch(setUserData(userData));

    if (email && firstname && surname) {
        const userDisplayName = `${firstname} ${surname}`;
        const update = {
            userEmail: email,
            userDisplayName: userDisplayName,
        };
        dispatch(setUserDetails(update));
    }
};

const updateUserThunk = (update) => async (dispatch, getState) => {
    const uid = getState().user.id;
    let batch = writeUser(uid, update);
    await batch.commit();
};

const changeActiveWorkspaceThunk = (workspaceId) => async (dispatch, getState) => {
    const uid = auth.currentUser.uid;
    let userUpdate = { activeWorkspaceId: workspaceId || deleteField(), activeBusinessUnitId: deleteField() };
    let batch = writeUser(uid, userUpdate);
    await batch.commit();
};

const dropWorkspace = () => async (dispatch, getState) => {
    const uid = auth.currentUser.uid;
    let userUpdate = { activeWorkspaceId: deleteField(), activeBusinessUnitId: deleteField() };
    let batch = writeUser(uid, userUpdate);
    dispatch(resetSnapshots());
    dispatch(resetWorkspace());
    dispatch(resetBusinessUnit());
    await batch.commit();
};

const changeActiveBusinessUnitThunk = (unitId) => async (dispatch, getState) => {
    const uid = getState().config.claims.uid;
    let batch = writeUser(uid, { activeBusinessUnitId: unitId });
    await batch.commit();
};

const updateNotificationReadTimeThunk = () => async (dispatch, getState) => {
    const uid = getState().config.claims.uid;
    let batch = writeUser(uid, { dateLastReadNotifications: new Date() });
    await batch.commit();
};

const joinWorkspaceThunk = (joinDetails, onJoined) => async (dispatch, getState) => {
    const { uid, employeeId, firstname, surname, workspaceId, workspaceName } = joinDetails;
    const employeeUpdate = {
        firstname: firstname,
        surname: surname,
        inviteAccepted: true,
    };
    let batch = writeEmployee(workspaceId, employeeId, employeeUpdate);
    const userUpdate = {
        firstname: firstname,
        surname: surname,
        workspaces: { [workspaceId]: workspaceName },
        invites: [],
    };
    batch = writeUser(uid, userUpdate, batch);
    await batch.commit();
    if (onJoined) onJoined();
};

export {
    changeActiveBusinessUnitThunk,
    changeActiveWorkspaceThunk,
    dropWorkspace,
    joinWorkspaceThunk,
    updateNotificationReadTimeThunk,
    updateUserThunk,
    userSignOutThunk,
};
