import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from "../redux/appSlice";
import CreateRole from "../features/Roles/components/organisms/RoleEditors/CreateRole";
import FillRole from "../features/Roles/components/organisms/RoleEditors/FillRole";
import MoveRoleOrEmployee from "../features/Roles/components/organisms/RoleEditors/MoveRoleOrEmployee";
import ChangeEmployeeBusinessUnit from "../features/BusinessUnits/Components/ChangeEmployeeBusinessUnit";

const AppModals = () => {
    const dispatch = useDispatch();
    const activeModal = useSelector((state) => state.app.activeModal);

    const closeModal = () => {
        dispatch(setActiveModal(null));
    };

    switch (activeModal) {
        case "createRole":
            return <CreateRole isOpen={true} toggle={closeModal} />;
        case "fillVacantRole":
            return <FillRole isOpen={true} toggle={closeModal} />;
        case "moveRole":
            return <MoveRoleOrEmployee isOpen={true} toggle={closeModal} />;
        case "changeBusinessUnit":
            return <ChangeEmployeeBusinessUnit isOpen={true} toggle={closeModal} />;
        default:
            return null;
    }
};

export default AppModals;
