import { useSelector } from "react-redux";
import { useCallback } from "react";

export function useAssessmentColors() {
    const assessmentColors = useSelector((state) => state.config.everyoneConfig.assessmentColors);

    const getRatingColor = useCallback(
        (rating) => {
            return assessmentColors[rating];
        },
        [assessmentColors]
    );

    return {
        getRatingColor,
        assessmentColors,
    };
}
