import classNames from "classnames";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Each, RichText } from "../../../../common/components";
import { confirmFirst } from "../../../../common/utils/confirmFirst";
import { DownloadIcon, HistoryIcon, PlusIcon } from "../../../../components/Icons/MyIcons";
import MyDrawer from "../../../../components/MyComponents/Drawer";
import { useToggle } from "../../../../hooks/useToggle";
import ScheduledActionCard from "../../../ScheduledActions/ScheduledActionCard";
import useScheduledActions from "../../../ScheduledActions/hooks/useScheduledActions";
import ScheduleActionModal from "../../View/ScheduleActionModal";
import ScheduledActionModal from "../../containers/ScheduledActionModal";
import CompletedActions from "../organisms/CompletedActions";
import { MyModal, MyModalBody, MyModalHeader } from "../../../../components/MyComponents/Modal";

const EmptyPlan = ({ toggleModal }) => (
    <div className="flex-1 flex p-4 col-span-12 justify-center">
        <div className="max-w-md flex items-center flex-col space-y-4 py-12">
            <h3>No Development Scheduled</h3>
            <p className="tracking-tight">Browse the Library and start adding Actions.</p>
            <Button onClick={toggleModal}>
                <PlusIcon />
            </Button>
        </div>
    </div>
);

const CreateActionCard = ({ toggleModal }) => {
    return (
        <div className="flex-1 flex min-w-40 flex-col bg-transparent items-center justify-center space-y-2">
            <Button shape="circle" onClick={toggleModal}>
                <PlusIcon />
            </Button>
            <h5>Add to Plan</h5>
        </div>
    );
};

const ShowMore = ({ expandedDoc, onClose }) => {
    if (!expandedDoc) return null;
    return (
        <MyModal isOpen={true} onToggle={onClose} size="lg">
            <MyModalHeader onToggle={onClose}>Action Details</MyModalHeader>
            <MyModalBody>
                <div className="flex flex-col justify-between bg-white rounded-lg px-8 py-4">
                    <RichText readOnly removePadding content={expandedDoc.description} />
                </div>
            </MyModalBody>
        </MyModal>
    );
};

const DevelopmentPlanTemplate = ({
    activeEmployee = {},
    className,
    onScheduleActions,
    onCompleteActions,
    onCancelActions,
}) => {
    const employeeId = activeEmployee?.id;
    const [expandedDoc, setExpandedDoc] = useState();
    const [reportType, setReportType] = useState("scheduled");
    const [showSideArea, setShowSideArea] = useState(false);
    const [showModal, toggleModal] = useToggle();
    const [showReport, toggleReport] = useToggle();
    const { scheduledActionIds, completedActionIds } = activeEmployee;
    const { fetchAction, completedActions, incompleteActions } = useScheduledActions(
        employeeId,
        scheduledActionIds,
        completedActionIds
    );

    const handleScheduleAction = (actionId) => {
        const title = "Schedule Action";
        const message = "Are you sure you want to schedule this action?";
        confirmFirst({
            title,
            message,
            onConfirm: () => {
                const batch = onScheduleActions([actionId]);
                return batch.commit();
            },
            onAfter: () => {
                toast.success("Action has been scheduled");
            },
        });
    };

    const handleCompleteAction = (actionId) => {
        const title = "Complete Action";
        const message = "Are you sure you want to mark this action as complete?";
        confirmFirst({
            title,
            message,
            onConfirm: () => {
                const batch = onCompleteActions([actionId]);
                return batch.commit();
            },
            onAfter: () => {
                toast.success("Action has been completed");
            },
        });
    };

    const handleDeleteAction = (actionId) => {
        const title = "Cancel Action";
        const message = "Are you sure you want to cancel this action?";
        confirmFirst({
            title,
            message,
            onConfirm: () => {
                const batch = onCancelActions([actionId]);
                return batch.commit();
            },
            onAfter: () => {
                toast.success("Action has been cancelled");
            },
        });
    };

    const handleShowScheduledReport = () => {
        setReportType("scheduled");
        toggleReport();
    };

    const noScheduledActions = incompleteActions.length === 0;

    return (
        <MyDrawer
            setIsOpen={setShowSideArea}
            isOpen={showSideArea}
            className="h-full flex"
            contentClassName="flex-1"
            drawerEnd={true}
            noClickClose={false}
            side={
                <CompletedActions
                    isOpen={showSideArea}
                    completedActions={completedActions}
                    fetchAction={fetchAction}
                    setReportType={setReportType}
                    onScheduleAction={handleScheduleAction}
                    onCompleteAction={handleCompleteAction}
                    onDeleteAction={handleDeleteAction}
                    toggleReport={toggleReport}
                />
            }
        >
            <div className={classNames("flex-1 flex flex-col overflow-auto", className)}>
                <div className="toolbar rounded-t-lg border justify-between pb-1 px-6">
                    <h5>Development Plan</h5>
                    <div className="flex space-x-1 overflow-y-scroll">
                        <Button onClick={toggleModal} size="sm">
                            <PlusIcon size={16} />
                            <span>Add To Plan</span>
                        </Button>
                        <Button onClick={handleShowScheduledReport} size="sm" disabled={noScheduledActions}>
                            <DownloadIcon size={16} />
                            <span>Download Report</span>
                        </Button>
                        <Button onClick={() => setShowSideArea(true)} size="sm">
                            <HistoryIcon size={16} />
                            <span>View Completed</span>
                        </Button>
                    </div>
                </div>
                <div className="bg-diagonal-stripes border rounded-b-lg p-6 flex flex-col flex-1 overflow-auto">
                    <div className="overflow-y-scroll grid grid-cols-12 gap-2">
                        <Each
                            of={incompleteActions}
                            renderEmpty={() => <EmptyPlan toggleModal={toggleModal} />}
                            render={(action) => (
                                <div
                                    key={action.id}
                                    className="grid-item-xl flex card bg-primary bg-opacity-10 border-primary glass rounded-box border overflow-hidden"
                                >
                                    <ScheduledActionCard
                                        status="scheduled"
                                        fetchAction={fetchAction}
                                        actionId={action.id}
                                        actionName={action.actionName}
                                        onShowMore={setExpandedDoc}
                                        onComplete={handleCompleteAction}
                                        onDelete={handleDeleteAction}
                                    />
                                </div>
                            )}
                        />
                        {incompleteActions.length > 0 && (
                            <div className="flex">
                                <CreateActionCard toggleModal={toggleModal} />
                            </div>
                        )}
                    </div>
                </div>
                <ScheduleActionModal
                    activeEmployee={activeEmployee}
                    toggle={toggleModal}
                    isOpen={showModal}
                    fetchAction={fetchAction}
                    onCreate={handleScheduleAction}
                />
                <ScheduledActionModal
                    isOpen={showReport}
                    toggle={toggleReport}
                    employee={activeEmployee}
                    reportType={reportType}
                />
                <ShowMore expandedDoc={expandedDoc} onClose={() => setExpandedDoc(null)} />
            </div>
        </MyDrawer>
    );
};

export default DevelopmentPlanTemplate;
