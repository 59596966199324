import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useLoggedNavigate from "../../common/hooks/useLoggedNavigate";
import { BackIcon } from "../../components/Icons/MyIcons";
import { usePathArray } from "../../hooks/usePathArray";
import { useEmployees } from "../Employees/hooks/useEmployees";
import { useRoles } from "../Roles/hooks/useRoles";

const Breadcrumbs = () => {
    const params = useParams();
    const navigate = useLoggedNavigate();
    const location = useLocation();
    const pathArray = usePathArray();
    const activePage = pathArray[0];
    const { roleId, employeeId, actionId, boardId, traitId, cycleId, iterationId } = params;
    const { getEmployee } = useEmployees();
    const { getRole } = useRoles();
    const ownConfig = useSelector((state) => state.config.ownConfig);
    const orgView = ownConfig?.orgView;
    const activeBusinessUnitId = useSelector((state) => state.app.activeBusinessUnitId);
    const actions = useSelector((state) => state.workspace.actions);
    const traits = useSelector((state) => state.workspace.traits);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const businessUnits = useSelector((state) => state.workspace.businessUnits);
    const activeBusinessUnit = businessUnits?.[activeBusinessUnitId];
    const businessUnitName = activeBusinessUnit?.businessUnitName;
    const pageName = activePage && activePage.replace(/-/g, " ");
    const liveCycles = useSelector((state) => state.workspace.liveCycles);
    let breadcrumbs = getBreadcrumbs(activePage);
    breadcrumbs = breadcrumbs.filter((breadcrumb) => breadcrumb.label);
    const handleBack = () => {
        navigate(-1);
    };

    const handleNavigate = (navigateTo) => {
        if (location.pathname === navigateTo) return;
        if (!navigateTo) return;
        switch (navigateTo) {
            case "/cycles":
                navigate("/cycles/live");
                break;
            default:
                navigate(navigateTo);
                break;
        }
    };

    function getBreadcrumbs(id) {
        const rootLink = activePage === "people" ? `/organisation/${orgView}` : `/${activePage}`;
        let breadcrumbs = [
            { label: businessUnitName, link: "/organisation" },
            { label: pageName, link: rootLink },
        ];
        let label;

        switch (id) {
            case "organisation":
                if (employeeId) {
                    const employee = getEmployee(employeeId);
                    label = employee.displayName;
                    breadcrumbs.push({ label });
                } else if (roleId) {
                    const role = getRole(roleId);
                    const roleLabel = role.jobTitle || "Unknown Role";
                    const incumbent = getEmployee(role?.incumbentId);
                    label = incumbent?.displayName || "Vacant";
                    if (!incumbent) {
                        label = `${roleLabel} (${label})`;
                    }
                    breadcrumbs.push({ label });
                }
                const activeTab = pathArray[pathArray.length - 1];
                if (activeTab) {
                    if (pathArray.includes("reviews")) {
                        const reviewer = getEmployee(activeTab);
                        if (reviewer) {
                            breadcrumbs.push({ label: reviewer.displayName });
                        }
                    } else {
                        const tabLabel = activeTab.replace(/-/g, " ");
                        breadcrumbs.push({ label: tabLabel });
                    }
                }
                break;
            case "actions":
                if (actionId) {
                    const action = actions[actionId];
                    breadcrumbs.push({ label: action?.actionName });
                }
                break;
            case "traits":
                if (traitId) {
                    const trait = traits[traitId];
                    breadcrumbs.push({ label: trait?.traitName });
                }
                break;
            case "talentboards":
                if (boardId) {
                    const board = talentBoards[boardId];
                    breadcrumbs.push({ label: board?.boardName });
                }
                break;
            case "cycles":
                if (cycleId) {
                    const cycle = liveCycles[cycleId];
                    if (iterationId) {
                        breadcrumbs.push({ label: cycle?.cycleName, link: `/cycles/live/${cycleId}/iterations` });
                        breadcrumbs.push({ label: "Results" });
                    } else {
                        breadcrumbs.push({ label: cycle?.cycleName });
                    }
                }

                break;
            default:
                break;
        }

        return breadcrumbs;
    }

    return (
        <div className="ul breadcrumbs">
            <ul>
                <li className="clickable font-bold" onClick={handleBack}>
                    <BackIcon size={32} />
                </li>
                {breadcrumbs.map((breadcrumb, i) => {
                    if (!breadcrumb) return null;
                    const { label, link } = breadcrumb;
                    const cleanLabel = label.length > 42 ? `${label.substring(0, 42)}...` : label;
                    return (
                        <li
                            key={`breadcrumb-${i}`}
                            className={classNames("font-bold uppercase", { clickable: !!link })}
                            onClick={() => handleNavigate(link)}
                        >
                            {cleanLabel}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Breadcrumbs;
