import { useMemo } from "react";
import Breadcrumbs from "../../../../common/components/molecules/Breadcrumbs/Breadcrumbs";
import { useRoleRelationships } from "../../hooks/useRoleRelationships";
import { useRoles } from "../../hooks/useRoles";

const DepartmentBreadcrumbs = ({ topLevelRoleId, roleId, placeholderLabel = "All Roles", onItemClick = () => {} }) => {
    const { getAllDepartmentRoleIdsAbove, getAllRoleIdsBelow } = useRoleRelationships();
    const { getRole } = useRoles();

    const authorizedRoleIds = useMemo(() => getAllRoleIdsBelow(topLevelRoleId), [topLevelRoleId, getAllRoleIdsBelow]);

    const items = useMemo(() => {
        const role = getRole(roleId) || {};

        const parentDepartments = getAllDepartmentRoleIdsAbove(roleId);
        const authorizedDepartments = parentDepartments.filter((departmentRoleId) =>
            authorizedRoleIds.includes(departmentRoleId)
        );

        const parentCrumbs = authorizedDepartments.map((departmentRoleId) => {
            const departmentRole = getRole(departmentRoleId);
            return {
                id: departmentRoleId,
                label: departmentRole?.department,
            };
        });

        const dirtyItems = [
            {
                id: topLevelRoleId,
                label: "All Departments",
            },
            ...parentCrumbs,
            {
                id: roleId,
                label: role.department,
            },
        ];

        // Ensure only one item with the same id
        return dirtyItems.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
    }, [roleId, topLevelRoleId, authorizedRoleIds, getRole, getAllDepartmentRoleIdsAbove]);

    return <Breadcrumbs items={items} onItemClick={onItemClick} placeholder={placeholderLabel} />;
};

export default DepartmentBreadcrumbs;
