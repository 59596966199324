import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "react-daisyui";

const MyCheckbox = ({
    label,
    fullWidth,
    size = "md",
    checked,
    checkAtEnd,
    color = "primary",
    hideLabel,
    uppercase,
    bold,
    onChange = () => {},
}) => {
    const forId = label.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
    const accessibility = {
        "aria-label": label,
        id: forId,
    };
    const textSizeClass = `text-${size}`;

    return (
        <div className={classNames("flex cursor-pointer", { "w-full": fullWidth })}>
            <div
                className={classNames("flex items-center", {
                    "order-2 ms-1": !checkAtEnd,
                    "me-1": checkAtEnd,
                    hidden: hideLabel,
                })}
            >
                <label
                    className={classNames(textSizeClass, "cursor-pointer flex items-center", {
                        uppercase,
                        "font-medium": !bold,
                        "font-bold": bold,
                    })}
                    htmlFor={forId}
                >
                    {label}
                </label>
            </div>
            <div className={classNames("flex items-center", { "me-2": !checkAtEnd })}>
                <Checkbox size={size} color={color} {...accessibility} checked={checked} onChange={onChange} />
            </div>
        </div>
    );
};

MyCheckbox.propTypes = {
    label: PropTypes.string,
    hideLabel: PropTypes.bool,
    labelClasses: PropTypes.string,
    uppercase: PropTypes.bool,
    bold: PropTypes.bool,
    fullWidth: PropTypes.bool,
    checked: PropTypes.bool,
    checkAtEnd: PropTypes.bool,
    color: PropTypes.oneOf(["primary", "secondary", "success", "warning", "error", "info"]),
    onChange: PropTypes.func,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

export default MyCheckbox;
