import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTopLevelRoleId } from "../../BusinessUnits/hooks/useTopLevelRoleId";

export function useSubordinates(topLevelRoleId, includeTopRole = true) {
    const preppedEmployees = useSelector((state) => state.businessUnit.preppedEmployees);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const highestAllowedRoleId = useTopLevelRoleId();

    const subordinates = useMemo(() => {
        const roleId = topLevelRoleId || highestAllowedRoleId;
        let included = [];
        if (!roleId || roleId === rootRoleId) {
            // When no role filtering is needed, include all employees with a role
            included = Object.values(preppedEmployees).filter((employee) => !!employee.roleId);
        } else {
            // When there is filtering, include all employees below the selected role
            included = Object.values(preppedEmployees).filter((emp) => emp.roleIdsAbove.includes(roleId));

            // If the selected role should be included, add it
            if (includeTopRole) {
                const topEmployee = Object.values(preppedEmployees).find((employee) => employee.roleId === roleId);
                if (topEmployee) {
                    included.push(topEmployee);
                }
            }
        }
        return included;
    }, [rootRoleId, preppedEmployees, highestAllowedRoleId, topLevelRoleId, includeTopRole]);

    return subordinates;
}
