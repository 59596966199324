import { buildComponentName } from "../../../common/utils/buildComponentName";
import { useOrgNodeContext } from "../../../pages/organisation/outlets/OrgNode/OrgNodePage";
import { withLiveTalentData } from "../../Workspace/hoc/withLiveTalentData";

export const withOrgNodeContext = (Component) => {
    const WithOrgNodeContext = (props) => {
        const contextProps = useOrgNodeContext();
        return <Component {...props} {...contextProps} useParentContext={useOrgNodeContext} />;
    };
    WithOrgNodeContext.displayName = buildComponentName("withOrgNodeContext", Component);
    return withLiveTalentData(WithOrgNodeContext);
};
