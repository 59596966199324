import { arrayRemove, arrayUnion } from "firebase/firestore";
import { batchSet, createBatch, getDocRef, getSubCollectionRef } from "../../../firebase/firebaseActions";
import { useActionRefs } from "../../Actions/refs/useActionRefs";
import { useEmployee } from "../../Employees/hooks/useEmployee";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";

function updateAction(actionsRef, actionId, unitDocId, actionUpdate, batch) {
    const actionRef = getDocRef(actionsRef, actionId);
    const trackerDocsRef = getSubCollectionRef(actionRef, "actionTracking");
    const unitDocRef = getDocRef(trackerDocsRef, unitDocId);
    batch = batchSet(unitDocRef, actionUpdate, batch);
    return batch;
}

export function useEmployeeScheduler(employeeId) {
    const { actionsRef } = useActionRefs();
    const { employeeRef } = useEmployeeRefs(employeeId);
    const { employee } = useEmployee(employeeId);
    const unitDocId = employee?.unitDocId;

    // TODO:  Currently cancelling an incomplete action is the same as deleting a complete one
    // ...    Break this into separate functions
    // ...    When deleting, check which talent boards require this action to be completed
    // ...    Remove the employee from the tracking collection for the boards
    const handleCancelActions = (actionIds, batch = createBatch()) => {
        // if arguments aren't arrays, convert them to one
        if (!Array.isArray(actionIds)) actionIds = [actionIds];

        // Update the employee docs
        const employeeUpdate = {
            scheduledActionIds: arrayRemove(...actionIds),
            completedActionIds: arrayRemove(...actionIds),
        };
        batch = batchSet(employeeRef, employeeUpdate, batch);

        // Update the primary action doc
        const actionUpdate = {
            scheduledEmployeeIds: arrayRemove(employeeId),
            completedEmployeeIds: arrayRemove(employeeId),
        };
        actionIds.forEach((actionId) => {
            batch = updateAction(actionsRef, actionId, unitDocId, actionUpdate, batch);
        });
        return batch;
    };

    // eslint-disable-next-line no-unused-vars
    const handleDeleteActions = (actionIds, batch = createBatch()) => {
        // Remove in the same way as cancelling
        batch = handleCancelActions(actionIds, batch);
        // Delete the action from the snapshot
    };

    // TODO:  Check which talent boards require this action to be completed
    // ...    Add the employee to the tracking collection for the board
    const handleCompleteActions = (actionIds, batch = createBatch()) => {
        // if arguments aren't arrays, convert them to one
        if (!Array.isArray(actionIds)) actionIds = [actionIds];

        // Update the employee docs
        const employeeUpdate = {
            scheduledActionIds: arrayRemove(...actionIds),
            completedActionIds: arrayUnion(...actionIds),
        };
        batch = batchSet(employeeRef, employeeUpdate, batch);

        // Update the primary action doc
        const actionUpdate = {
            scheduledEmployeeIds: arrayRemove(employeeId),
            completedEmployeeIds: arrayUnion(employeeId),
        };
        actionIds.forEach((actionId) => {
            batch = updateAction(actionsRef, actionId, unitDocId, actionUpdate, batch);
        });
        return batch;
    };

    const handleScheduleActions = (actionIds, batch = createBatch()) => {
        // if arguments aren't arrays, convert them to one
        if (!Array.isArray(actionIds)) actionIds = [actionIds];

        // Update the employee docs
        const employeeUpdate = {
            scheduledActionIds: arrayUnion(...actionIds),
            completedActionIds: arrayRemove(...actionIds),
        };
        batch = batchSet(employeeRef, employeeUpdate, batch);

        // Update the primary action doc
        const actionUpdate = {
            scheduledEmployeeIds: arrayUnion(employeeId),
            completedEmployeeIds: arrayRemove(employeeId),
        };
        actionIds.forEach((actionId) => {
            batch = updateAction(actionsRef, actionId, unitDocId, actionUpdate, batch);
        });
        return batch;
    };

    return {
        scheduleActions: handleScheduleActions,
        completeActions: handleCompleteActions,
        cancelActions: handleCancelActions,
    };
}
