import classNames from "classnames";
import React, { useMemo } from "react";
import { FaQuestion, FaSitemap } from "react-icons/fa";
import { useSelector } from "react-redux";
import { InviteIcon } from "../../../components/Icons/MyIcons";
import MyButton from "../../../components/MyComponents/Button";
import { useEmployees } from "../../Employees/hooks/useEmployees";
import { useRoles } from "../../Roles/hooks/useRoles";
import { useClaims } from "../../User/hooks/useClaims";
import { useIsThisMe } from "../../User/hooks/useIsThisMe";
import { useOrgActions } from "../hooks/useOrgActions";

export const OrgNodeDetailsCard = ({ className, showActions, onToggleEditEmployee, onToggleEditRole }) => {
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const { isAdmin } = useClaims();
    const isThisUser = useIsThisMe(selectedEmployeeId);
    const { getEmployee } = useEmployees();
    const employee = getEmployee(selectedEmployeeId);
    const { getRole } = useRoles();
    const selectedRole = getRole(selectedRoleId);
    const { jobTitle } = selectedRole || {};
    const { employeePageActions } = useOrgActions();
    const preppedActions = useMemo(() => {
        if (!isAdmin || isThisUser) return employeePageActions;
        const editEmployee = {
            groupId: "employeeActions",
            id: "editEmployee",
            employeePage: true,
            label: "Edit Employee",
            hidden: !selectedEmployeeId,
            icon: InviteIcon,
            onClick: onToggleEditEmployee,
        };
        const editRole = {
            groupId: "roleActions",
            id: "editRole",
            employeePage: true,
            hidden: !selectedRoleId,
            label: "Edit Role",
            icon: FaSitemap,
            onClick: onToggleEditRole,
        };
        return [editEmployee, editRole, ...employeePageActions];
    }, [
        selectedEmployeeId,
        selectedRoleId,
        employeePageActions,
        isAdmin,
        isThisUser,
        onToggleEditEmployee,
        onToggleEditRole,
    ]);

    return (
        <div className={classNames(className)}>
            <div>
                <h3>{employee?.displayName || "Vacant"}</h3>
                <h4 className="text-base-500">{jobTitle}</h4>
            </div>
            {showActions && (
                <div className="flex space-x-2">
                    {preppedActions.map((item) => {
                        const { icon, label, onClick, hidden, ...props } = item;
                        const iconSize = props.iconSize || 20;
                        const Icon = icon || FaQuestion;
                        const key = `${item.id}-${selectedEmployeeId}`;
                        const tooltip = label || item.actionName;
                        return (
                            <MyButton
                                key={key}
                                hidden={hidden}
                                shape="circle"
                                size="sm"
                                tooltip={tooltip}
                                tooltipPosition="right"
                                onClick={onClick}
                            >
                                <Icon size={iconSize} />
                            </MyButton>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default OrgNodeDetailsCard;
