import { useSelector } from "react-redux";
import { buildComponentName } from "../../../common/utils/buildComponentName";
import { useAssessmentColors } from "../../Config/hooks/useAssessmentColors";
import { useCustomFields } from "../../CustomFields/hooks/useCustomFields";

export const withLiveTalentData = (Component) => {
    const WithLiveTalentData = (props) => {
        const talentBoards = useSelector((state) => state.workspace.talentBoards);
        const traits = useSelector((state) => state.workspace.traits);
        const talentAreas = useSelector((state) => state.workspace.talentAreas);
        const snapshots = useSelector((state) => state.snapshots.employeeSnapshots);
        const preppedEmployees = useSelector((state) => state.businessUnit.preppedEmployees);
        const roles = useSelector((state) => state.businessUnit.roles);
        const { assessmentColors, getRatingColor } = useAssessmentColors();
        const { groupedFields } = useCustomFields();
        return (
            <Component
                {...props}
                {...groupedFields}
                talentBoards={talentBoards}
                traits={traits}
                talentAreas={talentAreas}
                snapshots={snapshots}
                employees={preppedEmployees}
                roles={roles}
                assessmentColors={assessmentColors}
                getRatingColor={getRatingColor}
            />
        );
    };
    WithLiveTalentData.displayName = buildComponentName("withLiveTalentData", Component);
    return WithLiveTalentData;
};
