import { useSelector } from "react-redux";
import { useBusinessUnitsApi } from "../../BusinessUnits/hooks/useBusinessUnitApi";

export function useTalentBoardsApi() {
    const boardMap = useSelector((state) => state.businessUnit.boardMap);
    const { updateBoardMap } = useBusinessUnitsApi();

    function updateRoleTalentBoards(roleId, prevBoards = [], newLinkedBoardIds = [], batch) {
        let boardUpdate = {};

        // Remove boards
        const boardsToRemove = prevBoards.filter((id) => !newLinkedBoardIds.includes(id));
        boardsToRemove.forEach((boardId) => {
            let currentLinks = boardMap[boardId] || [];
            boardUpdate[boardId] = currentLinks.filter((id) => id !== roleId);
        });

        // Add boards
        const boardsToAdd = newLinkedBoardIds.filter((id) => !prevBoards.includes(id));
        boardsToAdd.forEach((boardId) => {
            let currentLinks = boardMap[boardId] || [];
            boardUpdate[boardId] = [...currentLinks, roleId];
        });

        batch = updateBoardMap(boardUpdate, batch);
        return batch;
    }

    return {
        updateRoleTalentBoards,
    };
}
