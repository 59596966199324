import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef } from "react";
import { useResizeDetector } from "react-resize-detector";
import ReactTooltip from "react-tooltip";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { useCheckedIds } from "../../../../hooks/useCheckedIds";
import { sortArrayOfObjects } from "../../../utils/arrayUtils";
import { useTableSort } from "./hooks/useTableSort";
import InfiniteTableHeader from "./organisms/InfiniteTableHeader";
import InfiniteTableRow from "./organisms/InfiniteTableRow";
import { exportTableToXl } from "./utils/exportTableToXl";

const InfiniteTable = ({
    tableId,
    tableName,
    columns = [],
    data = [],
    defaultSortKey = "id",
    defaultSortOrder = "asc",
    itemSize = 35,
    selectedRowId,
    className,
    showCheckBoxes,
    hideExport,
    hideActionMenu,
    checkHeader,
    rowActions,
    bulkActions,
    getRowClass,
    onRowClick = () => {},
    onRowDoubleClick = () => {},
    onHeaderClick = () => {},
}) => {
    const listRef = useRef();
    const { height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const [checkedIds, setCheckedIds] = useCheckedIds(tableId);
    const { sortKey, sortOrder, setSorting } = useTableSort(
        tableId,
        defaultSortKey || columns[0]?.id,
        defaultSortOrder
    );

    const headerHeight = useMemo(() => {
        return height || itemSize + 2;
    }, [itemSize, height]);

    const sortedData = useMemo(() => {
        const sortDesc = sortOrder === "desc";
        const useKey = sortKey || defaultSortKey;
        if (!data) return [];
        return sortArrayOfObjects(data, useKey, sortDesc);
    }, [data, defaultSortKey, sortKey, sortOrder]);

    const allChecked = useMemo(() => {
        if (sortedData.length === 0) return false;
        const allIds = sortedData.map((row) => row.id);
        return allIds.every((id) => checkedIds.includes(id));
    }, [sortedData, checkedIds]);

    const selectedIndex = useMemo(
        () => sortedData.findIndex((row) => row.id === selectedRowId),
        [sortedData, selectedRowId]
    );

    useEffect(() => {
        if (listRef.current && selectedIndex !== null) {
            listRef.current.scrollToItem(selectedIndex);
        }
    }, [selectedIndex, sortedData]);

    const handleHeaderClick = (key) => {
        setSorting(key);
        onHeaderClick(key);
    };

    const handleRowClick = (id) => {
        onRowClick(id);
    };

    const handleRowDoubleClick = (id) => {
        onRowDoubleClick(id);
    };

    const handleToggleCheck = (id) => {
        const newCheckedIds = checkedIds.includes(id)
            ? checkedIds.filter((checkedId) => checkedId !== id)
            : [...checkedIds, id];
        setCheckedIds(newCheckedIds);
    };

    const handleCheckAll = () => {
        let newCheckedIds = [];
        const allIds = sortedData.map((row) => row.id);
        if (!allChecked) {
            // ensure unique
            newCheckedIds = [...new Set([...checkedIds, ...allIds])];
        } else {
            // Remove all ids in sortedData from checkedIds
            newCheckedIds = checkedIds.filter((id) => !allIds.includes(id));
        }
        setCheckedIds(newCheckedIds);
    };

    const handleGetStyle = (col) => {
        return { width: col.width, minWidth: col.minWidth || col.width };
    };

    const handleExportClick = () => {
        const exportableColumns = columns.filter((col) => !col.excludeFromExport);
        const checkedData = sortedData.filter((row) => checkedIds.includes(row.id));
        exportTableToXl(checkedData, exportableColumns, tableName);
    };

    if (!tableId) return null;

    return (
        <div className={classNames("overflow-x-scroll h-full", className)}>
            <div className="h-full flex flex-col overflow-hidden min-w-fit">
                <div ref={ref}>
                    <InfiniteTableHeader
                        tableId={tableId}
                        columns={columns}
                        checkedIds={checkedIds}
                        showCheckboxes={showCheckBoxes}
                        hideExport={hideExport}
                        checkHeader={checkHeader}
                        allChecked={allChecked}
                        bulkActions={bulkActions}
                        getStyle={handleGetStyle}
                        onExportClick={handleExportClick}
                        onCheckAll={handleCheckAll}
                        onHeaderClick={handleHeaderClick}
                    />
                </div>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listRef}
                            height={height - headerHeight}
                            itemCount={sortedData.length}
                            itemSize={itemSize}
                            width={width}
                            style={{ overflowX: "visible" }}
                            itemData={{ data: sortedData, columns }}
                        >
                            {({ index, style }) => (
                                <InfiniteTableRow
                                    index={index}
                                    style={style}
                                    selectedRowId={selectedRowId}
                                    data={sortedData}
                                    columns={columns}
                                    showCheckBoxes={showCheckBoxes}
                                    checkedIds={checkedIds}
                                    rowActions={rowActions}
                                    getRowClass={getRowClass}
                                    hideActionMenu={hideActionMenu}
                                    getStyle={handleGetStyle}
                                    onCheck={handleToggleCheck}
                                    onRowClick={handleRowClick}
                                    onRowDoubleClick={handleRowDoubleClick}
                                    onExportClick={handleExportClick}
                                />
                            )}
                        </List>
                    )}
                </AutoSizer>
                <ReactTooltip id={tableId} place="top" delayShow={220} />
            </div>
        </div>
    );
};

InfiniteTable.propTypes = {
    tableId: PropTypes.string,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowActions: PropTypes.array,
    rowActionGroupLabels: PropTypes.object,
    onRowClick: PropTypes.func,
    onHeaderClick: PropTypes.func,
};

export default InfiniteTable;
