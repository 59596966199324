import { useCallback, useMemo } from "react";
import { useScheduleApi } from "./useScheduleApi";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { setScheduledUpdates } from "../../Workspace/utils/workspaceSlice";
import { useDispatch } from "react-redux";
import { useClaims } from "../../User/hooks/useClaims";

export function useScheduleListener() {
    const dispatch = useDispatch();
    const { scheduledUpdatesRef } = useScheduleApi();
    const { isDeveloper } = useClaims();

    // TODO: Remove isDeveloper requirement once feature is complete
    const shouldListen = useMemo(() => {
        return !!scheduledUpdatesRef && isDeveloper;
    }, [scheduledUpdatesRef, isDeveloper]);

    const handleDataReceived = useCallback(
        (querySnapshot) => {
            const data = prepQuerySnapshot(querySnapshot);
            dispatch(setScheduledUpdates(data));
        },
        [dispatch]
    );

    return useListener(scheduledUpdatesRef, handleDataReceived, shouldListen);
}
