import { useState } from "react";
import MyButton from "../../../../components/MyComponents/Button";

// Higher-Order Component
const withSubmit = (Wrapped) => {
    const WithSubmit = (props) => {
        const [isSubmitting, setIsSubmitting] = useState(false);

        const handleClick = async () => {
            setIsSubmitting(true);
            try {
                // Ensure onClick is a function before calling it
                if (typeof props.onClick === "function") {
                    await props.onClick();
                }
            } catch (error) {
                console.error("Error during submission:", error);
            } finally {
                setIsSubmitting(false);
            }
        };

        const disabled = isSubmitting || props.disabled;

        return <Wrapped {...props} onClick={handleClick} loading={isSubmitting} disabled={disabled} />;
    };

    // Add display name for debugging
    WithSubmit.displayName = `withSubmit(${Wrapped.displayName || Wrapped.name || "Component"})`;

    return WithSubmit;
};

const Button = (props) => {
    return <MyButton {...props} />;
};

export const SubmitButton = withSubmit(Button);

export default Button;
