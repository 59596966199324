import classNames from "classnames";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Button } from "../../../../common/components";
import MyBadge from "../../../../components/MyComponents/Badge";
import { withReviewStatus } from "../../hoc/withReviewStatus";
import { useMemo } from "react";

const SelfItem = ({ cycleName, getStatusValues, onItemClick }) => {
    const { color, label, actionRequired } = useMemo(() => getStatusValues(), [getStatusValues]);
    return (
        <div className={classNames("flex py-4 px-8 bg-opacity-5 space-x-8")}>
            <div className="flex-2 flex items-center">
                <h6>{cycleName}</h6>
            </div>
            <div className="flex-1 flex justify-end items-center">
                <MyBadge color={color}>{label}</MyBadge>
            </div>
            <div className={classNames("flex items-center", { "animate-pulse text-primary": actionRequired })}>
                <Button size="xs" shape="circle" color="ghost" onClick={onItemClick}>
                    <FaArrowUpRightFromSquare size={18} />
                </Button>
            </div>
        </div>
    );
};

const TeamItem = ({ cycleName, displayName, getStatusValues, onItemClick }) => {
    const { color, label, actionRequired } = useMemo(() => getStatusValues(), [getStatusValues]);
    return (
        <div className={classNames("flex py-4 px-8 bg-opacity-5 space-x-8")}>
            <div className="flex-1 items-center flex">
                <h6>{displayName}</h6>
            </div>
            <div className="flex-1 items-center flex">
                <h6>{cycleName}</h6>
            </div>
            <div className="flex-1 flex justify-end items-center">
                <MyBadge color={color}>{label}</MyBadge>
            </div>
            <div className={classNames("flex items-center", { "animate-pulse text-primary": actionRequired })}>
                <Button size="xs" shape="circle" color="ghost" onClick={onItemClick}>
                    <FaArrowUpRightFromSquare size={18} />
                </Button>
            </div>
        </div>
    );
};

const SelfReviewQueueItem = withReviewStatus(SelfItem);
const TeamReviewQueueItem = withReviewStatus(TeamItem);

export { SelfReviewQueueItem, TeamReviewQueueItem };
