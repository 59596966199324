import { useCallback, useMemo } from "react";
import { useAssessmentColors } from "../../Config/hooks/useAssessmentColors";
import { useDepartmentTree } from "../../Roles/hooks/useDepartmentTree";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import { buildCohortSnapshot } from "../utils/buildCohortSnapshot";

export function useLiveInsightTree(topLevelRoleId, preppedEmployees, roles, talentAreas, talentBoards, traits) {
    const { getAllRoleIdsBelow, getDepartmentMemberIds } = useRoleRelationships();
    const { getRatingColor } = useAssessmentColors();
    const includedRoles = useMemo(() => {
        const allRoleIdsBelow = getAllRoleIdsBelow(topLevelRoleId);
        let included = {};
        for (let roleId of allRoleIdsBelow) {
            included[roleId] = roles[roleId];
        }
        return included;
    }, [roles, topLevelRoleId, getAllRoleIdsBelow]);

    const getExtraSunburstData = useCallback(
        (entry = {}) => {
            const { id } = entry;
            const role = includedRoles[id];
            const memberIds = getDepartmentMemberIds(id);
            const members = preppedEmployees.filter((employee) => memberIds.includes(employee.id));
            const snapshot = buildCohortSnapshot(members, talentAreas, talentBoards, traits);
            const tooltip = role?.department || role?.jobTitle || "Overall Strength";
            const color = getRatingColor(snapshot?.overallRating);
            return {
                label: "",
                tooltip,
                snapshot,
                color,
            };
        },
        [talentAreas, talentBoards, traits, preppedEmployees, includedRoles, getRatingColor, getDepartmentMemberIds]
    );

    return useDepartmentTree(topLevelRoleId, includedRoles, getExtraSunburstData);
}
