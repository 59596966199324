import { useEffect, useRef } from "react";
import { Each } from "../../../../../common/components";
import MeasureCard from "../MeasureCard/MeasureCard";
import classNames from "classnames";

const EmptyState = ({ hidden }) => {
    if (hidden) return null;
    return (
        <div className="flex-1 flex flex-col items-center justify-center space-y-4 border rounded-lg py-12">
            <h3 className="text-base-500">No Measures Included</h3>
        </div>
    );
};

const MeasureGroup = ({
    locked,
    measures,
    updateObjective,
    title = "Measures",
    hideEmptyState = false,
    allowAdd = false,
}) => {
    const creatorRef = useRef(null);
    const initialisedRef = useRef(false);
    const measureCount = measures.length;

    useEffect(() => {
        if (creatorRef.current && initialisedRef.current) {
            creatorRef.current.scrollIntoView({ behavior: "smooth" });
            creatorRef.current.focus();
        }
        initialisedRef.current = true;
    }, [measures]);

    const handleEditOrAddMeasure = (measure) => {
        updateObjective("editOrAddMeasure", measure);
    };

    const handleRemoveMeasure = (measure) => {
        updateObjective("removeMeasure", measure);
    };

    const hidden = !allowAdd || locked;

    return (
        <div className="flex-1 space-y-2 flex flex-col">
            <h6 className="text-sm text-base-800 tracking-tight font-medium">{title}</h6>
            <div className="flex-1 space-y-2 flex flex-col">
                <Each
                    of={measures}
                    renderEmpty={() => <EmptyState hidden={hideEmptyState} />}
                    render={(measure) => {
                        return (
                            <MeasureCard
                                measure={measure}
                                locked={locked}
                                onChange={handleEditOrAddMeasure}
                                onRemove={handleRemoveMeasure}
                            />
                        );
                    }}
                />
                <MeasureCard
                    ref={creatorRef}
                    hidden={hidden}
                    placeholder={measureCount === 0 ? "Add your first measure" : "Next Measure? (optional)"}
                    creatorMode
                    locked={locked}
                    onChange={handleEditOrAddMeasure}
                    onRemove={handleRemoveMeasure}
                />
                <div
                    className={classNames(
                        { hidden: hidden || measureCount > 0 },
                        "bg-base-150 text-base-500 font-bold border rounded-box px-6 py-3 text-sm tracking-tight"
                    )}
                >
                    Next Measure
                </div>
            </div>
        </div>
    );
};

export default MeasureGroup;
