import { groupBy } from "lodash";
import React from "react";
import SearchField from "../../../../common/components/molecules/SearchField/SearchField";
import { useDynamicFilter } from "../../../../common/hooks/useDynamicFilter";
import applogger from "../../../../common/utils/applogger";
import ReviewQueueTable from "../organisms/ReviewQueueTable";
import classNames from "classnames";

const TABLE_ID = "subordinate-reviews";

const Toolbar = ({ onFilterChange }) => (
    <div className="toolbar rounded-box border space-x-2 w-full">
        <div className="flex items-center space-x-2">
            <SearchField onChange={onFilterChange} placeholder="Search Active Reviews" />
        </div>
    </div>
);

const SubordinateReviewsTemplate = ({ className, subordinateReviews, userEmployeeId, onMarkEmployeesDone }) => {
    const [filteredReviews, onSearch] = useDynamicFilter(subordinateReviews, [
        "cycleName",
        "displayName",
        "managerName",
    ]);

    const handleItemClick = (item) => {
        console.log(item);
    };

    const handleMarkEmployeesDone = (reviewIds) => {
        // Get the checked reviews
        const reviews = filteredReviews.filter((review) => reviewIds.includes(review.id));

        // Grouped the reviews by cycleId
        const reviewsByCycle = groupBy(reviews, "cycleId");

        // For each cycle, add doneoveride for each employee
        Object.entries(reviewsByCycle).forEach(([cycleId, reviews]) => {
            const reviewsToComplete = reviews.filter((review) => {
                const { employeeRequired, managerRequired, selfDone, managerDone } = review;
                const selfDoneOrNotNeeded = !employeeRequired || selfDone;
                const managerDoneOrNotNeeded = !managerRequired || managerDone;
                return !selfDoneOrNotNeeded || !managerDoneOrNotNeeded;
            });
            const employeeIdsToComplete = reviewsToComplete.map((review) => review.employeeId);
            const batch = onMarkEmployeesDone(cycleId, employeeIdsToComplete);
            try {
                batch.commit();
            } catch (error) {
                applogger.error("Error marking employees as done", error);
            }
        });
    };

    return (
        <div className={classNames("flex-1 overflow-hidden flex flex-col space-y-2", className)}>
            <Toolbar onFilterChange={onSearch} />
            <div className="flex-1 rounded border overflow-hidden flex flex-col">
                <ReviewQueueTable
                    tableId={TABLE_ID}
                    reviewQueue={filteredReviews}
                    onItemClick={handleItemClick}
                    onMarkEmployeesDone={handleMarkEmployeesDone}
                    userEmployeeId={userEmployeeId}
                />
            </div>
        </div>
    );
};

export default SubordinateReviewsTemplate;
