import { calculateAverageOfRatings } from "../../Snapshots/utils/snapshotHelpers";

export function calculateTalentAreaRating(talentArea, ratings) {
    const { linkedTraitIds } = talentArea;
    const ratingList = linkedTraitIds.map((traitId) => {
        return ratings[traitId];
    }).filter(rating => rating !== undefined);
    if (ratingList.length === 0) return null;
    const score = calculateAverageOfRatings(ratingList);
    return score
}
