import classNames from "classnames";
import { readableColor } from "polished";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { useDontShrink } from "../../../../common/hooks/useDontShrink";
import { EmptyIcon } from "../../../../components/Icons/MyIcons";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";
import { useAssessmentColors } from "../../../Config/hooks/useAssessmentColors";
import DepartmentSunburst from "../../../Insights/components/atoms/DepartmentSunburst";
import { calculateTalentAreaRating } from "../../../Insights/utils/calculateTalentAreaRating";
import { withLiveTalentData } from "../../../Workspace/hoc/withLiveTalentData";
import TraitWordCloud from "./TraitWordCloud";

export const TalentSrengthHelp = ({ getRatingColor }) => {
    const strongColor = getRatingColor(99);
    const weakColor = getRatingColor(0);
    return (
        <div className="space-y-2">
            <p>
                Word colors represent the <span className="font-semibold">absolute value</span> of each rating. Low
                ratings will always be <span style={{ color: weakColor }}>red</span>, high ratings will be{" "}
                <span style={{ color: strongColor }}>green</span>.
            </p>
            <p>
                Word sizes represent the <span className="font-semibold">relative value</span> of each rating, in
                relation to the other ratings that have been received.
            </p>
        </div>
    );
};

const TalentAreaSection = ({ talentAreas, talentAreaId, rating, selected, onSelect, getRatingColor }) => {
    const [isHovered, setIsHovered] = useState(false);
    const talentArea = talentAreas[talentAreaId];
    const ratingColor = getRatingColor(rating);
    const tabStyle = {
        backgroundColor: ratingColor,
        color: readableColor(ratingColor),
    };
    const chevronStyle = {
        color: ratingColor,
    };

    return (
        <div
            className={classNames(
                "relative cursor-pointer py-3 flex-1 flex items-center border-b border-base-100 min-h-20 border",
                {
                    hovered: isHovered,
                }
            )}
            style={tabStyle}
            onClick={onSelect}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex justify-center items-center flex-1">
                <h6 className="flex-1 text-sm font-bold text-center">{talentArea.talentAreaName}</h6>
            </div>
            <div
                className={classNames("absolute -right-4", {
                    hidden: !selected,
                })}
                style={chevronStyle}
            >
                <AiFillCaretRight size={24} />
            </div>
        </div>
    );
};

const TalentStrength = ({
    traits,
    talentAreas,
    ratings = {},
    column,
    isPending,
    maxWordSize = 32,
    assessmentColors,
    departmentTree,
    selectedDepartment,
    filteredDepartmentId,
    setFilteredDepartmentId,
    fetchTrait,
}) => {
    const [selectedTalentAreaId, setSelectedTalentAreaId] = useState(false);
    const { ref, minHeight = 200 } = useDontShrink();
    const { getRatingColor } = useAssessmentColors(assessmentColors);

    const talentAreaRatings = useMemo(() => {
        if (Object.keys(ratings).length === 0) return [];
        return Object.values(talentAreas)
            .map((talentArea) => {
                const talentAreaRating = calculateTalentAreaRating(talentArea, ratings);
                if (talentAreaRating === null) return null;
                return {
                    id: talentArea.id,
                    rating: talentAreaRating,
                };
            })
            .filter((rating) => rating !== null);
    }, [ratings, talentAreas, traits]);

    const wordCloudRatingsArray = useMemo(() => {
        // When selected, filter traits for specific talent areas
        if (selectedTalentAreaId) {
            const selectedTalentArea = talentAreas[selectedTalentAreaId];
            const linkedTraitIds = selectedTalentArea?.linkedTraitIds || [];
            return linkedTraitIds
                .map((traitId) => {
                    const rating = ratings[traitId];
                    if (!rating) return null;
                    return {
                        id: traitId,
                        rating: rating,
                    };
                })
                .filter((rating) => rating !== null);
        } else {
            return Object.entries(ratings).map(([traitId, rating]) => {
                return {
                    id: traitId,
                    rating: rating,
                };
            });
        }
    }, [ratings, talentAreas, selectedTalentAreaId]);

    const handleSelectTalentArea = (talentAreaId) => {
        if (talentAreaId === selectedTalentAreaId) {
            setSelectedTalentAreaId(false);
        } else {
            setSelectedTalentAreaId(talentAreaId);
        }
    };

    const isEmpty = talentAreaRatings.length === 0;

    talentAreaRatings.sort((a, b) => b.rating - a.rating);

    if (isEmpty) {
        return (
            <MyEmptyState message="No Talent Data Yet" className="flex-1">
                <EmptyIcon size={64} />
            </MyEmptyState>
        );
    }

    const titleClasses = "flex tracking-tight bg-info bg-opacity-5 border-b justify-center py-3 text-lg";

    return (
        <div
            className="flex flex-1 items-stretch border rounded-lg divide-x overflow-hidden"
            ref={ref}
            style={{ minHeight }}
        >
            {departmentTree && (
                <div className="relative flex-1 min-w-sm max-w-sm flex flex-col">
                    <div className="flex-1 flex flex-col">
                        <div className={titleClasses}>Department Filter</div>
                        <div className="flex-1 p-4 flex flex-col justify-center">
                            <DepartmentSunburst
                                departmentTree={departmentTree}
                                activeSegmentId={filteredDepartmentId}
                                onSegmentClick={setFilteredDepartmentId}
                            />
                        </div>
                        {/* Overlayed Element */}
                        <div className="absolute inset-0 flex items-center justify-center pointer-events-none pt-10">
                            <h4>{selectedDepartment?.department || "All Departments"}</h4>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex-1 max-w-sm flex flex-col">
                <div className={titleClasses}>Talent Area Filter</div>
                <div className="flex-1 flex flex-col">
                    {talentAreaRatings.map((entry) => (
                        <TalentAreaSection
                            talentAreas={talentAreas}
                            key={entry.id}
                            rating={entry.rating}
                            talentAreaId={entry.id}
                            selected={selectedTalentAreaId === entry.id}
                            rowView={!column}
                            getRatingColor={getRatingColor}
                            onSelect={() => handleSelectTalentArea(entry.id)}
                        />
                    ))}
                </div>
            </div>
            <div className="flex-2 flex flex-col overflow-scroll">
                <div className={titleClasses}>Strengths</div>
                <div className="flex-1 flex flex-col justify-center">
                    <TraitWordCloud
                        traits={traits}
                        isPending={isPending}
                        ratings={wordCloudRatingsArray}
                        maxSize={maxWordSize}
                        assessmentColors={assessmentColors}
                        fetchTrait={fetchTrait}
                    />
                </div>
            </div>
        </div>
    );
};

TalentStrength.propTypes = {
    ratings: PropTypes.object,
    maxWordSize: PropTypes.number,
    className: PropTypes.string,
    minHeight: PropTypes.number,
};

export default withLiveTalentData(TalentStrength);
