import * as d3 from "d3";
import { debounce } from "lodash";
import { readableColor } from "polished";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import Skeleton from "../../../common/components/atoms/Skeleton/Skeleton";
import { isChartReady } from "../../../common/utils/chartUtils";
import { getUniqueId } from "../../../helpers/helpers";

const SunburstChart = ({
    data = [],
    width,
    height,
    disableTooltip = false,
    activeSegmentId = null,
    setActiveSegmentId = () => {},
    onSegmentClick = () => {},
}) => {
    const svgRef = useRef(null);
    const chartId = useMemo(() => getUniqueId(), []);
    const [chartIsReady, setChartIsReady] = useState(false);

    useEffect(() => {
        setChartIsReady(true);
    }, []);

    useEffect(() => {
        const chartIsReady = isChartReady(data, height, width);
        const debouncedSetActiveSegmentId = debounce(setActiveSegmentId, 300);
        const defaultColor = "#e5e5e5";

        if (chartIsReady) {
            const radius = Math.min(width, height) / 2;
            const ringWidth = 1;
            const adjustedRadius = radius - ringWidth;

            const partition = (data) => d3.partition().size([2 * Math.PI, adjustedRadius])(d3.hierarchy(data).count());

            const root = partition(data);
            let activeNodesSet = new Set();

            if (activeSegmentId) {
                const activeNode = root.descendants().find((d) => d.data.id === activeSegmentId);
                if (activeNode) {
                    activeNode.descendants().forEach((d) => activeNodesSet.add(d));
                }
            }

            d3.select(svgRef.current).selectAll("*").remove();

            const svg = d3
                .select(svgRef.current)
                .attr("viewBox", [-width / 2, -height / 2, width, height])
                .style("font", "10px sans-serif");

            svg.append("circle")
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", adjustedRadius)
                .attr("fill", "#e5e5e5")
                .attr("stroke", "#fff")
                .attr("stroke-width", 1);

            const arc = d3
                .arc()
                .startAngle((d) => d.x0)
                .endAngle((d) => d.x1)
                .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.01))
                .padRadius(adjustedRadius / 2)
                .innerRadius((d) => d.y0)
                .outerRadius((d) => d.y1);

            svg.append("g")
                .selectAll("g")
                .data(root.descendants())
                .join("g")
                .attr("class", "segment")
                .each(function (d) {
                    const segmentGroup = d3.select(this);

                    // Determine whether this segment is active
                    const isActive = !activeSegmentId || activeNodesSet.has(d);

                    // Draw the arc
                    segmentGroup
                        .append("path")
                        .attr("fill", d?.data?.color || defaultColor)
                        .attr("stroke", "#fff")
                        .attr("stroke-width", 1)
                        .attr("d", arc(d))
                        .attr("class", "cursor-pointer focus:outline-none")
                        .attr("data-tip", d?.data?.tooltip)
                        .attr("data-for", chartId)
                        .style("opacity", isActive ? 1 : 0.3)
                        .on("mouseover", function () {
                            debouncedSetActiveSegmentId(d.data.id);
                        })
                        .on("mouseout", function () {
                            debouncedSetActiveSegmentId(null);
                        })
                        .on("click", function () {
                            onSegmentClick(d);
                        });

                    // Calculate centroid of the arc for label positioning
                    const [x, y] = arc.centroid(d);

                    // If it's the root, place the label at the center with larger font size
                    if (d.depth === 0) {
                        svg.append("text")
                            .attr("text-anchor", "middle")
                            .attr("dy", "0.35em")
                            .attr("pointer-events", "none")
                            .style("font-size", "16px")
                            .style("font-weight", "600")
                            .style("fill", readableColor(d?.data?.color || defaultColor))
                            .text(d?.data?.label);
                    } else {
                        // For other segments, place label in the center of each arc
                        segmentGroup
                            .append("text")
                            .attr("x", x)
                            .attr("y", y)
                            .attr("dy", "0.35em")
                            .attr("text-anchor", "middle")
                            .attr("pointer-events", "none")
                            .style("font-size", "10px")
                            .style("fill", readableColor(d?.data?.color || defaultColor))
                            .style("opacity", isActive ? 1 : 0) // Hide label if segment is faded
                            .text(d?.data?.label);
                    }
                });

            const ringArc = d3
                .arc()
                .startAngle(0)
                .endAngle(2 * Math.PI)
                .innerRadius(adjustedRadius)
                .outerRadius(radius);

            svg.append("path").attr("d", ringArc).attr("fill", "none").attr("stroke", "#fff").attr("stroke-width", 1);

            ReactTooltip.rebuild();
        }
    }, [data, width, height, activeSegmentId, chartId, onSegmentClick, setActiveSegmentId]);

    if (data.length === 0) return <Skeleton height={height} width={width} />;
    if (!chartIsReady) return <Skeleton height={height} width={width} />;

    return (
        <>
            <svg ref={svgRef}></svg>
            {!disableTooltip && <ReactTooltip id={chartId} delayShow={300} />}
        </>
    );
};

export default SunburstChart;
