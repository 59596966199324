import React from "react";
import { useDispatch } from "react-redux";
import SignInPanel from "../../SignIn/components/templates/SignInPanel";
import { configureWorkspaceThunk } from "../utils/workspaceThunks";
import CreateWorkspace from "./CreateWorkspace";

const ConfigureWorkspace = () => {
    const dispatch = useDispatch();
    const handleConfigureWorkspace = (formValues) => {
        const userInfo = {
            firstname: formValues.firstname,
            surname: formValues.surname,
        };
        dispatch(configureWorkspaceThunk(userInfo, formValues.workspaceName));
    };


    return (
        <div className="p-10 absolute inset-0 bg-base-150 flex items-center justify-center">
            <div className="w-full max-w-xl bg-base-100 shadow rounded py-3 px-5">
                <SignInPanel
                    title="Configure Your Workspace"
                    description="Please enter your name and the name of your workspace."
                    signInStatus="signed-in"
                >
                    <CreateWorkspace onSubmit={handleConfigureWorkspace} />
                </SignInPanel>
            </div>
        </div>
    );
};

export default ConfigureWorkspace;
