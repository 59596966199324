import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useConfig } from "../../Config/hooks/useConfig";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import { useOrgTableConfig } from "../OrgTable/hooks/useOrgTableConfig";

export function useOrgData(topLevelRoleId, highestAllowedRoleId) {
    const preppedEmployees = useSelector((state) => state.businessUnit.preppedEmployees);
    const { ownConfig } = useConfig();
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const { getAllRoleIdsBelow } = useRoleRelationships();
    const { orgView } = ownConfig;
    const { activeOrgTableId } = useOrgTableConfig();

    // Org Table Data
    const includeTopRole = useMemo(() => {
        return orgView === "table" || orgView === "chart";
    }, [orgView]);

    const includeInactive = useMemo(() => {
        return orgView === "table" && activeOrgTableId === "iacEmp" && isAdmin;
    }, [orgView, isAdmin, activeOrgTableId]);

    const filteredEmployees = useMemo(() => {
        const roleId = topLevelRoleId || highestAllowedRoleId;
        const employeeArray = Object.values(preppedEmployees);

        let included = [];
        if (!roleId || roleId === rootRoleId) {
            // When no role filtering is needed, include all employees with a role
            included = employeeArray.filter((employee) => !!employee.roleId);
        } else {
            // When there is filtering, include all employees below the selected role
            const subordinateRoleIds = getAllRoleIdsBelow(roleId);
            included = employeeArray.filter((employee) => {
                return subordinateRoleIds.includes(employee.roleId);
            });

            // If the selected role should be included, add it
            if (includeTopRole) {
                const topEmployee = employeeArray.find((employee) => employee.roleId === roleId);
                if (topEmployee) {
                    included.push(topEmployee);
                }
            }
        }

        if (includeInactive && roleId === rootRoleId) {
            const inactiveEmployees = employeeArray.filter((employee) => !employee.roleId);
            included.push(...inactiveEmployees);
        }
        return included;
    }, [
        rootRoleId,
        preppedEmployees,
        highestAllowedRoleId,
        topLevelRoleId,
        includeInactive,
        includeTopRole,
        getAllRoleIdsBelow,
    ]);

    return [filteredEmployees];
}
