import { createSlice } from "@reduxjs/toolkit";

export const UPDATE_TRAIT = "workspace/updateTrait";
export const UPDATE_TALENT_AREA = "workspace/updateTalentArea";
export const UPDATE_TALENT_BOARD = "workspace/updateTalentBoard";
export const UPDATE_ACTION = "workspace/updateAction";
export const REMOVE_TALENT_ITEMS = "workspace/removeTalentItems";
export const REMOVE_TALENT_BOARD = "workspace/removeTalentBoard";
export const SET_TRAIT_MAP = "workspace/updateTraitMap";

const initialState = {
    workspaceId: null,
    businessUnits: {},
    notifications: [],
    traits: {},
    talentAreas: {},
    talentBoards: {},
    actions: {},
    traitMap: {},
    reviews: {
        activeReviews: [],
        activeSelfReviews: [],
        activeTeamReviews: [],
    },
    scheduledUpdates: [],
    runningScheduleIds: [],
    talentDataLinked: false,
    pendingChanges: [],
    workspaceInitialised: false,
    lastInitialised: null,
    reviewsAreSubmitting: false,
};

const slice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        resetWorkspace: () => initialState,
        setWorkspaceId: (state, action) => {
            Object.assign(state, initialState);
            state.workspaceId = action.payload;
        },
        setWorkspaceData(state, action) {
            Object.assign(state, action.payload);
            const { configured } = action.payload;
            state.isConfigured = configured;
        },
        setNotifications(state, action) {
            state.notifications = action.payload || [];
        },
        setReviewQueue(state, action) {
            state.reviewQueue = action.payload || [];
        },
        setApprovals(state, action) {
            state.approvals = action.payload || [];
        },
        setBusinessUnits(state, action) {
            state.businessUnits = action.payload;
        },
        setTalentData(state, action) {
            Object.assign(state, { ...action.payload });
            state.talentDataLinked = true;
        },
        setLiveCycles(state, action) {
            state.liveCycles = action.payload;
        },
        setActiveReviews(state, action) {
            state.reviews = action.payload;
            state.reviewsAreSubmitting = false;
        },
        setReviewsAreSubmitting(state, action) {
            state.reviewsAreSubmitting = action.payload;
        },
        setScheduledUpdates(state, action) {
            const scheduledUpdates = action.payload;
            state.scheduledUpdates = scheduledUpdates;
            const runningScheduleIds = [];
            for (const cycleId of Object.keys(scheduledUpdates)) {
                const cycle = scheduledUpdates[cycleId];
                if (cycle.isRunning) {
                    runningScheduleIds.push(cycleId);
                }
            }
            state.runningScheduleIds = runningScheduleIds;
        },
        setWorkspaceInitialised(state) {
            state.workspaceInitialised = true;
            state.lastInitialised = new Date().toISOString();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(UPDATE_TRAIT, (state, action) => {
            const [traitId, trait] = action.payload;
            const newTrait = { id: traitId, ...trait };
            const currentTrait = state.traits[traitId] || {};
            state.traits[traitId] = { ...currentTrait, ...newTrait };
        });
        builder.addCase(UPDATE_TALENT_AREA, (state, action) => {
            const [talentAreaId, talentArea] = action.payload;
            const newTalentArea = { id: talentAreaId, ...talentArea };
            const currentTalentArea = state.talentAreas[talentAreaId] || {};
            state.talentAreas[talentAreaId] = { ...currentTalentArea, ...newTalentArea };
        });
        builder.addCase(UPDATE_TALENT_BOARD, (state, action) => {
            const [talentBoardId, talentBoard] = action.payload;
            const newTalentBoard = { id: talentBoardId, ...talentBoard };
            const currentTalentBoard = state.talentBoards[talentBoardId] || {};
            state.talentBoards[talentBoardId] = { ...currentTalentBoard, ...newTalentBoard };
        });
        builder.addCase(UPDATE_ACTION, (state, action) => {
            const [actionId, thisAction] = action.payload;
            const newAction = { id: actionId, ...thisAction };
            const currentAction = state.actions[actionId] || {};
            state.actions[actionId] = { ...currentAction, ...newAction };
        });
        builder.addCase(REMOVE_TALENT_BOARD, (state, action) => {
            const [boardId, traitMapUpdate] = action.payload;
            const newTalentBoards = { ...state.talentBoards };
            delete newTalentBoards[boardId];
            state.talentBoards = newTalentBoards;
            state.traitMap = traitMapUpdate;
        });
        builder.addCase(SET_TRAIT_MAP, (state, action) => {
            const traitMapUpdate = action.payload;
            state.traitMap = traitMapUpdate;
        });
        builder.addCase(REMOVE_TALENT_ITEMS, (state, action) => {
            const [removeFrom, removedIds] = action.payload;
            let newData = { ...state[removeFrom] };
            let newTraitMap = { ...state.traitMap };
            removedIds.forEach((id) => {
                delete newData[id];
                delete newTraitMap[id];
            });
            state[removeFrom] = newData;
            state.traitMap = newTraitMap;
        });
    },
});

const { actions, reducer } = slice;

export const {
    resetWorkspace,
    setWorkspaceId,
    setWorkspaceInitialised,
    setWorkspaceData,
    setNotifications,
    setReviewQueue,
    setApprovals,
    setBusinessUnits,
    setTalentData,
    setActiveReviews,
    setScheduledUpdates,
    setReviewsAreSubmitting,
} = actions;

export default reducer;
