import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import MyButton from "../../../components/MyComponents/Button";
import MyInputGroup from "../../../components/MyComponents/InputGroup";
import MyTextInput from "../../../components/MyComponents/TextInput";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import { useLanguage } from "../../../translations/LanguageContext";

const CreateWorkspaceInnerForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isValid,
}) => {
    const handleSetValue = (name, value) => {
        setFieldValue(name, value);
    };

    const invalid = !isValid || Object.keys(values).length === 0

    return (
        <div className="space-y-4">
            <MyInputGroup
                label="Workspace Name"
                name="workspaceName"
                errors={errors.workspaceName}
                required
                placeholder="required"
            >
                <MyTextInput
                    maxLength={FIELD_SIZE_LIMITS.WORKSPACE_NAME}
                    invalid={errors.workspaceName && touched.workspaceName}
                    onChange={handleSetValue}
                    onBlur={handleBlur}
                    value={values.workspaceName}
                />
            </MyInputGroup>
            <div className="flex space-x-3">
                <div className="flex-1">
                    <MyInputGroup label="First Name" name="firstname" errors={errors.firstname} placeholder="required" required>
                        <MyTextInput
                            maxLength={FIELD_SIZE_LIMITS.FIRSTNAME}
                            invalid={errors.firstname && touched.firstname}
                            onChange={handleSetValue}
                            onBlur={handleBlur}
                            value={values.firstname}
                        />
                    </MyInputGroup>
                </div>
                <div className="flex-1">
                    <MyInputGroup label="Surname" name="surname" errros={errors.surname} placeholder="required" required>
                        <MyTextInput
                            maxLength={FIELD_SIZE_LIMITS.SURNAME}
                            invalid={errors.surname && touched.surname}
                            onChange={handleSetValue}
                            onBlur={handleBlur}
                            value={values.surname}
                        />
                    </MyInputGroup>
                </div>
            </div>
            <div className="flex justify-center">
                <MyButton color="primary" label="create workspace" disabled={invalid} onClick={handleSubmit}>
                    Create Workspace
                </MyButton>
            </div>
        </div>
    );
};

const CreateWorkspaceForm = withFormik({
    mapPropsToValues: (props) => {
        return {}
    },
    validationSchema: (props) => {
        return Yup.object().shape(props.validationSchema);
    },
    enableReinitialize: true,
    handleSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
})(CreateWorkspaceInnerForm);

const CreateWorkspace = (props) => {
    const { appLanguage } = useLanguage();
    const validationSchema = {
        workspaceName: Yup.string()
            .required(appLanguage("warnings", "required"))
            .max(FIELD_SIZE_LIMITS.WORKSPACE_NAME, appLanguage("warnings", "length_max")),
        firstname: Yup.string()
            .required(appLanguage("warnings", "required"))
            .max(FIELD_SIZE_LIMITS.FIRSTNAME, appLanguage("warnings", "length_max")),
        surname: Yup.string()
            .required(appLanguage("warnings", "required"))
            .max(FIELD_SIZE_LIMITS.SURNAME, appLanguage("warnings", "length_max")),
    };
    return <CreateWorkspaceForm {...props} validationSchema={validationSchema} />;
};

export default CreateWorkspace;
