import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import {
    CreateRoleIcon,
    DeleteEmployeeIcon,
    DeleteIcon,
    FillRoleIcon,
    MoveRoleIcon,
    VacateRoleIcon
} from "../../../components/Icons/MyIcons";
import { setActiveModal } from "../../../redux/appSlice";
import { useEmployees } from "../../Employees/hooks/useEmployees";
import { useRoles } from "../../Roles/hooks/useRoles";
import { doesRoleHaveChildren } from "../../Roles/utils/roleHelpers";

export function useOrgActions(excludeIds = []) {
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const roleLimit = useSelector((state) => state.config.everyoneConfig.roleLimit);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const { roleCount, deleteRole, vacateRole } = useRoles();
    const { deleteEmployee } = useEmployees();
    const hasChildren = doesRoleHaveChildren(parentMap, selectedRoleId);
    const isThisUser = !!selectedEmployeeId && selectedEmployeeId === userEmployeeId;
    const roleLimitReached = roleLimit && roleCount >= roleLimit;

    const allActions = [
        {
            groupId: "employeeActions",
            notSelf: true,
            adminOnly: true,
            id: "deleteEmployee",
            label: "Remove Employee",
            icon: DeleteEmployeeIcon,
            onClick: () => {
                function confirmDeleteEmployee() {
                    const deleteBatch = deleteEmployee(selectedEmployeeId);
                    try {
                        deleteBatch.commit();
                    } catch (error) {
                        console.error("Error deleting employee", error);
                    }
                }
                if (selectedEmployeeId) {
                    const title = "Delete Employee?";
                    const message = "This action cannot be undone.";
                    confirmAlert({
                        title,
                        message,
                        buttons: [
                            { label: "Cancel", onClick: () => {} },
                            { label: "Delete", onClick: () => confirmDeleteEmployee() },
                        ],
                    });
                }
            },
        },
        {
            groupId: "orgActions",
            filledOnly: true,
            id: "vacateRole",
            label: "Vacate Role",
            adminOnly: true,
            icon: VacateRoleIcon,
            onClick: () => {
                function confirmVacate() {
                    vacateRole(selectedRoleId);
                }
                if (selectedEmployeeId) {
                    const title = "Vacate Role?";
                    let message = "The employee won't be deleted and they can be re-added to a role later.";
                    confirmAlert({
                        title,
                        message,
                        buttons: [
                            { label: "Cancel", onClick: () => {} },
                            { label: "Vacate", onClick: () => confirmVacate() },
                        ],
                    });
                }
            },
        },
        {
            groupId: "orgActions",
            vacantOnly: true,
            adminOnly: true,
            id: "fillRole",
            label: "Fill Role",
            icon: FillRoleIcon,
            onClick: () => dispatch(setActiveModal("fillVacantRole")),
        },
        {
            groupId: "orgActions",
            adminOnly: true,
            id: "createRole",
            label: "Create New Role",
            icon: CreateRoleIcon,
            onClick: () => {
                if (roleLimitReached) {
                    const title = "You have reached your role limit.";
                    const message = "Please contact your account manager.";
                    confirmAlert({ title, message, buttons: [{ label: "OK", onClick: () => {} }] });
                } else {
                    dispatch(setActiveModal("createRole"));
                }
            },
        },
        {
            groupId: "orgActions",
            id: "moveRole",
            label: "Move Role",
            adminOnly: true,
            icon: MoveRoleIcon,
            onClick: () => dispatch(setActiveModal("moveRole")),
        },
        {
            groupId: "orgActions",
            childlessOnly: true,
            id: "removeRole",
            label: "Remove Role",
            adminOnly: true,
            icon: DeleteIcon,
            onClick: () => {
                function confirmDeleteRole() {
                    deleteRole(selectedRoleId);
                }
                if (selectedRoleId) {
                    const title = "Delete Role?";
                    const message = "This action cannot be undone.";
                    confirmAlert({
                        title,
                        message,
                        buttons: [
                            { label: "Cancel", onClick: () => {} },
                            { label: "Delete", onClick: () => confirmDeleteRole() },
                        ],
                    });
                }
            },
        },
    ];

    const orgActions = allActions.filter((action) => {
        if (action.adminOnly && !isAdmin) return false;
        if (action.groupId === "employeeActions" && !selectedEmployeeId) return false;
        if (action.groupId === "orgActions" && !selectedRoleId) return false;
        if (action.filledOnly && !selectedEmployeeId) return false;
        if (action.vacantOnly && !!selectedEmployeeId) return false;
        if (action.childlessOnly && hasChildren) return false;
        if (action.notSelf && isThisUser) return false;
        if (excludeIds.includes(action.id)) return false;
        return true;
    });

    const employeePageActions = orgActions.filter((action) => action.employeePage);

    return { orgActions, employeePageActions };
}
