import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePopper } from "react-popper";

const MyTooltip = ({ tooltip, children, color = "info", position = "top" }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipElement, setTooltipElement] = useState(null);
    const triggerRef = useRef(null);
    const delayRef = useRef(null);
    const { styles, attributes } = usePopper(triggerRef.current, tooltipElement, {
        placement: position,
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    const handleMouseEnter = () => {
        delayRef.current = setTimeout(() => {
            setShowTooltip(true);
        }, 500); // Adjust the delay time as needed (in milliseconds)
    };

    const handleMouseLeave = () => {
        clearTimeout(delayRef.current);
        setShowTooltip(false);
    };

    if (!tooltip) return children;
    return (
        <div ref={triggerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {showTooltip && (
                <div
                    ref={setTooltipElement}
                    className={classNames(
                        "font-medium capitalize py-1 px-2 tracking-tight text-xs rounded",
                        `bg-${color}`,
                        `text-${color}-content`
                    )}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    {tooltip}
                </div>
            )}
        </div>
    );
};

MyTooltip.propTypes = {
    tooltip: PropTypes.string,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "success",
        "warning",
        "error",
        "info",
        "accent",
        "neutral",
        "tooltip",
    ]),
    position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

export default MyTooltip;
