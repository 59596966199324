// Imports
import {
    AiOutlineCloudDownload,
    AiOutlineFileDone,
    AiOutlineUser,
    AiOutlineUserAdd,
    AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { BiCog, BiWindowOpen } from "react-icons/bi";
import { BsArchive } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import {
    FaMailBulk as BulkIcon,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaBalanceScaleLeft,
    FaBorderAll,
    FaCheck,
    FaCheckCircle,
    FaChevronDown,
    FaChevronLeft,
    FaChevronRight,
    FaChevronUp,
    FaDna,
    FaEllipsisH,
    FaEllipsisV,
    FaExpand,
    FaFilter,
    FaFlag,
    FaInfoCircle,
    FaLock,
    FaLockOpen,
    FaMapMarkerAlt,
    FaPlus,
    FaRegClock,
    FaRegCopy,
    FaRegEnvelope,
    FaRegTrashAlt,
    FaSave,
    FaSitemap,
    FaTable,
    FaTasks,
    FaThumbsUp,
    FaUndoAlt,
    FaUserAltSlash,
    FaUserCheck,
    FaUserClock,
    FaUserSlash,
    FaUsers,
    FaWalking,
} from "react-icons/fa";
import { FaMinus, FaRegComments, FaRepeat } from "react-icons/fa6";
import { FiCamera, FiCameraOff, FiMove, FiSunset, FiUserMinus, FiUserPlus } from "react-icons/fi";
import { GiRadarSweep } from "react-icons/gi";
import { GoGoal, GoWorkflow } from "react-icons/go";
import { GrDocumentMissing } from "react-icons/gr";
import { ImBlocked, ImOffice } from "react-icons/im";
import { IoMdHelp } from "react-icons/io";
import { IoOpenOutline } from "react-icons/io5";
import {
    MdAccountTree,
    MdKeyboardBackspace,
    MdLockOpen,
    MdLockOutline,
    MdOutlineAssignment,
    MdOutlineAssignmentInd,
    MdOutlineAssignmentLate,
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineCalendarToday,
    MdOutlineCheckBox,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineClose,
    MdOutlineCompareArrows,
    MdOutlineControlPointDuplicate,
    MdOutlineEditNote,
    MdOutlineExpandLess,
    MdOutlineExpandMore,
    MdOutlineHelp,
    MdOutlineHistory,
    MdOutlinePreview,
    MdOutlineSpaceDashboard,
    MdOutlineStart,
    MdOutlineWatchLater,
    MdPeople,
    MdRocketLaunch,
    MdWorkspaces
} from "react-icons/md";
import { RiSortDesc, RiSurveyLine } from "react-icons/ri";
import { TbDashboard } from "react-icons/tb";
import { TiWarningOutline } from "react-icons/ti";
import { VscSyncIgnored } from "react-icons/vsc";
import { BallTriangle, Oval, Puff, Rings, ThreeDots } from "react-loading-icons";

// Constants
const LOADING_ICON_PROPS = {
    stroke: "#4878ff",
    strokeOpacity: 0.325,
    speed: 0.75,
};
const DEFAULT_SIZE = 24;

// HOC to add default props
const withDefaultProps = (IconComponent) => (props) => <IconComponent size={DEFAULT_SIZE} {...props} />;

const withLoadingIconProps = (IconComponent) => (props) => {
    const { size = DEFAULT_SIZE, ...rest } = props;
    return <IconComponent size={size} {...LOADING_ICON_PROPS} {...rest} />;
};

export const RevokeIcon = withDefaultProps(ImBlocked);
export const InviteIcon = withDefaultProps(AiOutlineUsergroupAdd);
export const VacateRoleIcon = withDefaultProps(FiUserMinus);
export const FillRoleIcon = withDefaultProps(FiUserPlus);
export const ViewRoleIcon = withDefaultProps(BiWindowOpen);
export const ViewTalentMapIcon = withDefaultProps(FaMapMarkerAlt);
export const ViewStrengthsIcon = withDefaultProps(FaDna);
export const CreateRoleIcon = withDefaultProps(AiOutlineUserAdd);
export const ViewActionsIcon = withDefaultProps(FaTasks);
export const MoveRoleIcon = withDefaultProps(FiMove);
export const OrgIcon = withDefaultProps(FaSitemap);
export const TableIcon = withDefaultProps(FaTable);
export const RatingIcon = withDefaultProps(FaDna);
export const BranchIcon = withDefaultProps(MdAccountTree);
export const DashboardIcon = withDefaultProps(TbDashboard);
export const AssessmentPendingIcon = withDefaultProps(MdOutlineAssignmentLate);
export const AssessmentDoneIcon = withDefaultProps(MdOutlineAssignmentTurnedIn);
export const AssessmentLateIcon = withDefaultProps(MdOutlineWatchLater);
export const RequestAssessmentIcon = withDefaultProps(MdOutlineAssignmentReturned);
export const CompleteAssessmentIcon = withDefaultProps(MdOutlineAssignmentInd);
export const SkipAssessmentIcon = withDefaultProps(VscSyncIgnored);
export const ViewAssessmentIcon = withDefaultProps(MdOutlineAssignment);
export const OpenAssessmentIcon = withDefaultProps(FaLockOpen);
export const ClosedAssessmentIcon = withDefaultProps(FaLock);
export const SnapshotEnabledIcon = withDefaultProps(FiCamera);
export const SnapshotDisabledIcon = withDefaultProps(FiCameraOff);
export const TalentBoardIcon = withDefaultProps(FaBorderAll);
export const TraitRatedIcon = withDefaultProps(FaCheckCircle);
export const TalentAreaIcon = withDefaultProps(GiRadarSweep);
export const FlagIcon = withDefaultProps(FaFlag);
export const TeamIcon = withDefaultProps(FaUsers);
export const OwnerIcon = withDefaultProps(AiOutlineUser);
export const CopyIcon = withDefaultProps(FaRegCopy);
export const LaunchIcon = withDefaultProps(MdRocketLaunch);
export const StartIcon = withDefaultProps(MdOutlineStart);
export const HelpIcon = withDefaultProps(IoMdHelp);
export const ReRerunIcon = withDefaultProps(FaRepeat);
export const LoadingIconOval = withLoadingIconProps(Oval);
export const LoadingIconRings = withLoadingIconProps(Rings);
export const LoadingIconPuff = withLoadingIconProps(Puff);
export const LoadingIconTriangle = withLoadingIconProps(BallTriangle);
export const LoadingIconDots = withLoadingIconProps(ThreeDots);
export const CheckIcon = withDefaultProps(FaCheck);
export const WarningIcon = withDefaultProps(TiWarningOutline);
export const CalendarIcon = withDefaultProps(MdOutlineCalendarToday);
export const EllipsisIcon = withDefaultProps(FaEllipsisV);
export const SettingsIcon = withDefaultProps(BiCog);
export const ViewIcon = withDefaultProps(IoOpenOutline);
export const FilterIcon = withDefaultProps(FaFilter);
export const InfoIcon = withDefaultProps(FaInfoCircle);
export const DownloadIcon = withDefaultProps(AiOutlineCloudDownload);
export const UserIcon = withDefaultProps(FaUserCheck);
export const PendingUserIcon = withDefaultProps(FaUserClock);
export const NotUserIcon = withDefaultProps(FaUserAltSlash);
export const PeopleIcon = withDefaultProps(MdPeople);
export const DuplicateIcon = withDefaultProps(MdOutlineControlPointDuplicate);
export const CompareIcon = withDefaultProps(MdOutlineCompareArrows);
export const HistoryIcon = withDefaultProps(MdOutlineHistory);
export const DeleteEmployeeIcon = withDefaultProps(FaUserSlash);
export const BusinessUnitIcon = withDefaultProps(ImOffice);
export const EmailIcon = withDefaultProps(FaRegEnvelope);
export const PreviewIcon = withDefaultProps(MdOutlinePreview);
export const ProfileIcon = withDefaultProps(CgProfile);
export const WorkspaceIcon = withDefaultProps(MdWorkspaces);
export const SurveyIcon = withDefaultProps(RiSurveyLine);
export const CheckBoxCheckedIcon = withDefaultProps(MdOutlineCheckBox);
export const CheckBoxUncheckedIcon = withDefaultProps(MdOutlineCheckBoxOutlineBlank);
export const DownChevronIcon = withDefaultProps(FaChevronDown);
export const UpChevronIcon = withDefaultProps(FaChevronUp);
export const LeftChevronIcon = withDefaultProps(FaChevronLeft);
export const RightChevronIcon = withDefaultProps(FaChevronRight);
export const BackIcon = withDefaultProps(MdKeyboardBackspace);
export const EditIcon = withDefaultProps(MdOutlineEditNote);
export const DeleteIcon = withDefaultProps(FaRegTrashAlt);
export const UndoIcon = withDefaultProps(FaUndoAlt);
export const CrossIcon = withDefaultProps(MdOutlineClose);
export const BackToStartIcon = withDefaultProps(FaAngleDoubleLeft);
export const SkipToEndIcon = withDefaultProps(FaAngleDoubleRight);
export const ReopenIcon = withDefaultProps(FaUndoAlt);
export const SortIcon = withDefaultProps(RiSortDesc);
export const SaveIcon = withDefaultProps(FaSave);
export const AcceptIcon = withDefaultProps(FaThumbsUp);
export const CheckInIcon = withDefaultProps(FaBalanceScaleLeft);
export const CompleteIcon = withDefaultProps(AiOutlineFileDone);
export const InProgressIcon = withDefaultProps(FaEllipsisH);
export const NotesIcon = withDefaultProps(FaRegComments);
export const ObjectiveIcon = withDefaultProps(GoGoal);
export const PrivateIcon = withDefaultProps(FaLock);
export const ArchiveIcon = withDefaultProps(BsArchive);
export const CloseIcon = withDefaultProps(MdOutlineClose);
export const LessIcon = withDefaultProps(MdOutlineExpandLess);
export const MoreIcon = withDefaultProps(MdOutlineExpandMore);
export const PlusIcon = withDefaultProps(FaPlus);
export const MinusIcon = withDefaultProps(FaMinus);
export const MeasureIcon = withDefaultProps(FaBalanceScaleLeft);
export const CascadeIcon = withDefaultProps(GoWorkflow);
export const TourIcon = withDefaultProps(MdOutlineHelp);
export const ToDoIcon = withDefaultProps(FaRegClock);
export const OverviewIcon = withDefaultProps(MdOutlineSpaceDashboard);
export const EmptyIcon = withDefaultProps(GrDocumentMissing);
export const LockIcon = withDefaultProps(MdLockOutline);
export const LockOpenIcon = withDefaultProps(MdLockOpen);
export const SunsetIcon = withDefaultProps(FiSunset);

// Togglable Icons
export const LessMoreIcon = (props) => (props.checked ? <LessIcon {...props} /> : <MoreIcon {...props} />);
export const PlusMinusIcon = (props) => (props.checked ? <MinusIcon {...props} /> : <PlusIcon {...props} />);

// Export any direct icons without additional props (if needed)
export { BulkIcon, FaExpand as ExpandIcon, FaExpand as OpenIcon, FaWalking as StartRoleIcon };

