import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
    getDocRef,
    getSubCollectionRef,
    getUsersCollectionRef,
    getWorkspacesCollectionRef
} from "../../../firebase/firebaseActions";
import { useListener } from "../../../firebase/hooks/useListener";
import { setEveryoneConfig, setOwnConfig } from "../utils/configSlice";
import { ensureSerializable } from "../../../common/utils/objectUtils";

function useOwnConfig(uid, workspaceId) {
    const dispatch = useDispatch();

    const userConfigRef = useMemo(() => {
        if (!uid || !workspaceId) return null;
        const usersRef = getUsersCollectionRef();
        const userDocRef = getDocRef(usersRef, uid);
        const userConfigRef = getSubCollectionRef(userDocRef, "config");
        const userWorkspaceConfigRef = getDocRef(userConfigRef, workspaceId);
        return userWorkspaceConfigRef;
    }, [uid, workspaceId]);

    const handleDataReceived = useCallback(
        (doc) => {
            const data = doc.data();
            const cleaned = ensureSerializable(data);
            dispatch(setOwnConfig(cleaned));
        },
        [dispatch]
    );

    return useListener(userConfigRef, handleDataReceived);
}

function useEveryoneConfig(workspaceId) {
    const dispatch = useDispatch();

    const everyoneConfigRef = useMemo(() => {
        if (!workspaceId) return null;
        const workspacesRef = getWorkspacesCollectionRef();
        const workspaceRef = getDocRef(workspacesRef, workspaceId);
        const configCollectionRef = getSubCollectionRef(workspaceRef, "config");
        return getDocRef(configCollectionRef, "workspaceConfig");
    }, [workspaceId]);

    const handleDataReceived = useCallback(
        (doc) => {
            const data = doc.data();
            const cleaned = ensureSerializable(data);
            dispatch(setEveryoneConfig(cleaned));
        },
        [dispatch]
    );

    return useListener(everyoneConfigRef, handleDataReceived);
}

export function useConfigListeners(uid, workspaceId) {
    const ownConfigStatus = useOwnConfig(uid, workspaceId);
    const everyoneConfigStatus = useEveryoneConfig(workspaceId);
    const isReady = useMemo(() => {
        const allStatus = [ownConfigStatus, everyoneConfigStatus];
        return allStatus.every((status) => status === "success");
    }, [ownConfigStatus, everyoneConfigStatus]);

    return isReady;
}
