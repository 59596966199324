import * as d3 from "d3";
import { darken, lighten, readableColor } from "polished";
import React, { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { useUniqueId } from "../../../common/hooks/useUniqueId";
import { isChartReady } from "../../../common/utils/chartUtils";

const HorizontalBarChart = ({ width, data, barColor = "#4878ff" }) => {
    const chartId = useUniqueId();
    const chartRef = useRef(null);

    useEffect(() => {
        // Clear the previous chart
        const MOCK_HEIGHT_IS_READY = 100;
        const chartIsReady = isChartReady(data, MOCK_HEIGHT_IS_READY, width);
        if (chartIsReady) {
            d3.select(chartRef.current).selectAll("*").remove();

            // Define bar height and calculate total height based on number of bars
            const barHeight = 40; // Fixed height per bar
            const chartHeight = data.length * barHeight + 20; // Add padding

            // Set up the SVG canvas with calculated height
            const svg = d3.select(chartRef.current).attr("width", width).attr("height", chartHeight);

            // Define margins
            const margin = { top: 10, right: 20, bottom: 10, left: 10 };

            // Set up the scales
            const xScale = d3
                .scaleLinear()
                .domain([0, d3.max(data, (d) => d.value)])
                .range([0, width - margin.left - margin.right]);

            const yScale = d3
                .scaleBand()
                .domain(data.map((d) => d.id))
                .range([0, chartHeight - margin.top - margin.bottom])
                .padding(0.1); // Slight padding between bars

            // Render bars with rounded right corners
            svg.selectAll("rect")
                .data(data)
                .enter()
                .append("rect")
                .attr("x", margin.left)
                .attr("y", (d) => yScale(d.id) + margin.top)
                .attr("height", yScale.bandwidth())
                .attr("width", (d) => xScale(d.value))
                .attr("fill", barColor)
                .attr("rx", 10) // Rounded corners
                .attr("ry", 10) // Rounded corners
                .attr("data-tip", (d) => d.tooltip || "") // Add tooltip only if present
                .attr("data-for", chartId);

            // Render labels inside bars
            svg.selectAll(".label")
                .data(data)
                .enter()
                .append("text")
                .attr("class", "label")
                .attr("x", margin.left + 5) // Padding from left within the bar
                .attr("y", (d) => yScale(d.id) + yScale.bandwidth() / 2 + margin.top)
                .attr("dy", ".35em")
                .text((d) => `${d.label} - (${d.value})`)
                .attr("fill", readableColor(barColor))
                .attr("text-anchor", "start");

            ReactTooltip.rebuild();
        }
    }, [data, width, chartId, barColor]);

    return (
        <>
            <svg ref={chartRef}></svg>
            <ReactTooltip id={chartId} place="top" effect="solid" delayShow={300} />
        </>
    );
};

export default HorizontalBarChart;
