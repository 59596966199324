import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import { useTalentRefs } from "../refs/useTalentRefs";
import setEntireWorkspaceThunk from "../thunks/setEntireWorkspaceThunk";

export function useTalentListener() {
    const dispatch = useDispatch();
    const { talentInfoRef } = useTalentRefs();

    const handleDataReceived = useCallback(
        (querySnapshot) => {
            let talentData = {};
            querySnapshot.forEach((doc) => {
                //const doc = change.doc;
                const docData = doc.data();
                switch (doc.id) {
                    case "traitMap":
                        talentData[doc.id] = docData;
                        break;
                    default:
                        Object.entries(docData).forEach(([id, item]) => {
                            docData[id] = { id: id, ...item };
                        });
                        talentData[doc.id] = docData;
                }
            });
            dispatch(setEntireWorkspaceThunk({ talent: talentData }));
        },
        [dispatch]
    );

    return useListener(talentInfoRef, handleDataReceived);
}

/*
export function useTalentListener() {
    const dispatch = useDispatch();
    const [rawTalentData, setRawTalentData] = useState();
    const { talentInfoRef } = useTalentRefs();

    const handleDataReceived = useCallback((querySnapshot) => {
        let talentData = {};
        querySnapshot.forEach((doc) => {
            //const doc = change.doc;
            const docData = doc.data();
            switch (doc.id) {
                case "traitMap":
                    talentData[doc.id] = docData;
                    break;
                default:
                    Object.entries(docData).forEach(([id, item]) => {
                        docData[id] = { id: id, ...item };
                    });
                    talentData[doc.id] = docData;
            }
        });
        setRawTalentData(talentData);
    }, []);

    // Relink talent data once rawTalentData has been stable for 1 second
    useEffect(() => {
        if (rawTalentData) {
            const timer = setTimeout(() => {
                dispatch(setTalentData(rawTalentData));
                setRawTalentData(null);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [dispatch, rawTalentData]);

    return useListener(talentInfoRef, handleDataReceived);
}
*/
