import { doc } from "firebase/firestore";
import { useBusinessUnitRefs } from "../refs/useBusinessUnitRefs";
import { batchSet } from "../../../firebase/firebaseActions";

export function useBusinessUnitsApi() {
    const { businessUnitRef } = useBusinessUnitRefs();

    const updateBoardMap = (update, batch) => {
        const boardMapRef = doc(businessUnitRef, "maps", "boardMap");
        batch = batchSet(boardMapRef, update, batch);
        return batch;
    };

    return {
        updateBoardMap,
    };
}