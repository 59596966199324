import { arrayUnion, collection, doc, serverTimestamp } from "firebase/firestore";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { batchSet } from "../../../firebase/firebaseActions";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { getUniqueId } from "../../../common/utils/getUniqueId";
import { removeUndefined } from "../../../common/utils/objectUtils";
import { useBusinessUnits } from "../../BusinessUnits/hooks/useBusinessUnits";

function getEmployeeRef(employeesRef, employeeId) {
    if (!employeesRef) return null;
    return doc(employeesRef, employeeId);
}

function getObjectivesRef(employeesRef, employeeId, objectiveId) {
    if (!employeesRef) return null;
    const employeeRef = getEmployeeRef(employeesRef, employeeId);
    const objectivesColRef = collection(employeeRef, "objectives");
    if (!objectiveId) return objectivesColRef;
    return doc(objectivesColRef, objectiveId);
}

function getNotesRef(employeesRef, employeeId, noteId) {
    if (!employeesRef) return null;
    const employeeRef = getEmployeeRef(employeesRef, employeeId);
    const notesColRef = collection(employeeRef, "notes");
    if (!noteId) return notesColRef;
    return doc(notesColRef, noteId);
}

function getAssessmentsRef(employeesRef, employeeId, assessmentId) {
    if (!employeesRef) return null;
    const employeeRef = getEmployeeRef(employeesRef, employeeId);
    const assessmentsColRef = collection(employeeRef, "assessments");

    if (!assessmentId) return assessmentsColRef;
    return doc(assessmentsColRef, assessmentId);
}

export function useEmployeesApi() {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const businessUnitId = useSelector((state) => state.businessUnit.businessUnitId);
    const workspaceRef = useWorkspaceRef();
    const { getUnitDocId } = useBusinessUnits();

    const employeesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "employees");
    }, [workspaceRef]);

    const handleCreateEmployee = (employeeId, employee, batch) => {
        const employeeRef = getEmployeeRef(employeesRef, employeeId);
        const { unitDocId, userRole, ...rest } = employee;
        const employeeUnitDocId = unitDocId || getUnitDocId("employees", 200);
        const enhancedEmployee = {
            ...rest,
            isUser: true,
            customClaims: {},
            userRole: userRole || "manager",
            unitDocId: employeeUnitDocId,
            businessUnitId,
            workspaceId,
            inviteAccepted: false,
        };
        removeUndefined(enhancedEmployee);
        batch = batchSet(employeeRef, enhancedEmployee, batch);
        return batch;
    };

    const handleUpdateEmployee = (employeeId, employee, batch) => {
        const employeeRef = getEmployeeRef(employeesRef, employeeId);
        const keysNotToSave = ["talentAssessent", "selfAssessment", "linkedTalentBoardIds", "displayName", "id"];
        const cleaned = { ...employee };
        keysNotToSave.forEach((key) => delete cleaned[key]);
        removeUndefined(cleaned);
        batch = batchSet(employeeRef, cleaned, batch);
        return batch;
    };

    const handleArchiveSnapshot = (employeeId, snapshot, batch) => {
        const snapshotRef = doc(employeesRef, employeeId, "archived", "snapshot");
        if (snapshotRef && snapshot) {
            const { flags, ratings, objectives, experience } = snapshot;
            const preppedSnapshot = {
                flags,
                ratings,
                obj: objectives,
                exp: experience,
            };
            removeUndefined(preppedSnapshot);
            batch = batchSet(snapshotRef, preppedSnapshot, batch);
            return batch;
        }
    };

    function handleSaveObjective(employeeId, objective, iterationId = null, batch) {
        let { id, ...enhancedObjective } = objective;
        if (!enhancedObjective.createdOn) enhancedObjective.createdOn = serverTimestamp();
        if (enhancedObjective.isComplete && !enhancedObjective.completedInIterationId && !!iterationId) {
            enhancedObjective.completedInIterationId = iterationId;
            enhancedObjective.completedOn = serverTimestamp();
        }
        if (iterationId) {
            enhancedObjective.includedInIterationIds = arrayUnion(iterationId);
        }
        enhancedObjective.workspaceId = workspaceId;
        const objectiveRef = getObjectivesRef(employeesRef, employeeId, id);
        batch = batchSet(objectiveRef, enhancedObjective, batch);
        return batch;
    }

    function handleRemoveObjective(employeeId, objectiveId, batch) {
        const objectiveRef = getObjectivesRef(employeesRef, employeeId, objectiveId);
        batch.delete(objectiveRef);
        return batch;
    }

    function handleSaveNote(employeeId, note, batch) {
        const isNewNote = !note.id;
        const noteId = note.id || getUniqueId();
        const noteRef = getNotesRef(employeesRef, employeeId, noteId);
        const enhancedNote = { ...note, workspaceId };
        if (isNewNote) {
            enhancedNote.createdOn = serverTimestamp();
        } else {
            enhancedNote.updatedOn = serverTimestamp();
        }
        batch = batchSet(noteRef, enhancedNote, batch);
        return batch;
    }

    const handleSaveAssessment = (employeeId, assessmentId, assessment, batch) => {
        removeUndefined(assessment);
        const enhancedAssessment = { ...assessment, workspaceId, updatedOn: serverTimestamp() };
        const assessmentRef = getAssessmentsRef(employeesRef, employeeId, assessmentId);
        batch = batchSet(assessmentRef, enhancedAssessment, batch);
        return batch;
    };

    return {
        createEmployee: handleCreateEmployee,
        updateEmployee: handleUpdateEmployee,
        archiveSnapshot: handleArchiveSnapshot,
        saveObjective: handleSaveObjective,
        removeObjective: handleRemoveObjective,
        saveNote: handleSaveNote,
        saveAssessment: handleSaveAssessment,
    };
}
