import classNames from "classnames";
import React, { useState } from "react";

const MySteps = ({ steps = [], currentStep = 0, startingStep = 0, onStepClick }) => {
    const [furthestStep, setFurthestStep] = useState(startingStep);

    const handleStepClick = (step, i) => {
        if (!onStepClick) return;
        if (i - furthestStep > 1) return;
        if (i > furthestStep) setFurthestStep(i);
        onStepClick(step, i);
    };

    return (
        <ul className="steps">
            {steps.map((step, i) => {
                const reached = i <= currentStep;
                const active = i === currentStep;
                return (
                    <li
                        className={classNames("step", { "cursor-pointer": onStepClick, "step-primary": reached })}
                        key={step.id}
                        onClick={() => handleStepClick(step, i)}
                    >
                        <span
                            className={classNames(
                                "mx-4",
                                { "text-primary": reached },
                                {
                                    "font-bold": active,
                                    "text-base-500": !reached,
                                }
                            )}
                        >
                            {step.label}
                        </span>
                    </li>
                );
            })}
        </ul>
    );
};

export default MySteps;
