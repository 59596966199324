import classNames from "classnames";
import React, { useState } from "react";
import { LoadingIndicator, RichText } from "../../../../components";
import { LeftChevronIcon } from "../../../../components/Icons/MyIcons";
import MyButton from "../../../../components/MyComponents/Button";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../../components/MyComponents/Modal";
import { SubmitButton } from "../../atoms/Button/Button";
import HelperDropdown from "../../molecules/HelperDropdown/HelperDropdown";
import StepperProgress from "./organisms/StepperProgress";

const StepperModal = ({
    bodyClassName,
    isOpen,
    isWaiting,
    height,
    width,
    size = "md",
    submitLabel = "Submit",
    extraButtons = [],
    headerText,
    progressAtTop = false,
    removePadding,
    navigateBlocked,
    closeBlocked,
    nextBlocked,
    allowSubmit,
    onSubmit,
    onToggle,
    infoMessage,
    children,
    onStepChange = () => {},
}) => {
    const [stepIndex, setStepIndex] = useState(0);

    const handleStepChange = (newStepIndex) => {
        if (navigateBlocked) return;
        setStepIndex(newStepIndex);
        onStepChange(newStepIndex);
    };

    const handleStepperItemClick = (index) => {
        if (navigateBlocked) return;
        handleStepChange(index);
    };

    const handleSubmit = () => {
        if (navigateBlocked) return;
        onSubmit();
    };

    const activeStep = React.Children.toArray(children)[stepIndex];
    const steps = React.Children.map(children, (child) => child?.props?.step);
    const showProgress = steps.filter((step) => !!step.label).length > 1;
    const childCount = React.Children.count(children) - 1;
    const isLastStep = stepIndex === childCount;
    const prevDisabled = isWaiting || navigateBlocked || stepIndex === 0;
    const nextDisabled = isWaiting || navigateBlocked || nextBlocked || stepIndex === childCount;
    const submitDisabled = isWaiting || !allowSubmit || navigateBlocked;

    return (
        <MyModal isOpen={isOpen} size={size} height={height} width={width}>
            <MyModalHeader onToggle={onToggle} closeBlocked={closeBlocked}>
                {headerText}
            </MyModalHeader>
            <MyModalBody className={classNames("flex-1 flex", { "flex-col": progressAtTop })}>
                <div
                    className={classNames({
                        hidden: !showProgress,
                        "w-fit min-w-44 border-e": !progressAtTop,
                        "border-b flex items-center justify-between px-6": progressAtTop,
                    })}
                >
                    <StepperProgress
                        hidden={!showProgress}
                        vertical={!progressAtTop}
                        stepIndex={stepIndex}
                        steps={steps}
                        onItemClick={handleStepperItemClick}
                    />
                    {activeStep?.props?.step?.help && (
                        <HelperDropdown title={activeStep.label}>
                            <RichText readOnly removePadding content={activeStep?.props?.step?.help} />
                        </HelperDropdown>
                    )}
                </div>
                <div
                    className={classNames("flex-1 overflow-auto bg-background", bodyClassName, {
                        "py-4 px-6": !removePadding,
                    })}
                >
                    {isWaiting ? <LoadingIndicator /> : activeStep}
                </div>
            </MyModalBody>
            <MyModalFooter>
                <div className="flex-1 flex justify-end items-center space-x-2">
                    <div className="px-6 flex-1 flex items-center justify-between text-xs text-medium text-primary capitalize tracking-tight">
                        {infoMessage}
                    </div>
                    <MyButton
                        disabled={prevDisabled}
                        shape="circle"
                        color="ghost"
                        size="sm"
                        onClick={() => handleStepChange(stepIndex - 1)}
                    >
                        <LeftChevronIcon size={18} />
                    </MyButton>
                    {extraButtons.map((button, index) => (
                        <MyButton key={`extra-btn-${index}`} extraClasses="min-w-fit w-28" size="sm" {...button}>
                            {button.label}
                        </MyButton>
                    ))}
                    <MyButton
                        extraClasses="min-w-fit w-28"
                        hidden={isLastStep}
                        disabled={nextDisabled}
                        size="sm"
                        onClick={() => handleStepChange(stepIndex + 1)}
                    >
                        Next
                    </MyButton>
                    <SubmitButton
                        extraClasses="min-w-fit w-28"
                        hidden={!isLastStep}
                        disabled={submitDisabled}
                        size="sm"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        {submitLabel}
                    </SubmitButton>
                </div>
            </MyModalFooter>
        </MyModal>
    );
};

export default StepperModal;
