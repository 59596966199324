import React, { useState } from "react";
import useComponentToImage from "../../../../common/hooks/useComponentToImage";
import { useSubordinates } from "../../../Employees/hooks/useSubordinates";
import TalentMapToolbar from "../molecules/TalentMapToolbar";
import TalentMap from "../organisms/TalentMap";
import classNames from "classnames";

const INCLUDE_TOP_ROLE = false;

const TalentMapSubPageTemplate = ({ roleId, navigateToOrgNode, className }) => {
    const subordinates = useSubordinates(roleId, INCLUDE_TOP_ROLE);
    const [hideToolbar, setHideToolbar] = useState(false);
    const [talentMapRef, onExportTalentMap] = useComponentToImage();

    const handleItemClick = (employeeId) => {
        navigateToOrgNode(null, employeeId);
    };

    const handleSegmentOpen = (val) => {
        setHideToolbar(val);
    };

    return (
        <div className={classNames(className, "flex-1 flex flex-col")}>
            {!hideToolbar && (
                <div className="toolbar border rounded-lg">
                    <TalentMapToolbar onExport={onExportTalentMap} hideControls />
                </div>
            )}
            <div className="flex-1 overflow-hidden flex flex-col">
                <TalentMap
                    talentMapRef={talentMapRef}
                    preppedEmployees={subordinates}
                    onItemClick={handleItemClick}
                    onSegmentOpen={handleSegmentOpen}
                />
            </div>
        </div>
    );
};

export default TalentMapSubPageTemplate;
