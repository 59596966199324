import { arrayUnion, collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import { getUniqueId } from "../../../common/utils/getUniqueId";
import { batchSet } from "../../../firebase/firebaseActions";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { advanceDueDate } from "../utils/advanceDueDate";

export function useScheduleApi() {
    const workspaceRef = useWorkspaceRef();

    const scheduledUpdatesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "scheduledUpdates");
    }, [workspaceRef]);

    const markEmployeesDone = (cycleId, employeeIds, batch) => {
        const update = {
            doneOverrides: arrayUnion(...employeeIds),
        };
        const docRef = doc(scheduledUpdatesRef, cycleId);
        batch = batchSet(docRef, update, batch);
        return batch;
    };

    const saveScheduledUpdate = (cycleId, data, batch) => {
        const { id, ...rest } = data;
        if (!scheduledUpdatesRef) return null;
        if (!cycleId || !data) throw new Error("cycle Id or data is missing");
        const docRef = doc(scheduledUpdatesRef, cycleId);
        batch = batchSet(docRef, rest, batch);
        return batch;
    };

    const sunsetCycle = (cycle, batch) => {
        const cycleId = cycle.id;
        const sunset = {
            ...cycle,
            doneOverrides: [],
            isLive: false,
            isRunning: false,
            dueOn: null,
        };
        return saveScheduledUpdate(cycleId, sunset, batch);
    };

    const launcbIteration = (cycle, batch) => {
        const cycleId = cycle.id;
        const today = new Date();
        const launched = {
            ...cycle,
            doneOverrides: [],
            isLive: true,
            isRunning: true,
            dueOn: today,
        };
        return saveScheduledUpdate(cycleId, launched, batch);
    };

    const completeIteration = (cycle, batch) => {
        const cycleId = cycle.id;
        const nextIterationId = getUniqueId(12);
        const historyEntry = {
            iterationId: cycle.currentIterationId,
            completedOn: new Date(),
            label: cycle.cycleName,
            pages: cycle.pages,
        };
        const updatedHistory = [...cycle.history, historyEntry];
        const nextDueOn = advanceDueDate(cycle.dueOn, cycle.recurs);
        const cycleUpdate = {
            doneOverrides: [],
            currentIterationId: nextIterationId,
            history: updatedHistory,
            isRunning: false,
            dueOn: nextDueOn,
        };
        return saveScheduledUpdate(cycleId, cycleUpdate, batch);
    };

    return {
        scheduledUpdatesRef,
        launcbIteration,
        sunsetCycle,
        completeIteration,
        saveScheduledUpdate,
        markEmployeesDone,
    };
}
