import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocRef, getSubCollectionRef, getWorkspacesCollectionRef } from "../../../firebase/firebaseActions";
import { useListener } from "../../../firebase/hooks/useListener";
import { checkIfFirestoreRef } from "../../../firebase/utils/identifyFirestoreRef";
import setEntireWorkspaceThunk from "../../Workspace/thunks/setEntireWorkspaceThunk";

export function useSnapshotListener() {
    const dispatch = useDispatch();
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const businessUnitId = useSelector((state) => state.businessUnit.businessUnitId);
    const userRoleId = useSelector((state) => state.user.userRoleId);
    const isAdmin = useSelector((state) => state.user.isAdmin);

    // Admin's listen to the business unit, others listen to the role
    const workspaceRef = useMemo(() => {
        if (!workspaceId) return null;
        const workspacesRef = getWorkspacesCollectionRef();
        return getDocRef(workspacesRef, workspaceId);
    }, [workspaceId]);

    const businessUnitsRef = useMemo(() => {
        if (!workspaceRef) return null;
        return getSubCollectionRef(workspaceRef, "businessUnits");
    }, [workspaceRef]);

    const rolesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return getSubCollectionRef(workspaceRef, "roles");
    }, [workspaceRef]);

    const snapshotCollectionRef = useMemo(() => {
        if (!workspaceId || !businessUnitId) return null;
        let docRef = null;
        if (isAdmin) {
            docRef = getDocRef(businessUnitsRef, businessUnitId);
        } else if (userRoleId) {
            docRef = getDocRef(rolesRef, userRoleId);
        } else {
            return null;
        }
        return getSubCollectionRef(docRef, "assessmentSnapshots");
    }, [workspaceId, businessUnitId, userRoleId, isAdmin, businessUnitsRef, rolesRef]);

    // Check if the workspace, business unit and boards are ready
    const readyToConnect = useMemo(() => {
        return checkIfFirestoreRef(snapshotCollectionRef);
    }, [snapshotCollectionRef]);

    const handleDataReceived = useCallback(
        (querySnapshot) => {
            let snapshots = {};
            querySnapshot.docChanges().forEach((change) => {
                const data = change.doc.data() || {};
                snapshots = { ...snapshots, ...data };
            });

            // Remove any snapshots that are no longer live
            let liveSnapshotIds = [];
            querySnapshot.forEach((doc) => {
                const snapshotsInDoc = doc.data();
                if (snapshotsInDoc) {
                    liveSnapshotIds = [...liveSnapshotIds, ...Object.keys(snapshotsInDoc)];
                }
            });
            liveSnapshotIds = [...new Set(liveSnapshotIds)];

            dispatch(setEntireWorkspaceThunk({ snapshots, liveSnapshotIds }));
        },
        [dispatch]
    );

    return useListener(snapshotCollectionRef, handleDataReceived, readyToConnect);
}