import { useMemo } from "react";
import { useRoleRelationships } from "./useRoleRelationships";
import { useRoles } from "./useRoles";

export function deepIdSearch(obj, targetId) {
    if (obj.id === targetId) {
        return obj;
    }
    if (obj.children && Array.isArray(obj.children)) {
        for (const child of obj.children) {
            const found = deepIdSearch(child, targetId);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

export function useDepartmentTree(topLevelRoleId, roles, getValues = () => {}) {
    const { getSubDepartments } = useRoleRelationships();
    const { getRole } = useRoles();

    const handleSearchDepartmentTree = (node, roleId) => {
        return deepIdSearch(node, roleId);
    };

    const departmentTree = useMemo(() => {
        const rolesIsArray = Array.isArray(roles);
        function helperCreateNodes(roleId, isTopLevel = false) {
            let role;
            if (roleId === topLevelRoleId) {
                role = getRole(roleId);
            } else if (rolesIsArray) {
                role = roles.find((role) => role.id === roleId);
            } else {
                role = getRole(roleId);
            }
            if (!role) return null;
            const subDepartments = getSubDepartments(roleId); // Returns array of sub-roles
            const departmentNode = {
                id: role.id,
                isTopLevel,
                department: role.department || role.title,
                children: subDepartments
                    .map((subRole) => helperCreateNodes(subRole.id))
                    .filter((child) => child !== null),
            };
            const values = getValues(departmentNode) || {};
            console.log(values, "values");
            return { ...departmentNode, ...values };
        }

        // Generate the full tree starting from the top-level role
        const topNode = helperCreateNodes(topLevelRoleId, true);
        return topNode || {};
    }, [topLevelRoleId, roles, getRole, getSubDepartments, getValues]);

    return {
        departmentTree,
        searchDepartmentTree: handleSearchDepartmentTree,
    };
}
