import React from "react";
import * as Yup from "yup";
import { FormStepper } from "../../../../../components";
import { FIELD_SIZE_LIMITS } from "../../../../../constants";
import { useLanguage } from "../../../../../translations/LanguageContext";
import EmployeeDetailsStep from "./Steps/EmployeeDetailsStep";
import IncumbentStep from "./Steps/IncumbentStep";

export const CreateOrFillRoleInnerForm = (props) => {
    const { appLanguage } = useLanguage();
    const { toggle, values, status, language, hideIncumbentStep } = props;

    const handleSubmit = () => {
        props.onSubmit(values, status);
    };

    const validationSchemas = [
        {
            jobTitle: Yup.string()
                .required(appLanguage("warnings", "required"))
                .max(FIELD_SIZE_LIMITS.JOB_TITLE, appLanguage("warnings", "length_max")),
        },
        {
            firstname: Yup.string()
                .required(appLanguage("warnings", "required"))
                .max(FIELD_SIZE_LIMITS.FIRSTNAME, appLanguage("warnings", "length_max")),
            surname: Yup.string()
                .required(appLanguage("warnings", "required"))
                .max(FIELD_SIZE_LIMITS.SURNAME, appLanguage("warnings", "length_max")),
            employeeNumber: Yup.string().max(FIELD_SIZE_LIMITS.EMPLOYEE_NO, appLanguage("warnings", "length_max")),
            email: Yup.string()
                .email()
                .required(appLanguage("warnings", "required"))
                .max(FIELD_SIZE_LIMITS.EMAIL, appLanguage("warnings", "length_max")),
            emailInUse: Yup.boolean().oneOf([false], appLanguage("warnings", "email_in_use")),
            employeeNumberInUse: Yup.boolean().oneOf([false], appLanguage("warnings", "employee_number_in_use")),
        },
        {},
    ];
    const invalidOveride = status.fillWith === "vacant";

    return (
        <FormStepper
            isOpen={status.isOpen}
            toggle={toggle}
            onSubmit={handleSubmit}
            validationSchemas={validationSchemas}
            values={values}
            headerText={status.headerText}
            isWaiting={status.isWaiting}
            invalidOveride={invalidOveride}
            hideStepProgress
            width="md"
            height="xl"
        >
            {!hideIncumbentStep && <IncumbentStep {...props} title="Role Details" language={language} />}
            <EmployeeDetailsStep {...props} title="Employee Details" language={language} />
        </FormStepper>
    );
};

export default CreateOrFillRoleInnerForm;
