import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import InfiniteTableContextMenu from "./InfiniteTableContextMenu";

const InfiniteTableRow = (props) => {
    const {
        index,
        style,
        data,
        columns,
        selectedRowId,
        showCheckBoxes = false,
        checkedIds = [],
        rowActions,
        hideActionMenu,
        getRowClass = () => {},
        getStyle = () => {},
        onCheck = () => {},
        onRowClick = () => {},
        onRowDoubleClick = () => {},
    } = props;
    const row = data[index];
    if (!row) return null;
    const isSelected = selectedRowId === row.id;
    const checked = checkedIds.includes(row.id);
    const rowClass = getRowClass && getRowClass(row);
    const showMenu = !hideActionMenu && !!rowActions;
    return (
        <div
            style={style}
            className={classNames("clickable flex border border-dashed w-full", rowClass, {
                "bg-blue-100": isSelected,
            })}
            onClick={() => onRowClick(row.id)}
            onDoubleClick={() => onRowDoubleClick(row.id)}
        >
            {showCheckBoxes && (
                <div className="w-12 middle border-r">
                    <input
                        className="checkbox checkbox-primary checkbox-xs"
                        checked={checked}
                        type="checkbox"
                        onChange={() => onCheck(row.id)}
                    />
                </div>
            )}
            {columns.map((col) => {
                const flexSize = col.size === "sm" ? 1 : col.size === "lg" ? 3 : 2;
                const flexClass = `flex-${flexSize}`;
                const rowClassName = col.cellClassBuilder ? col.cellClassBuilder(row) : "ps-2";
                const style = getStyle(col);
                return (
                    <div
                        key={col.id}
                        className={classNames(
                            "flex items-center text-sm font-medium overflow-x-scroll whitespace-nowrap hide-scroll-bars",
                            rowClassName,
                            {
                                [flexClass]: !col.width,
                                "justify-center": col.justifyCenter,
                            }
                        )}
                        style={style}
                        title={col.tooltip}
                    >
                        {col.cellRenderer ? col.cellRenderer(row, col) : row[col.id]}
                    </div>
                );
            })}
            <div className="w-12 flex items-center justify-center">
                {showMenu && <InfiniteTableContextMenu hidden={!isSelected} menuId={row?.id} actions={rowActions} />}
            </div>
        </div>
    );
};

InfiniteTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowActions: PropTypes.array,
    showCheckBoxes: PropTypes.bool,
    checkedIds: PropTypes.array,
    getStyle: PropTypes.func,
    onCheck: PropTypes.func,
    getRowClass: PropTypes.func,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
};

export default InfiniteTableRow;
