import { deleteField, writeBatch } from "firebase/firestore";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_PAGE_PATH } from "../../../constants";
import { db } from "../../../firebase/firebase";
import { getUniqueId } from "../../../helpers/helpers";
import { CLEAR_SELECTED } from "../../../redux/appSlice";
import { useBusinessUnits } from "../../BusinessUnits/hooks/useBusinessUnits";
import { useTopLevelRoleId } from "../../BusinessUnits/hooks/useTopLevelRoleId";
import { REMOVE_ROLE, VACATE_ROLE } from "../../BusinessUnits/utils/businessUnitSlice";
import { useEmployees } from "../../Employees/hooks/useEmployees";
import { useEmployeesApi } from "../../Employees/hooks/useEmployeesApi";
import { writeEmployee } from "../../Employees/utils/employeeDb";
import { useSnapshots } from "../../Snapshots/hooks/useSnapshots";
import { disableRole, writeRole } from "../utils/rolesDb";

function getRoleHelper(roleId, roles) {
    return roles?.[roleId] || {};
}

function getChildRoleIdsHelper(roleId, parentMap) {
    let childRoleIds = Object.entries(parentMap || {})
        .filter(([, parentRoleId]) => parentRoleId === roleId)
        .map(([childRoleId]) => childRoleId);
    return childRoleIds;
}

function getAllRoleIdsBelowHelper(roleId, parentMap) {
    let allChildRoles = [];
    function helper_recursive_child_roles(roleId) {
        const childRoles = getChildRoleIdsHelper(roleId, parentMap);
        allChildRoles.push(...childRoles);
        childRoles.forEach((childRoleId) => {
            helper_recursive_child_roles(childRoleId);
        });
    }
    helper_recursive_child_roles(roleId);
    return allChildRoles;
}

function checkCanManageRoleHelper(roleId, topLevelRoleId, isAdmin, parentMap) {
    if (isAdmin) return true;
    const allowedRoles = getAllRoleIdsBelowHelper(topLevelRoleId, parentMap);
    return allowedRoles.includes(roleId);
}

function getRoleLinkHelper(roleId, topLevelRoleId, isAdmin, parentMap) {
    const canManageRole = checkCanManageRoleHelper(roleId, topLevelRoleId, isAdmin, parentMap);
    if (canManageRole) {
        return `${ROLE_PAGE_PATH}/${roleId}/team`;
    }
}

function getAllRoleParentRoleIdsHelper(roleId, parentMap) {
    let parentRoles = [];
    let parentRoleId = parentMap[roleId];
    while (parentRoleId) {
        parentRoles.push(parentRoleId);
        parentRoleId = parentMap[parentRoleId];
    }
    return parentRoles;
}

function getChildEmployeeIdsHelper(roleId, roles, parentMap) {
    const childRoleIds = getChildRoleIdsHelper(roleId, parentMap);
    const childEmployeeIds = childRoleIds.map((childRoleId) => roles[childRoleId]?.incumbentId);
    return childEmployeeIds.filter(Boolean);
}

function getAllEmployeeIdsBelowHelper(roleId, roles, parentMap) {
    const allRoleIdsBelow = getAllRoleIdsBelowHelper(roleId, parentMap);
    const allEmployeeIdsBelow = allRoleIdsBelow.map((roleId) => roles[roleId]?.incumbentId);
    return allEmployeeIdsBelow.filter(Boolean);
}

export function useRoles() {
    const dispatch = useDispatch();
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const roles = useSelector((state) => state.businessUnit.roles);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const businessUnitId = useSelector((state) => state.businessUnit.businessUnitId);
    const defaultTLR = useTopLevelRoleId();
    const { getUnitDocId } = useBusinessUnits();
    const { getEmployee } = useEmployees();
    const { getSnapshot } = useSnapshots();
    const { archiveSnapshot } = useEmployeesApi();

    const roleCount = useMemo(() => Object.keys(roles || {}).length, [roles]);

    // Ensures the new role id is unique (ids are short so collision isn't entirely impossible)
    function getNewRoleId() {
        const newId = getUniqueId();
        const existingRole = roles[newId];
        if (existingRole) {
            return getNewRoleId();
        } else {
            return newId;
        }
    }

    const getRole = useCallback(
        (roleId) => {
            return roles[roleId];
        },
        [roles]
    );

    function getChildRoleIds(roleId) {
        return getChildRoleIdsHelper(roleId, parentMap);
    }

    function getAllRoleIdsBelow(roleId) {
        return getAllRoleIdsBelowHelper(roleId, parentMap);
    }

    function checkCanManageRole(roleId) {
        return checkCanManageRoleHelper(roleId, defaultTLR, isAdmin, parentMap);
    }

    function getRoleLink(roleId) {
        return getRoleLinkHelper(roleId, defaultTLR, isAdmin, parentMap);
    }

    function getAllRoleParentRoleIds(roleId) {
        return getAllRoleParentRoleIdsHelper(roleId, parentMap);
    }

    function getChildEmployeeIds(roleId) {
        return getChildEmployeeIdsHelper(roleId, roles, parentMap);
    }

    function getAllEmployeeIdsBelow(roleId) {
        return getAllEmployeeIdsBelowHelper(roleId, roles, parentMap);
    }

    async function moveRole(roleId, newParentId) {
        const role = getRole(roleId);
        const { incumbentId } = role;
        const update = { parentRoleId: newParentId };
        let batch = writeRole(workspaceId, roleId, update);
        if (incumbentId) {
            // If the employee has a snapshot, archive it and await for it to finish
            // The snapshot will be restored and moved by the cloud function
            const snapshot = getSnapshot(incumbentId);
            if (snapshot) {
                const archiveSnapshotBatch = archiveSnapshot(incumbentId, snapshot);
                await archiveSnapshotBatch.commit();
            }
            batch = writeEmployee(workspaceId, incumbentId, { parentRoleId: newParentId }, batch);
        }
        return batch.commit();
    }

    function updateRole(roleId, roleUpdate, batch = writeBatch(db)) {
        // Add ids required
        const currentRole = getRole(roleId);
        const unitDocId = currentRole?.unitDocId || getUnitDocId("roles", 300);
        const role = {
            ...roleUpdate,
            id: roleId,
            businessUnitId,
            unitDocId,
        };
        batch = writeRole(workspaceId, roleId, role, batch);
        return batch;
    }

    async function deleteRole(roleId) {
        const role = getRole(roleId);
        let batch = writeBatch(db);
        if (role) {
            const incumbentId = role.incumbentId;
            const employee = getEmployee(incumbentId);
            if (employee) {
                const employeeUpdate = {
                    roleId: deleteField(),
                    parentRoleId: deleteField(),
                    userRole: deleteField(),
                    inviteAccepted: deleteField(),
                };
                batch = writeEmployee(workspaceId, incumbentId, employeeUpdate, batch);
            }
            batch = disableRole(workspaceId, roleId, batch);
            dispatch({ type: CLEAR_SELECTED, payload: ["selectedRoleId", "selectedEmployeeId"] });
            dispatch({ type: VACATE_ROLE, payload: roleId });
            dispatch({ type: REMOVE_ROLE, payload: roleId });
            await batch.commit();
        }
    }

    async function vacateRole(roleId) {
        const role = getRole(roleId);
        const employeeId = role?.incumbentId;
        const employee = getEmployee(employeeId);
        let batch = writeBatch(db);
        if (role) {
            batch = writeRole(workspaceId, roleId, { incumbentId: deleteField() }, batch);
        }
        if (employee) {
            let update = { roleId: deleteField(), parentRoleId: deleteField() };
            update = {
                ...update,
                isUser: false,
                inviteAccepted: false,
                userRole: deleteField(),
                customClaims: {},
            };
            batch = writeEmployee(workspaceId, employeeId, update, batch);

            // Write the snapshot to the employee archive so it can be restored later
            const snapshot = getSnapshot(employeeId);
            if (snapshot) {
                batch = archiveSnapshot(employeeId, snapshot, batch);
            }
        }
        dispatch({ type: VACATE_ROLE, payload: roleId });
        dispatch({ type: CLEAR_SELECTED, payload: ["selectedEmployeeId"] });
        await batch.commit();
    }

    return {
        roleCount,
        getRole,
        getChildRoleIds,
        getAllRoleIdsBelow,
        getChildEmployeeIds,
        getAllEmployeeIdsBelow,
        getAllRoleParentRoleIds,
        checkCanManageRole,
        getRoleLink,
        getNewRoleId,
        moveRole,
        updateRole,
        deleteRole,
        vacateRole,
    };
}

export {
    checkCanManageRoleHelper as checkCanManageRole,
    getAllEmployeeIdsBelowHelper as getAllEmployeeIdsBelow,
    getAllRoleIdsBelowHelper as getAllRoleIdsBelow,
    getAllRoleParentRoleIdsHelper as getAllRoleParentRoleIds,
    getChildEmployeeIdsHelper as getChildEmployeeIds,
    getChildRoleIdsHelper as getChildRoleIds,
    getRoleHelper as getRole,
    getRoleLinkHelper as getRoleLink
};

