import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sortArrayOfObjects } from "../../../common/utils/arrayUtils";
import { useConfig } from "../../Config/hooks/useConfig";
import { useEmployee } from "../../Employees/hooks/useEmployee";
import { useEmployeeRelationships } from "../../Employees/hooks/useEmployeeRelationships";
import { filterEmployees } from "../../Employees/hooks/useFilteredEmployees";
import { useSubordinates } from "../../Employees/hooks/useSubordinates";
import { buildFormId } from "../../Forms/utils/buildFormId";
import { filterRoles } from "../../Roles/hooks/useRoleFilters";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import { buildFormChangedStatus } from "../../Schedule/utils/buildFormChangedStatus";
import { buildFormDueStatus } from "../../Schedule/utils/buildFormDueStatus";
import { getParticipation } from "../../Schedule/utils/getParticipation";
import { setActiveReviews } from "../../Workspace/utils/workspaceSlice";

function getParticipantIdList(
    employeeId,
    roles,
    subordinates,
    cycle = {},
    managerRequired,
    employeeRequired,
    getChildRoleIds,
    getAllRoleIdsBelow
) {
    if (!managerRequired) {
        // Manager isn't required to do the review, return own reviews only
        return subordinates.filter((employee) => employee.id === employeeId).map((employee) => employee.id);
    }

    let filteredSubordinates = [...subordinates];
    if (!employeeRequired) {
        //Employee isn't required to do the review, filter out employee's own reviews
        filteredSubordinates = subordinates.filter((employee) => employee.id !== employeeId);
    }

    const { roleFilters, employeeFilters } = cycle;
    const filteredRoles = filterRoles(roles, roleFilters, getChildRoleIds, getAllRoleIdsBelow);

    // filter the employees based on the roles that pass the role filters
    const passedRoleFilter = filteredSubordinates.filter((employee) => {
        return filteredRoles.some((role) => employee.roleId === role.id);
    });

    // filter the employees based on the employee filters
    const passedEmployeeFilter = filterEmployees(employeeFilters, passedRoleFilter);

    // Remove any review where the user isn't a participant

    return passedEmployeeFilter.map((employee) => employee.id);
}

export function useActiveReviews(employeeId) {
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.businessUnit.roles);
    const cycles = useSelector((state) => state.workspace.scheduledUpdates);
    const { preppedEmployee } = useEmployee(employeeId);
    const { getChildRoleIds, getAllRoleIdsBelow } = useRoleRelationships();
    const { roleId } = preppedEmployee || {};
    const subordinates = useSubordinates(roleId);
    const { getDirectReportIds } = useEmployeeRelationships();
    const { ownConfig } = useConfig();
    const { lastViewed = {} } = ownConfig;

    const directReportIds = useMemo(() => {
        return getDirectReportIds(employeeId);
    }, [employeeId, getDirectReportIds]);

    const runningCycles = useMemo(() => {
        return Object.values(cycles).filter((cycle) => {
            return cycle?.isRunning;
        });
    }, [cycles]);

    const activeReviews = useMemo(() => {
        const returnValue = [];
        for (const cycle of runningCycles) {
            const { id: cycleId, pages, currentIterationId } = cycle;
            const participationRequired = getParticipation(pages);
            const { managerRequired, employeeRequired } = participationRequired;
            const participantIdList = getParticipantIdList(
                employeeId,
                roles,
                subordinates,
                cycle,
                managerRequired,
                employeeRequired,
                getChildRoleIds,
                getAllRoleIdsBelow
            );

            // Need to check if the role passes the filter
            // need to check if the employee passes the filter

            for (const subordinate of subordinates) {
                const { id: employeeId } = subordinate;
                const dueStatus = buildFormDueStatus(employeeId, subordinate, cycle, participationRequired);
                const reviewId = buildFormId(currentIterationId, employeeId);
                if (participantIdList.includes(employeeId)) {
                    returnValue.push({
                        ...subordinate,
                        ...cycle,
                        ...dueStatus,
                        iterationId: currentIterationId,
                        cycleId,
                        employeeId,
                        id: reviewId,
                    });
                }
            }
        }
        return returnValue;
    }, [employeeId, subordinates, runningCycles, getAllRoleIdsBelow, getChildRoleIds, roles]);

    const activeSelfReviews = useMemo(() => {
        const returnValue = activeReviews
            .filter((review) => {
                const { employeeRequired } = review;
                return review.employeeId === employeeId && employeeRequired;
            })
            .map((review) => {
                const { iterationId, employeeId, managerAssessedOn, selfAssessedOn } = review;
                const hasChanged = buildFormChangedStatus(
                    "employee",
                    iterationId,
                    employeeId,
                    managerAssessedOn,
                    selfAssessedOn,
                    lastViewed
                );
                return {
                    ...review,
                    hasChanged,
                };
            });
        return sortArrayOfObjects(returnValue, "selfDone");
    }, [activeReviews, employeeId, lastViewed]);

    const activeTeamReviews = useMemo(() => {
        const returnValue = activeReviews
            .filter((incompleteReview) => {
                return directReportIds.includes(incompleteReview.employeeId);
            })
            .map((review) => {
                const { iterationId, employeeId, managerAssessedOn, selfAssessedOn } = review;
                const hasChanged = buildFormChangedStatus(
                    "manager",
                    iterationId,
                    employeeId,
                    managerAssessedOn,
                    selfAssessedOn,
                    lastViewed
                );
                return {
                    ...review,
                    hasChanged,
                };
            });
        return sortArrayOfObjects(returnValue, "managerDone");
    }, [activeReviews, directReportIds, lastViewed]);

    useEffect(() => {
        // dispatch to redux once stable
        const handler = setTimeout(() => {
            dispatch(setActiveReviews({ activeReviews, activeSelfReviews, activeTeamReviews }));
        }, 400);
        return () => {
            clearTimeout(handler);
        };
    }, [dispatch, activeReviews, activeSelfReviews, activeTeamReviews]);

    return {
        activeSelfReviews,
        activeTeamReviews,
        activeReviews,
    };
}
