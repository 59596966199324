import { confirmAlert } from "react-confirm-alert";
import applogger from "./applogger";
import { toast } from "react-toastify";

export async function confirmFirst(actionDetails) {
    if (!actionDetails) throw new Error("No action details provided");
    const {
        title = "Are you sure?",
        message = "Do you definitely want to do this?",
        confirmLabel = "Confirm",
        cancelLabel = "Cancel",
        onConfirm = () => {},
        onCancel = () => {},
        onAfter = () => {},
        showToast = true,
        toastMessage = null,
        renderCustom,
    } = actionDetails;

    async function handleConfirm() {
        try {
            await onConfirm();
            onAfter(toastMessage);
            if (showToast && toastMessage) {
                toast.success(toastMessage);
            }
            return true;
        } catch (err) {
            applogger.error("Error performing action", err);
            return false;
        }
    }

    function handleCancel() {
        onCancel();
        return false;
    }

    if (renderCustom) {
        confirmAlert({
            title: title,
            message: message,
            customUI: ({ onClose }) => {
                return renderCustom({ onClose, handleConfirm, handleCancel });
            },
        });
    } else {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: cancelLabel,
                    onClick: handleCancel,
                },
                {
                    label: confirmLabel,
                    onClick: handleConfirm,
                },
            ],
        });
    }
}
