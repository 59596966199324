import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import setEntireWorkspaceThunk from "../../Workspace/thunks/setEntireWorkspaceThunk";
import { useBusinessUnitRefs } from "../refs/useBusinessUnitRefs";

export function useRoleInfoListener() {
    const dispatch = useDispatch();
    const { roleInfoRef } = useBusinessUnitRefs();

    const handleDataReceived = useCallback(
        (snapshot) => {
            // Get the changes ready
            const roles = {};
            snapshot.docChanges().forEach((change) => {
                const infoDoc = change.doc.data();
                Object.entries(infoDoc).forEach(([roleId, role]) => {
                    roles[roleId] = {
                        id: roleId,
                        incumbentId: role.incumbentId || null,
                        unitDocId: change.doc.id,
                        ...role,
                    };
                });
            });

            // Check which roles are still live
            let liveIds = [];
            snapshot.forEach((doc) => {
                const docData = doc.data();
                if (roles) {
                    liveIds = [...liveIds, ...Object.keys(docData)];
                }
            });

            dispatch(setEntireWorkspaceThunk({ roles, liveRoleIds: liveIds }));
            //dispatch(setRoles({ updates: roles, liveRoleIds: liveIds }));
        },
        [dispatch]
    );

    return useListener(roleInfoRef, handleDataReceived);
}
