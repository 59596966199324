import { deleteDoc, doc, serverTimestamp } from "firebase/firestore";
import { useSelector } from "react-redux";
import { getUniqueId } from "../../../helpers/helpers";
import { useEmployees } from "../../Employees/hooks/useEmployees";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { writeEmployeeNote } from "../../Employees/utils/employeeDb";

export function useNotesApi(employeeId) {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { getEmployee } = useEmployees();
    const author = getEmployee(userEmployeeId);
    const { notesRef } = useEmployeeRefs(employeeId);

    const editNote = (note) => {
        // Update the note in the database
        const updatedNote = { ...note, updatedOn: serverTimestamp() };
        const batch = writeEmployeeNote(workspaceId, employeeId, updatedNote);
        batch.commit();
        return updatedNote;
    };

    const deleteNote = (noteId) => {
        if (!noteId) return;
        const noteRef = doc(notesRef, noteId);
        deleteDoc(noteRef);
    };

    function createNote(note) {
        // Set the local state notes
        const noteId = getUniqueId();
        const fullNote = {
            ...note,
            employeeId,
            isPrivate: note.isPrivate || false,
            id: noteId,
            authorId: userEmployeeId || null,
            createdOn: serverTimestamp(),
        };
        const batch = writeEmployeeNote(workspaceId, employeeId, fullNote);
        batch.commit();

        // Return the created note pre-prepped for the UI
        return {
            ...note,
            id: noteId,
            authorId: userEmployeeId || null,
            author: author || null,
            isAuthor: true,
            createdOn: "Just Now",
        };
    }

    return { createNote, deleteNote, editNote };
}
