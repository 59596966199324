import classNames from "classnames";
import { darken } from "polished";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BackIcon, CrossIcon } from "../../../../components/Icons/MyIcons";
import { getAssessmentColors } from "../../../Config/utils/configSelectors";
import { showRatingColor } from "../../../Snapshots/utils/snapshotHelpers";
import { getEmployee } from "../../../Employees/utils/employeeSelectors";
import { getRole } from "../../../Roles/utils/roleSelectors";
import MyButton from "../../../../components/MyComponents/Button";
import { Button } from "../../../../common/components";

const EmployeeEntry = ({ entry, onClick, isFullPage }) => {
    const employee = useSelector((state) => getEmployee(state, entry.employeeId));
    const roleId = employee && employee.roleId;
    const role = useSelector((state) => getRole(state, roleId));
    const assessmentColors = useSelector(getAssessmentColors);
    const backgroundColor = showRatingColor(assessmentColors, entry.z);
    const color = useMemo(() => {
        return darken(0.3, backgroundColor);
    }, [backgroundColor]);

    if (!isFullPage) {
        const style = { backgroundColor: backgroundColor, color: color, border: "solid .5px white", padding: "3px 0" };
        return (
            <div
                className="col-span-4 flex justify-center clickable items-center"
                onClick={() => onClick(entry.employeeId)}
                style={style}
            >
                <p className="font-bold text-tiny mb-0 text-break overflow-auto text-nowrap hide-scroll-bars">
                    {employee?.displayName}
                </p>
            </div>
        );
    } else {
        return (
            <div
                className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 px-3 py-2 text-center rounded-lg clickable"
                onClick={() => onClick(entry.employeeId)}
                style={{ backgroundColor: backgroundColor, color: color, border: "solid 1px white" }}
            >
                <p className="font-bold mb-0 text-break">{employee.displayName}</p>
                <p className="mb-0 text-break">{role.jobTitle}</p>
            </div>
        );
    }
};

const SegmentHeader = ({ segment, onClose }) => (
    <div className="flex justify-between items-center px-4 pt-4">
        <div className="flex-1">
            <Button size="sm" color="primary" onClick={onClose}>
                <BackIcon />
                <span>Back To Map</span>
            </Button>
        </div>
        <div className="flex-1 flex justify-center">
            <h2 className="text-2xl font-bold">{segment.tile.title}</h2>
        </div>
        <div className="flex-1 flex justify-end">
            <Button size="sm" onClick={onClose} shape="circle">
                <CrossIcon />
            </Button>
        </div>
    </div>
);

const SegmentView = ({ onItemClick, segment, isFullPage, onClose }) => {
    const cohort = useMemo(() => {
        let newCohort = segment.cohort || [];
        newCohort.sort((a, b) => parseFloat(b.z) - parseFloat(a.z));
        return newCohort;
    }, [segment]);

    return (
        <div className={classNames("flex-1 flex flex-col", { "pb-5 px-4 overflow-auto": isFullPage })}>
            {isFullPage && <SegmentHeader segment={segment} onClose={onClose} />}
            <div className={classNames("grid grid-cols-12", { "pt-3 overflow-scroll pb-20": isFullPage })}>
                {cohort.map((entry) => (
                    <EmployeeEntry isFullPage={isFullPage} key={entry.employeeId} entry={entry} onClick={onItemClick} />
                ))}
            </div>
        </div>
    );
};

export default SegmentView;
