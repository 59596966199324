import _ from "lodash";
import { useSelector } from "react-redux";
import { writeTalentItem } from "../../Workspace/utils/workspaceDb";



export function useTalentBoards() {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);

    function getTalentBoard(boardId) {
        return talentBoards[boardId];
    }

    function getPreppedBoardIds(parentRole, autoAssignedBoardIds) {
        const { linkedTalentBoardIds = [] } = parentRole;
        let defaultBoardIds = linkedTalentBoardIds.filter((id) => !autoAssignedBoardIds.includes(id));
        defaultBoardIds = _.uniq(defaultBoardIds);
        return defaultBoardIds;
    }

    function createTalentBoard(boardId, board) {
        const batch = writeTalentItem(workspaceId, "talentBoards", boardId, board);
        batch.commit();
    }


    return {
        createTalentBoard,
        getPreppedBoardIds,
        getTalentBoard,
    };
}
