import React, { useState } from "react";
import FloatingActionButton from "../../components/FloatingActionButton/FloatingActionButton";
import { BusinessUnitIcon, PlusIcon } from "../../components/Icons/MyIcons";
import MyButton from "../../components/MyComponents/Button";
import TalentAreaLegend from "../TalentAreas/components/atoms/TalentAreaLegend";
import { CreateBusinessUnit, EditBusinessUnit } from "./Components/BusinessUnitForms";
import { useSelector } from "react-redux";

const BusinessUnits = () => {
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editId, setEditId] = useState(false);
    const handleToggleModal = () => {
        setCreateModalOpen(!createModalOpen);
    };

    return (
        <div className="fade flex-1 w-full flex flex-col items-stretch bg-base-150 overflow-hidden">
            <div className="flex justify-center p-4 border-b bg-base-100">
                <TalentAreaLegend talentAreas={talentAreas} />
            </div>
            <CreateBusinessUnit isOpen={createModalOpen} toggle={handleToggleModal} />
            <EditBusinessUnit isOpen={!!editId} toggle={setEditId} businessUnitId={editId} />
            <FloatingActionButton icon={BusinessUnitIcon}>
                <MyButton
                    size="sm"
                    shape="circle"
                    tooltip="Create Business Unit"
                    onClick={() => setCreateModalOpen(true)}
                >
                    <PlusIcon />
                </MyButton>
            </FloatingActionButton>
        </div>
    );
};

export default BusinessUnits;
