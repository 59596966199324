import { PropTypes } from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { IdPicker } from "../../../../components";
import { useGroupedTalentBoards } from "../../hooks/useGroupedTalentBoards";

const tempDefaultProps = {
    autoType: "everything",
    selectedTalentBoardIds: [],
    excludeIds: [],
    isClearable: true,
    onBlur: () => {},
    onChange: () => {},
}

const TalentBoardPicker = (props) => {
    const mergedProps = { ...tempDefaultProps, ...props };
    const { selectedIds, excludeIds, isMulti, isClearable, autoType } = mergedProps;
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const { autoAssignedBoardIds, manualAssignedBoardIds, managementBoardIds, globalBoardIds } =
        useGroupedTalentBoards();

    const filteredIds = useMemo(() => {
        switch (autoType) {
            case "all":
                return globalBoardIds;
            case "management":
                return managementBoardIds;
            case "manual":
                return manualAssignedBoardIds;
            case "allAuto":
                return autoAssignedBoardIds;
            case "everything":
            default:
                return Object.keys(talentBoards);
        }
    }, [autoType, talentBoards, autoAssignedBoardIds, manualAssignedBoardIds, managementBoardIds, globalBoardIds]);

    const options = useMemo(() => {
        return Object.values(talentBoards).filter((board) => filteredIds.includes(board.id));
    }, [talentBoards, filteredIds]);

    const getLabel = (talentBoard) => {
        return talentBoard.boardName;
    };

    return (
        <IdPicker
            {...mergedProps}
            minWidth={260}
            name="talentBoards"
            getLabel={getLabel}
            options={options}
            selectedIds={selectedIds}
            excludeIds={excludeIds}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

TalentBoardPicker.propTypes = {
    autoType: PropTypes.oneOf(["all", "management", "manual", "allAuto", "everything"]),
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};;

export default TalentBoardPicker;
