import DepartmentBreadcrumbs from "../../../Roles/components/organisms/DepartmentBreadcrumbs";

const InsightsToolbar = ({ topLevelRoleId, departmentRoleId, onSelectDepartment }) => {
    const handleBreadcrumbClick = (breadcrumb) => {
        onSelectDepartment(breadcrumb.id);
    };
    return (
        <div className="toolbar border rounded-box flex justify-between space-x-2 w-full">
            <div className="flex items-center space-x-2">
                <DepartmentBreadcrumbs
                    topLevelRoleId={topLevelRoleId}
                    roleId={departmentRoleId}
                    onItemClick={handleBreadcrumbClick}
                />
            </div>
        </div>
    );
};

export default InsightsToolbar;
