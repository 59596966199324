import { buildComponentName } from "../../../common/utils/buildComponentName";
import { useDashboardContext } from "../../../pages/dashboard/DashboardPage";
import { withLiveTalentData } from "../../Workspace/hoc/withLiveTalentData";

export const withDashboardContext = (Component) => {
    const WithDashboardContext = (props) => {
        const contextProps = useDashboardContext();
        const hidden = contextProps.currentTab !== props.tabId;
        if (hidden) return null;
        return <Component {...props} {...contextProps} useParentContext={useDashboardContext} />;
    };
    WithDashboardContext.displayName = buildComponentName("withDashboardContext", Component);
    return withLiveTalentData(WithDashboardContext);
};
