import React from "react";
import { Each } from "../../../../common/components";
import { useRunOnMount } from "../../../../common/hooks/useRunOnMount";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";
import FormList from "../../../Forms/components/organisms/FormList";
import { useReviewQueueCounts } from "../../hooks/useReviewQueueCounts";
import { SelfReviewQueueItem, TeamReviewQueueItem } from "../molecules/ReviewQueueItem";
import ReviewQueueStats from "../organisms/ReviewQueueStats";
import classNames from "classnames";

const EmptyGroup = ({ message = "No active reviews" }) => {
    return <MyEmptyState message={message} type="tasks" className="border-none h-40" />;
};

const ActiveReviewsTemplate = ({
    activeSelfReviews,
    activeTeamReviews,
    activeReviews,
    userEmployeeId,
    formFetcher,
    onViewReview,
    className,
}) => {
    const { forms, fetchMore, hasMore, fetchStatus } = formFetcher;

    useRunOnMount(fetchMore, fetchStatus === "ready", "ActiveReviewsTemplate");

    const reviewQueueCounts = useReviewQueueCounts(activeSelfReviews, activeTeamReviews, activeReviews);
    return (
        <div className={classNames("flex-1 flex overflow-hidden", className)}>
            <div className="flex-1 flex-col overflow-scroll space-y-8 pe-6">
                <div>
                    <ReviewQueueStats {...reviewQueueCounts} />
                </div>
                <div className="space-y-2">
                    <h3>Your Own Reviews</h3>
                    <div className="bg-white rounded-lg divide-y border">
                        <Each
                            of={activeSelfReviews}
                            renderEmpty={() => <EmptyGroup />}
                            render={(entry) => (
                                <SelfReviewQueueItem
                                    entry={entry}
                                    userEmployeeId={userEmployeeId}
                                    onItemClick={onViewReview}
                                />
                            )}
                        />
                    </div>
                </div>
                <hr />
                <div className="space-y-2">
                    <h3>Your Team's Reviews</h3>
                    <div className="bg-white rounded-lg divide-y border">
                        <Each
                            of={activeTeamReviews}
                            renderEmpty={() => <EmptyGroup />}
                            render={(entry) => (
                                <TeamReviewQueueItem
                                    entry={entry}
                                    userEmployeeId={userEmployeeId}
                                    onItemClick={onViewReview}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="flex-1 max-w-xl h-full flex flex-col overflow-auto space-y-2 ps-6">
                <div className="flex-1 flex flex-col bg-white rounded-lg border overflow-auto">
                    <div className="p-4 bg-secondary bg-opacity-5">
                        <h3>Recently Submitted</h3>
                    </div>
                    <div className="flex-1 overflow-scroll">
                        <FormList
                            userEmployeeId={userEmployeeId}
                            forms={forms}
                            fetchMore={fetchMore}
                            fetchStatus={fetchStatus}
                            hasMore={hasMore}
                            onItemClick={onViewReview}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActiveReviewsTemplate;
