import { useEffect, useRef } from "react";
import applogger from "../utils/applogger";

export function useRunOnMount(onMount, enabled = true, calledBy) {
    const hasRun = useRef(false);
    useEffect(() => {
        if (!hasRun.current && enabled && onMount) {
            applogger.info("Running onMount", calledBy);
            hasRun.current = true;
            onMount();
        }
    }, [onMount, enabled, calledBy]);
}
