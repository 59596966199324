import { getDocs, limit, query, startAfter } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { readTracker } from "../common/utils/activityTracker";
import applogger from "../common/utils/applogger";
import { useRunOnMount } from "../common/hooks/useRunOnMount";

export function usePaginatedFetch(initialQueryRef, docsPerPage = 10, enableAutoFetch = false) {
    const [items, setItems] = useState([]);
    const attemptStatus = useRef("not-ready");
    const lastDoc = useRef(null);
    const hasMoreRef = useRef(true);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        if (!initialQueryRef) {
            attemptStatus.current = "not-ready";
        } else {
            attemptStatus.current = "ready";
        }
    }, [initialQueryRef]);

    const fetchMore = useCallback(async () => {
        if (!initialQueryRef || attemptStatus.current === "fetching" || !hasMoreRef.current) return false;
        attemptStatus.current = "fetching";
        try {
            // Create the query for the next batch
            let paginatedQuery = initialQueryRef;
            if (lastDoc.current) {
                paginatedQuery = query(initialQueryRef, startAfter(lastDoc.current), limit(docsPerPage));
            }
            readTracker(paginatedQuery);
            const querySnapshot = await getDocs(paginatedQuery);
            const newDocs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            if (newDocs.length > 0) {
                setItems((prevItems) => [
                    ...prevItems,
                    ...newDocs.filter((newDoc) => !prevItems.some((prevItem) => prevItem.id === newDoc.id)),
                ]);

                lastDoc.current = querySnapshot.docs[querySnapshot.docs.length - 1];

                if (newDocs.length < docsPerPage) {
                    hasMoreRef.current = false;
                    setHasMore(false);
                }
            } else {
                hasMoreRef.current = false;
                setHasMore(false);
            }
        } catch (error) {
            applogger.error("Error fetching documents: ", error);
        } finally {
            attemptStatus.current = "idle";
        }
    }, [initialQueryRef, docsPerPage]);

    // Will fetch the first set of documents on mount if enabled
    useRunOnMount(fetchMore, enableAutoFetch && !!initialQueryRef, "usePaginatedFetch");

    const addItem = useCallback((item, addToStart) => {
        setItems((prevItems) => {
            const existingItem = prevItems.find((i) => i.id === item.id);
            if (existingItem) {
                return prevItems.map((i) => {
                    if (i.id === item.id) {
                        return item;
                    } else {
                        return i;
                    }
                });
            } else if (addToStart) {
                return [item, ...prevItems];
            } else {
                return [...prevItems, item];
            }
        });
    }, []);

    const removeItem = useCallback((itemId) => {
        setItems((prevItems) => {
            return prevItems.filter((item) => item.id !== itemId);
        });
    }, []);

    return [items, fetchMore, hasMore, attemptStatus.current, addItem, removeItem];
}
