import { cloneDeep } from "lodash";

export function linkTraits(originalData) {
    const rawTalentData = cloneDeep(originalData);

    const { traitMap = {}, traits = {}, talentAreas = {}, talentBoards = {}, actions = {} } = rawTalentData;

    Object.entries(traitMap).forEach(([traitId, links]) => {
        const trait = traits[traitId] || { id: traitId };

        if (links.linkedTalentAreaId) {
            const talentArea = talentAreas[links.linkedTalentAreaId];
            if (talentArea) {
                talentArea.linkedTraitIds = talentArea.linkedTraitIds
                    ? [...talentArea.linkedTraitIds, traitId]
                    : [traitId];
            }
        }

        links.linkedTalentBoardIds?.forEach((talentBoardId) => {
            const talentBoard = talentBoards[talentBoardId];
            if (talentBoard) {
                talentBoard.linkedTraitIds = talentBoard.linkedTraitIds
                    ? [...talentBoard.linkedTraitIds, traitId]
                    : [traitId];
            }
        });

        links.linkedActionIds?.forEach((actionId) => {
            const action = actions[actionId];
            if (action) {
                action.linkedTraitIds = action.linkedTraitIds ? [...action.linkedTraitIds, traitId] : [traitId];
            }
        });

        traits[traitId] = { ...trait, ...links };
    });

    return { traitMap, traits, talentAreas, talentBoards, actions };
}
