import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

export function useGroupedTalentBoards() {
    const talentBoards = useSelector((state) => state.workspace.talentBoards);

    const autoAssignedBoardIds = useMemo(() => {
        return Object.values(talentBoards || {})
            .filter((board) => !!board.auto)
            .map((board) => board.id);
    }, [talentBoards]);

    const manualAssignedBoardIds = useMemo(() => {
        return Object.values(talentBoards || {})
            .filter((board) => !board.auto)
            .map((board) => board.id);
    }, [talentBoards]);

    const managementBoardIds = useMemo(() => {
        return Object.values(talentBoards || {})
            .filter((board) => board.auto === "management")
            .map((board) => board.id);
    }, [talentBoards]);

    const globalBoardIds = useMemo(() => {
        return Object.values(talentBoards || {})
            .filter((board) => board.auto === "all")
            .map((board) => board.id);
    }, [talentBoards]);

    const getLinkedBoardsIds = useCallback(
        (role) => {
            const allBoardIds = role?.linkedTalentBoardIds || [];
            const autoLinked = allBoardIds.filter((boardId) => autoAssignedBoardIds.includes(boardId));
            const manualLinked = allBoardIds.filter((boardId) => manualAssignedBoardIds.includes(boardId));
            return { autoLinked, manualLinked };
        },
        [autoAssignedBoardIds, manualAssignedBoardIds]
    );

    return { autoAssignedBoardIds, manualAssignedBoardIds, managementBoardIds, globalBoardIds, getLinkedBoardsIds };
}
