import classNames from "classnames";
import React, { useState } from "react";
import { useRunOnMount } from "../../../../common/hooks/useRunOnMount";
import { convertObjectToArray } from "../../../../common/utils/objectUtils";
import MyDrawer from "../../../../components/MyComponents/Drawer";
import NotesSideArea from "../../../Notes/containers/NotesSideArea";
import { useClaims } from "../../../User/hooks/useClaims";
import CascadesSideArea from "../../containers/CascadesSideArea";
import ObjectiveSettingModal from "../../containers/modals/ObjectiveSettingModal";
import ObjectiveViewCardGroup from "../organisms/ObjectiveCards/ObjectiveViewCard/ObjectiveViewCardGroup";
import ObjectiveCreatorHeader from "../organisms/ObjectiveSetting/ObjectiveCreatorHeader";

const ObjectivesTemplate = ({
    employeeId,
    userEmployeeId,
    isSelf,
    hasChildren,
    liveObjectives = {},
    itemsPerPage,
    className = "page page-padding",
    objectiveFetcher = [],
    allowCreate,
    onSaveObjective = () => {},
}) => {
    const [completeObjectives, fetchMore, hasMore, fetchStatus] = objectiveFetcher;
    const [showNotes, setShowNotes] = useState(false);
    const [showCascades, setShowCascades] = useState(false);
    const [newObjective, setNewObjective] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const liveObjectiveArray = convertObjectToArray(liveObjectives);
    const completeObjectivesArray = convertObjectToArray(completeObjectives);
    const { isDeveloper, isAdmin } = useClaims();

    // Fetch initial objectives
    useRunOnMount(fetchMore, fetchStatus === "ready", "ObjectivesTemplate");

    // Complete objectives only fetch, so if they are made live again they need filtering out
    const cleanedCompleteObjectives = completeObjectivesArray.filter((objective) => !liveObjectives[objective.id]);

    const handleCloseSideArea = () => {
        setShowNotes(false);
        setShowCascades(false);
    };

    const handleViewComments = (objective) => {
        setSelectedObjective(objective);
        setShowNotes(!!objective);
    };

    const handleViewCascades = (objective) => {
        setSelectedObjective(objective);
        setShowCascades(!!objective);
    };

    const handleCreateObjective = (fullNewObjective) => {
        onSaveObjective(fullNewObjective);
        setNewObjective(null);
    };

    console.log(showCascades, "showCascades");
    console.log(showNotes, "showNotes");

    return (
        <MyDrawer
            setIsOpen={handleCloseSideArea}
            isOpen={showNotes || showCascades}
            className="h-full flex"
            contentClassName="flex-1"
            sideAreaClassName="min-w-lg max-w-xl"
            drawerEnd={true}
            noClickClose={false}
            side={
                showNotes ? (
                    <NotesSideArea
                        userEmployeeId={userEmployeeId}
                        objective={selectedObjective}
                        onToggle={handleCloseSideArea}
                    />
                ) : (
                    <CascadesSideArea objective={selectedObjective} />
                )
            }
        >
            <div className={classNames("flex-1 space-y-8 !bg-white overflow-scroll", className)}>
                <div className="space-y-1 flex flex-col p-8 bg-primary bg-opacity-5 border rounded-box">
                    <h5>Live Objectives</h5>
                    <ObjectiveViewCardGroup
                        objectives={liveObjectiveArray}
                        viewingComments={showNotes}
                        emptyStateMessage="No Live Objectives"
                        onViewCascades={handleViewCascades}
                        onViewComments={handleViewComments}
                    />
                    {allowCreate && <ObjectiveCreatorHeader ownerEmployeeId={employeeId} onSubmit={setNewObjective} />}
                </div>
                <div className="space-y-1 flex flex-col p-8 bg-success bg-opacity-5 border rounded-box flex-grow overflow-visible">
                    <h5>Completed Objectives</h5>
                    <ObjectiveViewCardGroup
                        objectives={cleanedCompleteObjectives}
                        viewingComments={showNotes}
                        emptyStateMessage="No Completed Objectives"
                        hasMore={hasMore}
                        fetchStatus={fetchStatus}
                        itemsPerPage={itemsPerPage}
                        fetchMore={fetchMore}
                        onViewCascades={handleViewCascades}
                        onViewComments={handleViewComments}
                    />
                </div>
                <ObjectiveSettingModal
                    isOpen={!!newObjective}
                    objective={newObjective}
                    isSelf={isSelf}
                    hasChildren={hasChildren}
                    onToggle={() => setNewObjective(null)}
                    onSubmit={handleCreateObjective}
                />
            </div>
        </MyDrawer>
    );
};

export default ObjectivesTemplate;
