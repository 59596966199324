import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { usePrevious } from "../../hooks/usePrevious";
import { confirmFirst } from "../../common/utils/confirmFirst";
import { useDispatch, useSelector } from "react-redux";
import { setHasUnsavedEdits } from "../../redux/appSlice";

const MyDrawer = ({
    children,
    side,
    isOpen,
    setIsOpen,
    className,
    contentClassName,
    sideAreaClassName = "min-w-lg",
    drawerEnd = true,
    noClickClose,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const prevLocation = usePrevious(location);
    const unsavedEdits = useSelector((state) => state.app.hasUnsavedEdits);

    // Close on ESC press
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setIsOpen(false);
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [setIsOpen]);

    // Closes side area if the url path changes
    useEffect(() => {
        if (location !== prevLocation && isOpen) {
            setIsOpen(false);
        }
    }, [isOpen, location, prevLocation, setIsOpen]);

    const handleClose = useCallback(() => {
        if (unsavedEdits) {
            confirmFirst({
                title: "Unsaved changes",
                message: "You have unsaved changes. Are you sure you want to leave?",
                onConfirm: () => {
                    setIsOpen(false);
                    dispatch(setHasUnsavedEdits(false));
                },
            });
        } else {
            setIsOpen(false);
        }
    }, [unsavedEdits, dispatch, setIsOpen]);

    const handleOverlayClick = useCallback(() => {
        if (noClickClose) return;
        handleClose();
    }, [noClickClose, handleClose]);

    return (
        <div className={classNames("drawer overflow-hidden", className, { "drawer-end": drawerEnd })}>
            <input
                id="my-drawer"
                type="checkbox"
                className="drawer-toggle"
                checked={isOpen || false}
                onChange={() => setIsOpen(false)}
            />
            <div className={classNames("drawer-content flex flex-col overflow-hidden", contentClassName)}>
                {children}
            </div>
            <div className="drawer-side z-50">
                <label
                    htmlFor="my-drawer"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                    onClick={handleOverlayClick}
                ></label>
                <div className={classNames("bg-base-100 h-screen overflow-auto", sideAreaClassName)}>{side}</div>
            </div>
        </div>
    );
};

export default MyDrawer;
