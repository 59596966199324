import { PropTypes } from "prop-types";
import React from "react";
import { IdPicker } from "../../../components";
import { useSelector } from "react-redux";

const tempDefaultProps = {
    selectedIds: [],
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
};

const BusinessUnitPicker = (props) => {
    const mergedProps = { ...tempDefaultProps, ...props };
    const { selectedIds, excludeIds } = mergedProps;
    const businessUnits = useSelector((state) => state.workspace.businessUnits);

    const getLabel = (unit) => {
        return unit.businessUnitName;
    };

    if (businessUnits.length < 2) return null;

    return (
        <IdPicker
            {...mergedProps}
            name="businessUnits"
            placeholder="Business Unit"
            getLabel={getLabel}
            options={businessUnits}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds}
            isClearable={false}
            isMulti={false}
        />
    );
};

BusinessUnitPicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};


export default BusinessUnitPicker;
