import { useCallback } from "react";
import { useSelector } from "react-redux";

export function useRoleRelationships() {
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const roles = useSelector((state) => state.businessUnit.roles);

    const getChildRoleIds = useCallback(
        (roleId) => {
            let childRoleIds = Object.entries(parentMap)
                .filter(([, parentRoleId]) => parentRoleId === roleId)
                .map(([childRoleId]) => childRoleId);
            return childRoleIds;
        },
        [parentMap]
    );

    const getAllRoleIdsBelow = useCallback(
        (roleId) => {
            let allChildRoles = [];
            function helper_recursive_child_roles(roleId) {
                const childRoles = getChildRoleIds(roleId);
                allChildRoles.push(...childRoles);
                childRoles.forEach((childRoleId) => {
                    helper_recursive_child_roles(childRoleId);
                });
            }
            helper_recursive_child_roles(roleId);
            return allChildRoles;
        },
        [getChildRoleIds]
    );

    const getAllRoleIdsAbove = useCallback(
        (roleId) => {
            let parentRoles = [];
            let parentRoleId = parentMap[roleId];
            while (parentRoleId) {
                parentRoles.push(parentRoleId);
                parentRoleId = parentMap[parentRoleId];
            }
            return parentRoles;
        },
        [parentMap]
    );

    const getParentDepartment = useCallback(
        (roleId) => {
            function helperCheckForParentDepartment(roleId) {
                const parentRoleId = parentMap[roleId];
                const parentRole = roles[parentRoleId];
                if (!parentRole) {
                    return null;
                } else if (parentRole.department) {
                    return parentRole.id;
                } else {
                    return helperCheckForParentDepartment(parentRoleId);
                }
            }
            return helperCheckForParentDepartment(roleId);
        },
        [roles, parentMap]
    );

    const getAllDepartmentsAbove = useCallback(
        (roleId) => {
            const departmentHeadRoles = getAllRoleIdsAbove(roleId)
                .map((roleId) => roles[roleId])
                .filter((role) => role?.department);
            return departmentHeadRoles.map((role) => role?.department);
        },
        [roles, getAllRoleIdsAbove]
    );

    const getAllDepartmentRoleIdsAbove = useCallback(
        (roleId) => {
            const departmentHeadRoles = getAllRoleIdsAbove(roleId)
                .map((roleId) => roles[roleId])
                .filter((role) => role?.department);
            return departmentHeadRoles.map((role) => role?.id);
        },
        [roles, getAllRoleIdsAbove]
    );

    const getSubDepartments = useCallback(
        (roleId) => {
            function helperGetNextDepartment(roleId, allChildDepartmentHeads = []) {
                const childRoleIds = getChildRoleIds(roleId);
                if (childRoleIds.length === 0) {
                    return allChildDepartmentHeads;
                } else {
                    const childRoles = childRoleIds.map((roleId) => roles[roleId]);
                    const departmentRoles = childRoles.filter((role) => role.department);
                    allChildDepartmentHeads.push(...departmentRoles);
                    const standardRoles = childRoles.filter((role) => !role.department);
                    standardRoles.forEach((role) => {
                        return helperGetNextDepartment(role.id, allChildDepartmentHeads);
                    });
                }
                return allChildDepartmentHeads;
            }
            return helperGetNextDepartment(roleId);
        },
        [roles, getChildRoleIds]
    );

    const getRoleParentRoleIds = useCallback(
        (roleId) => {
            let parentRoles = [];
            let parentRoleId = parentMap[roleId];
            while (parentRoleId) {
                parentRoles.push(parentRoleId);
                parentRoleId = parentMap[parentRoleId];
            }
            return parentRoles;
        },
        [parentMap]
    );

    const getRoleOrgLevel = useCallback(
        (roleId) => {
            const allParents = getRoleParentRoleIds(roleId);
            return allParents.length;
        },
        [getRoleParentRoleIds]
    );

    const getDepartmentMemberIds = useCallback(
        (roleId) => {
            return getAllRoleIdsBelow(roleId)
                .map((roleId) => {
                    return roles[roleId];
                })
                .filter((role) => {
                    return !!role?.incumbentId;
                })
                .map((role) => {
                    return role.incumbentId;
                });
        },
        [getAllRoleIdsBelow, roles]
    );

    return {
        getAllRoleIdsAbove,
        getChildRoleIds,
        getAllRoleIdsBelow,
        getRoleOrgLevel,
        getRoleParentRoleIds,
        getParentDepartment,
        getAllDepartmentsAbove,
        getAllDepartmentRoleIdsAbove,
        getSubDepartments,
        getDepartmentMemberIds,
    };
}
