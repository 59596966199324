import { PAGE_MODULE_OPTIONS } from "../../../constants";

export function checkForPageTypes(relationship, type, pages) {
    const moduleIds = PAGE_MODULE_OPTIONS.filter((o) => o.moduleType === type).map((option) => option.id);
    const reviewerPages = pages.filter((page) => page.pageFor === relationship);
    return reviewerPages.some((page) => moduleIds.includes(page.moduleId));
}

export function checkForPages(relationship, checkForPages, pages) {
    const reviewerPages = pages.filter((page) => page.pageFor === relationship);
    return reviewerPages.some((page) => checkForPages.includes(page.moduleId));
}
