function createBccEmail(emails) {
    // ensure unique emails
    emails = [...new Set(emails)];
    // Initialize mailTo with bcc parameter
    let mailTo = "mailto:?bcc=";
    emails.forEach((email, index) => {
        mailTo += `${email}`;
        if (index < emails.length - 1) {
            mailTo += ",";
        }
    });
    window.open(mailTo, "_blank");
}

const emailManagers = (rows) => {
    let emails = rows
        .map((row) => {
            return row.managerEmail;
        })
        .filter((email) => !!email);

    createBccEmail(emails);
};

const emailEmployees = (rows) => {
    let emails = rows
        .map((row) => {
            return row.email;
        })
        .filter((email) => !!email);

    createBccEmail(emails);
};

const emailManagersAndEmployees = (rows) => {
    let emails = rows
        .map((row) => {
            const employeeEmail = row.email;
            const managerEmail = row.managerEmail;
            return [employeeEmail, managerEmail];
        })
        .flat()
        .filter((email) => !!email);

    createBccEmail(emails);
};

const emailIncomplete = (rows) => {
    let emails = rows
        .map((row) => {
            const employeeEmail = row.employeeRequired && !row.selfDone && row.email;
            const managerEmail = row.managerRequired && !row.managerDone && row.managerEmail;
            return [employeeEmail, managerEmail];
        })
        .flat()
        .filter((email) => !!email);

    createBccEmail(emails);
};

const createBulkEmail = (whoToEmail, employeeRows) => {
    switch (whoToEmail) {
        case "managers":
            emailManagers(employeeRows);
            break;
        case "everyone":
            emailManagersAndEmployees(employeeRows);
            break;
        case "incomplete":
            emailIncomplete(employeeRows);
            break;
        case "employees":
        default:
            emailEmployees(employeeRows);
            break;
    }
};

export { createBulkEmail };
