import { useEffect, useMemo } from "react";
import applogger from "../../../common/utils/applogger";
import { useBusinessUnitsListener } from "../../BusinessUnits/hooks/useBusinessUnitsListener";
import { useScheduleListener } from "../../Schedule/hooks/useScheduleListener";
import { useTalentListener } from "./useTalentListener";
import { useWorkspaceListener } from "./useWorkspaceListener";

export function useWorkspaceListeners() {
    const workspaceStatus = useWorkspaceListener();
    const talentStatus = useTalentListener();
    const businessUnitStatus = useBusinessUnitsListener();

    useScheduleListener();
    const allNecessaryLoaded = useMemo(() => {
        const all = [workspaceStatus, talentStatus, businessUnitStatus];
        return all.every((status) => status === "success");
    }, [workspaceStatus, talentStatus, businessUnitStatus]);

    // Timeout if the workspaces doesn't load within 15 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!allNecessaryLoaded) {
                applogger("Workspace timed out");
            }
        }, 15000);
        return () => clearTimeout(timer);
    }, [allNecessaryLoaded]);

    return allNecessaryLoaded;
}
