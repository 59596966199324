import { useMemo } from "react";

export function useReviewQueueCounts(activeSelfReviews = [], activeTeamReviews = [], activeReviews = []) {
    // Total Reviews
    const reviewCount = useMemo(() => {
        return activeSelfReviews.length + activeTeamReviews.length;
    }, [activeTeamReviews, activeSelfReviews]);

    // Action Required
    const actionRequiredCount = useMemo(() => {
        const ownReviews = activeSelfReviews.filter((review) => !review.selfDone);
        const teamReviews = activeTeamReviews.filter((review) => !review.managerDone);
        return ownReviews.length + teamReviews.length;
    }, [activeSelfReviews, activeTeamReviews]);

    // Waiting Others
    const waitingOthersCount = useMemo(() => {
        const ownReviews = activeSelfReviews.filter((review) => review.selfDone && !review.managerDone);
        const teamReviews = activeTeamReviews.filter((review) => review.managerDone && !review.selfDone);
        return ownReviews.length + teamReviews.length;
    }, [activeSelfReviews, activeTeamReviews]);

    // Complete
    const completeCount = useMemo(() => {
        const allReviews = [...activeSelfReviews, ...activeTeamReviews];
        const doneReviews = allReviews.filter((review) => review.selfDone && review.managerDone);
        return doneReviews.length;
    }, [activeSelfReviews, activeTeamReviews]);

    return { reviewCount, actionRequiredCount, waitingOthersCount, completeCount };
}
