import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import useLoggedNavigate from "../../../../common/hooks/useLoggedNavigate";
import applogger from "../../../../common/utils/applogger";
import { LoadingIndicator } from "../../../../components";
import { TeamIcon, UserIcon } from "../../../../components/Icons/MyIcons";
import MyDrawer from "../../../../components/MyComponents/Drawer";
import MySideMenu, { prepSideMenuItem } from "../../../../components/MyComponents/SideMenu";
import { useAssessmentListener } from "../../../../features/Employees/hooks/useAssessmentListener";
import { useEmployee } from "../../../../features/Employees/hooks/useEmployee";
import { useEmployeeListener } from "../../../../features/Employees/hooks/useEmployeeListener";
import { useEmployeesApi } from "../../../../features/Employees/hooks/useEmployeesApi";
import { useEmployeeRefs } from "../../../../features/Employees/refs/useEmployeeRefs";
import { usePaginatedForms } from "../../../../features/Forms/hooks/usePaginatedForms";
import { buildFormLink } from "../../../../features/Forms/utils/buildFormLinks";
import { useLiveObjectivesListener } from "../../../../features/Objectives/hooks/useLiveObjectivesListener";
import OrgNodeDetailsCard from "../../../../features/Organisation/components/OrgNodeDetailsCard";
import OrgNodeEditorSideArea from "../../../../features/Organisation/containers/OrgNodeEditorSideArea";
import { useSelectOrgNodes } from "../../../../features/Organisation/hooks/useSelectOrgNodes";
import { useRoleRelationships } from "../../../../features/Roles/hooks/useRoleRelationships";
import { useEmployeeScheduler } from "../../../../features/ScheduledActions/hooks/useEmployeeScheduler";
import { useSnapshots } from "../../../../features/Snapshots/hooks/useSnapshots";
import { useClaims } from "../../../../features/User/hooks/useClaims";
import { useIsThisMe } from "../../../../features/User/hooks/useIsThisMe";
import { useOwnData } from "../../../../features/User/hooks/useOwnData";
import { useIsWorkspaceReady } from "../../../../features/Workspace/hooks/useIsWorkspaceReady";
import { usePaginatedFetch } from "../../../../hooks/usePaginatedFetch";
import { usePathArray } from "../../../../hooks/usePathArray";
import { useToggle } from "../../../../hooks/useToggle";
import { deselectOrgNodes } from "../../../../redux/appSlice";
import { useOrgContext } from "../../OrgPage";
import { useSubordinates } from "../../../../features/Employees/hooks/useSubordinates";
import { useFetched } from "../../../../hooks/useFetched";

const OBJECTIVES_PER_PAGE = 8;

const employeePages = {
    id: "employee",
    title: "displayName",
    icon: UserIcon,
    pages: [
        {
            id: "overview",
            title: "Overview",
            requiresNotSelf: true,
        },
        { id: "objectives", title: "Objectives", requires: "objectives" },
        { id: "development-plan", title: "Development Plan", requiresNotSelf: true },
        { id: "history", title: "Review History" },
        { id: "comments", title: "Comments", requiresFalse: "hideCommentsTab" },
    ],
};

const rolePages = {
    id: "role",
    title: "Role",
    icon: TeamIcon,
    requiresChildren: true,
    pages: [
        { id: "role-overview", title: "Role Overview" },
        { id: "talent-map", title: "Talent Map" },
    ],
};

const OrgNodeContext = createContext();

export function useOrgNodeContext() {
    return useContext(OrgNodeContext);
}

const useSideMenuPages = ({ employeeId, roleId, activeEmployee, employeeLinkPrefix }) => {
    const { getRolePath } = useSelectOrgNodes();
    const roleLinkPrefix = useMemo(() => getRolePath(roleId), [getRolePath, roleId]);
    return useMemo(() => {
        const fieldOverrides = { employee: activeEmployee?.displayName };
        const preppedEmployeePages = prepSideMenuItem(employeePages, employeeLinkPrefix, fieldOverrides);
        const preppedRolePages = prepSideMenuItem(rolePages, roleLinkPrefix, fieldOverrides);
        let newPages = [];
        if (employeeId) {
            newPages.push(preppedEmployeePages);
        }
        if (roleId) {
            newPages.push(preppedRolePages);
        }
        return newPages;
    }, [employeeId, roleId, activeEmployee, employeeLinkPrefix, roleLinkPrefix]);
};

const useOrgNodeListeners = (employeeId) => {
    const [activeEmployee, setEmployee] = useState({});
    const [selfAssessment, setSelfAss] = useState({});
    const [liveObjectives, setLiveObjectives] = useState(null);
    const isSelf = useIsThisMe(employeeId);
    const employeeStatus = useEmployeeListener(employeeId, setEmployee, !isSelf);
    const selfAssessmentStatus = useAssessmentListener(employeeId, setSelfAss, "self");
    const objectivesStatus = useLiveObjectivesListener(employeeId, setLiveObjectives, !isSelf);
    const listenersReady = useMemo(() => {
        if (isSelf) return true;
        const allTrue = [employeeStatus, selfAssessmentStatus, objectivesStatus];
        return allTrue.every((bool) => bool);
    }, [isSelf, employeeStatus, selfAssessmentStatus, objectivesStatus]);
    return { listenersReady, activeEmployee, selfAssessment, liveObjectives };
};

const OrgNodePage = ({ employeeId, roleId, formMode }) => {
    const navigate = useLoggedNavigate();
    const pathArray = usePathArray();
    const { navigateToOrgNode } = useOrgContext();
    const { preppedEmployee } = useEmployee(employeeId);
    const [showEditRole, toggleEditRole] = useToggle();
    const [showEditEmployee, toggleEditEmployee] = useToggle();
    const isSelf = useIsThisMe(employeeId);
    const subpage = pathArray[pathArray.length - 1];
    const { isAdmin } = useClaims();
    const snapshot = useSelector((state) => state.snapshots.employeeSnapshots[employeeId]);
    const { saveObjective, removeObjective, saveNote, saveAssessment, updateEmployee } = useEmployeesApi();
    const { getEmployeePath } = useSelectOrgNodes();
    const { getChildRoleIds, getSubDepartments } = useRoleRelationships();
    const { scheduleActions, completeActions, cancelActions } = useEmployeeScheduler(employeeId);
    const { getFilteredSnapshots } = useSnapshots();
    const subordinates = useSubordinates(roleId, false);
    const [fetchTrait] = useFetched("traits");

    // listen and prep org node data
    const ownOrgData = useOwnData();
    const listenedOrgNodeData = useOrgNodeListeners(employeeId);
    const orgNodeData = isSelf ? ownOrgData : listenedOrgNodeData;
    const { activeEmployee, selfAssessment, managerAssessment, liveObjectives } = orgNodeData;
    const { listenersReady } = listenedOrgNodeData;

    // setup fetchers for form and objectives
    const revieweeId = employeeId;
    const { completeObjectivesRef } = useEmployeeRefs(employeeId);
    const objectiveFetcher = usePaginatedFetch(completeObjectivesRef, OBJECTIVES_PER_PAGE);
    const formFetcher = usePaginatedForms({ revieweeId, isComplete: true });

    const childRoleIds = useMemo(() => {
        if (!roleId) return [];
        return getChildRoleIds(roleId) || [];
    }, [roleId, getChildRoleIds]);

    const hasChildren = useMemo(() => childRoleIds.length > 0, [childRoleIds]);

    const employeeLinkPrefix = useMemo(() => getEmployeePath(employeeId), [getEmployeePath, employeeId]);

    const pages = useSideMenuPages({ employeeId, roleId, isSelf, activeEmployee, employeeLinkPrefix });

    const handleUnauthorised = () => {
        applogger.error("Unauthorised access - Navigating to organisation page");
        navigate("/organisation");
    };

    const handleUpdateEmployee = (update, batch) => {
        batch = updateEmployee(employeeId, update, batch);
        return batch;
    };

    const handleScheduleActions = (actionIds = [], batch) => {
        actionIds = Array.isArray(actionIds) ? actionIds : [actionIds];
        const alreadyScheduled = activeEmployee?.scheduledActionIds || [];
        const actionIdsToSchedule = actionIds.filter((id) => !alreadyScheduled.includes(id));
        if (actionIdsToSchedule.length === 0) return batch;
        batch = scheduleActions(actionIdsToSchedule, batch);
        return batch;
    };

    const handleSaveObjective = (objective, iterationId, batch) => {
        batch = saveObjective(employeeId, objective, iterationId, batch);
        return batch;
    };

    const handleRemoveObjective = (objectiveId, batch) => {
        batch = removeObjective(employeeId, objectiveId, batch);
        return batch;
    };

    function handleSaveAssessment(assessmentId, assessment, batch) {
        batch = saveAssessment(employeeId, assessmentId, assessment, batch);
        return batch;
    }

    function handleSaveNote(note, batch) {
        batch = saveNote(employeeId, note, batch);
        return batch;
    }

    const handleViewReview = (item) => {
        const { employeeId, cycleId, iterationId } = item;
        const link = buildFormLink(employeeId, cycleId, iterationId);
        navigate(link);
    };

    function handleCloseSideArea() {
        toggleEditRole(false);
        toggleEditEmployee(false);
    }

    const value = {
        isSelf,
        isAdmin,
        employeeId,
        roleId,
        currentScheduledActionIds: activeEmployee?.scheduledActionIds || [],
        completedActionIds: activeEmployee?.completedActionIds || [],
        preppedEmployee,
        activeEmployee,
        managerAssessment: { ratings: snapshot?.ratings || {}, flags: snapshot?.flags || {} },
        selfAssessment,
        employeePath: employeeLinkPrefix,
        liveObjectives,
        snapshot,
        childRoleIds,
        navigateToOrgNode,
        objectiveFetcher,
        formFetcher,
        subordinates,
        fetchTrait,
        onViewReview: handleViewReview,
        getFilteredSnapshots,
        getSubDepartments,
        onCompleteActions: completeActions,
        onCancelActions: cancelActions,
        onScheduleActions: handleScheduleActions,
        onUpdateEmployee: handleUpdateEmployee,
        onSaveObjective: handleSaveObjective,
        onSaveAssessment: handleSaveAssessment,
        onSaveNote: handleSaveNote,
        onRemoveObjective: handleRemoveObjective,
        onUnauthorised: handleUnauthorised,
    };

    if (!listenersReady) return <LoadingIndicator />;

    if (formMode) {
        return <OrgNodeContext.Provider value={value}>{<Outlet />}</OrgNodeContext.Provider>;
    }

    return (
        <div className="flex-1 w-full flex items-stretch overflow-hidden">
            <OrgNodeContext.Provider value={value}>
                <MyDrawer
                    setIsOpen={handleCloseSideArea}
                    isOpen={showEditEmployee || showEditRole}
                    className="h-full flex"
                    contentClassName="flex-1"
                    cont
                    drawerEnd={false}
                    noClickClose={true}
                    side={
                        <OrgNodeEditorSideArea
                            showEditEmployee={showEditEmployee}
                            showEditRole={showEditRole}
                            onClose={handleCloseSideArea}
                        />
                    }
                >
                    <div className="flex flex-1 overflow-auto">
                        <div className="flex-1 border-r flex flex-col max-w-80 bg-base-100">
                            <MySideMenu isSelf={isSelf} hasChildren={hasChildren} pages={pages} activePageId={subpage}>
                                <OrgNodeDetailsCard
                                    showActions
                                    onToggleEditEmployee={toggleEditEmployee}
                                    onToggleEditRole={toggleEditRole}
                                    className="p-3 space-y-3 bg-base-100"
                                />
                            </MySideMenu>
                        </div>
                        <div className="flex-3 flex flex-col items-stretch overflow-hidden">
                            <Outlet />
                        </div>
                    </div>
                </MyDrawer>
            </OrgNodeContext.Provider>
        </div>
    );
};

const withPrepareNode = (Component) => {
    const WithPrepareNode = (props) => {
        const params = useParams();
        const location = useLocation();
        const { roleId, employeeId } = params;
        const query = new URLSearchParams(location.search);
        const cycleId = query.get("cycle");
        const iterationId = query.get("iteration");
        const formMode = cycleId || iterationId;
        const dispatch = useDispatch();
        const isWorkspaceReady = useIsWorkspaceReady();
        const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
        const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
        const { selectEmployee, selectRole } = useOrgContext();

        // Deselect the org nodes when unmounting
        useEffect(() => {
            return () => {
                dispatch(deselectOrgNodes());
            };
        }, [dispatch]);

        // Ensure the correct entities are selected
        useEffect(() => {
            if (roleId) {
                selectRole(roleId);
            }
            if (employeeId) {
                selectEmployee(employeeId);
            }
        }, [selectRole, selectEmployee, roleId, employeeId]);

        const pageReady = useMemo(() => {
            const allTrue = [isWorkspaceReady];
            return allTrue.every((bool) => bool);
        }, [isWorkspaceReady]);

        if (!pageReady) return <LoadingIndicator />;
        return <Component {...props} formMode={formMode} employeeId={selectedEmployeeId} roleId={selectedRoleId} />;
    };

    return WithPrepareNode;
};

export default withPrepareNode(OrgNodePage);
