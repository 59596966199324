import { limit, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCollectionGroupRef } from "../../../firebase/firebaseActions";
import { usePaginatedFetch } from "../../../hooks/usePaginatedFetch";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";

export function usePaginatedForms({ reviewerId, revieweeId, docsPerPage = 10 }) {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const { formsRef } = useEmployeeRefs(revieweeId);

    const formsCollectionRef = useMemo(() => {
        return getCollectionGroupRef("forms");
    }, []);

    const initialFormsRef = useMemo(() => {
        if (!reviewerId && !revieweeId) return null;

        if (!reviewerId && revieweeId) {
            // The query is only for a single user, user their own forms colletions
            return query(
                formsRef,
                where("workspaceId", "==", workspaceId),
                where("completedOn", "!=", null),
                orderBy("completedOn", "desc"),
                limit(docsPerPage)
            );
        } else {
            // Requires a collection group query spanning all user forms collections
            const participantConditions = [];
            if (reviewerId) participantConditions.push(where("reviewerId", "==", reviewerId));
            if (revieweeId) participantConditions.push(where("revieweeId", "==", revieweeId));
            const formsQuery = query(
                formsCollectionRef,
                ...participantConditions,
                where("workspaceId", "==", workspaceId),
                where("completedOn", "!=", null),
                orderBy("completedOn", "desc"),
                limit(docsPerPage)
            );
            return formsQuery;
        }
    }, [formsCollectionRef, formsRef, workspaceId, docsPerPage, reviewerId, revieweeId]);

    const [forms, fetchMore, hasMore, fetchStatus] = usePaginatedFetch(initialFormsRef, docsPerPage);

    return {
        forms,
        fetchMore,
        hasMore,
        fetchStatus,
    };
}
