import { calculateOverallRating } from "../../Snapshots/utils/snapshotHelpers";

export const DEFAULT_LEVELS = [
    { id: "entry", label: "Entry-Level", defaultThreshold: 25 },
    { id: "mid", label: "Mid-Level", defaultThreshold: 75 },
    { id: "senior", label: "Senior", defaultThreshold: 100 },
];

export const DEFAULT_FIRST_LEVEL = DEFAULT_LEVELS[0];

export function getThreshold(traitId, level) {
    const { thresholds = {}, defaultThreshold } = level;
    const hasCustom = Object.keys(thresholds).includes(traitId);
    return hasCustom ? thresholds?.[traitId] : defaultThreshold;
}

export function checkUsingDefaults(traitId, level) {
    const { thresholds = {} } = level;
    return !thresholds?.[traitId];
}

export function hasPassedThreshold(traitId, level, rating) {
    const threshold = getThreshold(traitId, level);
    return rating >= threshold;
}

export function hasEmployeePassedLevel(ratings = {}, linkedTraitIds, level = DEFAULT_FIRST_LEVEL) {
    return linkedTraitIds.every((traitId) => {
        const rating = ratings[traitId];
        return hasPassedThreshold(traitId, level, rating);
    });
}

export function checkHasBoard(employee, boardId, auto) {
    switch (auto) {
        case "all":
            return true;
        case "managers":
            return employee.isManager;
        default:
            const { linkedTalentBoardIds = [] } = employee;
            return linkedTalentBoardIds.includes(boardId);
    }
}

export function getBoardStatus(employee, boardId, auto, levels, levelPasses) {
    const { boardLevels = {} } = employee;
    const assignedLevel = parseInt(boardLevels[boardId] || 1);
    const levelCount = levels.length;
    const hasBoard = checkHasBoard(employee, boardId, auto);
    if (!hasBoard && levelPasses === levelCount) {
        return "Could excel in role";
    } else if (!hasBoard && levelPasses > 0) {
        return "Suited to Role";
    } else if (!hasBoard && levelPasses === 0) {
        return "Not Suitable";
    } else if (hasBoard && levelPasses < assignedLevel) {
        return "Falling Short";
    } else if (hasBoard && levelPasses === assignedLevel) {
        return "Well Suited to Level";
    } else if (hasBoard && levelPasses > assignedLevel) {
        return "Ready to Promote";
    } else {
        return "Unknown";
    }
}

export function calculateTalentBoardRatings(thisEmployee, thisSnapshot, talentBoards, traits) {
    let boardRatings = {};
    const { ratings } = thisSnapshot;
    const { linkedTalentBoardIds = [] } = thisEmployee || {};
    for (let boardId of linkedTalentBoardIds) {
        const talentBoard = talentBoards[boardId];
        if (!talentBoard) continue;
        const { linkedTraitIds = [], levels = DEFAULT_LEVELS } = talentBoard;
        let levelPasses = 0;
        for (let level of levels) {
            const passed = hasEmployeePassedLevel(ratings, linkedTraitIds, level);
            if (passed) {
                levelPasses++;
            } else {
                break; // do not evaluate further levels once one fails
            }
        }
        const overallRating = calculateOverallRating(ratings, linkedTraitIds, traits);
        boardRatings[talentBoard.id] = {
            overall: overallRating,
            levelPasses: levelPasses,
        };
    }
    return boardRatings;
}
