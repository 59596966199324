import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { FaWpforms } from "react-icons/fa";
import Each from "../../../../common/components/molecules/Each/Each";
import { formatDate } from "../../../../common/utils/dateUtils";
import { LockIcon, LockOpenIcon } from "../../../../components/Icons/MyIcons";
import { useEmployee } from "../../../Employees/hooks/useEmployee";
import { useLiveCycles } from "../../../Schedule/hooks/useLiveCycles";
import { Button } from "../../../../common/components";

const EditBadge = ({ isActive }) => {
    return isActive ? <LockIcon /> : <LockOpenIcon className="text-success" />;
};

const FormEntry = ({ form, userEmployeeId, getLiveIterationId, onClick }) => {
    const { revieweeId, reviewerId, completedOn, cycleName, iterationId, cycleId } = form;
    const { preppedEmployee: reviewee } = useEmployee(revieweeId);
    const { preppedEmployee: reviewer } = useEmployee(reviewerId);
    const handleClick = () => {
        onClick(form);
    };
    const updateDate = formatDate(completedOn);

    const isActive = useMemo(() => {
        const currentIterationId = getLiveIterationId(cycleId);
        return currentIterationId === iterationId;
    }, [cycleId, iterationId, getLiveIterationId]);

    const colorClass = isActive ? "primary" : "success";

    function renderLabel() {
        const reviewerUser = userEmployeeId === reviewerId;
        const revieweeIsUser = userEmployeeId === revieweeId;
        const isSelfAssessment = reviewerId === revieweeId;
        const revieweeName = reviewee?.displayName || "Unknown Reviewee";
        const reviewerName = reviewer?.displayName || "Unknown Reviewer";
        if (reviewerUser) {
            return isSelfAssessment ? "Your Self Assessment" : `Your Review of ${revieweeName}`;
        }

        if (revieweeIsUser) {
            return isSelfAssessment ? "Your Self Assessment" : `Your Review of ${reviewerName}`;
        } else {
            return isSelfAssessment
                ? `Self Assessment by ${revieweeName}`
                : `Review of ${revieweeName} by ${reviewerName}`;
        }
    }

    return (
        <div onClick={handleClick} className="p-4 bg-white flex border-b space-x-2 cursor-pointer">
            <div
                className={classNames(
                    "items-center flex px-3 border rounded-full aspect-square",
                    `border-${colorClass}`,
                    `text-${colorClass}`
                )}
            >
                <FaWpforms size={22} />
            </div>
            <div className="flex-1 px-2 flex flex-col justify-between">
                <h5 className="font-bold">{cycleName}</h5>
                <h6>{renderLabel()}</h6>
            </div>
            <div className="flex flex-col justify-end items-end space-y-1">
                <EditBadge isActive={isActive} />
                <div className="date flex flex-col justify-end px-1">{updateDate}</div>
            </div>
        </div>
    );
};

const FormList = ({ userEmployeeId, forms, fetchMore, fetchStatus, hasMore, onItemClick }) => {
    const { getLiveIterationId } = useLiveCycles();
    const endOfListRef = useRef(null);

    const handleFetchMore = () => {
        fetchMore();
        endOfListRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="h-full flex flex-col overflow-hidden">
            <div className="border-t flex-1 flex flex-col overflow-scroll">
                <Each
                    of={forms}
                    render={(form) => (
                        <FormEntry
                            form={form}
                            userEmployeeId={userEmployeeId}
                            getLiveIterationId={getLiveIterationId}
                            onClick={onItemClick}
                        />
                    )}
                />
            </div>
            <div ref={endOfListRef} className="flex justify-center pb-4">
                {!hasMore ? null : (
                    <Button onClick={handleFetchMore} disabled={fetchStatus === "loading" || !hasMore}>
                        {fetchStatus === "loading" ? "Loading..." : "Load More"}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default FormList;
