import { IdPicker } from "../../../../components";
import MyInputGroup from "../../../../components/MyComponents/InputGroup";
import MyTextInput from "../../../../components/MyComponents/TextInput";
import { DEFAULT_ROLE_LEVELS, FIELD_SIZE_LIMITS } from "../../../../constants";
import EmployeePicker from "../../../Employees/components/EmployeePicker";
import TalentBoardPicker from "../../../TalentBoards/components/atoms/TalentBoardPicker";

const DEPARTMENT_HELP =
    "Roles are automatically placed in all departments above them in the Org Chart. You can make this Role the head of a new department by entering a new department name here.";

const RoleEditorTemplate = ({
    isAdmin,
    isSelf,
    parentDepartments,
    editedRole,
    updateField,
    manualBoards,
    autoBoards,
    changeBoards,
}) => {
    const handleUpdateRole = (updateType, newVal) => {
        updateField(updateType, newVal);
    };

    const handleChangeBoards = (boardIds) => {
        changeBoards(boardIds);
    };

    const departmentString = parentDepartments.join(", ");

    return (
        <div className="pt-2 space-y-2">
            <MyInputGroup label="Job Title" name="jobTitle" placeholder="required" required>
                <MyTextInput
                    maxLength={FIELD_SIZE_LIMITS.JOB_TITLE}
                    onChange={handleUpdateRole}
                    value={editedRole.jobTitle || ""}
                    autoComplete="off"
                />
            </MyInputGroup>
            <MyInputGroup
                label="Department"
                name="department"
                placeholder={departmentString}
                description={DEPARTMENT_HELP}
            >
                <MyTextInput
                    maxLength={FIELD_SIZE_LIMITS.GENERIC_NAME}
                    onChange={handleUpdateRole}
                    disabled={!isAdmin}
                    value={editedRole.department || ""}
                    autoComplete="off"
                />
            </MyInputGroup>
            <MyInputGroup label="Assigned Talent Boards" name="linkedTalentBoardIds">
                <TalentBoardPicker
                    autoType="manual"
                    isMulti
                    disabled={!isAdmin}
                    selectedIds={manualBoards}
                    onChange={handleChangeBoards}
                />
            </MyInputGroup>
            <MyInputGroup label="Auto-Assigned Talent Boards" name="autoLinkedTalentBoardIds">
                <TalentBoardPicker autoType="allAuto" isMulti disabled selectedIds={autoBoards} onChange={() => {}} />
            </MyInputGroup>
            <MyInputGroup hidden={!isAdmin} label="Default Bench Level" name="level" required>
                <IdPicker
                    options={DEFAULT_ROLE_LEVELS}
                    selectedIds={editedRole.level}
                    onChange={(level) => handleUpdateRole("level", level)}
                />
            </MyInputGroup>
            <MyInputGroup hidden={!isAdmin || isSelf} label="Potential Successors" name="successorIds" required>
                <EmployeePicker
                    isMulti
                    excludeIds={[editedRole.incumbentId]}
                    selectedIds={editedRole.successorIds}
                    onChange={(successorIds) => handleUpdateRole("successorIds", successorIds)}
                />
            </MyInputGroup>
        </div>
    );
};

export default RoleEditorTemplate;
