import React from "react";
import MyRange from "../../../../components/MyComponents/Range";
import { CONFIDENCE_LABELS } from "../../../../constants";

const ConfidenceSlider = ({ confidence = 0, onChange, size = "xs", color = "primary", disabled }) => {
    const confidenceLabel = CONFIDENCE_LABELS[confidence] || "Confidence not set";

    const handleClick = (e) => {
        if (!confidence && confidence !== 0) {
            onChange(1);
        }
    };
    return (
        <div className="flex-1 space-y-2" onClick={handleClick}>
            <h6 className="text-sm text-base-800 tracking-tight font-medium">Confidence</h6>
            <MyRange
                disabled={disabled}
                hideVal
                min={0}
                max={100}
                step={25}
                value={confidence}
                size={size}
                color={color}
                showSteps
                onChange={onChange}
            />
            <div className="tracking-tight text-center font-medium text-sm">{confidenceLabel}</div>
        </div>
    );
};

export default ConfidenceSlider;
