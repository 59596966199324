import classNames from "classnames";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Input, Textarea } from "react-daisyui";

const tempDefaultProps = {
    type: "text",
    label: "label missing",
    className: "text-sm h-10",
    onChange: () => {},
    onBlur: () => {},
};

const MyTextInput = (props) => {
    const mergedProps = { ...tempDefaultProps, ...props };
    const {
        value,
        className,
        invalid,
        forId,
        color,
        hideBorder,
        hideFocusBorder,
        onChange,
        onBlur,
        textArea,
        maxLength,
        min,
        max,
        ...cleanProps
    } = mergedProps;
    const [unblurredVal, setUnblurredVal] = useState(value);
    const debouncedUpdateRef = useRef();

    if (!debouncedUpdateRef.current) {
        debouncedUpdateRef.current = debounce((field, val) => {
            onChange(field, val);
        }, 500);
    }

    useEffect(() => {
        const withinLimit = Math.max(min, Math.min(max, value));
        setUnblurredVal(isNaN(withinLimit) ? value : withinLimit);
    }, [value, min, max]);

    const handleChange = (e) => {
        if (mergedProps.readOnly) return;
        let newVal = e.target.value;
        // When there's a max length; enforce it
        if (maxLength && newVal.length > maxLength) {
            newVal = newVal.slice(0, maxLength);
        }
        setUnblurredVal(newVal);
        debouncedUpdateRef.current(mergedProps.name, newVal);
    };

    const handleBlur = (e) => {
        let newVal = e.target.value;
        const isNumber = typeof value === "number";
        if (isNumber) {
            // When there's a min or max value; enforce it
            newVal = Math.max(min, Math.min(max, newVal));
        } else if (maxLength && newVal.length > maxLength) {
            // When there's a max length; enforce it
            newVal = newVal.slice(0, maxLength);
        }
        setUnblurredVal(newVal);
        debouncedUpdateRef.current.cancel();
        onChange(mergedProps.name, newVal);
        onBlur(mergedProps.name, newVal);
    };

    const preppedProps = {
        ...cleanProps,
        id: forId || mergedProps.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""),
        color: !invalid ? "neutral" : "error",
        bordered: !hideBorder,
        "aria-describedby": mergedProps.placeholder,
        onChange: handleChange,
        onBlur: handleBlur,
        value: unblurredVal === undefined ? "" : unblurredVal,
    };

    if (textArea) {
        return (
            <Textarea
                {...preppedProps}
                className={classNames(className, {
                    "focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none": hideFocusBorder,
                })}
            />
        );
    } else {
        return (
            <Input
                {...preppedProps}
                className={classNames(className, {
                    "focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none": hideFocusBorder,
                })}
            />
        );
    }
};

MyTextInput.propTypes = {
    type: PropTypes.string,
    inputOnly: PropTypes.bool,
    textArea: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    hideBorder: PropTypes.bool,
    hideFocusBorder: PropTypes.bool,
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default MyTextInput;
