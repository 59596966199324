import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import { checkIfFirestoreRef } from "../../../firebase/utils/identifyFirestoreRef";
import setEntireWorkspaceThunk from "../../Workspace/thunks/setEntireWorkspaceThunk";
import { useBusinessUnitRefs } from "../refs/useBusinessUnitRefs";

export function useParentMapListener() {
    const dispatch = useDispatch();
    const { parentMapRef } = useBusinessUnitRefs();

    const shouldListen = useMemo(() => {
        return checkIfFirestoreRef(parentMapRef);
    }, [parentMapRef]);

    const handleDataReceived = useCallback(
        (doc) => {
            const parentMap = doc.data() || {};
            dispatch(setEntireWorkspaceThunk({ parentMap }));
        },
        [dispatch]
    );

    return useListener(parentMapRef, handleDataReceived, shouldListen);
}
