import { useSelector } from "react-redux";

export function useClaims() {
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const claims = useSelector((state) => state.user.claims);
    const topLevelAccess = claims?.topLevelAccess;
    const userRole = claims?.userRole;
    const isDeveloper = claims?.developer;
    const isAdmin = userRole === "admin" || userRole === "owner" || isDeveloper;
    const canCreateAdmins = claims.canCreateAdmins;
    const userRoleId = claims.roleId

    return { topLevelAccess, userEmployeeId, userRole, userRoleId, isAdmin, isDeveloper, canCreateAdmins };
}
