import React, { useMemo } from "react";
import HorizontalBarChart from "../../../../components/Charts/BaseCharts/HorizontalBarChart";
import ChartContainer from "../../../../components/Charts/ChartContainer";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";
import { OBJECTIVE_BLOCKER_OPTIONS } from "../../../../constants";
import { sortArrayOfObjects } from "../../../../helpers/helpers";

const BlockersBarChart = ({ blockers, showXLabels }) => {
    const sortedChartData = useMemo(() => {
        const data = Object.entries(blockers).map(([id, value]) => {
            const fullBlocker = OBJECTIVE_BLOCKER_OPTIONS.find((option) => option.id === id);
            return {
                label: fullBlocker?.label || id,
                value: Array.isArray(value) ? value.length : value,
                employeeIds: value,
                id,
            };
        });
        return sortArrayOfObjects(data, "value", "desc");
    }, [blockers]);

    const totalBlockerCount = sortedChartData.length;
    if (!totalBlockerCount)
        return (
            <div className="h-80 border rounded-lg flex justify-center items-center text-base-500">
                <h6>No Blockers</h6>
            </div>
        );

    return (
        <div className="flex-1 flex flex-col">
            <ChartContainer className="flex-1" responsiveHeight>
                <HorizontalBarChart data={sortedChartData} />
            </ChartContainer>
        </div>
    );
};

export default BlockersBarChart;
