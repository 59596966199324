import { withFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SELECT_EMPLOYEE } from "../../../../../redux/appSlice";
import { useLanguage } from "../../../../../translations/LanguageContext";
import { useBusinessUnits } from "../../../../BusinessUnits/hooks/useBusinessUnits";
import { FILL_ROLE } from "../../../../BusinessUnits/utils/businessUnitSlice";
import { useCustomFields } from "../../../../CustomFields/hooks/useCustomFields";
import { useEmployees } from "../../../../Employees/hooks/useEmployees";
import { useEmployeesApi } from "../../../../Employees/hooks/useEmployeesApi";
import { useRoles } from "../../../hooks/useRoles";
import CreateOrFillRoleInnerForm from "./CreateOrFillRoleForm";
import { useBusinessUnitsApi } from "../../../../BusinessUnits/hooks/useBusinessUnitApi";

const CreateOrFillRoleForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isOpen: props.isOpen,
            fillVacantRole: props.fillVacantRole,
            incumbentId: props.selectedRole && props.selectedRole.incumbentId,
            isWaiting: props.isWaiting,
            headerText: props.headerText,
            existingEmployee: false,
            existingEmail: false,
        };
    },
    mapPropsToValues: (props) => {
        return {
            ...props.defaultRoleValues,
            employeeNumberInUse: false,
        };
    },
    onSubmit: (formValues, { props }) => {
        props.onSubmit(formValues);
    },
    enableReinitialize: true,
})(CreateOrFillRoleInnerForm);

const FillRole = (props) => {
    const dispatch = useDispatch();
    const { appLanguage } = useLanguage();
    const language = appLanguage("forms", "create_or_fill_role");
    const { customEmployeeFields } = useCustomFields();
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const headerText = language.fill_role;
    const { getNewEmployeeId } = useEmployees();
    const { updateEmployee, createEmployee } = useEmployeesApi();
    const { buildBoardMapUpdate } = useBusinessUnits();
    const { updateBoardMap } = useBusinessUnitsApi();
    const { updateRole, getRole } = useRoles();
    const selectedRole = getRole(selectedRoleId);
    const parentRoleId = selectedRole.parentRoleId;

    const handleSubmit = async (formValues) => {
        let {
            customClaims,
            existingEmployeeId,
            jobTitle,
            employeeNumberInUse,
            talentBoardIds,
            emailInUse,
            unitDocId,
            ...employeeFormValues
        } = formValues;

        // Use the existing employee id or create a new one
        const employeeId = existingEmployeeId || getNewEmployeeId();

        // Update the role
        let roleUpdate = {
            jobTitle: jobTitle,
            incumbentId: employeeId,
        };
        let batch = updateRole(selectedRoleId, roleUpdate);

        // update the employee
        if (existingEmployeeId) {
            // updating a current employee
            const employeeUpdate = {
                isUser: false,
                inviteAccepted: false,
                userRole: "manager",
                customClaims: {},
                roleId: selectedRoleId,
                parentRoleId,
            };
            batch = updateEmployee(existingEmployeeId, employeeUpdate, batch);
        } else {
            // creating a new employee
            const newEmployee = { ...employeeFormValues, roleId: selectedRoleId, parentRoleId };
            batch = createEmployee(employeeId, newEmployee, batch);
        }

        // Update the talent board map
        let boardMapUpdate = buildBoardMapUpdate(selectedRoleId, talentBoardIds);
        if (Object.keys(boardMapUpdate).length > 0) {
            batch = updateBoardMap(boardMapUpdate, batch);
        }
        try {
            await batch.commit();
            let selectThese = {
                employeeId: employeeId,
            };
            dispatch({ type: FILL_ROLE, payload: [selectedRoleId, employeeId] });
            dispatch({ type: SELECT_EMPLOYEE, payload: selectThese });

            props.toggle();
        } catch (error) {
            console.error("Error filling role", error);
        }
    };

    return (
        <CreateOrFillRoleForm
            hideIncumbentStep
            isOpen={props.isOpen}
            toggle={props.toggle}
            language={language}
            customEmployeeFields={customEmployeeFields}
            onSubmit={handleSubmit}
            headerText={headerText}
            excludeVacant
            defaultRoleValues={selectedRole}
        />
    );
};

export default FillRole;
