import { useEffect, useMemo } from "react";
import { FaHome } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "../../../../common/components/atoms/Select/Select";
import { useOrgContext } from "../../../../pages/organisation/OrgPage";
import { useFilteredRoles } from "../../../Roles/hooks/useFilteredRoles";
import { useRoleOptions } from "../../../Roles/hooks/useRoleOptions";

const OrgTopRolePicker = () => {
    const { activeTab, userRoleId, rootRoleId, topLevelRoleId, highestAllowedRoleId, setTopLevelRoleId } =
        useOrgContext();
    const workspaceName = useSelector((state) => state.workspace.workspaceName);
    const rolesForOrgChartAndTable = useFilteredRoles({ baseRoleId: rootRoleId });
    const rolesForMap = useFilteredRoles({
        baseRoleId: highestAllowedRoleId,
        excludeIds: [userRoleId],
    });

    // Determine the roles to include
    const includedRoles = useMemo(() => {
        switch (activeTab) {
            case "table":
            case "chart":
                return rolesForOrgChartAndTable;
            case "map":
                return rolesForMap;
            default:
                return [];
        }
    }, [activeTab, rolesForOrgChartAndTable, rolesForMap]);

    const roleOptions = useRoleOptions({ includedRoles, shortLabel: true });

    // Change the selected role to the base role when on talent map
    useEffect(() => {
        if (activeTab === "map") {
            // CHeck if the currently selected role is the user's branch
            const roleIds = rolesForMap.map((role) => role.id);
            if (!roleIds.includes(topLevelRoleId)) {
                setTopLevelRoleId(highestAllowedRoleId);
            }
        }
    }, [activeTab, rolesForMap, highestAllowedRoleId, setTopLevelRoleId, topLevelRoleId]);

    const isClearable = useMemo(() => {
        if (!topLevelRoleId) return false;
        if (topLevelRoleId === rootRoleId) return false;
        return true;
    }, [topLevelRoleId, rootRoleId]);

    const handleChangeTopLevelRole = (roleId) => {
        const defaultRole = activeTab === "map" ? highestAllowedRoleId : rootRoleId;
        setTopLevelRoleId(roleId || defaultRole);
    };

    const handleGoToSelf = () => {
        if (topLevelRoleId === userRoleId) return;
        if (!userRoleId) return;
        setTopLevelRoleId(userRoleId);
    };

    return (
        <div className="rounded flex items-center justify-stretch border">
            {userRoleId && (
                <div className="hover:text-primary hover:bg-base-300 cursor-pointer px-2" onClick={handleGoToSelf}>
                    <FaHome size={16} />
                </div>
            )}
            <Select
                isClearable={isClearable}
                options={roleOptions}
                selectedIds={topLevelRoleId}
                placeholder={workspaceName}
                onChange={handleChangeTopLevelRole}
            />
        </div>
    );
};

export default OrgTopRolePicker;
