import { useState, useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

export function useDontShrink() {
    const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const [minWidth, setMinWidth] = useState(0);
    const [minHeight, setMinHeight] = useState(0);

    useEffect(() => {
        if (width > minWidth) {
            setMinWidth(width);
        }
        if (height > minHeight) {
            setMinHeight(height);
        }
    }, [height, width, minHeight, minWidth]);

    useEffect(() => {
        let resizeTimeout;
        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                setMinWidth(0);
                setMinHeight(0);
            }, 200);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return { ref, height, minWidth, minHeight };
}
