import React from "react";

const withIndicator = (Wrapped) => {
    const WithIndicator = ({ count, ...props }) => {
        return (
            <div className="indicator">
                {count > 0 && <span className="indicator-item badge badge-primary">{count}</span>}
                <Wrapped {...props} />
            </div>
        );
    };

    // Set display name for better DevTools readability
    WithIndicator.displayName = `withIndicator(${Wrapped.displayName || Wrapped.name || 'Component'})`;

    return WithIndicator;
};

export default withIndicator;

