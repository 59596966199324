export function buildReviewStatusValues(userEmployeeId, entry, relationship) {
    const { employeeId, managerId, selfDone, managerDone, employeeRequired, managerRequired, hasChanged } = entry;
    let label = "";
    let color = "";
    let actionRequired = false;
    const userIsManager = userEmployeeId === managerId;
    const userIsEmployee = userEmployeeId === employeeId;
    const twoParty = employeeRequired && managerRequired;
    const selfDoneOrNotNeeded = !employeeRequired || selfDone;
    const managerDoneOrNotNeeded = !managerRequired || managerDone;
    const allDone = selfDoneOrNotNeeded && managerDoneOrNotNeeded;
    const userIsParticipant = userIsManager || userIsEmployee;

    if (allDone && userIsParticipant && hasChanged) {
        // User is a participant in a completed form that their counterpart has updated
        if (hasChanged && userIsParticipant) {
            label = `Complete - (Updated)`;
            color = "success";
            return { label, color, actionRequired };
        }
    }

    if (allDone) {
        // All parties have completed the form
        label = "Complete";
        color = "success";
        actionRequired = false;
        return { label, color, actionRequired };
    } else {
        if (twoParty) {
            // Manager and employee both need to complete the form
            if (!selfDone && !managerDone) {
                // Neither have completed the form
                label = userIsParticipant ? "Awaiting your Response" : "Awaiting Responses";
                color = userIsParticipant ? "primary" : "secondary";
                actionRequired = userIsParticipant;
            } else if (selfDone && !managerDone) {
                // The Manager has not completed the form
                label = userIsManager ? "Awaiting your Response" : "Awaiting Manager";
                color = userIsManager ? "primary" : "secondary";
                actionRequired = userIsManager;
            } else if (!selfDone && managerDone) {
                // The employee has not completed the form
                label = userIsEmployee ? "Awaiting your Response" : "Awaiting Employee";
                color = userIsEmployee ? "primary" : "secondary";
                actionRequired = userIsEmployee;
            }
        } else {
            // Only one party needs to complete the form
            if (!selfDoneOrNotNeeded) {
                // The employee is suppose to complete the form but hasn't
                label = userIsEmployee ? "Awaiting your Response" : "Awaiting Employee";
                color = userIsEmployee ? "primary" : "secondary";
                actionRequired = userIsEmployee;
            } else if (!managerDoneOrNotNeeded) {
                // The manager is suppose to complete the form but hasn't
                label = userIsManager ? "Awaiting your Response" : "Awaiting Manager";
                color = userIsManager ? "primary" : "secondary";
                actionRequired = userIsManager;
            }
        }
    }

    return { label, color, actionRequired };
}
