import { useRef } from "react";
import { Each } from "../../../../../../common/components";
import Button from "../../../../../../common/components/atoms/Button/Button";
import { useHighlander } from "../../../../../../common/hooks/useHighlander";
import { usePreserveOrder } from "../../../../../../common/hooks/usePreserveOrder";
import ObjectiveViewCard from "./ObjectiveViewCard";
import MyEmptyState from "../../../../../../components/MyComponents/EmptyState";

const EmptyGroup = ({ message = "No Objectives" }) => {
    return <MyEmptyState message={message} type="tasks" className="border rounded-lg flex-1" />;
};

const ObjectiveViewCardGroup = ({
    objectives,
    fetchStatus,
    onViewCascades,
    viewingComments,
    emptyStateMessage,
    onViewComments,
    hasMore,
    fetchMore,
}) => {
    const ordered = usePreserveOrder(objectives);
    const [highlander, toggleHighlander] = useHighlander();
    const endOfListRef = useRef(null);

    const handleFetchMore = () => {
        fetchMore();
        endOfListRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleToggle = (objective) => {
        if (viewingComments) return;
        toggleHighlander(objective?.id);
    };

    return (
        <div className="flex flex-col flex-1 justify-between space-y-8">
            <div className="flex-1 flex flex-col">
                <Each
                    of={ordered}
                    renderEmpty={() => <EmptyGroup message={emptyStateMessage} />}
                    render={(objective) => {
                        return (
                            <ObjectiveViewCard
                                fadeMe={highlander && highlander !== objective.id}
                                toggled={highlander === objective.id}
                                onToggle={handleToggle}
                                onViewCascades={onViewCascades}
                                onViewComments={onViewComments}
                                locked={true}
                                objective={objective}
                            />
                        );
                    }}
                />
            </div>
            <div ref={endOfListRef} className="flex justify-center">
                {!hasMore ? null : (
                    <Button onClick={handleFetchMore} disabled={fetchStatus === "loading" || !hasMore}>
                        {fetchStatus === "loading" ? "Loading..." : "Load More"}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ObjectiveViewCardGroup;
