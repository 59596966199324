import { useCallback, useState } from "react";

export function useToggle(initialVal = false) {
    const [value, setValue] = useState(initialVal);

    const toggle = useCallback((setToVal) => {
        setValue((prev) => {
            if (typeof setToVal === "boolean") {
                return setToVal;
            } else {
                return !prev;
            }
        });
    }, []);

    return [value, toggle];
}
