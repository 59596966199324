import { useMemo } from "react";
import { useBoardMapListener } from "./useBoardMapListener";
import { useEmployeeInfoListener } from "./useEmployeeInfoListener";
import { useParentMapListener } from "./useParentMapListener";
import { useRoleInfoListener } from "./useRoleInfoListener";
import { useEmployeePrepper } from "./useEmployeePrepper";

export function useBusinessUnitListeners() {
    const employeeStatus = useEmployeeInfoListener();
    const roleStatus = useRoleInfoListener();
    useParentMapListener();
    useBoardMapListener(employeeStatus, roleStatus);
    useEmployeePrepper();

    const allLoaded = useMemo(() => {
        const all = [employeeStatus, roleStatus];
        return all.every((status) => status === "success");
    }, [employeeStatus, roleStatus]);

    return allLoaded;
}
