import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../common/components";
import MyButton from "../components/MyComponents/Button";
import { setHasUnsavedEdits } from "../redux/appSlice";

const SideAreaFooter = ({ hasChanges, disableSave, onSubmit, saving, onDiscard }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (hasChanges) {
            dispatch(setHasUnsavedEdits(true));
        } else {
            dispatch(setHasUnsavedEdits(false));
        }
    }, [dispatch, hasChanges]);

    const handleSubmit = () => {
        dispatch(setHasUnsavedEdits(false));
        onSubmit();
    };

    return (
        <div className="p-4 border-t flex space-x-4 justify-end">
            <MyButton label="discard changes" color="warning" disabled={!hasChanges || saving} onClick={onDiscard}>
                Discard Changes
            </MyButton>
            <Button
                loading={saving}
                label="save"
                color="primary"
                disabled={!hasChanges || disableSave}
                onClick={handleSubmit}
            >
                Save
            </Button>
        </div>
    );
};

export default SideAreaFooter;
