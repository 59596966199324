import { countBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDepartmentTree } from "../../../Roles/hooks/useDepartmentTree";
import BlockersBarChart from "../../containers/charts/BlockersBarChart";
import CascadeSunburst from "../atoms/CascadeSunburst";

function getBlockersOnCascades(employeeSnapshots, cascadeParentId) {
    return Object.values(employeeSnapshots)
        .flatMap((employee) => employee?.objectives?.blockers || [])
        .filter((blocker) => blocker.pId === cascadeParentId)
        .map((blocker) => blocker.bId);
}

function getAverageRate(objectiveSnapshots, key) {
    if (objectiveSnapshots.length === 0) return null;
    const allValues = objectiveSnapshots.map((snapshot) => snapshot[key]).filter(Boolean);
    const average = allValues.length > 0 ? allValues.reduce((acc, val) => acc + val, 0) / allValues.length : null;
    return Math.round(average);
}

function getCascadeSunburstData(
    entry,
    dataKey = "confidence",
    employeeSnapshots,
    getRatingColor,
    getRole,
    getDepartmentMemberIds
) {
    const { id } = entry;
    const role = getRole(id);
    const memberIds = getDepartmentMemberIds(id);
    const memberObjectiveSnapshots = Object.entries(employeeSnapshots)
        .filter(([empId, _]) => memberIds.includes(empId))
        .map(([_, snapshot]) => snapshot?.objectives)
        .filter(Boolean);
    const value = getAverageRate(memberObjectiveSnapshots, dataKey);
    const defaultTooltip = dataKey === "confidence" ? "Confidence" : "Success Rate";
    const baseTooltip = role?.department || role?.jobTitle || defaultTooltip;
    const tooltip = `${baseTooltip}: (${value || "N/A"})`;
    const color = getRatingColor(value);
    return {
        label: "",
        tooltip,
        [dataKey]: value,
        color,
    };
}

const CascadeTemplate = ({
    objective,
    employeeSnapshots,
    getEmployee,
    getRole,
    getRatingColor,
    getDepartmentMemberIds,
}) => {
    const roles = useSelector((state) => state.workspace.roles);
    const { id: objectiveId, ownerEmployeeId } = objective;
    const objectiveOwner = getEmployee(ownerEmployeeId);
    const objectiveOwnerRoleId = objectiveOwner?.roleId;

    const handleGetExtraSunburstData = useCallback(
        (entry) => {
            return getCascadeSunburstData(
                entry,
                "confidence",
                employeeSnapshots,
                getRatingColor,
                getRole,
                getDepartmentMemberIds
            );
        },
        [employeeSnapshots, getRatingColor, getRole, getDepartmentMemberIds]
    );

    const { departmentTree } = useDepartmentTree(objectiveOwnerRoleId, roles, handleGetExtraSunburstData);

    const blockerGroups = useMemo(() => {
        const allInstances = getBlockersOnCascades(employeeSnapshots, objectiveId);
        return countBy(allInstances);
    }, [employeeSnapshots, objectiveId]);

    return (
        <div className="flex-1 flex flex-col">
            <h4 className="text-center mb-3">Cascade Confidence</h4>
            <div className="flex flex-col items-center space-y-2 px-12">
                <CascadeSunburst cascadeTree={departmentTree} />
            </div>
            <hr className="my-6" />
            <h4 className="text-center mb-3">Blockers</h4>
            <div className="flex flex-col space-y-4">
                <BlockersBarChart blockers={blockerGroups} showXLabels />
            </div>
        </div>
    );
};

export default CascadeTemplate;

//<BlockersBarChart liveBlockers={blockerGroups} showXLabels />
