import React from "react";
import MyCheckbox from "../../../../components/MyComponents/Checkbox";
import MyInputGroup from "../../../../components/MyComponents/InputGroup";
import MyTextInput from "../../../../components/MyComponents/TextInput";
import { CLAIM_OPTIONS, FIELD_SIZE_LIMITS, USER_ROLES } from "../../../../constants";
import CustomFieldDisplay from "../../../CustomFields/CustomFieldDisplay";
import UserRolePicker from "../../../User/components/atoms/UserRolePicker";

const EmployeeEditorTemplate = ({ editedEmployee, isAdmin, userCanCreateAdmins, customFields, updateField }) => {
    const {
        custom = {},
        firstname,
        surname,
        employeeNumber,
        email,
        userRole,
        customClaims: editedClaims = {},
    } = editedEmployee;

    const assigningAdmin = userRole === "admin";
    const fullRoleDetails = USER_ROLES[userRole];
    const roleInfo = fullRoleDetails && fullRoleDetails.tooltip;
    const claimList = Object.entries(editedClaims || {})
        .filter(([_, value]) => value)
        .map(([key, _]) => {
            return CLAIM_OPTIONS.find((claim) => claim.id === key);
        });

    const handleUpdateEmployee = (updateType, newVal) => {
        updateField(updateType, newVal);
    };

    const handleUpdateCustomField = (fieldId, value) => {
        updateField("custom", [fieldId, value]);
    };

    const handleChangeRole = (userRole) => {
        updateField("userRole", userRole);
    };

    const handleToggleClaim = (claimId) => {
        const currentVal = editedClaims[claimId] || false;
        updateField(claimId, !currentVal);
    };

    return (
        <div className="space-y-12 max-w-xl min-w-xl">
            <div className="space-y-3">
                <h3>Employee Details</h3>
                <hr />
                <MyInputGroup label="First Name" name="firstname" placeholder="required">
                    <MyTextInput
                        maxLength={FIELD_SIZE_LIMITS.FIRSTNAME}
                        onChange={handleUpdateEmployee}
                        value={firstname || ""}
                        autoComplete="off"
                    />
                </MyInputGroup>
                <MyInputGroup label="Surname" name="surname" placeholder="required">
                    <MyTextInput
                        maxLength={FIELD_SIZE_LIMITS.SURNAME}
                        onChange={handleUpdateEmployee}
                        value={surname || ""}
                        autoComplete="off"
                    />
                </MyInputGroup>
                <MyInputGroup label="Employee Number" name="employeeNumber" placeholder="optional">
                    <MyTextInput
                        maxLength={FIELD_SIZE_LIMITS.EMPLOYEE_NO}
                        readOnly={!isAdmin}
                        onChange={handleUpdateEmployee}
                        value={employeeNumber || ""}
                        autoComplete="off"
                    />
                </MyInputGroup>
                {customFields.map((field) => (
                    <MyInputGroup key={field.id} label={field.label} name={field.id}>
                        <CustomFieldDisplay
                            field={field}
                            value={custom?.[field.id]}
                            onChange={handleUpdateCustomField}
                        />
                    </MyInputGroup>
                ))}
            </div>
            <div className="space-y-3 px-1">
                <h3>User Access</h3>
                <p className="text-sm">
                    You can control this employee's access here. When you change an employee email address, they will
                    have to rejoin Talent Mapper, but all their data will be retained.
                </p>
                <hr />
                <MyInputGroup label="Email" name="email" placeholder="required">
                    <MyTextInput
                        type="email"
                        maxLength={FIELD_SIZE_LIMITS.EMAIL}
                        onChange={handleUpdateEmployee}
                        value={email || ""}
                        autoComplete="off"
                    />
                </MyInputGroup>
                <div className="flex flex-col border-t space-x-2 px-1 pb-40">
                    <div className="flex-1 space-y-4">
                        <div className="space-y-1 pt-2">
                            <UserRolePicker
                                selectedIds={userRole || "none"}
                                canCreateAdmins={userCanCreateAdmins || false}
                                onChange={handleChangeRole}
                            />
                        </div>
                        {assigningAdmin && (
                            <div className="space-y-2">
                                <div className="max-w-xs">
                                    <MyCheckbox
                                        size="sm"
                                        label="Can Create Admins"
                                        checked={editedClaims.canCreateAdmins || false}
                                        onChange={() => handleToggleClaim("canCreateAdmins")}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-2 py-2 space-y-1 text-sm">
                        {assigningAdmin && (
                            <h6 className="text-error">WARNING: Admins have full access to your workspace.</h6>
                        )}
                        {roleInfo && !assigningAdmin && (
                            <div className="space-x-1">
                                <span className="text-info">Role Info:</span>
                                <span>{roleInfo}</span>
                            </div>
                        )}
                        <ul className="space-y-2 bullets divide-y">
                            {claimList.map((claim) => (
                                <li className="py-2" key={`info-${claim.id}`}>
                                    {claim?.tooltip}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeEditorTemplate;
