import React, { Suspense, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import useLoggedNavigate from "./common/hooks/useLoggedNavigate";
import applogger from "./common/utils/applogger";
import { LoadingIndicator } from "./components";
import { buildDashboardLink } from "./features/Dashboard/utils/buildDashboardLink";
import Page404 from "./Page404";
import { CorePages, EmployeePages, OrgPages, RolePages, SchedulePages, SettingsPages, TalentPages } from "./pages";
import "./scss/style.scss";
import { useSessionContext } from "./Session";

const App = () => {
    const navigate = useLoggedNavigate();
    const { authStatus, userEmployeeId } = useSessionContext();
    const claimsAreReady = useSelector((state) => state.user.claimsAreReady);
    const dashboardLink = buildDashboardLink(userEmployeeId);

    useEffect(() => {
        switch (authStatus) {
            case "signed-out":
                applogger.info("user is signed out; navigated to sign-in request");
                navigate("/sign-in/request");
                break;
            default:
                break;
        }
    }, [navigate, authStatus]);

    const renderRoleRoutes = () => (
        <Route path="roles">
            <Route path=":roleId">
                <Route path="role-overview" element={<RolePages.RoleOverview />} />
                <Route path="talent-map" element={<RolePages.TalentMap />} />
            </Route>
        </Route>
    );

    const renderEmployeeRoutes = () => (
        <Route path="employees">
            <Route path=":employeeId">
                <Route path="objectives">
                    <Route index element={<EmployeePages.Objectives />} />
                </Route>
                <Route path="development-plan" element={<EmployeePages.DevelopmentPlan />} />
                <Route path="overview" element={<EmployeePages.Overview />} />
                <Route path="forms" element={<EmployeePages.Form />} />
                <Route path="comments" element={<EmployeePages.Comments />} />
                <Route path="history" element={<EmployeePages.ReviewHistory />} />
            </Route>
        </Route>
    );

    if (!claimsAreReady) return <LoadingIndicator fullscreen />;
    return (
        <Suspense fallback={<CorePages.Fallback />}>
            <Routes>
                <Route path="*" element={<CorePages.Workspace />} />
                <Route path="/" element={<CorePages.Workspace />}>
                    <Route index element={<Navigate to={dashboardLink} />} />
                    <Route path="dashboard" element={<CorePages.Dashboard />} />
                    <Route path="organisation" element={<OrgPages.Org />}>
                        <Route index element={<Navigate to="chart" />} />
                        <Route path="table" element={<OrgPages.OrgTable />} />
                        <Route path="chart" element={<OrgPages.OrgChart />} />
                        <Route path="map" element={<OrgPages.TalentMap />} />
                        <Route path="view" element={<OrgPages.OrgNode />}>
                            {renderRoleRoutes()}
                            {renderEmployeeRoutes()}
                        </Route>
                        <Route path="*" element={<Navigate to="chart" />} />
                    </Route>
                    <Route path="settings" element={<SettingsPages.Index />}>
                        <Route path="profile" element={<SettingsPages.Profile />} />
                        <Route path="upload-workspace" element={<SettingsPages.Upload />} />
                        <Route path="maintenance" element={<SettingsPages.Maintenance />} />
                        <Route path="workspace" element={<SettingsPages.Workspace />} />
                        <Route path="custom-fields" element={<SettingsPages.CustomFields />} />
                    </Route>
                    <Route path="business-units">
                        <Route index element={<CorePages.BusinessUnits />} />
                        <Route path=":unitId" element={<CorePages.BusinessUnits />} />
                    </Route>
                    <Route path="traits">
                        <Route index element={<TalentPages.Traits />} />
                    </Route>
                    <Route path="talentboards">
                        <Route index element={<TalentPages.TalentBoards />} />
                        <Route path=":boardId" element={<TalentPages.TalentBoard />}>
                            <Route path="bench" element={<TalentPages.BenchStrength />} />
                            <Route path="levels" element={<TalentPages.LevelConfig />} />
                            <Route path="settings" element={<TalentPages.BoardConfig />} />
                        </Route>
                    </Route>
                    <Route path="actions">
                        <Route index element={<TalentPages.Actions />} />
                        <Route path=":actionId" element={<TalentPages.Action />}>
                            <Route path="linked-traits" element={<TalentPages.ActionLinkedTraits />} />
                            <Route path="completed" element={<TalentPages.ActionCompleted />} />
                            <Route path="scheduled" element={<TalentPages.ActionScheduled />} />
                            <Route path="candidates" element={<TalentPages.ActionCandidates />} />
                            <Route path="config" element={<TalentPages.ActionConfig />} />
                        </Route>
                    </Route>
                    <Route path="talent-areas">
                        <Route index element={<TalentPages.TalentAreas />} />
                    </Route>
                    <Route path="schedule">
                        <Route index element={<SchedulePages.Schedule />} />
                    </Route>
                </Route>
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Suspense>
    );
};

export default App;
