
const ReviewQueueStats = ({ reviewCount, actionRequiredCount, waitingOthersCount, completeCount }) => {
    return (
        <div className="stats shadow flex bg-info bg-opacity-5">
            <div className="stat">
                <div className="stat-title">Total Active</div>
                <div className="stat-value">{reviewCount}</div>
            </div>
            <div className="stat">
                <div className="stat-title">Response Needed</div>
                <div className="stat-value">{actionRequiredCount}</div>
            </div>
            <div className="stat">
                <div className="stat-title">Waiting On Others</div>
                <div className="stat-value">{waitingOthersCount}</div>
            </div>
            <div className="stat">
                <div className="stat-title">Complete</div>
                <div className="stat-value">{completeCount}</div>
            </div>
        </div>
    );
};

export default ReviewQueueStats;
