import React from "react";
import { FormStep, IdPicker } from "../../../../../../components";
import MyInputGroup from "../../../../../../components/MyComponents/InputGroup";
import MyTextInput from "../../../../../../components/MyComponents/TextInput";
import { DEFAULT_ROLE_LEVELS, FIELD_SIZE_LIMITS } from "../../../../../../constants";
import TalentBoardPicker from "../../../../../TalentBoards/components/atoms/TalentBoardPicker";

const DEPARTMENT_INFO =
    "Department names are inherited from their parent role. All children of this role will have this department name unless you specify a different one.";

const IncumbentStep = (props) => {
    const { values, errors, touched, handleBlur, setFieldValue, setFieldTouched } = props;
    const handleValueChange = (field, newVal) => {
        setFieldValue(field, newVal);
    };

    return (
        <FormStep className="p-6 space-y-4">
            <MyInputGroup label="Job Title" name="jobTitle" errors={errors.jobTitle} placeholder="required">
                <MyTextInput
                    maxLength={FIELD_SIZE_LIMITS.JOB_TITLE}
                    invalid={errors.jobTitle && touched.jobTitle}
                    onChange={handleValueChange}
                    onBlur={handleBlur}
                    value={values.jobTitle || ""}
                    autoComplete="off"
                />
            </MyInputGroup>
            <MyInputGroup label="Department" name="department" errors={errors.department} description={DEPARTMENT_INFO}>
                <MyTextInput
                    maxLength={FIELD_SIZE_LIMITS.JOB_TITLE}
                    invalid={errors.department && touched.department}
                    onChange={handleValueChange}
                    onBlur={handleBlur}
                    value={values.department || ""}
                    autoComplete="off"
                />
            </MyInputGroup>
            <MyInputGroup label="Talent Boards" name="talentBoardIds">
                <TalentBoardPicker
                    isMulti
                    autoType="manual"
                    selectedIds={values.talentBoardIds || []}
                    onBlur={() => {
                        setFieldTouched("talentBoardIds", true);
                    }}
                    onChange={(selectedTalentBoardId) => {
                        setFieldValue("talentBoardIds", selectedTalentBoardId);
                    }}
                />
            </MyInputGroup>
            <MyInputGroup label="Bench Level" name="level" required>
                <IdPicker
                    options={DEFAULT_ROLE_LEVELS}
                    selectedIds={values.level}
                    onChange={(level) => handleValueChange("level", level)}
                />
            </MyInputGroup>
        </FormStep>
    );
};

export default IncumbentStep;
