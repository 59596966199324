import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    blocked: false,
    isJoining: false,
    claimsAreReady: false,
    activeWorkspaceId: null,
    activeBusinessUnitId: null,
    ownEmployeeDoc: {},
    ownSelfAssessment: {},
    ownObjectives: {},
    isDeveloper: false,
    isAdmin: false,
    isEditor: false,
    invites: [],
    workspaces: {},
    userData: null,
    workspaceIds: [],
    claims: {},
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetUser: () => initialState,
        setUserData: (state, action) => {
            const { claims, invites, ...userData } = action.payload;
            const { workspaceClaims: allWorkspaceClaims, ...restOfClaims } = claims || {};
            const workspaceClaims = allWorkspaceClaims?.[userData.activeWorkspaceId] || {};
            const cleanedClaims = { ...restOfClaims, ...workspaceClaims };
            const hasAdminRole = workspaceClaims?.userRole === "owner" || workspaceClaims?.userRole === "admin";
            const hasEditorRole = hasAdminRole || workspaceClaims?.userRole === "editor";
            let newState = {
                ...initialState,
                ...userData,
                blocked: userData?.blocked,
                allWorkspaceClaims: claims?.workspaceClaims,
                activeWorkspaceId: userData.activeWorkspaceId,
                activeBusinessUnitId: userData.activeBusinessUnitId,
                claims: cleanedClaims,
                invites: invites || [],
                userRoleId: workspaceClaims?.roleId,
                userEmployeeId: workspaceClaims?.employeeId,
                isAdmin: claims?.developer || hasAdminRole,
                isEditor: claims?.developer || hasEditorRole,
                isDeveloper: claims?.developer,
                claimsAreReady: !!workspaceClaims || claims.developer,
            };
            Object.assign(state, newState);
        },
        setOwnObjectives: (state, action) => {
            state.ownObjectives = action.payload;
        },
        setOwnEmloyeeDoc: (state, action) => {
            state.ownEmployeeDoc = action.payload;
        },
        setOwnSelfAssessment: (state, action) => {
            state.ownSelfAssessment = action.payload;
        },
    },
});

const { actions, reducer } = slice;

export const { resetUser, setUserData, setOwnObjectives, setOwnEmloyeeDoc, setOwnSelfAssessment } = actions;

export default reducer;
