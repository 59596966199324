import { withFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormStep, FormStepper } from "../../../../../components";
import MyInputGroup from "../../../../../components/MyComponents/InputGroup";
import { MOVE_ROLE } from "../../../../BusinessUnits/utils/businessUnitSlice";
import { useEmployees } from "../../../../Employees/hooks/useEmployees";
import { useRoles } from "../../../hooks/useRoles";
import { listChildRoleIds } from "../../../utils/roleHelpers";
import RolePicker from "../../atoms/RolePicker";

const MoveRoleOrEmployeeInnerForm = ({
    toggle,
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validationSchemas,
    status,
}) => {
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const { getRole } = useRoles();
    const { getEmployee } = useEmployees();
    const selectedRole = getRole(selectedRoleId);
    const selectedEmployee = getEmployee(selectedEmployeeId);
    const title = selectedEmployee && selectedRole.jobTitle;
    const subTitle = selectedEmployee ? selectedEmployee.displayName : selectedRole.jobTitle;

    return (
        <FormStepper
            isOpen={status.isOpen}
            toggle={toggle}
            onSubmit={handleSubmit}
            values={values}
            headerText="Move Role"
            isWaiting={status.isWaiting}
            validationSchemas={validationSchemas}
            height="lg"
            width="sm"
        >
            <FormStep title="Move To" className="py-4 px-6 space-y-4">
                <div>
                    <h6 className="text-xl font-bold mb-2">{title}</h6>
                    {subTitle && <p className="text-sm text-base-500">{subTitle}</p>}
                </div>
                <hr />
                <MyInputGroup label="Select New Parent" name="parentRoleId">
                    <RolePicker
                        selectedIds={values.parentRoleId || ""}
                        filterBasisRoleId={selectedRoleId}
                        onlyBranch={false}
                        excludeBranch
                        excludeSelf
                        excludeParent
                        onBlur={() => {
                            setFieldTouched("parentRoleId", true);
                        }}
                        maxMenuHeight={180}
                        onChange={(parentRoleId) => {
                            setFieldValue("parentRoleId", parentRoleId);
                        }}
                    />
                </MyInputGroup>
                <div className="text-sm tracking-tight space-y-2 px-2">
                    <h6 className="text-error">Important</h6>
                    <ul className="bullets space-y-1">
                        <li>
                            This Role and <span className="font-bold">all Roles beneath it</span> will move to the new
                            Parent.
                        </li>
                        <li>
                            If you only wish to move the Employee, Cancel this operation and Vacate the Role. You will
                            then be able to assign the Employee to their new Role.
                        </li>
                        <li>A Role cannot be moved to any Role that sits underneath it in the Org Chart.</li>
                    </ul>
                </div>
            </FormStep>
        </FormStepper>
    );
};

const MoveRoleOrEmployeeForm = withFormik({
    mapPropsToStatus: (props) => {
        return {
            isOpen: props.isOpen,
            childRoles: props.childRoles,
            hasChildren: props.hasChildren,
            isWaiting: props.isWaiting,
        };
    },
    mapPropsToValues: (props) => {
        return {};
    },
    validationSchema: (props) => {
        let mergedSchema = {};
        props.validationSchemas.forEach((schema) => {
            mergedSchema = { ...mergedSchema, ...schema };
        });
        return Yup.object().shape(mergedSchema);
    },
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
})(MoveRoleOrEmployeeInnerForm);

const MoveRoleOrEmployee = (props) => {
    const dispatch = useDispatch();
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const { moveRole } = useRoles();
    const [isWaiting, setIsWaiting] = useState(false);
    const childRoles = listChildRoleIds(parentMap, selectedRoleId);
    const hasChildren = childRoles.length > 0;
    const toggle = props.toggle;

    const validationSchemas = [
        {
            parentRoleId: Yup.string().required(),
        },
    ];

    const handleSubmit = async (values) => {
        if (values.parentRoleId) {
            setIsWaiting(true);
            try {
                await moveRole(selectedRoleId, values?.parentRoleId);
                dispatch({ type: MOVE_ROLE, payload: [selectedRoleId, values?.parentRoleId] });
                toggle();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <MoveRoleOrEmployeeForm
            roleIsVacant={!selectedEmployeeId}
            childRoles={childRoles}
            hasChildren={hasChildren}
            isWaiting={isWaiting}
            validationSchemas={validationSchemas}
            onSubmit={handleSubmit}
            {...props}
        />
    );
};

export default MoveRoleOrEmployee;
