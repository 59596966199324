import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LoadingIndicator } from "../../../../components";
import { SIDEBAR_WIDTH } from "../../../../constants";
import AppModals from "../../../../containers/AppModals";
import AppSideBar from "../../../../containers/AppSideBar";
import AppNavBar from "../../../AppNavBar/AppNavBar";

const WorkspaceTemplate = ({ fallback }) => (
    <div id="app-workspace" className="fixed inset-0 flex overflow-auto">
        <div className="flex w-full">
            <div className="shrink-0">
                <AppSideBar width={SIDEBAR_WIDTH} />
            </div>
            <div className="flex-1 flex flex-col">
                <div className="shrink-0">
                    <AppNavBar />
                </div>
                <div className="flex-1 overflow-auto flex" style={{ paddingLeft: SIDEBAR_WIDTH }}>
                    <div className="flex-1 flex flex-col overflow-auto">
                        {fallback ? <LoadingIndicator /> : <Outlet />}
                    </div>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={4000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </div>
        <AppModals />
    </div>
);

export default WorkspaceTemplate;
