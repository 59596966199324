import { useState } from "react";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../../components/MyComponents/Modal";
import ObjectiveSettingContent from "../../components/organisms/ObjectiveSetting/ObjectiveSettingContent";
import { Button } from "../../../../common/components";

const ObjectiveSettingModal = ({ isSelf, objective, hasChildren, isOpen, onToggle, onSubmit }) => {
    const [editedObjective, setEditedObjective] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const showCheckIn = isSelf;

    const handleSubmit = () => {
        onSubmit(editedObjective);
    }

    return (
        <MyModal isOpen={isOpen} onToggle={onToggle} onSubmit={onSubmit} width="lg" height="xl">
            <MyModalHeader title="Objective Setting" onToggle={onToggle}>
                Set New Objective
            </MyModalHeader>
            <MyModalBody>
                <ObjectiveSettingContent
                    isInSideArea
                    hasChildren={hasChildren}
                    objective={objective}
                    toggled={isOpen}
                    showCheckIn={showCheckIn}
                    onChange={setEditedObjective}
                    onValidation={setIsValid}
                />
            </MyModalBody>
            <MyModalFooter>
                <Button size="sm" variant="outline" onClick={onToggle}>
                    Discard
                </Button>
                <Button size="sm" color="primary" variant="outline" onClick={handleSubmit} disabled={!isValid}>
                    Save
                </Button>
            </MyModalFooter>
        </MyModal>
    );
};

export default ObjectiveSettingModal;
