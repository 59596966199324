import { useCallback, useMemo } from "react";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { formatName } from "../../../common/utils/stringUtils";
import { useListener } from "../../../firebase/hooks/useListener";
import { useEmployeeRefs } from "../refs/useEmployeeRefs";

export function useEmployeeListener(empId, onDataReceived, enabled) {
    const { employeeRef } = useEmployeeRefs(empId);

    const shouldListen = useMemo(() => {
        return enabled && !!empId && !!onDataReceived;
    }, [enabled, empId, onDataReceived]);

    const handleDataReceived = useCallback(
        (doc) => {
            let employeeData = doc.data();
            if (employeeData) {
                const employeeId = doc.id;
                const { talentAssessment, selfAssessment, ...rest } = employeeData;
                employeeData = ensureSerializable(rest);
                const displayName = formatName(rest);
                const prepped = { ...rest, id: employeeId, displayName };
                onDataReceived(prepped);
            } else {
                onDataReceived({});
            }
        },
        [onDataReceived]
    );

    return useListener(employeeRef, handleDataReceived, shouldListen);
}
