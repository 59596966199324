import { buildComponentName } from "../../../common/utils/buildComponentName";
import { buildReviewStatusValues } from "../utils/buildReviewStatusValues";

export const withReviewStatus = (Wrapped) => {
    const WithReviewStatus = ({ entry, userEmployeeId, onItemClick }) => {
        const { cycleName, employeeId, managerId, displayName } = entry;
        const userIsManager = userEmployeeId === managerId;
        const userIsEmployee = userEmployeeId === employeeId;

        const handleGetStatusValues = () => {
            return buildReviewStatusValues(userEmployeeId, entry);
        };

        const handleClickItem = () => {
            onItemClick(entry);
        };

        return (
            <Wrapped
                cycleName={cycleName}
                displayName={displayName}
                userIsManager={userIsManager}
                userIsEmployee={userIsEmployee}
                getStatusValues={handleGetStatusValues}
                onItemClick={handleClickItem}
            />
        );
    };

    // Add display name for debugging
    WithReviewStatus.displayName = buildComponentName("withReviewStatus", Wrapped);
    return WithReviewStatus;
};
