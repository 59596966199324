// Firebase stuff
import { getApp, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import applogger from "../common/utils/applogger";

const hostname = window.location.hostname;
const IS_DEV = process.env.NODE_ENV === "development";
const IS_TEST = process.env.NODE_ENV === "test";
const IS_LOCAL_HOST = hostname === "localhost";
const IS_LOCAL_NETWORK = hostname.includes("192.168");
const IS_PRODUCTION = hostname.includes("app.dothings.io");

applogger.info("ENV", process.env.NODE_ENV);

// Live production project
const prodConfig = {
    apiKey: "AIzaSyCk4UxmtzurxvYBUQ_DAbXd8s-BzqW43xI",
    authDomain: "dothings-production.firebaseapp.com",
    projectId: "dothings-production",
    storageBucket: "dothings-production.appspot.com",
    messagingSenderId: "284826806002",
    appId: "1:284826806002:web:fd6aeb50ca5d620b07526b",
};

// Test project
const stagingConfig = {
    apiKey: "AIzaSyD_yX1mksXDFvb-GHusTkoniCkX9bUvHR4",
    authDomain: "dothings-staging-app.firebaseapp.com",
    projectId: "dothings-staging-app",
    storageBucket: "dothings-staging-app.appspot.com",
    messagingSenderId: "700807384200",
    appId: "1:700807384200:web:26bca912c4d8fc1ed1633d",
};

export const connectedToEmulators = IS_DEV || IS_TEST || IS_LOCAL_HOST || IS_LOCAL_NETWORK;
//export const connectedToEmulators = false;
const liveConfig = IS_PRODUCTION ? prodConfig : stagingConfig;
const firebaseConfig = connectedToEmulators ? stagingConfig : liveConfig;
//const firebaseConfig = prodConfig;
export const projectId = firebaseConfig.projectId;

applogger.info("Firebase project", firebaseConfig.projectId);

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Initialize firebase instance
const app = getApp();
export const db = getFirestore();
export const functions = getFunctions(app, "europe-west2");
export const auth = getAuth();

// Use emulators if in Development
export const disableEmailsInDev = false;
export const disableEmails = connectedToEmulators && disableEmailsInDev;

if (connectedToEmulators) {
    applogger.info("Using empulators", `projectId: ${firebaseConfig.projectId}`);
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
} else {
    applogger.warn("Using production project");
}
