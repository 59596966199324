// Formats a date or firestore timestamp
export function formatDate(rawDate, showDay = true, showMonth = true, showYear = true) {
    if (!rawDate) return null;
    let date;
    if (rawDate instanceof Date) {
        date = rawDate;
    } else if (typeof rawDate === "object" && "seconds" in rawDate) {
        date = new Date(rawDate.seconds * 1000);
    } else if (typeof rawDate === "string") {
        date = new Date(rawDate);
    } else {
        return rawDate;
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (isNaN(day) || isNaN(year)) return rawDate;

    let formattedDate = "";
    if (showDay) formattedDate += `${day} `;
    if (showMonth) formattedDate += `${month} `;
    if (showYear) formattedDate += year;

    return formattedDate;
}

export function displayDate(date) {
    return date ? new Date(date).toLocaleDateString("en-GB") : null;
}

export function getMostRecentDate(dates) {
    // Filter out falsy values (e.g., null, undefined) and valid date strings or Date objects
    const validDates = dates.filter(Boolean);

    if (validDates.length === 0) {
        return null; // Return null or a fallback value if there are no valid dates
    }

    // Return the most recent date
    return new Date(Math.max(...validDates.map((date) => new Date(date))));
}

export function convertTimestampToDate(data) {
    if (!data) return null;
    if (data && typeof data === "object" && "seconds" in data) {
        // Convert Firestore timestamp to JavaScript Date
        return new Date(data.seconds * 1000);
    } else {
        try {
            const date = new Date(data);
            if (isNaN(date.getTime())) {
                return new Date(); // Return today's date if parsing fails
            }
            return date;
        } catch {
            return null;
        }
    }
}

export function convertDateToTimestamp(date) {
    const seconds = Math.floor(date.getTime() / 1000);
    const nanoseconds = (date.getTime() % 1000) * 1000000;
    return { seconds, nanoseconds };
}

export function firstDateIsBeforeSecondDate(firstDate, secondDate) {
    const first = convertTimestampToDate(firstDate);
    const second = convertTimestampToDate(secondDate);
    switch (true) {
        case !first && !!second:
            // the first date has no value but the second does; 2nd date is the most recent
            return true;
        case !!first && !second:
            // the second date has no value but the first does; 1st date is the most recent
            return false;
        case !first && !second:
            // neither date has an value; return false
            return false;
        default:
            return first < second;
    }
}

export function daysAgo(date) {
    const currentDate = new Date();
    const inputDate = convertTimestampToDate(date);
    const timeDiff = currentDate - inputDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return isNaN(daysAgo) ? 0 : daysAgo;
}

export function daysUntil(date) {
    const currentDate = new Date();
    const inputDate = convertTimestampToDate(date);
    const timeDiff = inputDate - currentDate;
    const daysUntil = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return isNaN(daysUntil) ? 0 : daysUntil;
}
