import { buildComponentName } from "../../../common/utils/buildComponentName";
import { useOrgContext } from "../../../pages/organisation/OrgPage";
import { withLiveTalentData } from "../../Workspace/hoc/withLiveTalentData";

export const withOrgContext = (Component) => {
    const WithOrgContext = (props) => {
        const contextProps = useOrgContext();
        return <Component {...props} {...contextProps} />;
    };
    WithOrgContext.displayName = buildComponentName("withOrgContext", Component);
    return withLiveTalentData(WithOrgContext);
};
