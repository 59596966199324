import { createContext, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useLoggedNavigate from "../../common/hooks/useLoggedNavigate";
import { LoadingIndicator } from "../../components";
import MyTabs from "../../components/MyComponents/Tabs";
import DevelopmentPlanTemplate from "../../features/Actions/components/templates/DevelopmentPlanTemplate";
import { useFeatureFlips } from "../../features/Config/hooks/useFeatureFlips";
import ActiveReviewsTemplate from "../../features/Dashboard/components/templates/ActiveReviewsTemplate";
import SubordinateReviewsTemplate from "../../features/Dashboard/components/templates/SubordinateReviewsTemplate";
import { withDashboardContext } from "../../features/Dashboard/hoc/withDashboardContext";
import { useEmployeeRefs } from "../../features/Employees/refs/useEmployeeRefs";
import { usePaginatedForms } from "../../features/Forms/hooks/usePaginatedForms";
import { buildFormLink } from "../../features/Forms/utils/buildFormLinks";
import InsightsTemplate from "../../features/Insights/components/templates/InsightsTemplate";
import ObjectivesTemplate from "../../features/Objectives/components/templates/ObjectivesTemplate";
import { useSelectOrgNodes } from "../../features/Organisation/hooks/useSelectOrgNodes";
import { useRoleRelationships } from "../../features/Roles/hooks/useRoleRelationships";
import { useScheduleApi } from "../../features/Schedule/hooks/useScheduleApi";
import { useEmployeeScheduler } from "../../features/ScheduledActions/hooks/useEmployeeScheduler";
import { useSnapshots } from "../../features/Snapshots/hooks/useSnapshots";
import TalentMapSubPageTemplate from "../../features/TalentMap/components/templates/TalentMapSubPageTemplate";
import { useClaims } from "../../features/User/hooks/useClaims";
import { useOwnData } from "../../features/User/hooks/useOwnData";
import { withWaitForWorkspace } from "../../features/Workspace/hoc/withWaitForWorkspace";
import { usePaginatedFetch } from "../../hooks/usePaginatedFetch";
import { useSubordinates } from "../../features/Employees/hooks/useSubordinates";
import { useFetched } from "../../hooks/useFetched";

const OBJECTIVES_PER_PAGE = 10;

const TABS = [
    { key: "active", label: "Your Active Reviews" },
    { key: "subordinates", label: "Active Subordinate Reviews" },
    { key: "objectives", label: "Your Objectives" },
    { key: "development", label: "Your Development Plan" },
    { key: "branch", label: "Your Branch", requiresChildren: true },
    { key: "map", label: "Talent Map", requiresChildren: true },
];

const DashboardContext = createContext();

export function useDashboardContext() {
    return useContext(DashboardContext);
}

const ActiveReviews = withDashboardContext(ActiveReviewsTemplate);
const Objectives = withDashboardContext(ObjectivesTemplate);
const SubordinateReviews = withDashboardContext(SubordinateReviewsTemplate);
const DevelopmentPlan = withDashboardContext(DevelopmentPlanTemplate);
const BranchPage = withDashboardContext(InsightsTemplate);
const TalentMap = withDashboardContext(TalentMapSubPageTemplate);

const DashboardPage = () => {
    const navigate = useLoggedNavigate();
    const location = useLocation();
    const { hideDevelopmentPlan } = useFeatureFlips();
    const { userEmployeeId, userRoleId } = useClaims();
    const { liveObjectives, activeEmployee } = useOwnData();
    const searchParams = new URLSearchParams(location.search);
    const currentTab = searchParams.get("tab") || "active";
    const { activeReviews, activeSelfReviews, activeTeamReviews } = useSelector((state) => state.workspace.reviews);
    const reviewsAreSubmitting = useSelector((state) => state.workspace.reviewsAreSubmitting);
    const { markEmployeesDone } = useScheduleApi();
    const { scheduleActions, completeActions, cancelActions } = useEmployeeScheduler(userEmployeeId);
    const { getChildRoleIds, getSubDepartments, getAllRoleIdsBelow } = useRoleRelationships();
    const { getFilteredSnapshots } = useSnapshots();
    const { getOrgNodeLink } = useSelectOrgNodes();
    const subordinates = useSubordinates(userRoleId, false);
    const [fetchTrait] = useFetched("traits")

    // Fetchers for objectives and forms
    const { completeObjectivesRef } = useEmployeeRefs(userEmployeeId);
    const reviewerId = userEmployeeId;
    const formFetcher = usePaginatedForms({ reviewerId, userEmployeeId, docsPerPage: 8 });
    const objectiveFetcher = usePaginatedFetch(completeObjectivesRef, OBJECTIVES_PER_PAGE);

    const childRoleIds = useMemo(() => {
        if (!userRoleId) return [];
        return getChildRoleIds(userRoleId) || [];
    }, [userRoleId, getChildRoleIds]);
    const hasChildren = useMemo(() => childRoleIds.length > 0, [childRoleIds]);

    const tabs = useMemo(() => {
        return TABS.filter((tab) => {
            if (tab.key === "development") return !hideDevelopmentPlan;
            return tab.requiresChildren ? hasChildren : true;
        });
    }, [hideDevelopmentPlan, hasChildren]);

    const subordinateReviews = useMemo(() => {
        return activeReviews.filter((review) => review.employeeId !== userEmployeeId);
    }, [activeReviews, userEmployeeId]);

    const navigateToOrgNode = (roleId, employeeId) => {
        const orgNodeLink = getOrgNodeLink(roleId, employeeId);
        if (orgNodeLink) {
            navigate(orgNodeLink);
        }
    };

    const handleChangeTab = (newTab) => {
        navigate(`?tab=${newTab}`);
    };

    const handleViewReview = (item) => {
        const { employeeId, cycleId, iterationId } = item;
        const link = buildFormLink(employeeId, cycleId, iterationId);
        navigate(link);
    };

    const value = {
        isSelf: true,
        isAdmin: false, // determines if the user can create objectives on their objective page
        activeEmployee,
        activeSelfReviews,
        activeTeamReviews,
        activeReviews,
        subordinateReviews,
        currentTab,
        employeePath: "dashboard",
        employeeId: userEmployeeId,
        roleId: userRoleId,
        userEmployeeId,
        liveObjectives,
        childRoleIds,
        hasChildren,
        objectiveFetcher,
        formFetcher,
        subordinates,
        fetchTrait,
        getChildRoleIds,
        getAllRoleIdsBelow,
        navigateToOrgNode,
        getSubDepartments,
        getFilteredSnapshots,
        onCompleteActions: completeActions,
        onScheduleActions: scheduleActions,
        onCancelActions: cancelActions,
        onViewReview: handleViewReview,
        onSaveObjective: () => {},
        onMarkEmployeesDone: markEmployeesDone,
        onChangeTab: handleChangeTab,
    };

    if (!activeEmployee) return <LoadingIndicator />;
    if (reviewsAreSubmitting) return <LoadingIndicator message="Submitting Forms..." />;

    return (
        <DashboardContext.Provider value={value}>
            <div className="py-4 px-8 flex flex-col h-full overflow-auto">
                <div className="flex justify-start">
                    <MyTabs variant="bordered" tabs={tabs} activeTabKey={currentTab} onChange={handleChangeTab} />
                </div>
                <div className="flex-1 flex flex-col bg-white rounded-tr-lg rounded-b-lg border overflow-auto">
                    <ActiveReviews tabId="active" currentTab={currentTab} className="p-8" />
                    <SubordinateReviews tabId="subordinates" currentTab={currentTab} className="p-8" />
                    <Objectives tabId="objectives" currentTab={currentTab} className="p-8" />
                    <DevelopmentPlan tabId="development" currentTab={currentTab} className="p-8" />
                    <BranchPage tabId="branch" currentTab={currentTab} className="p-0" />
                    <TalentMap tabId="map" currentTab={currentTab} className="p-4 flex-1" />
                </div>
            </div>
        </DashboardContext.Provider>
    );
};

export default withWaitForWorkspace(DashboardPage);
