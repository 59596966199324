import { useSelector } from "react-redux";

export function useLiveCycles() {
    const liveCycles = useSelector((state) => state.workspace.scheduledUpdates);

    function getLiveIterationId(cycleId) {
        const cycle = liveCycles[cycleId];
        return cycle?.currentIterationId;
    }

    return {
        liveCycles,
        getLiveIterationId,
    };
}
