import { useSelector } from "react-redux";
import { useAssessmentColors } from "../../Config/hooks/useAssessmentColors";
import { useEmployees } from "../../Employees/hooks/useEmployees";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import { useRoles } from "../../Roles/hooks/useRoles";
import CascadeTemplate from "../components/templates/CascadeTemplate";

const CascadesSideArea = ({ objective }) => {
    const employeeSnapshots = useSelector((state) => state.snapshots.employeeSnapshots);
    const { getEmployee } = useEmployees();
    const { getRole } = useRoles();
    const { getDepartmentMemberIds } = useRoleRelationships();
    const { getRatingColor } = useAssessmentColors();
    if (!objective) return null;
    return (
        <div className="h-full flex flex-1 flex-col items-center overflow-hidden">
            <div className="py-4 px-8 bg-base-150 w-full border-b">
                <h2>Cascade Overview</h2>
            </div>
            <div className="flex-1 flex flex-col overflow-scroll py-4 px-8 w-full">
                <CascadeTemplate
                    objective={objective}
                    employeeSnapshots={employeeSnapshots}
                    getEmployee={getEmployee}
                    getRole={getRole}
                    getRatingColor={getRatingColor}
                    getDepartmentMemberIds={getDepartmentMemberIds}
                />
            </div>
        </div>
    );
};

export default CascadesSideArea;
