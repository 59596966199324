import { createContext, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import applogger from "./common/utils/applogger";
import { UserMessage } from "./components";
import { setBusinessUnitId } from "./features/BusinessUnits/utils/businessUnitSlice";
import { useUserListener } from "./features/User/hooks/useUserListener";
import { auth } from "./firebase/firebase";
import SelectWorkspace from "./pages/sign-in/outlets/SelectWorkspace";
import { setUid } from "./redux/appSlice";
import SignInApp from "./SignInApp";

const SessionContext = createContext();
const timeout = 30 * 60 * 1000;

export function useSessionContext() {
    return useContext(SessionContext);
}

const Session = () => {
    const dispatch = useDispatch();
    const [isIdle, setIsIdle] = useState(false);
    const [authStatus, setAuthStatus] = useState("not-checked");
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { isDeveloper, invites, blocked, activeWorkspaceId, activeBusinessUnitId } = useSelector(
        (state) => state.user
    );
    const showSelectWorkspace = isDeveloper && !activeWorkspaceId;
    const userListenerStatus = useUserListener();
    const workspaceDisabled = useSelector((state) => state.workspace.disabled);

    useEffect(() => {
        if (userListenerStatus === "failed") {
            const timeout = setTimeout(() => {
                setAuthStatus("unauthorised");
            }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [userListenerStatus]);

    useEffect(() => {
        if (workspaceDisabled) {
            setAuthStatus("unauthorised");
        }
    }, [workspaceDisabled]);

    // eslint-disable-next-line
    const onIdle = () => {
        setIsIdle(true);
    };
    useIdleTimer({ onIdle, timeout });

    useEffect(() => {
        dispatch(setBusinessUnitId(activeBusinessUnitId || null));
    }, [dispatch, activeBusinessUnitId]);

    // Check the firebase auth state
    useEffect(() => {
        applogger.info("Checking auth state");
        // Listen to auth changes
        let unsubscribe;
        try {
            unsubscribe = auth.onAuthStateChanged(async (authUser) => {
                if (authUser) {
                    await authUser.getIdToken();
                    const { uid } = authUser;
                    dispatch(setUid(uid));
                    setAuthStatus("signed-in");
                } else {
                    dispatch(setUid(null));
                    setAuthStatus("signed-out");
                    applogger.info("no auth user");
                }
            });
        } catch (error) {
            applogger.error(error);
        }

        return () => {
            unsubscribe();
            dispatch(setUid(null));
            setAuthStatus("not-checked");
        };
    }, [dispatch]);

    useEffect(() => {
        if (blocked) {
            throw new Error("blocked");
        }
        if (authStatus === "unauthorised" || authStatus === "failed") {
            setAuthStatus("not-checked");
            throw new Error("unauthorised");
        }
    }, [blocked, authStatus]);

    const value = {
        authStatus,
        activeWorkspaceId,
        userEmployeeId,
        isDeveloper,
        invites,
        blocked,
    };

    if (authStatus === "not-checked") return null;
    if (isIdle) return <UserMessage title="You've been inactive for a while." message="Please refresh the page." />;
    if (showSelectWorkspace) return <SelectWorkspace title="Select a workspace" message="Please select a workspace." />;
    return (
        <SessionContext.Provider value={value}>
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/*" element={<App />} />
                    <Route path="sign-in/*" element={<SignInApp />} />
                </Routes>
            </BrowserRouter>
        </SessionContext.Provider>
    );
};

export default Session;
